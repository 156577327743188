import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';
import { Service } from './service';

const logoutService = service.getp<Service>(ServiceId.LOGOUT);
const logoutService$ = service.get<Service>(ServiceId.LOGOUT);

export default logoutService;

export function logout(): Promise<void> {
  return logoutService.logout();
}

export const listenForLogout = logoutService$.listenForLogout;
