import { UIIcon } from '@dropbox/dig-icons';
import { HideLine, ShowLine } from '@dropbox/dig-icons/assets';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import styles from './PrivacyToggle.module.css';

export const PrivacyToggle: React.FC = () => {
  const [privacyModeEnabled, setPrivacyModeEnabled] = useAtom(
    privacyModeEnabledAtom,
  );

  const togglePrivacyMode = () => {
    setPrivacyModeEnabled(!privacyModeEnabled);
  };

  return (
    <div className={styles.privacyToggleWrapper}>
      <IconButtonWithTooltip
        variant="filled"
        shape="circular"
        size="large"
        onClick={togglePrivacyMode}
        tooltipProps={{
          title: privacyModeEnabled
            ? i18n.t('disable_privacy_mode')
            : i18n.t('enable_privacy_mode'),
          placement: 'left-start',
        }}
        className={styles.privacyToggle}
      >
        <UIIcon src={privacyModeEnabled ? ShowLine : HideLine} />
      </IconButtonWithTooltip>
    </div>
  );
};
