import { List } from '@dropbox/dig-components/list';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
  CloseLine,
  DeleteLine,
  DragHandleLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { flyoutPanelViewAtom } from '@mirage/mosaics/FlyoutPanel/atoms';
import {
  clearLocalEventLog,
  getLocalEvents,
  subscribeToLocalEvents,
} from '@mirage/service-product-logging';
import { LocalPAPEvent } from '@mirage/service-product-logging/service';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import classnames from 'classnames';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import EventItem from './EventItem';
import styles from './EventLog.module.css';

const MIN_VIEWER_HEIGHT = 44; // Keep it at least a little bit visible
const MAX_VIEWER_HEIGHT = 800;

export const isAnalyticsViewerOpenAtom = atomWithStorage(
  'isAnalyticsViewerOpen',
  false,
);

const filterTextAtom = atomWithStorage('analyticsViewerFilterText', '');

export default function EventLog({ onClose }: { onClose: () => void }) {
  const [filter, setFilter] = useAtom(filterTextAtom);
  const [flyoutPanelView] = useAtom(flyoutPanelViewAtom);
  const [events, setEvents] = useState<LocalPAPEvent[]>([]);
  const [isDragging, setIsDragging] = useState(false);
  const [height, setHeight] = useState(300);
  const [startY, setStartY] = useState(0);
  const [minimized, setMinimized] = useState(false);

  const onFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    setFilter(value);
  };

  useEffect(() => {
    getLocalEvents().then(setEvents);
  }, []);

  useEffect(() => {
    const subscription = subscribeToLocalEvents((newEvents) => {
      setEvents(newEvents);
    });
    return () => subscription.unsubscribe();
  }, []);

  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      let filterText = filter;
      let negate = false;
      if (filterText.startsWith('!')) {
        filterText = filterText.slice(1);
        negate = true;
      }
      const match =
        `${event.action}.${event.object}`.includes(filterText) ||
        Object.values(event.properties || {}).some((value) => {
          return typeof value === 'string' && value.includes(filterText);
        }) ||
        filterText === '';
      return negate ? !match : match;
    });
  }, [events, filter]);

  useEffect(() => {
    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.userSelect = ''; // Re-enable text selection when not dragging
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        const currentY = e.clientY;
        const newHeight = height + (startY - currentY);
        if (newHeight > MIN_VIEWER_HEIGHT && newHeight < MAX_VIEWER_HEIGHT) {
          // Ensure the new height is within reasonable bounds
          setHeight(newHeight);
          setStartY(currentY); // Update startY to the current position
        }
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
      document.body.style.userSelect = 'none'; // Disable text selection during drag
    }
    return () => {
      if (isDragging) {
        window.removeEventListener('mousemove', handleMouseMove);
        window.removeEventListener('mouseup', handleMouseUp);
      }
    };
  }, [height, isDragging, startY]);

  return (
    <div
      className={classnames(styles.container, {
        [styles.minimized]: minimized,
        [styles.flyoutOpen]: flyoutPanelView !== 'closed',
      })}
      style={
        {
          '--event-log-container-height': `${height}px`,
          '--event-log-minimum-height': `${MIN_VIEWER_HEIGHT}px`,
        } as React.CSSProperties
      }
    >
      <div className={styles.header}>
        <Title className={styles.listTitle}>PAP Events</Title>
        <div className={styles.dragHandleContainer}>
          <UIIcon
            className={styles.dragHandle}
            onMouseDown={(e) => {
              setIsDragging(true);
              setStartY(e.clientY); // Capture the starting position of the mouse
            }}
            src={DragHandleLine}
          />
        </div>
        <div className={styles.headerActions}>
          {!minimized && (
            <IconButtonWithTooltip
              tooltipProps={{ title: 'Clear Events' }}
              variant="filled"
            >
              <UIIcon src={DeleteLine} onClick={() => clearLocalEventLog()} />
            </IconButtonWithTooltip>
          )}
          <IconButtonWithTooltip
            tooltipProps={{ title: minimized ? 'Expand' : 'Minimize' }}
            variant="filled"
          >
            <UIIcon
              src={!minimized ? ChevronDownLine : ChevronUpLine}
              onClick={() => setMinimized(!minimized)}
            />
          </IconButtonWithTooltip>
          <IconButtonWithTooltip
            tooltipProps={{ title: 'Close' }}
            variant="filled"
          >
            <UIIcon src={CloseLine} onClick={onClose} />
          </IconButtonWithTooltip>
        </div>
      </div>
      {events.length > 0 && (
        <div className={styles.filterContainer}>
          <TextInput
            placeholder="Filter Events"
            value={filter}
            onChange={onFilterChange}
          />
        </div>
      )}
      <List className={styles.listContainer}>
        {filteredEvents.map((event) => (
          <EventItem key={event.eventId} event={event} />
        ))}
      </List>
    </div>
  );
}
