import { Button } from '@dropbox/dig-components/buttons';
import { Select, TextInput } from '@dropbox/dig-components/text_fields';
import { Text, Title } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, EditLine } from '@dropbox/dig-icons/assets';
import { ComposeSources } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSources';
import { DeleteConfirmationModal } from '@mirage/mosaics/ComposeAssistant/components/settings/DeleteConfirmationModal';
import { useComposeSourcesCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { ChangeEvent, memo, useCallback, useEffect, useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import styles from './VoicesPane.module.css';

interface VoicePaneProps {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  deleteVoice: (voiceId: string) => void;
  initialVoiceID: string | undefined;
  onDone: () => void;
}
export const VoicesPane = memo(
  ({
    voices,
    saveVoice,
    deleteVoice,
    initialVoiceID,
    onDone,
  }: VoicePaneProps) => {
    const [selectedVoiceID, setSelectedVoiceID] = useState<string | undefined>(
      initialVoiceID,
    );
    const currentVoice =
      selectedVoiceID === undefined
        ? voices[0]
        : voices.find((voice) => voice.id === selectedVoiceID);

    // when current voice got deleted, reset to first voice
    useEffect(() => {
      if (currentVoice === undefined && voices.length > 0) {
        setSelectedVoiceID(voices[0]?.id);
      }
    }, [currentVoice, voices]);

    const handleDeleteVoice = useCallback(
      (voiceId: string) => {
        deleteVoice(voiceId);
      },
      [deleteVoice],
    );

    if (currentVoice === undefined) {
      return null;
    }
    return (
      <VoicesPaneContent
        voices={voices}
        saveVoice={saveVoice}
        voiceToModify={currentVoice}
        selectedVoiceID={selectedVoiceID}
        setSelectedVoiceID={setSelectedVoiceID}
        onDeleteVoice={handleDeleteVoice}
        onDone={onDone}
      />
    );
  },
);
VoicesPane.displayName = 'VoicesPane';

const NEW_VOICE_ID = 'NEW_VOICE';

interface VoicesPaneContentProps {
  voices: ComposeVoice[];
  saveVoice: (voice: ComposeVoice) => Promise<void>;
  voiceToModify: ComposeVoice;
  selectedVoiceID: string | undefined;
  setSelectedVoiceID: (value: string) => void;
  onDeleteVoice: (voiceId: string) => void;
  onDone: () => void;
}
export const VoicesPaneContent = memo(
  ({
    voices,
    saveVoice,
    voiceToModify,
    selectedVoiceID,
    setSelectedVoiceID,
    onDeleteVoice,
    onDone,
  }: VoicesPaneContentProps) => {
    const [currentVoice, setCurrentVoice] = useState(voiceToModify);
    const sourcesContents = useComposeSourcesCache(currentVoice.sources);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const handleSelectVoice = useCallback(
      (selectID: string) => {
        switch (selectID) {
          case NEW_VOICE_ID: {
            const voice: ComposeVoice = {
              id: uuid4(),
              name: i18n.t('compose_new_style'),
              sources: [],
            };
            saveVoice(voice)
              .then(() => {
                setSelectedVoiceID(voice.id);
                return;
              })
              .catch((e) => {
                throw e;
              });
            break;
          }
          default:
            setSelectedVoiceID(selectID);
            break;
        }
      },
      [saveVoice, setSelectedVoiceID],
    );
    const handleAddSource = useCallback((source: ComposeSource) => {
      setCurrentVoice((prevVoice) => {
        if (
          prevVoice.sources.some(
            (s) => getSourceUUID(s) === getSourceUUID(source),
          )
        ) {
          return prevVoice; // ignore if source already exists
        }
        return {
          ...prevVoice,
          sources: [...prevVoice.sources, source],
        };
      });
    }, []);
    const handleRemoveSource = useCallback((source: ComposeSource) => {
      setCurrentVoice((prevVoice) => {
        return {
          ...prevVoice,
          sources: prevVoice.sources.filter(
            (s) => getSourceUUID(s) !== getSourceUUID(source),
          ),
        };
      });
    }, []);
    const handleChangeVoiceNameInput = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        setCurrentVoice((prevVoice) => {
          return {
            ...prevVoice,
            name: e.target.value,
          };
        });
      },
      [],
    );
    const handleClickSave = useCallback(() => {
      saveVoice(currentVoice);
      onDone();
    }, [currentVoice, onDone, saveVoice]);
    const handleClickDelete = useCallback((e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDeleteModalOpen(true);
    }, []);

    useEffect(() => {
      setCurrentVoice(voiceToModify);
    }, [voiceToModify]);

    return (
      <>
        <div className={styles.VoicePaneContent}>
          <Title
            size="medium"
            className={styles.VoicePaneTitle}
            weightVariant="emphasized"
          >
            {i18n.t('compose_style_pane_title')}
          </Title>
          <Text
            size="medium"
            tagName="div"
            color="subtle"
            className={styles.VoicesPaneDescription}
          >
            {i18n.t('compose_style_pane_description')}
          </Text>
          <Text
            size="small"
            tagName="div"
            color="standard"
            isBold
            className={styles.VoicePaneLabel}
          >
            {i18n.t('compose_style_pane_select_style_label')}
          </Text>
          <div
            className={classNames(
              styles.VoicePaneControls,
              styles.VoicePaneControlsSmall,
            )}
          >
            <Select
              id="select_voice"
              value={selectedVoiceID}
              onChange={handleSelectVoice}
            >
              {voices.map((voice) => (
                <Select.Option value={voice.id} key={voice.id}>
                  {voice.name}
                </Select.Option>
              ))}
              <Select.Option
                value={NEW_VOICE_ID}
                key={NEW_VOICE_ID}
                withAccessory={<UIIcon src={AddLine} />}
              >
                {i18n.t('compose_context_form_create_style')}
              </Select.Option>
            </Select>
          </div>
          <Text
            size="small"
            tagName="div"
            color="standard"
            isBold
            className={styles.VoicePaneLabel}
          >
            {i18n.t('compose_style_pane_rename')}
          </Text>
          <div
            className={classNames(
              styles.VoicePaneControls,
              styles.VoicePaneControlsSmall,
            )}
          >
            <TextInput
              value={currentVoice.name}
              size="medium"
              withLeftAccessory={<UIIcon src={EditLine} />}
              onChange={handleChangeVoiceNameInput}
            />
          </div>
          <div className={styles.VoicePaneControls}>
            <ComposeSources
              sources={currentVoice.sources}
              sourcesContentCache={sourcesContents}
              onAddSource={handleAddSource}
              onRemoveSource={handleRemoveSource}
              variant="sources-before-search-input"
              hideEmptySources
              hasBorder
              hideSearchResultsLabel
              sourcesTitleLabel={
                <>
                  <Text
                    size="small"
                    tagName="div"
                    color="standard"
                    isBold
                    className={styles.VoicePaneLabel}
                  >
                    {i18n.t('compose_style_pane_references')}
                  </Text>
                </>
              }
              searchTitleLabel={
                <div
                  className={classNames(
                    styles.VoicePaneLabel,
                    styles.VoicePaneSearchResultsPadding,
                  )}
                >
                  <Text size="small" tagName="div" color="standard" isBold>
                    {i18n.t('compose_style_pane_references_description')}
                  </Text>
                  <Text size="small" tagName="div" color="subtle">
                    {i18n.t('compose_style_pane_references_subtitle')}
                  </Text>
                </div>
              }
            />
          </div>
        </div>
        <div className={styles.VoicePaneFooter}>
          <Button
            size="medium"
            variant="outline"
            tone="destructive"
            onClick={handleClickDelete}
          >
            <Text isBold color="error">
              {i18n.t('compose_style_pane_delete')}
            </Text>
          </Button>
          <div>
            <Text
              size="small"
              color="subtle"
              monospace
              className={styles.sourceCountLabel}
            >
              {i18n.t('compose_style_pane_sources_count', {
                count: currentVoice.sources.length,
              })}
            </Text>
            <Button
              size="medium"
              variant="primary"
              onClick={handleClickSave}
              disabled={areVoicesEqual(voiceToModify, currentVoice)}
            >
              {i18n.t('compose_style_pane_save')}
            </Button>
          </div>
        </div>
        {isDeleteModalOpen && (
          <DeleteVoiceConfirmationModal
            isOpen
            onCancel={() => setIsDeleteModalOpen(false)}
            onSubmit={() => {
              onDeleteVoice(currentVoice.id);
              setIsDeleteModalOpen(false);
            }}
          />
        )}
      </>
    );
  },
);
VoicesPaneContent.displayName = 'VoicesPaneContent';

function areVoicesEqual(voice1: ComposeVoice, voice2: ComposeVoice): boolean {
  const sourcesUUIDs1 = new Set(
    voice1.sources.map((source) => getSourceUUID(source)),
  );
  const sourcesUUIDs2 = new Set(
    voice2.sources.map((source) => getSourceUUID(source)),
  );
  return (
    voice1.id === voice2.id &&
    voice1.name === voice2.name &&
    sourcesUUIDs1.size === sourcesUUIDs2.size &&
    [...sourcesUUIDs1].every((uuid) => sourcesUUIDs2.has(uuid))
  );
}

interface DeleteVoiceConfirmationModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  isOpen: boolean;
}
export const DeleteVoiceConfirmationModal = memo(
  ({ onSubmit, onCancel, isOpen }: DeleteVoiceConfirmationModalProps) => {
    return (
      <DeleteConfirmationModal
        title={i18n.t('compose_delete_style_confirm_title')}
        description={i18n.t('compose_delete_style_confirm_description')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isOpen={isOpen}
      />
    );
  },
);
DeleteVoiceConfirmationModal.displayName = 'DeleteVoiceConfirmationModal';
