import { UIIcon } from '@dropbox/dig-icons';
import {
  GridViewLine,
  ListViewLine,
  OneClickNdaLine,
  UnorderedListLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackItemShortcutsAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import { FullScreenStackPreset, SortIconMenu } from '../../SortIconMenu';
import { AddItemButton } from './AddItemButton';
import { SearchInStackMenu } from './SearchInStack';
import styles from './Section.module.css';

interface SectionsHeaderProps {
  namespaceId: string;
  publicPreview?: boolean;
}

export const SectionsHeader = ({
  namespaceId,
  publicPreview = false,
}: SectionsHeaderProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const augustRevision = useStackPageAugustRevisionEnabled();
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const stack = useAtomValue(activeStackAtom);
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);
  const { sortPreference, setStackByIdSortPreference } =
    useStackByIdSortSettings(namespaceId);
  const { defaultSection } = useAtomValue(activeStackSectionsAtom);

  return (
    <TwoColumnGridCard
      settingId="stack_action_bar"
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: (
          <>
            <div className={styles.actionWrapper}>
              <div
                className={classnames(styles.actionGroup, {
                  [styles.actionGroupAugust]: augustRevision,
                })}
              >
                <SearchInStackMenu
                  // Force re-render when stack changes
                  key={stack?.namespace_id}
                  items={items}
                  onOpenLink={() => {
                    if (stack) {
                      reportPapEvent(
                        PAP_Open_DashLink({
                          ...stackDerivePAPProps(stack),
                          featureLine: 'stacks',
                        }),
                      );
                    }
                  }}
                />
                <SortIconMenu
                  augustRevision={augustRevision}
                  sortPreference={sortPreference}
                  onSortSelect={setStackByIdSortPreference}
                  optionsPreset={FullScreenStackPreset}
                  defaultOption={{
                    option: StackSortOption.SECTION,
                  }}
                />
              </div>
              {!augustRevision && (
                <>
                  <div className={styles.actionGroup}>
                    <IconButtonWithTooltip
                      tooltipProps={{
                        title: i18n.t('section_management_label'),
                      }}
                      variant="transparent"
                      disabled
                    >
                      <UIIcon src={UnorderedListLine} />
                    </IconButtonWithTooltip>
                  </div>
                  <div className={styles.actionGroup}>
                    <IconButtonWithTooltip
                      tooltipProps={{
                        title: i18n.t('density_option_list_label'),
                      }}
                      variant="opacity"
                      disabled
                    >
                      <UIIcon src={ListViewLine} />
                    </IconButtonWithTooltip>
                    <IconButtonWithTooltip
                      tooltipProps={{
                        title: i18n.t('density_option_grid_label'),
                      }}
                      variant="transparent"
                      disabled
                    >
                      <UIIcon src={GridViewLine} />
                    </IconButtonWithTooltip>
                  </div>
                  <div className={styles.actionGroup}>
                    <IconButtonWithTooltip
                      tooltipProps={{
                        title: i18n.t('multiselect_option_label'),
                      }}
                      variant="transparent"
                      disabled
                    >
                      <UIIcon src={OneClickNdaLine} />
                    </IconButtonWithTooltip>
                  </div>
                </>
              )}
            </div>
            {!augustRevision &&
              (publicPreview || (hasWritePermissions && defaultSection)) && (
                <AddItemButton
                  align="right"
                  variant="primary"
                  section={defaultSection ?? undefined}
                  // Emphasize add button when there are no stack items yet.
                  emphasize={!publicPreview && (!items || items.length === 0)}
                  publicPreview={publicPreview}
                  largeVersion
                />
              )}
          </>
        ),
      }}
      isAlwaysCollapsed={false}
      showDividerLine={false}
      className={classnames(styles.actionRow, {
        [styles.actionRowAugust]: augustRevision,
      })}
    />
  );
};
