import { dash, dash_feed, users } from '@dropbox/api-v2-client';
import { Trans } from '@mirage/translations';
import { ActivityLabelSubject } from '../ActivityLabel/ActivityLabelSubject';

type DocumentActivityLabelProps = {
  activity: dash_feed.ActivityItem;
  document: dash.SearchResult;
  currentAccount?: users.FullAccount;
};

type EditDocumentActivityLabelProps = {
  action: dash_feed.object_action_unionEdit;
} & DocumentActivityLabelProps;

export const DocumentActivityLabel = ({
  activity,
  document,
  currentAccount,
}: DocumentActivityLabelProps) => {
  const action = activity.action?.object_action;
  switch (action?.['.tag']) {
    case 'edit':
      return (
        <EditDocumentActivityLabel
          currentAccount={currentAccount}
          action={action}
          activity={activity}
          document={document}
        />
      );
    default:
      return null;
  }
};

export const EditDocumentActivityLabel = ({
  activity,
  currentAccount,
}: EditDocumentActivityLabelProps) => {
  if (activity.actor) {
    const isMe = activity.actor.email === currentAccount?.email;
    const actorName = activity.actor.name;
    // Naomi Triller modified
    return (
      <Trans
        i18nKey={
          isMe
            ? 'activity_feed_expanded_label_edit_document_me'
            : 'activity_feed_expanded_label_edit_document'
        }
        values={{ actorName }}
        components={{
          subject: <ActivityLabelSubject />,
        }}
      />
    );
  } else {
    // we don't always know who did the edit
    // Somone modified
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_edit_document_no_actor"
        components={{ subject: <ActivityLabelSubject /> }}
      />
    );
  }
};
