import { useConsolidatedSubscription } from '@mirage/shared/hooks/useConsolidatedSubscription';
import { atom, useAtom } from 'jotai';
import { getIsDfbUser } from '../eligibility';

const isDfbAtom = atom<boolean | undefined>(undefined);

// TODO: Move this to service-entitlements when that exists
export function useIsDfbUser() {
  const [isDfb, setIsDfb] = useAtom(isDfbAtom);

  useConsolidatedSubscription({
    hookName: 'useIsDfbUser',
    setState: setIsDfb,
    getInitialData: getIsDfbUser,
    subscribe: (_data) => () => {},
  });

  return isDfb;
}
