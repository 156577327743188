import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';

export const useIsCompanyStacksEnabled = () => {
  return convertFeatureValueToBool(
    useFeatureFlagValue(
      'dash_web_2024_10_02_show_company_pinned_stacks',
      false,
    ),
  );
};
