import { ActivityResult } from '@mirage/shared/feed/activity-result';
import { Recommendation } from '@mirage/shared/search/recommendation';
import { urlToEntityType } from '@mirage/shared/util/urlRulesUtil';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { RecentConnectorContent } from '@mirage/service-recent-content/types';

const GOOGLE_DRIVE_ENTITIES = ['gdoc', 'gsheet', 'gslide'] as const;
type GoogleDriveEntity = (typeof GOOGLE_DRIVE_ENTITIES)[number];

export const CALENDAR_CONNECTORS = ['googleCalendar', 'outlook'];

function getGoogleDriveEntityFromUrl(url: string) {
  const entityType = urlToEntityType(url) as GoogleDriveEntity;
  if (GOOGLE_DRIVE_ENTITIES.includes(entityType)) {
    return entityType;
  }
  return null;
}

export type EntityType = GoogleDriveEntity;

export function getEntityTypeFromResult(
  result:
    | SearchResult
    | Recommendation
    | RecentConnectorContent
    | SummarizableResult
    | ActivityResult,
): EntityType | null {
  // TODO: Remove this check when backend begins providing icon or subconnector information
  if (!result.url) {
    return null;
  }

  switch (result.connectorInfo?.connectorName) {
    case 'googledrive':
      return getGoogleDriveEntityFromUrl(result.url);
    default:
      return null;
  }
}
