import i18next, { t } from 'i18next';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next, Trans as I18NextTrans } from 'react-i18next';
import en from './langs/en-US';

import type {
  GetValidI18nKeys,
  TFunction,
  TransComponentType,
  TransObjShape,
} from '@mirage/translations/types';

export type I18nKey = GetValidI18nKeys<typeof en>;

function convertTranslations(translationObject: TransObjShape) {
  return Object.fromEntries(
    Object.entries(translationObject).map(([key, value]) => [
      key as I18nKey,
      value.message,
    ]),
  );
}

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  returnEmptyString: false,
  returnNull: false,
  keySeparator: false,
  resources: {
    en: {
      translation: convertTranslations(en),
    },
  },
});

const i18n = { ...i18next, t: t as TFunction<typeof en>, unchecked: t };

export default i18n;

export const Trans = I18NextTrans as TransComponentType<typeof en>;
