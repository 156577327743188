import { stacks } from '@dropbox/api-v2-client';
import { AvailableThemeOverridesStrict } from '@dropbox/dig-foundations';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';
import { useEffect, useState } from 'react';
import { getDefaultColorIndex, getTheme } from './utils';

export const useCurrentBackgroundTheme = (stack?: stacks.Stack | null) => {
  const pathname = getRoutePathname();
  const [theme, setTheme] = useState<AvailableThemeOverridesStrict | undefined>(
    undefined,
  );

  useEffect(() => {
    let colorIndex;
    if (pathname === '/stacks/new') {
      colorIndex = getDefaultColorIndex();
    }

    if (stack && stack.stack_data?.color_index !== undefined) {
      colorIndex = stack.stack_data?.color_index;
    }

    if (colorIndex !== undefined) {
      setTheme(getTheme(colorIndex).subtle);
    } else {
      setTheme(undefined);
    }
  }, [pathname, stack]);

  return theme;
};
