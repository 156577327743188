import { useIsMobileSize } from '@mirage/shared/responsive/mobile';
import {
  PersonObject,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import { isDesktopLocalFile } from '@mirage/shared/search/search-result';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { useResizeObserver } from '@react-hookz/web';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { getCalendarSubtitle } from '../util/resultUtil';
import { DefaultMetadata } from './DefaultMetadata';
import { LocalFileMetadata } from './LocalFileMetadata';
import { MetadataItem } from './MetadataItem';
import styles from './ResultSubtitle.module.css';

import type { SearchResult } from '@mirage/service-dbx-api';

type ResultSubtitleProps = {
  result: SearchResult;
  onClickPerson?: (person: PersonObject) => void;
  activeFilters?: SearchFilter[];
};

export const ResultSubtitle = ({
  result,
  onClickPerson,
  activeFilters,
}: ResultSubtitleProps) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const subtitleRef = useRef<HTMLDivElement>(null);
  const isMobileSize = useIsMobileSize();

  const [tooltipHover, setTooltipHover] = useState(false);
  const [metadataHover, setMetadataHover] = useState(false);

  const handleResize = () => {
    if (subtitleRef.current) {
      const { scrollWidth, clientWidth } = subtitleRef.current;
      setIsOverflowing(scrollWidth > clientWidth);
    }
  };
  useResizeObserver(subtitleRef, handleResize);

  const renderContent = () => {
    let metadataEl;
    if (result?.recordType?.['.tag'] === 'event') {
      metadataEl = <MetadataItem title={getCalendarSubtitle(result)} />;
    } else if (isDesktopLocalFile(result)) {
      metadataEl = <LocalFileMetadata result={result} />;
    } else {
      metadataEl = (
        <DefaultMetadata
          result={result}
          onClickPerson={onClickPerson}
          activeFilters={activeFilters}
        />
      );
    }

    return (
      <div
        className={classNames(styles.metadataContainer, {
          [styles.isMobile]: isMobileSize,
        })}
      >
        {metadataEl}
      </div>
    );
  };

  return (
    <>
      {isOverflowing && (
        <DigTooltip.Control
          open={tooltipHover || metadataHover}
          triggerRef={subtitleRef}
          placement="top"
          onMouseEnter={() => setTooltipHover(true)}
          onMouseLeave={() => setTooltipHover(false)}
          // Allows user to mvoe their mouse onto the tooltip without it disappearing
          style={{ background: 'transparent' }}
        >
          {renderContent()}
        </DigTooltip.Control>
      )}
      <div
        ref={subtitleRef}
        onMouseEnter={() => setMetadataHover(true)}
        onMouseLeave={() => setMetadataHover(false)}
        className={classNames(styles.container, {
          [styles.fadeout]: isOverflowing,
        })}
      >
        <>{renderContent()}</>
      </div>
    </>
  );
};
