import { v4 as uuidv4 } from 'uuid';

import type { RecentContent } from '../types';

// NOTE: CAUTION FOR EDITING
// This is mock data used specifically for OTC roadshow demos by Morgan/Umesh.
// Please check with them before making changes
export const mockRecentData: RecentContent[] = [
  {
    url: 'https://www.canva.com/',
    title: 'Canva: Visual Suite For Everyone',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://hbr.org/',
    title: 'Harvard Business Review - Ideas and Advice for Leaders',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.nytimes.com/',
    title: 'The New York Times - Breaking News, US News, World News and Videos',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.dropbox.com/home',
    title: 'Files - Dropbox',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.fastcompany.com/',
    title:
      'Fast Company | Business News, Innovation, Technology, Work Life and Design',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://ads.google.com/home/?pli=1',
    title: 'Google Ads - Get Customers and Sell More with Online Advertising',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://www.docsend.com/',
    title: 'Secure Document Sharing, eSignature & Analytics - Docsend',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
  {
    url: 'https://sign.dropbox.com/',
    title: 'Dropbox Sign eSignature Agreements -- Work Smarter -- Dropbox',
    visit_ms: 1630000000000,
    uuid: uuidv4(),
    connectorInfo: { type: 'browser_history' },
    connectionId: 'browser_history',
  },
];
