import { ml_capabilities } from '@dropbox/api-v2-client/types/dropbox_types';
import {
  ContentCacheLoaded,
  SourcesContentCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  getCachedSourceInfo,
  truncateContent,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-tools';
import { GetAssistantResponseParams } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import { tagged } from '@mirage/service-logging';
import {
  ComposeAssistantConversationMessage,
  getSourceUUID,
  isPreConfiguredVoiceID,
  PreConfiguredVoiceID,
} from '@mirage/shared/compose/compose-session';

const logger = tagged('ComposeAssistant/llm-prompts');

export function getInitialPromptMessage(
  params: GetAssistantResponseParams,
): ml_capabilities.ChatMessage {
  const writeDraftPrompt = getWriteDraftPrompt(params);
  return {
    '.tag': 'system_message',
    content: {
      text: `You are an AI assistant helping a user as they are drafting a new document.\n${writeDraftPrompt}}`,
    },
  };
}

function getWriteDraftPrompt(params: GetAssistantResponseParams) {
  if (params.mustGenerateDraft) {
    return 'Call the "write_doc" function with the content of the document to be written.';
  } else if (params.markdownContent.trim().length > 0) {
    return "If the user asks you to update the draft or suggest an edit, you must call the 'write_doc' function with the full content of the updated draft.";
  } else {
    return "When you are ready to begin a draft, call the 'write_doc' function with content of the draft.";
  }
}

export function getCurrentDraftPromptMessage(
  params: GetAssistantResponseParams,
): ml_capabilities.ChatMessage | undefined {
  if (params.markdownContent.trim().length > 0) {
    return {
      '.tag': 'system_message',
      content: {
        text: `Current content of the draft:\n${params.markdownContent}`,
      },
    };
  } else {
    return undefined;
  }
}

export function getSoucePromptMessage(
  sourcesContents: SourcesContentCache,
  onlyIncludeMetadata: boolean,
): {
  sourcePromptMessage: ml_capabilities.ChatMessage | undefined;
  indexedCachedSources: Map<number, ContentCacheLoaded>;
} {
  const sourcePromptMessageStrings: string[] = [];
  const indexedCachedSources = new Map<number, ContentCacheLoaded>();
  for (const sourceContent of Object.values(sourcesContents)) {
    if (sourceContent.state !== 'loaded') {
      logger.error(
        'unable to load source content',
        getSourceUUID(sourceContent.source),
      );
      continue;
    }
    const idx = sourcePromptMessageStrings.length + 1;
    indexedCachedSources.set(idx, sourceContent);
    const { title, url } = getCachedSourceInfo(sourceContent);
    let message = `Source: ${idx}
Title: ${title}
URL: ${url}`;
    if (!onlyIncludeMetadata) {
      message += `\nContent:
# START OF CONTENT OF SOURCE ${idx}

${truncateContent(sourceContent.content)}

# END OF CONTENT OF SOURCE ${idx}`;
    }
    sourcePromptMessageStrings.push(message);
  }

  if (sourcePromptMessageStrings.length > 0) {
    if (!onlyIncludeMetadata) {
      const text = `The user has also provided a number of sources that should be referenced by the draft.
Make sure to include links to the URLs provided when referencing information from these sources.
The sources are listed below:

${sourcePromptMessageStrings.join('\n\n')}`;
      return {
        sourcePromptMessage: {
          '.tag': 'system_message',
          content: {
            text,
          },
        },
        indexedCachedSources,
      };
    } else {
      const text = `The user has also provided a number of sources that may be useful in drafting the document.
You can access these sources by calling the 'read_source' function.
When referencing the sources, make sure to include links to the URLs provided.
The sources are listed below:

${sourcePromptMessageStrings.join('\n\n')}`;
      return {
        sourcePromptMessage: {
          '.tag': 'system_message',
          content: {
            text,
          },
        },
        indexedCachedSources,
      };
    }
  }
  return {
    sourcePromptMessage: undefined,
    indexedCachedSources,
  };
}

export function getVoicePromptMessage(
  voiceID: string,
  voiceSourceContents: SourcesContentCache,
): ml_capabilities.ChatMessage | undefined {
  const messageString = isPreConfiguredVoiceID(voiceID)
    ? getPreconfiguredVoicePromptMessageString(voiceID)
    : getCustomVoicePromptMessageString(voiceSourceContents);
  return messageString === undefined
    ? undefined
    : {
        '.tag': 'system_message',
        content: {
          text: messageString,
        },
      };
}

export function getCustomVoicePromptMessageString(
  voiceSourceContents: SourcesContentCache,
) {
  const voiceSourcesContentStrings: string[] = [];
  for (const cacheContent of Object.values(voiceSourceContents)) {
    if (cacheContent.state !== 'loaded') {
      logger.error(
        'unable to load source content',
        getSourceUUID(cacheContent.source),
      );
      continue;
    }
    voiceSourcesContentStrings.push(cacheContent.content);
  }
  if (voiceSourcesContentStrings.length > 0) {
    const voicePromptHeader =
      'When creating a draft, mimic the tone and word choices of the following documents:';
    const voiceSourcesString = voiceSourcesContentStrings
      .map(
        (s, i) =>
          `# VOICE REFERENCE DOCUMENT ${i + 1}:\n${truncateContent(s)}\n`,
      )
      .join('\n');
    return `\n${voicePromptHeader}
# START OF EXAMPLE DOCUMENTS TO MIMIC
${voiceSourcesString}
# END OF EXAMPLE DOCUMENTS TO MIMIC
`;
  }
  return undefined;
}

export function getPreconfiguredVoicePromptMessageString(
  preConfiguredVoiceID: PreConfiguredVoiceID,
): string {
  switch (preConfiguredVoiceID) {
    case 'preset_informative':
      return 'When writing a draft, create a detailed and straightforward explanation. Use clear and factual language, focusing on providing accurate information that is easy to understand. Include any necessary background information, key details, and relevant data points. Avoid opinions or persuasive language.';
    case 'preset_persuasive':
      return 'When writing a draft, write a compelling and persuasive piece. Use structured arguments and evidence to convince the reader of your viewpoint. Highlight the benefits and advantages, and include a strong call to action at the end. Use language that engages and motivates the reader to take action';
    case 'preset_instructional':
      return 'When writing a draft, create a step-by-step guide on the task or process. Use clear and concise language, and organize the content into numbered steps or bullet points. Include any necessary prerequisites and tips to avoid common mistakes. Make sure each step is easy to follow and actionable.';
    case 'preset_narrative':
      return 'When writing a draft, write a narrative piece about the event, experience, or topic. Start with a captivating introduction, develop the story with a personal or reflective tone, and conclude with a key message or lesson. Use storytelling techniques like vivid descriptions and emotional appeals to engage the reader.';
    case 'preset_informal':
      return 'When writing a draft, write as an informal message. Use a casual and conversational tone as if speaking directly to the reader. Keep the language light and approachable, and avoid technical jargon. You can use contractions and informal expressions to make the content feel friendly and relatable.';
    case 'preset_analytical':
      return 'When writing a draft, write as an analytical report. Use data and evidence to support your arguments, and structure the content logically with an introduction, main body, and conclusion. Include charts, graphs, or tables where necessary to visualize data. Focus on providing a fact-based and reasoned analysis.';
    default:
      preConfiguredVoiceID satisfies never;
      throw new Error(`Unknown voice type: ${preConfiguredVoiceID}`);
  }
}

export function getVoiceRewriteDraftPromptMessageString(
  voiceID: string,
  voiceSourceContents: SourcesContentCache,
) {
  return isPreConfiguredVoiceID(voiceID)
    ? getPreconfiguredVoiceRewriteDraftPromptMessageString(voiceID)
    : getCustomVoiceRewriteDraftPromptMessageString(voiceSourceContents);
}

export function getCustomVoiceRewriteDraftPromptMessageString(
  voiceSourceContents: SourcesContentCache,
) {
  const voiceSourcesContentStrings: string[] = [];
  for (const cacheContent of Object.values(voiceSourceContents)) {
    if (cacheContent.state !== 'loaded') {
      logger.error(
        'unable to load source content',
        getSourceUUID(cacheContent.source),
      );
      continue;
    }
    voiceSourcesContentStrings.push(cacheContent.content);
  }
  if (voiceSourcesContentStrings.length > 0) {
    const voicePromptHeader =
      'Rerwrite draft by mimicing the tone and word choices of the following documents:';
    const voiceSourcesString = voiceSourcesContentStrings
      .map(
        (s, i) =>
          `# VOICE REFERENCE DOCUMENT ${i + 1}:\n${truncateContent(s)}\n`,
      )
      .join('\n');
    return `\n${voicePromptHeader}
  # START OF EXAMPLE DOCUMENTS TO MIMIC
  ${voiceSourcesString}
  # END OF EXAMPLE DOCUMENTS TO MIMIC
  `;
  }
  return undefined;
}

export function getPreconfiguredVoiceRewriteDraftPromptMessageString(
  preConfiguredVoiceID: PreConfiguredVoiceID,
) {
  switch (preConfiguredVoiceID) {
    case 'preset_informative':
      return 'Rewrite draft to make it more informative and factual. Ensure that the language is clear and the information is presented logically without any subjective opinions.';
    case 'preset_persuasive':
      return 'Rewrite draft to make it more persuasive. Use strong, convincing language, add supporting evidence, and conclude with a clear call to action.';
    case 'preset_instructional':
      return 'Rewrite draft to make it more instructional. Break it down into clear steps or bullet points, using concise language that makes it easy to follow.';
    case 'preset_narrative':
      return 'Rewrite draft to make it more narrative and engaging. Use storytelling elements, include personal or reflective insights, and create a smooth flow from beginning to end.';
    case 'preset_informal':
      return 'Rewrite draft to make it more informal and conversational. Use casual language, contractions, and a friendly tone.';
    case 'preset_analytical':
      return 'Rewrite draft to make it more analytical. Include relevant data, structure the content logically, and use a formal tone to present a reasoned argument.';
    default:
      preConfiguredVoiceID satisfies never;
      throw new Error(`Unknown voice type: ${preConfiguredVoiceID}`);
  }
}

export function messageToPromptMessage(
  message: ComposeAssistantConversationMessage,
): ml_capabilities.ChatMessage | undefined {
  switch (message.type) {
    case 'instruction':
      return {
        '.tag': 'system_message',
        content: {
          text:
            message.title + (message.subtitle ? '\n' + message.subtitle : ''),
        },
      };
    case 'message': {
      if (message.ignoreMessageForPrompt) {
        return undefined;
      }
      return {
        '.tag': getPromptMessageTagForRole(message.role),
        content: {
          text: message.rawPromptText || message.text,
        },
      };
    }
    default:
      message satisfies never;
      throw new Error(`Unknown message type: ${message}`);
  }
}

function getPromptMessageTagForRole(role: 'user' | 'assistant' | 'system') {
  switch (role) {
    case 'user':
      return 'user_message';
    case 'assistant':
      return 'assistant_message';
    case 'system':
      return 'system_message';
    default:
      role satisfies never;
      throw new Error(`Unknown role: ${role}`);
  }
}
