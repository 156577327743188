import { Text } from '@dropbox/dig-components/typography';
import { PrettyShortcuts } from '@mirage/shared/keyboard-shortcuts/PrettyShortcuts';
import i18n from '@mirage/translations';
import styles from './TypeaheadShortcutBanner.module.css';

type TypeaheadKeyboardShortcutBannerProps = {
  isDarwin: boolean;
  showAddToStackShortcut?: boolean;
  showSummarizeShortcut?: boolean;
};

export const TypeaheadKeyboardShortcutBanner = ({
  isDarwin,
  showAddToStackShortcut = false,
  showSummarizeShortcut = false,
}: TypeaheadKeyboardShortcutBannerProps) => (
  <div className={styles.container}>
    {showSummarizeShortcut && (
      <div className={styles.shortcut}>
        <PrettyShortcuts
          shortcut={[isDarwin ? '⌘' : 'CTRL', 'S']}
          isDarwin={isDarwin}
          editable={false}
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('summarize_action')}
        </Text>
      </div>
    )}
    {showAddToStackShortcut && (
      <div className={styles.shortcut}>
        <PrettyShortcuts
          shortcut={[isDarwin ? '⌘' : 'CTRL', 'G']}
          isDarwin={isDarwin}
          editable={false}
        />
        <Text size="small" monospace className={styles.text}>
          {i18n.t('add_to_stack')}
        </Text>
      </div>
    )}
    <div className={styles.shortcut}>
      <PrettyShortcuts
        shortcut={[isDarwin ? '⌘' : 'CTRL', 'C']}
        editable={false}
        isDarwin={isDarwin}
      />
      <Text size="small" monospace className={styles.text}>
        {i18n.t('copy_link')}
      </Text>
    </div>
    <div className={styles.shortcut}>
      <PrettyShortcuts
        shortcut={['/new']}
        isDarwin={isDarwin}
        editable={false}
        casingOverride="lowercase"
      />
      <Text size="small" monospace className={styles.text}>
        {i18n.t('typeahead_shortcut_create')}
      </Text>
    </div>
  </div>
);
