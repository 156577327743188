import { tagged } from '@mirage/service-logging';
import { defaultSettings } from '@mirage/service-settings/service';
import { AvatarSettings } from '@mirage/service-settings/service/types';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';

import type { KeyboardEvent } from 'react';

const logger = tagged('keyboard-shortcuts/hooks');

export enum GlobalShortcutActions {
  AppShortcut = 'appShortcut',
}

export type ShortcutActions = GlobalShortcutActions;

// TODO - Mapping of GlobalShortcutActions to Amplitude logging types
export const shortcutMapType: {
  [key in ShortcutActions]: KeyboardShortcutType;
} = {
  [GlobalShortcutActions.AppShortcut]: 'appShortcut',
};

export type KeyboardShortcutType = 'appShortcut';

const ERROR_TIMEOUT = 3 * 1000;
const MAX_KEYSTROKES = 4;
const RESERVED_KEYS = new Set(['Escape', 'Tab']);

// Maps react's synthetic event.key to electron's modifier keys
// https://www.electronjs.org/docs/api/accelerator
const ModifierKeys = new Set([
  'Command',
  'Alt',
  'AltGr',
  'Option',
  'Meta',
  'Shift',
  'Super',
  'Control',
  'CommandOrControl',
]);

// Some of the exports in this file are used by Unified Desktop (rPOLLUX) and should not be removed
export const KEYCODE_MAP: Record<string, string> = {
  106: '*',
  107: '+',
  109: '-',
  110: '.',
  111: '/',
  186: ';',
  187: '=',
  188: ',',
  189: '-',
  190: '.',
  191: '/',
  192: '`',
  219: '[',
  220: '\\',
  221: ']',
  222: "'",
};

const REMAPPED_VALUES = new Map([[' ', 'Space']]);

// Sorts short cut keys so Super is first, then any other modifier, then everything else (letters etc.)
export const keyStrokeSorter = (a: string, b: string) => {
  // setting Supper (windows key) to the front
  if (a === 'Super') {
    return -1;
  }
  if (b === 'Super') {
    return 1;
  }
  const aIsModifier = ModifierKeys.has(a);
  const bIsModifier = ModifierKeys.has(b);
  if (aIsModifier && !bIsModifier) {
    return -1;
  } else if (!aIsModifier && bIsModifier) {
    return 1;
  } else if (aIsModifier && bIsModifier) {
    return a.localeCompare(b);
  } else {
    return a.localeCompare(b);
  }
};

export const sortShortcutString = (shortcutString: string) =>
  shortcutString.split('+').sort(keyStrokeSorter).join('+');

export const hasModifier = (hotKeysArray: string[]) => {
  return hotKeysArray.some((k) => ModifierKeys.has(k));
};

export const hasNonModifier = (hotKeysArray: string[]) => {
  return hotKeysArray.some((k) => !ModifierKeys.has(k));
};

export const hasOnlyOneNonModifier = (hotKeysArray: string[]) => {
  const nonModifiers = hotKeysArray.filter((k) => !ModifierKeys.has(k));
  return nonModifiers.length < 2;
};

/*
 ************** Hook *****************
 */

export function useKeyboardShortcut(
  platform: string,
  settingId: GlobalShortcutActions,
  settings: AvatarSettings,
  setSetting: (
    settingName: keyof AvatarSettings,
    value?: number | string,
  ) => void,
  setEditing: (toggle: boolean) => void,
) {
  let registeredKeybinding = '';

  useEffect(() => {
    registeredKeybinding = settings[settingId];
  }, [settingId, settings]);

  const [recordingBuffer, setRecordingBuffer] = useState<string[]>(
    registeredKeybinding?.length ? registeredKeybinding.split('+') : [],
  );

  const [validKeyboardShortcut, setValidKeyboardShortcut] =
    useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [previousRegistered, setPreviousRegistered] = useState(
    registeredKeybinding.length ? registeredKeybinding.split('+') : [],
  );
  logger.debug(`previousRegistered`, previousRegistered);
  useEffect(() => {
    setPreviousRegistered(
      typeof registeredKeybinding === 'string' && registeredKeybinding.length
        ? registeredKeybinding.split('+')
        : [],
    );
  }, [registeredKeybinding]);

  useEffect(() => {
    if (registeredKeybinding && errorMessage) {
      logger.warn('registeredKeybinding error', errorMessage);
    }
  }, [errorMessage]);

  const maybeSetKBShortcut = useCallback(() => {
    if (!hasModifier(recordingBuffer)) {
      setErrorMessage(i18n.t('settings_kb_error_no_valid_modifier'));
      return;
    }

    if (!hasNonModifier(recordingBuffer)) {
      setErrorMessage(i18n.t('settings_kb_error_no_valid_char'));
      return;
    }

    if (errorMessage !== '') {
      logger.debug(`Not setting ${settingId}, error: ${errorMessage}`);
      return;
    }

    const newKeybinding = recordingBuffer
      .map((value) => REMAPPED_VALUES.get(value) ?? value)
      .join('+');
    if (newKeybinding === registeredKeybinding) {
      logger.debug(`Not setting ${settingId}, No change`);
      return;
    }

    if (RESERVED_KEYS.has(newKeybinding)) {
      logger.debug(`Not setting ${settingId}, has reserved keys`);
      setErrorMessage(i18n.t('settings_kb_error_reserved'));
      return;
    }
    logger.info(`setting ${settingId} to ${newKeybinding}`);
    setSetting(settingId, newKeybinding);
    setEditing(false);
  }, [errorMessage, recordingBuffer, registeredKeybinding, setErrorMessage]);

  // Clear error message if current shortcut is valid
  useEffect(() => {
    setValidKeyboardShortcut(
      hasModifier(recordingBuffer) && hasNonModifier(recordingBuffer),
    );

    const timer = setTimeout(() => {
      if (
        errorMessage &&
        hasModifier(recordingBuffer) &&
        hasNonModifier(recordingBuffer)
      ) {
        setErrorMessage('');
      }

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, ERROR_TIMEOUT);
  }, [errorMessage, recordingBuffer]);

  /*
   ****** keyDownHandler Event Handlers *******
   */

  const keyDownHandler = (e: KeyboardEvent) => {
    logger.debug('keyDownHandler keydown', e.key);
    if (e.key === 'Escape') {
      cancelKBShortcut();
      return;
    }

    if (e.key === 'Tab') {
      // Tab used for navigation
      logger.debug('returning early for Tab');
      return;
    }

    if (e.key === 'Enter') {
      // Enter sets the shortcut
      logger.debug('Enter pressed');
      return;
    }

    if (recordingBuffer.length === MAX_KEYSTROKES) {
      // Dont allow too many shortcuts
      setErrorMessage(
        i18n.t('cannot_have_more_than_max_keystrokes_keystrokes', {
          MAX_KEYSTROKES,
        }),
      );
      logger.debug('returning early MAX_KEYSTROKES');
      return;
    }

    // Normalize certain keys
    let keyStroke = e.key;
    if (KEYCODE_MAP[e.keyCode]) {
      keyStroke = KEYCODE_MAP[e.keyCode];
      logger.debug('keyDownHandler KEYCODE_MAP[e.keyCode]: true');
    } else if (e.keyCode >= 48 && e.keyCode <= 90) {
      logger.debug('keyDownHandler e.keyCode >= 48 && e.keyCode <= 90: true');
      keyStroke = String.fromCharCode(e.keyCode);
    } else if (e.key === 'Meta') {
      keyStroke = platform === 'darwin' ? 'Command' : 'Control';
    }

    if (recordingBuffer.includes(keyStroke)) {
      // Dont allow duplicate keys, e.g "A"+"A"
      // no error, fail silently
      logger.debug('returning early duplicate keystrokes');
      return;
    }

    const newRecordingBuffer = [...recordingBuffer, keyStroke].sort(
      keyStrokeSorter,
    );

    if (!hasOnlyOneNonModifier(newRecordingBuffer)) {
      // Dont allow multiple non modifiers, e.g "A"+"B"
      setErrorMessage(i18n.t('settings_kb_error_multi_letter'));
      logger.debug('returning early only one modifier');
      return; // Only one non-modifier key is allowed
    }
    setRecordingBuffer(newRecordingBuffer);
  };

  const setKBShortcut = () => {
    // set to current or set to previous
    logger.debug('setKBShortcut');
    if (!recordingBuffer.length && previousRegistered.length) {
      setRecordingBuffer(previousRegistered);
    }
    maybeSetKBShortcut();
  };

  const editKBShortcut = () => {
    logger.debug('hooks onFocus now');
    setRecordingBuffer([]);
  };

  const resetKBShortcut = () => {
    logger.debug(`setting to default ${defaultSettings[settingId]}`);
    // TODO @cdunavan_dbx find a way to get env all the way to here
    setSetting(settingId, `CommandOrControl+E`);
    setEditing(false);
  };

  const cancelKBShortcut = () => {
    setRecordingBuffer([]);
    setErrorMessage('');
    setEditing(false);
    logger.debug('cancelKBShortcut recordingBuffer', recordingBuffer);
    logger.debug('cancelKBShortcut previousRegistered', previousRegistered);
  };

  return {
    recordingBuffer,
    errorMessage,
    keyDownHandler,
    cancelKBShortcut,
    editKBShortcut,
    setKBShortcut,
    resetKBShortcut,
    validKeyboardShortcut,
  };
}
