import { StackFilterOption } from '@mirage/service-settings/service/types';
import { atom, useAtom } from 'jotai';

const filterPreferenceOnStartPageAtom = atom<StackFilterOption>(
  StackFilterOption.ALL,
);

// hook for maintaining the filter state of "Stacks" in the Tab Panel on the Start Page; not to be confused with
// preference maintained in the stacks page
export const useStacksTabFilterPreference = () => {
  const [startPageStackFilterPreference, setStartPageStackFilterPreference] =
    useAtom(filterPreferenceOnStartPageAtom);

  // Improvement (AYC): persist these settings
  // see example with useStackpagePreferences; service-settings/useSettings

  return {
    startPageStackFilterPreference,
    setStartPageStackFilterPreference,
  };
};
