import * as dbx from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import {
  LinkSummaryError,
  LinkSummarySuccess,
  Question,
  QuestionAnswer,
} from '@mirage/shared/stack-item-summary/types';
import i18n from '@mirage/translations';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const logger = tagged('linkSummarizer');

export function useLinkSummary(url: string) {
  const [summary, setSummary] = useState<
    LinkSummarySuccess | LinkSummaryError | undefined
  >(undefined);
  const [isRefreshed, setIsRefreshed] = useState(false);

  useEffect(() => {
    setSummary(undefined); // Reset summary state on URL change or refresh
    const subscription = dbx.grpc.getLinkSummary(url, isRefreshed).subscribe({
      next: setSummary,
      error: (error: Error) => {
        setSummary({
          type: 'error',
          description: i18n.t('stack_item_qna_summary_unexpected_error'),
        });
        logger.error('Cannot fetch link summary', error);
      },
    });

    return () => subscription.unsubscribe();
  }, [url, isRefreshed]);

  const refreshSummary = useCallback(() => {
    setIsRefreshed(!isRefreshed);
  }, [isRefreshed]);

  return { summary, refreshSummary };
}

export function useQuestionHistory(
  summary: LinkSummarySuccess | LinkSummaryError | undefined,
  url: string,
) {
  const [questionHistory, setQuestionHistory] = useState<QuestionAnswer[]>([]);

  const onSubmitQuestion = useCallback(
    async (question: Question) => {
      const questionId = uuidv4();
      setQuestionHistory((prev) => [
        ...prev,
        { question, answer: { text: '' }, id: questionId },
      ]);

      if (summary && summary.type === 'success') {
        dbx.grpc.getAnswerForQuestion(summary, question, questionId).subscribe({
          next: (questionAnswer) => {
            setQuestionHistory((prev) =>
              prev.map((qa) =>
                qa.id === questionId
                  ? { ...qa, answer: questionAnswer.answer }
                  : qa,
              ),
            );
          },
          error: (error: Error) => {
            setQuestionHistory((prev) =>
              prev.map((qa) =>
                qa.id === questionId
                  ? {
                      ...qa,
                      answer: {
                        text: i18n.t('stack_item_qna_summary_unexpected_error'),
                        timestamp: Date.now(),
                      },
                    }
                  : qa,
              ),
            );
            logger.error('Cannot fetch answer', error);
          },
        });
      }
    },
    [summary, url],
  );

  return { questionAnswers: questionHistory, onSubmitQuestion };
}
