import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';

import type { SearchResult } from '@mirage/service-dbx-api';

export const enum Role {
  User = 'user',
  System = 'system',
  Error = 'error',
  Empty = 'empty',
}

export type ConversationMessage = {
  content: string | JSX.Element;
  role: Role;
  sources?: SearchResult[];
};

export const enum ChatEntryPoint {
  tabs = 'tabs',
  tabs_menu = 'tabs_menu',
  calendar = 'calendar',
  calendar_menu = 'calendar_menu',
  ask_dash_button = 'ask_dash_button',
  search_result_summary = 'search_result_summary',
  search_result_doc_question = 'search_result_doc_question',
  search_bar_inline = 'search_bar_inline',
  activity_feed_summary = 'activity_feed_summary',
}

// Subset of analytics event ActionSurfaceComponent that are relevant to conversations
export type AnswersActionComponent = Extract<
  ActionSurfaceComponent,
  'ask_dialog' | 'inline_answer'
>;
