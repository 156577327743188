// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3316
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { BrowserName } from '../enums/browser_name';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'scroll.activity_feed'
// Description: Scroll event for when user scrolls through activity feed
// Owner: otc-growth
export type Scroll_ActivityFeed = PAPEventGeneric<
  'dash',
  'scroll',
  'activity_feed',
  {
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // This indicates the user's current browser
    browserName?: BrowserName;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // Boolean event property that is set to true for Dash for Business (dfb) users.
    isDfbUser?: boolean;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The depth of the scroll
    scrollDepth?: number;
  }
>;

export function PAP_Scroll_ActivityFeed(
  properties?: Scroll_ActivityFeed['properties'],
): Scroll_ActivityFeed {
  return <Scroll_ActivityFeed>{
    class: 'dash',
    action: 'scroll',
    object: 'activity_feed',
    properties,
  };
}
