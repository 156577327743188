import classnames from 'classnames';
import { memo } from 'react';
import styles from './SideBarContainer.module.css';

interface SideBarContainerProps {
  headerActionLeft?: React.ReactNode;
  headerActionRight?: React.ReactNode;
  keepExpanded?: boolean;
  children: React.ReactNode;
}
export const SideBarContainer = memo(
  ({
    headerActionLeft,
    headerActionRight,
    keepExpanded,
    children,
  }: SideBarContainerProps) => {
    return (
      <div
        className={classnames(styles.container, {
          [styles.containerExpanded]: keepExpanded,
        })}
      >
        <div className={styles.header}>
          <div>{headerActionLeft}</div>
          <div>{headerActionRight}</div>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    );
  },
);
SideBarContainer.displayName = 'SideBarContainer';
