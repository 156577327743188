export const BROWSER_VIEW_KEYS = {
  // Hours: 1, 4, 8
  LAST_1_HOUR: 'last1h',
  LAST_4_HOURS: 'last4h',
  LAST_8_HOURS: 'last8h',
  // Days: 1, 3, 7
  LAST_1_DAY: 'last1d',
  LAST_3_DAYS: 'last3d',
  LAST_7_DAYS: 'last7d',
  // WARN: Server gives 30d value for browser views, but not for clicks!
  LAST_30_DAYS: 'last30d',
} as const;

type BrowserViewKey =
  (typeof BROWSER_VIEW_KEYS)[keyof typeof BROWSER_VIEW_KEYS];

export type BrowserViewMap = {
  [key in BrowserViewKey]: number;
};
