import * as React from "react";

import { Card as DigCard } from "@dropbox/dig-components/card";
import { Box, ThemeProvider } from "@dropbox/dig-foundations";
import { useMeasure } from "@react-hookz/web";
import classNames from "classnames";

import { ThemeFamilyKey, ThemeVariant, familyFor } from "../../utils/themes";

import { Contents } from "./Contents";
import { HEADER_ACTIONS_REVEALER_CLASSNAME } from "./Header";

import styles from "./Container.module.css";

export interface DashCardProps {
  /**
   * The contents of the card.
   *
   * For special alignment treatments of children, see `Card.Contents`.
   */
  children: React.ReactNode;

  /**
   * A color scheme for the card.
   *
   * If omitted, uses a subtle graphite theme.
   */
  theme?: {
    /** The family of colors to use. */
    familyKey: ThemeFamilyKey;

    /**
     *  The scheme within the theme family to use.
     *
     * @default "base"
     */
    variant?: ThemeVariant;
  };

  // TODO: Decide if we want to explicitly call out "dead" buffer zones around nested interactions.
  // We may want another subcomponent for those. A buffer zone would create an area around the
  // nested interactions that wouldn't let clicks through to the underlying card to help prevent
  // misclicks where someone goes for a nested interaction, misses it, and activates the card by
  // accident.
  /**
   * Whether the card is a link.
   *
   * The card must include a descendant `Card.Link` that will be the source target of the overall
   * card.
   *
   * When a card is a link, the entire area will be clickable through to the link's href.
   * Interactive components may still exist within a link card and will layer above the normal area
   * of the card (so clicking them will not activate the link). To prevent misclicks, make sure to
   * have a clear affordance for any nested interactive features within a card if the card is a
   * link.
   *
   * @default false
   */
  isLink?: boolean;
  /** A CSS class to apply to this card. */
  className?: string;
  /** Inline CSS styles */
  style?: React.CSSProperties;
  /**
   * The HTML element to use for this card.
   *
   * @default div
   */
  tagName?: "div" | "li" | "label";

  /**
   * A ref that will be invoked with the card's DOM node.
   *
   * Only accepts a callback for now since it's all that's needed as of writing but we could expand
   * this to accept any ref object. We could also potentially more tightly type the ref to the tag
   * name's specified element.
   */
  cardRef?: React.RefCallback<HTMLElement>;

  /**
   * Whether this card can have a selected state.
   *
   * The card itself does not track whether it is selected but will change it's presentation. Use
   * for features like multiselect for drag-and-drop.
   */
  selectable?: {
    /** Whether the card is selected. */
    isSelected: boolean;
    /** A callback that fires when the user attempts to toggle the selection state of the card. */
    onSelect: () => void;
  };

  /**
   * The variant of the card look.
   * @default borderless
   */
  variant?: "borderless" | "outline";

  /**
   * Whether the card should display a loading progress indicator
   * @default false
   */
  loading?: boolean;
}

/**
 * A flat card for arbitrary content within Dash.
 *
 * The card can be used in bento, grid, and list layouts and supports a variety of options like
 * selection state.
 *
 * The card itself has two options for the layout of its children which can be mixed and matched
 * within the same card. The standard layout within the card has uniform padding along the
 * horizontal and vertical axes. A breakout layout will allow children to cut into that horizontal
 * padding. Breakouts are useful for optically aligning content to the normal padding of the card
 * while supporting hover and focus states that flow into that padding. Use `Card.Contents` to apply
 * breakout spacing for a child of the card.
 */
export const Card: React.FC<DashCardProps> = ({
  children,
  theme,
  isLink,
  selectable,
  tagName,
  className,
  style,
  cardRef,
  variant = "borderless",
  loading = false,
}) => {
  const [measures, containerRef] = useMeasure();

  // Dynamically create parameters for the loading animation
  const boxStyle = {
    "--loader-animation-height": measures
      ? `${Math.sqrt(measures.height ** 2 + measures.width ** 2) + Math.max(measures.height, measures.width)}px`
      : "0px",
  };

  // NEEDS A DIG REQUEST
  // This is _SUPER_ weird. We're hacking this together to set the DOM node of the parent from the
  // child because the DIG Card sets a custom ref that only exposes the bounding rect and a focus
  // function. Ideally the card could just give us the full DOM element.
  //
  // We need to expose the full element to be able to animate it properly. There might be weird ways
  // to do it by wrapping in more divs but we should see if we can just get a full element ref
  // before we go deep down that path.
  const contentsRef = (el: HTMLDivElement | null) => {
    if (el && cardRef) {
      cardRef(el.parentElement);
    }
    if (el && containerRef) {
      containerRef.current = el;
    }
  };

  // Need an aria strategy for this
  function selectableProps() {
    if (!selectable) return {};

    const { isSelected, onSelect } = selectable;

    return {
      isSelected,
      onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.code === "Space") {
          event.preventDefault();
          onSelect();
        }
      },
      // Need capture here to intercept link clicks and cancel them if we have the shift modifier.
      onClickCapture: (event: React.MouseEvent) => {
        if (event.shiftKey) {
          event.preventDefault();
          event.stopPropagation();
          onSelect();
        }
      },
    };
  }

  const themeOverrides = theme
    ? familyFor(theme.familyKey)[theme.variant ?? "base"]
    : undefined;

  return (
    <ThemeProvider overrides={themeOverrides}>
      {({ getThemeProps }) => (
        <Box
          as={DigCard}
          tagName={tagName}
          {...getThemeProps({
            className: classNames(
              styles.card,
              HEADER_ACTIONS_REVEALER_CLASSNAME,
              className,
              {
                [styles.isLink]: isLink,
                [styles.isThemed]: !!theme,
                [styles.variantOutline]: variant === "outline",
                [styles.loading]: loading,
              },
            ),
            style: { ...style, ...boxStyle },
          })}
          hasBorders={false}
          isLink={isLink}
          focusBehavior={selectable ? "focus-visible" : "none"}
          {...selectableProps()}
        >
          <Contents contentsRef={contentsRef}>{children}</Contents>
        </Box>
      )}
    </ThemeProvider>
  );
};
