import { Text } from '@dropbox/dig-components/typography';
import ReactMarkdown from 'react-markdown';
import styles from './Markdown.module.css';

interface MarkdownProps {
  body: string;
}

// don't allow images in markdown.
const disallowedElements = ['img'];

export const Markdown: React.FC<MarkdownProps> = ({ body }) => {
  return (
    <div className={styles.markdownContainer}>
      <Text size="medium">
        <ReactMarkdown disallowedElements={disallowedElements}>
          {body}
        </ReactMarkdown>
      </Text>
    </div>
  );
};
