import { UIIcon } from '@dropbox/dig-icons';
import {
  EditLine,
  SidebarHideLine,
  SidebarShowLine,
} from '@dropbox/dig-icons/assets';
import {
  ComposeAssistantGrid,
  ComposeAssistantPageWrapper,
} from '@mirage/mosaics/ComposeAssistant/components/ComposeAssistantPageLayout';
import { SideBarContainer } from '@mirage/mosaics/ComposeAssistant/components/side-bar/SideBarContainer';
import { SidePanelContainer } from '@mirage/mosaics/ComposeAssistant/components/side-panel/SidePanelContainer';
import { ComposeConversation } from '@mirage/mosaics/ComposeAssistant/containers/ComposeConversation';
import { ComposeEditorPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeEditorPane';
import { ComposeSessionsPane } from '@mirage/mosaics/ComposeAssistant/containers/ComposeSessionsPane';
import { ComposeSessionsContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import { ComposeVoicesContextProvider } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  ComposeCurrentSessionContextProvider,
  useComposeCurrentSessionContext,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';

export const ComposeAssistantPage = memo(() => {
  const [keepSideBarExpanded, setKeepSideBarExpanded] = useState(true);
  const sidePanelButtons = !keepSideBarExpanded && (
    <>
      <ToggleExpandSideBarButton
        setExpanded={setKeepSideBarExpanded}
        variant="expand"
      />
      <NewSessionButton />
    </>
  );
  return (
    <ComposeSessionsContextProvider>
      <ComposeVoicesContextProvider>
        <ComposeCurrentSessionContextProvider>
          <ComposeAssistantPageWrapper>
            <ComposeAssistantGrid
              sideBar={
                <ComposeAssistSideBar
                  keepExpanded={keepSideBarExpanded}
                  setKeepExpanded={setKeepSideBarExpanded}
                />
              }
              sidePane={
                <SidePanelContainer leftTitleActions={sidePanelButtons}>
                  <ComposeConversation />
                </SidePanelContainer>
              }
              editorPane={<ComposeEditorPane />}
            />
          </ComposeAssistantPageWrapper>
        </ComposeCurrentSessionContextProvider>
      </ComposeVoicesContextProvider>
    </ComposeSessionsContextProvider>
  );
});
ComposeAssistantPage.displayName = 'ComposeAssistantPage';

interface ComposeAssistSideBarProps {
  keepExpanded: boolean;
  setKeepExpanded: (expanded: boolean) => void;
}
export const ComposeAssistSideBar = memo(
  ({ keepExpanded, setKeepExpanded }: ComposeAssistSideBarProps) => {
    const collapseSideBarButton = (
      <ToggleExpandSideBarButton
        setExpanded={setKeepExpanded}
        variant="collapse"
      />
    );
    return (
      <SideBarContainer
        headerActionLeft={keepExpanded ? collapseSideBarButton : undefined}
        headerActionRight={<NewSessionButton />}
        keepExpanded={keepExpanded}
      >
        <ComposeSessionsPane />
      </SideBarContainer>
    );
  },
);
ComposeAssistSideBar.displayName = 'ComposeAssistSideBar';

interface ToggleExpandSideBarButtonProps {
  variant: 'expand' | 'collapse';
  setExpanded: (expanded: boolean) => void;
}
export const ToggleExpandSideBarButton = memo(
  ({ variant, setExpanded }: ToggleExpandSideBarButtonProps) => {
    const handleClickExpandSideBar = useCallback(() => {
      setExpanded(variant === 'expand' ? true : false);
    }, [setExpanded, variant]);
    return (
      <IconButtonWithTooltip
        variant="borderless"
        tooltipProps={{
          title: variant === 'expand' ? i18n.t('expand') : i18n.t('collapse'),
        }}
        onClick={handleClickExpandSideBar}
      >
        <UIIcon
          src={variant === 'expand' ? SidebarShowLine : SidebarHideLine}
        />
      </IconButtonWithTooltip>
    );
  },
);
ToggleExpandSideBarButton.displayName = 'ToggleExpandSideBarButton';

export const NewSessionButton = memo(() => {
  const { newSession } = useComposeCurrentSessionContext();
  return (
    <IconButtonWithTooltip
      variant="borderless"
      tooltipProps={{
        title: i18n.t('compose_new_session'),
      }}
      onClick={() => newSession([])}
    >
      <UIIcon src={EditLine} />
    </IconButtonWithTooltip>
  );
});
NewSessionButton.displayName = 'NewSessionButton';
