import { Service as AuthService } from "@mirage/service-auth/service/index";
import { tryExchangeOauthToken } from "@mirage/service-auth/tokenExchange";
import { LoginData } from "@mirage/service-login-sync-v1/service/types";

import { Config } from "../shared/config";

export async function directLoginUsingAuthData(
  loginData: LoginData,
  authService: AuthService,
) {
  // Make sure we are logged in first before we can call callApiV2.
  await authService.migrateFromExternalManagement(loginData);

  // Get the refresh token for the correct api app, but don't fail login.
  // If this fails, the app will still work correctly.
  const succeeded = await tryExchangeOauthToken(
    Config.API_APP_ID,
    loginData,
    authService,
  );

  if (succeeded) {
    // Reload the page since now we are logged in.
    location.reload();
  } else {
    // Undo incomplete login.
    await authService.resetAuthenticationData();
  }
}
