import { ServiceId } from '@mirage/discovery/id';
import * as service from '@mirage/discovery/services';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { Service } from './service';

const composeService = service.getp<Service>(ServiceId.COMPOSE);

export default composeService;

export function loadComposeSessions(): Promise<ComposeSession[]> {
  return composeService.loadComposeSessions();
}

export function saveComposeSessions(sessions: ComposeSession[]) {
  return composeService.saveComposeSessions(sessions);
}

export function loadComposeVoices(): Promise<ComposeVoice[]> {
  return composeService.loadComposeVoices();
}

export function saveComposeVoices(voices: ComposeVoice[]) {
  return composeService.saveComposeVoices(voices);
}

export function tearDown(): Promise<void> {
  return composeService.tearDown();
}
