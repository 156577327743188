import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoLine } from '@dropbox/dig-icons/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useState } from 'react';
import { AvatarMemberOption } from './Avatar';
import { NewPermissionIcon, PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';
import {
  SharingMemberKeyWithPermission,
  SharingStackPermission,
  StackAccessLevel,
  StackInfo,
  StackMember,
  StackPermission,
} from './Types';
import {
  getSharingMemberIdentifier,
  hasStackWritePermissions,
  sharingMemberKeysMatch,
} from './Utils';

import type { stacks } from '@dropbox/api-v2-client';

const shouldShowPermissionsWarningForMember = (
  stackInfo: StackInfo,
  stackMember: StackMember,
): boolean => {
  if (stackInfo.accessLevel === StackAccessLevel.PUBLIC) {
    return (
      stackMember.permission === StackPermission.READ &&
      stackInfo.linkPermission === StackPermission.WRITE
    );
  }

  // If the stack access level is set to team, only show
  // the warning icon if they are on the same team as the owner
  if (stackInfo.accessLevel === StackAccessLevel.TEAM) {
    return (
      stackMember.permission === StackPermission.READ &&
      stackInfo.linkPermission === StackPermission.WRITE &&
      stackMember.isSameTeamAsOwner
    );
  }

  return false;
};

export const ShowMembers = ({
  currentMember,
  stackInfo,
  handleShareStackWithMembers,
  handleRevokeStackMember,
  openSnackbar,
}: {
  currentMember: StackMember;
  stackInfo: StackInfo;
  handleShareStackWithMembers: (
    sendNotifications: boolean,
    notificationMessage: string,
    members: SharingMemberKeyWithPermission[],
    onFailure: () => void,
  ) => Promise<void>;
  handleRevokeStackMember: (
    member: StackMember,
  ) => Promise<stacks.RemoveStackSharingMembersResponse | undefined>;
  openSnackbar: (message: string) => void;
}) => {
  const [loadingIds, setLoadingIds] = useState<Set<string>>(new Set());

  const addLoadingId = (id: string) => {
    setLoadingIds((loadingIds) => {
      return new Set(loadingIds.values()).add(id);
    });
  };

  const deleteLoadingId = (id: string) => {
    setLoadingIds((loadingIds) => {
      const newSet = new Set(loadingIds.values());
      newSet.delete(id);
      return newSet;
    });
  };

  const updateSharingPermissionsOnMember = async (
    stackMember: StackMember,
    newPermission: SharingStackPermission,
  ) => {
    if (newPermission === stackMember.permission) {
      return;
    }
    addLoadingId(getSharingMemberIdentifier(stackMember));
    await handleShareStackWithMembers(
      false,
      '',
      [{ memberKey: stackMember, permission: newPermission }],
      () => openSnackbar(i18n.t('failed_to_change_permissions')),
    );
    deleteLoadingId(getSharingMemberIdentifier(stackMember));
  };

  const revokeStackMember = async (stackMember: StackMember) => {
    const result = await handleRevokeStackMember(stackMember);
    if (!result) {
      openSnackbar(i18n.t('failed_to_remove_sharing_member'));
    }
    deleteLoadingId(getSharingMemberIdentifier(stackMember));
  };

  return (
    <div className={styles.sharingMembersContainer}>
      {stackInfo.members.map((stackMember) => (
        <div
          key={getSharingMemberIdentifier(stackMember)}
          className={styles.sharingMemberRow}
        >
          <AvatarMemberOption member={stackMember} avatarSize="small" />
          {
            // If the stack member is the owner or if the local user
            // only has view permissions, show non-editable text
            stackMember.permission === StackPermission.OWNER ||
            !hasStackWritePermissions(stackInfo.permission) ? (
              <Text className={styles.faintText} size="small" isBold>
                <NewPermissionIcon
                  permission={stackMember.permission}
                  withFaintStyle
                />
              </Text>
            ) : (
              <div className={styles.stackMemberWarningContainer}>
                {shouldShowPermissionsWarningForMember(
                  stackInfo,
                  stackMember,
                ) && (
                  <DigTooltip
                    title={i18n.t(
                      stackInfo.accessLevel === StackAccessLevel.TEAM
                        ? 'mismatched_permissions_warning_team'
                        : 'mismatched_permissions_warning_public',
                    )}
                    placement="bottom"
                  >
                    <UIIcon src={InfoLine} className={styles.warningIcon} />
                  </DigTooltip>
                )}
                <PermissionsMenu
                  sharePermission={stackMember.permission}
                  onSelectSharePermissions={(permissions) =>
                    void updateSharingPermissionsOnMember(
                      stackMember,
                      permissions,
                    )
                  }
                  onRevoke={() => void revokeStackMember(stackMember)}
                  buttonProps={{
                    variant: 'transparent',
                    hasNoUnderline: true,
                    isLoading: loadingIds.has(
                      getSharingMemberIdentifier(stackMember),
                    ),
                  }}
                  isLocalUserMenu={sharingMemberKeysMatch(
                    currentMember,
                    stackMember,
                  )}
                />
              </div>
            )
          }
        </div>
      ))}
    </div>
  );
};
