import { Button } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { ShareArrowLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { $generateHtmlFromNodes } from '@lexical/html';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { tagged } from '@mirage/service-logging';
import i18n from '@mirage/translations';
import { $getRoot } from 'lexical';
import { memo, useCallback } from 'react';
import styles from './ComposeEditor.module.css';

const logger = tagged('ComposeEditor/ExportButton');

export const ExportButton = memo(() => {
  const [editor] = useLexicalComposerContext();
  const handleSelection = useCallback(
    (selection: 'text' | 'markdown') => {
      editor.getEditorState().read(() => {
        switch (selection) {
          case 'text':
            {
              const htmlString = $generateHtmlFromNodes(editor, null);
              const textString = $getRoot().getTextContent();
              const data = [
                new ClipboardItem({
                  'text/plain': new Blob([textString], { type: 'text/plain' }),
                  'text/html': new Blob([htmlString], { type: 'text/html' }),
                }),
              ];
              navigator.clipboard.write(data);
            }
            break;
          case 'markdown':
            {
              const markdownString = $convertToMarkdownString(TRANSFORMERS);
              const data = [
                new ClipboardItem({
                  'text/plain': new Blob([markdownString], {
                    type: 'text/plain',
                  }),
                }),
              ];
              navigator.clipboard.write(data);
            }
            break;
          default:
            selection satisfies never;
            logger.error('unsupported action', selection);
            break;
        }
      });
    },
    [editor],
  );
  return (
    <Menu.Wrapper onSelection={handleSelection}>
      {({ getContentProps, getTriggerProps }) => (
        <>
          <Button
            {...getTriggerProps()}
            variant="borderless"
            size="medium"
            withIconStart={<UIIcon src={ShareArrowLine} />}
            className={styles.editorExportButton}
          >
            {i18n.t('compose_editor_export_action')}
          </Button>
          <Menu.Content {...getContentProps()} placement="bottom-start">
            <Menu.Segment>
              <Menu.ActionItem
                withTitle={i18n.t('compose_editor_export_action_copy_text')}
                value="text"
              />
              <Menu.ActionItem
                withTitle={i18n.t('compose_editor_export_action_copy_markdown')}
                value="markdown"
              />
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
});
ExportButton.displayName = 'ExportButton';
