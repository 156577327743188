import { stacks } from '@dropbox/api-v2-client';
import { Menu } from '@dropbox/dig-components/menu';
import { TextInputRefObject } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashNewLinkType } from '@mirage/analytics/events/enums/dash_new_link_type';
import { PAP_Change_DashNewLinkSearchQuery } from '@mirage/analytics/events/types/change_dash_new_link_search_query';
import { PAP_Click_DashNewLink } from '@mirage/analytics/events/types/click_dash_new_link';
import { PAP_Initiate_DashNewLinkSearch } from '@mirage/analytics/events/types/initiate_dash_new_link_search';
import { getTitleOfUrl } from '@mirage/service-stacks/service/url-title';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
} from '@mirage/service-stacks/service/utils';
import { ButtonWithTooltip } from '@mirage/shared/buttons/ButtonWithTooltip';
import { useDebounce } from '@mirage/shared/hooks/useDebounce';
import { isUrl } from '@mirage/shared/util/tiny-utils';
import { constructAbsoluteURL } from '@mirage/shared/util/urls';
import {
  activeStackActionAtom,
  activeStackAtom,
  activeStackItemShortcutsAtom,
  activeStackSessionIdAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { AddStackItemBoxAugRev } from '@mirage/stacks/AddStackItemMenuContent/AddStackItemBox';
import { TabSuggestion } from '@mirage/stacks/AddStackItemMenuContent/types';
import { fetchSearchResultsFromAPI } from '@mirage/stacks/Helpers/Utils';
import { useDefaultItemsToAdd } from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  AddStackItemMenuContent,
  AddStackItemMenuWrapper,
} from '../../AddStackItemMenuContent/AddStackItemMenuContent';
import { useAddLinkToStack } from '../hooks';
import { SectionsActionType } from '../types';
import styles from './AddItemButton.module.css';
import {
  useContentSuggestions,
  useOpenSuggestedLink,
  useShownContentSuggestions,
} from './hooks';

interface AddItemButtonProps {
  align: 'left' | 'right';
  variant: 'primary' | 'opacity';
  section?: stacks.Section;
  emphasize?: boolean;
  publicPreview?: boolean;
  largeVersion?: boolean;
}
export const AddItemButton: React.FC<AddItemButtonProps> = ({
  align,
  variant,
  section,
  emphasize,
  publicPreview,
  largeVersion,
}) => {
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const [currentAction, setCurrentAction] = useAtom(activeStackActionAtom);
  const stack = useAtomValue(activeStackAtom);
  const handleOpenSuggestedLink = useOpenSuggestedLink();
  const handleShowContentSuggestions = useShownContentSuggestions();
  const { contentSuggestions, isFetchingContentSuggestions } =
    useContentSuggestions();
  const defaultItemsToAdd = useDefaultItemsToAdd(items, contentSuggestions);

  const { reportPapEvent } = useMirageAnalyticsContext();

  const divRef = useRef<HTMLDivElement | null>(null);
  const pasteInputRef = useRef<TextInputRefObject | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [inputString, setInputString] = useState('');
  const [pasteInput, setPasteInput] = useState('');
  const [filenameInput, setFilenameInput] = useState('');
  const [itemsToAdd, setItemsToAdd] = useState<TabSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const dashNewLinkSessionId = useRef('');
  const dashLinkSearchSessionId = useRef('');
  const createStackSessionId = useAtomValue(activeStackSessionIdAtom);

  const addLinkToStackProp = useAddLinkToStack();

  // Debounce request to update suggestions until user stops modifying it for 1s
  const debouncedUpdateSuggestions = useDebounce(
    async function getSearchResults() {
      if (stack?.namespace_id) {
        dashLinkSearchSessionId.current = uuidv4();

        reportPapEvent(
          PAP_Initiate_DashNewLinkSearch({
            ...(stack ? stackDerivePAPProps(stack) : undefined),
            featureLine: 'stacks',
            createStackSessionId: createStackSessionId || undefined,
            dashNewLinkSessionId: dashNewLinkSessionId.current,
            dashLinkSearchSessionId: dashLinkSearchSessionId.current,
          }),
        );
      }

      try {
        const links = await fetchSearchResultsFromAPI(inputString);
        if (links) {
          setItemsToAdd(links);
        }
      } finally {
        setIsLoading(false);
      }
    },
    1000,
  );

  useEffect(() => {
    return () => {
      debouncedUpdateSuggestions.cancel();
    };
  }, [debouncedUpdateSuggestions]);

  useEffect(() => {
    // When inputString is not empty, don't override the search results.
    if (inputString === '') {
      setItemsToAdd(defaultItemsToAdd);
    }
  }, [inputString, defaultItemsToAdd]);

  useEffect(() => {
    // If input is a link, then stop searching.
    // Make the detection logic stricter by only recognizing `http...` strings.
    if (
      inputString &&
      isUrl(inputString) &&
      (inputString.startsWith('http://') || inputString.startsWith('https://'))
    ) {
      return;
    }

    setIsLoading(true);
    debouncedUpdateSuggestions();
  }, [inputString, debouncedUpdateSuggestions]);

  const addLinkToStack = useCallback(
    async (
      dashNewLinkType: DashNewLinkType,
      link: string | TabSuggestion,
      title?: string,
    ) => {
      let item: TabSuggestion;
      if (typeof link === 'string') {
        const url = link.trim();
        if (url === '') {
          return;
        }
        item = {
          url: constructAbsoluteURL(url),
          title: getTitleOfUrl(url, title),
        };
      } else {
        item = link;
      }
      const success = await addLinkToStackProp(
        section?.id ?? DEFAULT_SECTION_ID,
        item,

        dashNewLinkSessionId.current,
        dashLinkSearchSessionId.current,
        dashNewLinkType,
      );

      if (success) {
        setInputString('');
        setPasteInput('');
        setFilenameInput('');
        // Simulate a click on the wrapping div to close the menu gracefully
        divRef.current?.click();
      }
    },
    [addLinkToStackProp, section?.id],
  );

  useEffect(() => {
    if (currentAction !== SectionsActionType.ADD_ITEM) {
      setInputString('');
      setPasteInput('');
      setFilenameInput('');
      divRef.current?.click();
    }
  }, [currentAction]);

  useEffect(() => {
    if (menuIsOpen && stack?.namespace_id) {
      dashNewLinkSessionId.current = uuidv4();

      reportPapEvent(
        PAP_Click_DashNewLink({
          ...(stack ? stackDerivePAPProps(stack) : undefined),
          featureLine: 'stacks',
          createStackSessionId: createStackSessionId || undefined,
          dashNewLinkSessionId: dashNewLinkSessionId.current,
        }),
      );
      pasteInputRef.current?.focus();
    } else {
      setInputString('');
      setPasteInput('');
      setFilenameInput('');
    }
  }, [createStackSessionId, menuIsOpen, reportPapEvent, stack]);

  useEffect(() => {
    if (menuIsOpen && stack?.namespace_id && inputString) {
      reportPapEvent(
        PAP_Change_DashNewLinkSearchQuery({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          createStackSessionId: createStackSessionId || undefined,
          dashNewLinkSessionId: dashNewLinkSessionId.current,
          dashLinkSearchSessionId: dashLinkSearchSessionId.current,
          queryLength: inputString.length,
        }),
      );
    }
  }, [createStackSessionId, inputString, menuIsOpen, reportPapEvent, stack]);

  return (
    <div ref={divRef} className={styles.addItemMenuContainer}>
      <Menu.Wrapper
        onToggle={(event) => {
          setMenuIsOpen(event.isOpen);
          if (event.isOpen) {
            setInputString('');
            setPasteInput('');
            setFilenameInput('');
            setCurrentAction(SectionsActionType.ADD_ITEM);
          }
        }}
        closeOnSelection={true}
      >
        {({ getContentProps, getTriggerProps, triggerRef }) => (
          <div>
            <ButtonWithTooltip
              variant={variant}
              {...getTriggerProps()}
              aria-label={i18n.t('add')}
              className={classnames(
                largeVersion && styles.addButton,
                emphasize && styles.emphasized,
                menuIsOpen && styles.menuIsOpen,
              )}
              withIconStart={
                variant === 'primary' ? <UIIcon src={AddLine} /> : null
              }
              tooltipProps={{
                title:
                  publicPreview &&
                  i18n.t('public_stack_sign_in_to_add_item_cta'),
              }}
              disabled={publicPreview}
              data-testid="Stacks-addLinkButton"
            >
              <input type="hidden" role="button" />
              <div className={styles.addItemButtonText}>
                {i18n.t('add_to_stack')}
              </div>
            </ButtonWithTooltip>
            <AddStackItemMenuWrapper
              placement={align === 'left' ? 'bottom-start' : 'bottom-end'}
              getContentProps={getContentProps}
            >
              <AddStackItemMenuContent
                pasteInputRef={pasteInputRef}
                inputString={inputString}
                setInputString={setInputString}
                pasteInput={pasteInput}
                setPasteInput={setPasteInput}
                filenameInput={filenameInput}
                setFilenameInput={setFilenameInput}
                addLink={addLinkToStack}
                tabSuggestions={
                  itemsToAdd.length
                    ? itemsToAdd
                    : inputString.length
                    ? []
                    : defaultItemsToAdd
                }
                onOpenLink={handleOpenSuggestedLink}
                onShowLinks={
                  menuIsOpen ? handleShowContentSuggestions : undefined
                }
                isLoading={isFetchingContentSuggestions || isLoading}
                triggerElement={triggerRef.current}
              />
            </AddStackItemMenuWrapper>
          </div>
        )}
      </Menu.Wrapper>
    </div>
  );
};

interface AddItemButtonAugRevProps {
  align: 'left' | 'right';
  variant: 'primary' | 'opacity';
  contentSuggestions: TabSuggestion[];
  loadingSuggestions: boolean;
  section?: stacks.Section;
  emphasize?: boolean;
  publicPreview?: boolean;
  largeVersion?: boolean;
}
export const AddItemButtonAugRev: React.FC<AddItemButtonAugRevProps> = ({
  align,
  variant,
  contentSuggestions,
  loadingSuggestions,
  section,
  emphasize,
  publicPreview,
  largeVersion,
}) => {
  const [currentAction, setCurrentAction] = useAtom(activeStackActionAtom);
  const items = useAtomValue(activeStackItemShortcutsAtom);
  const stack = useAtomValue(activeStackAtom);
  const handleOpenSuggestedLink = useOpenSuggestedLink();
  const handleShowContentSuggestions = useShownContentSuggestions();

  const divRef = useRef<HTMLDivElement | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const addLinkToStack = useAddLinkToStack();

  const createStackSessionId = useAtomValue(activeStackSessionIdAtom);

  useEffect(() => {
    if (currentAction !== SectionsActionType.ADD_ITEM) {
      divRef.current?.click();
    }
  }, [currentAction]);

  return (
    <div ref={divRef} className={styles.addItemMenuContainer}>
      <Menu.Wrapper
        onToggle={(event) => {
          setMenuIsOpen(event.isOpen);
          if (event.isOpen) {
            setCurrentAction(SectionsActionType.ADD_ITEM);
          }
        }}
        closeOnSelection={true}
      >
        {({ getContentProps, getTriggerProps, triggerRef }) => (
          <div>
            <ButtonWithTooltip
              data-testid="Stacks-addLinkButton"
              variant={variant}
              {...getTriggerProps()}
              aria-label={i18n.t('add')}
              className={classnames(
                largeVersion && styles.addButton,
                emphasize && styles.emphasized,
                menuIsOpen && styles.menuIsOpen,
              )}
              withIconStart={
                variant === 'primary' ? <UIIcon src={AddLine} /> : null
              }
              tooltipProps={{
                title:
                  publicPreview &&
                  i18n.t('public_stack_sign_in_to_add_item_cta'),
              }}
              disabled={publicPreview}
            >
              <input type="hidden" role="button" />
              <div className={styles.addItemButtonText}>
                {i18n.t('add_to_stack')}
              </div>
            </ButtonWithTooltip>
            <AddStackItemMenuWrapper
              placement={align === 'left' ? 'bottom-start' : 'bottom-end'}
              getContentProps={getContentProps}
            >
              <AddStackItemBoxAugRev
                // Force re-render when the stack changes
                key={stack?.namespace_id}
                stack={stack}
                existingItems={items}
                sectionIdToAddItem={section?.id ?? DEFAULT_SECTION_ID}
                contentSuggestions={contentSuggestions}
                loadingSuggestions={loadingSuggestions}
                addLinkToStack={addLinkToStack}
                createStackSessionId={createStackSessionId || ''}
                onOpenLink={handleOpenSuggestedLink}
                onShowLinks={
                  menuIsOpen ? handleShowContentSuggestions : undefined
                }
                useResize
                triggerElement={triggerRef.current}
                onLinkAdded={() => {
                  // Simulate a click on the wrapping div to close the menu gracefully
                  divRef.current?.click();
                }}
                focusOnLoad={menuIsOpen}
              />
            </AddStackItemMenuWrapper>
          </div>
        )}
      </Menu.Wrapper>
    </div>
  );
};
