import { UIIcon } from '@dropbox/dig-icons';
import { Twinkle2Line } from '@dropbox/dig-icons/assets';
import { useFlyoutPanel } from '@mirage/mosaics/FlyoutPanel/useFlyoutPanel';
import { ResultAction } from '@mirage/mosaics/Results/ResultActions';
import { ChatEntryPoint } from '@mirage/mosaics/SummaryQnaPanel/SummaryQna';
import { DisplayActionTypes } from '@mirage/search/SearchResults/ResultRow/types';
import i18n from '@mirage/translations';
import { type NormalizeResultInputType, SummaryQnAEnum } from './utils';

export const SummarizeAction = ({
  result,
  summaryQnAType,
  entrypoint,
  resultPosition,
  resultPositionNoCta,
  summarizable,
}: {
  result?: NormalizeResultInputType;
  summaryQnAType: SummaryQnAEnum;
  entrypoint: ChatEntryPoint;
  resultPosition: number;
  resultPositionNoCta: number;
  summarizable: string | undefined;
}) => {
  const { summarize } = useFlyoutPanel();

  if (typeof result === 'object' && summarizable === 'yes_summarizable') {
    return (
      <ResultAction
        action={{
          key: 'summarize',
          title: i18n.t('summarize_action'),
          type: DisplayActionTypes.IconButton,
          icon: <UIIcon src={Twinkle2Line} />,
          onClick: () => {
            if (result) {
              summarize(
                result,
                summaryQnAType,
                entrypoint,
                resultPosition,
                resultPositionNoCta,
              );
            }
          },
        }}
      />
    );
  }
  return (
    <ResultAction
      action={{
        key: 'summarize',
        title: i18n.t('summarize_action_not_available_tooltip'),
        type: DisplayActionTypes.IconButton,
        icon: <UIIcon src={Twinkle2Line} />,
        disabled: true,
      }}
    />
  );
};
