/* eslint-disable no-nested-ternary */
import { TextColorType } from '@dropbox/dig-components/typography';
import styled from '@emotion/styled';
import * as React from 'react';
import ButtonListItem from './ButtonListItem';
import CheckboxListItem from './CheckboxListItem';
import RadioListItem from './RadioListItem';
import SegmentButtonsListItem from './SegmentButtonsListItem';

export type ListItemProps = Readonly<{
  /** left hand side icon */
  icon?: React.ReactNode;
  textColor?: string;
  checkbox?: React.ReactNode;
  componentType?: 'checkbox' | 'button' | 'space' | 'radio' | 'segment-buttons';
  checked?: boolean;
  onClick?: (e: Event) => void;
  /** default title */
  title: React.ReactNode;
  /** optional  */
  subtitle: React.ReactNode;
  /** default actions - can be buttons or links because it is outside the parent button hierarchy */
  displayActions?: React.ReactNode | null;
  /** optional a11y parameters */
  ariaLabel?: string;
  role?: string;
  disabled?: boolean;
  displayActionsOnHover?: boolean;
  tooltipTitle?: string;
}>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ListItem = React.forwardRef<ListItemProps, any>((props, ref) => {
  const {
    icon,
    textColor = 'standard',
    componentType = 'button',
    onClick,
    title = '',
    displayActions = null,
    value,
    options,
    ariaLabel,
    role,
    disabled = false,
    subtitle,
    displayActionsOnHover = false,
    tooltipTitle,
  } = props;

  const [shouldDisplayActions, setShouldDisplayActions] = React.useState(
    !displayActionsOnHover,
  );

  return (
    <Row
      ref={ref}
      onMouseEnter={() =>
        displayActionsOnHover && setShouldDisplayActions(true)
      }
      onMouseLeave={() =>
        displayActionsOnHover && setShouldDisplayActions(false)
      }
    >
      <Item>
        {componentType === 'checkbox' && (
          <CheckboxListItem
            icon={icon}
            onClick={onClick}
            value={value}
            title={title}
            ariaLabel={ariaLabel}
            disabled={disabled}
            tooltipTitle={tooltipTitle}
          />
        )}
        {componentType === 'button' && (
          <ButtonListItem
            onClick={onClick}
            icon={icon}
            textColor={textColor as TextColorType}
            title={title}
            ariaLabel={ariaLabel}
            role={role}
            subtitle={subtitle}
            tooltipTitle={tooltipTitle}
          />
        )}
        {componentType === 'radio' && (
          <RadioListItem
            icon={icon}
            onClick={onClick}
            value={value}
            title={title}
            ariaLabel={ariaLabel}
          />
        )}
        {componentType === 'segment-buttons' && (
          <SegmentButtonsListItem
            onClick={onClick}
            icon={icon}
            title={title}
            ariaLabel={ariaLabel}
            value={value}
            options={options}
          />
        )}
      </Item>
      <Actions>{shouldDisplayActions && displayActions}</Actions>
    </Row>
  );
});
ListItem.displayName = 'ListItem';

export default ListItem;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Row = styled.li<any>`
  background-color: var(--dig-color__background__base);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 56px;
  cursor: default;
  padding: 0px 12px;

  &:hover,
  &:active {
    background-color: var(--dig-color__background__subtle);
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  outline: none;
  border: none;
  text-align: left;
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 22px;
`;
