import { atom } from 'jotai';

export const _currentLocationStateAtom = atom<string | null>(null);

/**
 * The current route path. `/`, `/stack/:stack_id`, `/settings`, etc.
 * @returns The current route path, or null if the current route is not set.
 */
export const currentLocationAtom = atom<string | null>((get) =>
  get(_currentLocationStateAtom),
);
