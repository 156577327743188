import { PAPEvent } from "@mirage/analytics/events/base/event";
import { ServiceConfig } from "@mirage/service-product-logging/service";
import {
  isExtensionInstalled,
  isNewTabEnabled,
} from "@mirage/settings/utils/webExtensionsHelpers";
import { isIframe } from "@mirage/shared/util/tiny-utils";

import { Config } from "../shared/config";

const LOG_PAP_EVENTS = true;

export function copyToClipboard(event: PAPEvent) {
  try {
    const eventJson = JSON.stringify(event, null, 2);
    navigator.clipboard.writeText(eventJson);
  } catch (error) {
    console.error(error);
  }
}

export const getProductLoggingServiceConfig = (
  isDev: boolean,
): ServiceConfig => ({
  papClientConfig: {
    clientId: Config.OAUTH_CLIENT_KEY,
    clientSecret: Config.OAUTH_CLIENT_SECRET,
    transportType: "sdk",
  },
  dashSurface: "webapp",
  logging: LOG_PAP_EVENTS,
  copyToClipboard,
  isDev,
  papEnvironment: isDev ? "development" : "production",
  buildVersion: Config.BUILD_VERSION,
  reportValidationErrorsToSentry: true,
  getExtensionPropertiesForLog: () => ({
    isExtensionInstalled: isIframe() || isExtensionInstalled(), // Set the appropriate value
    isNewTabEnabled: isIframe() || isNewTabEnabled(), // Set the appropriate value
  }),
});
