import { Button } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { RotateLeftLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { CloseDashChatType } from '@mirage/analytics/events/enums/close_dash_chat_type';
import { DashAnswersFeedbackTag } from '@mirage/analytics/events/enums/dash_answers_feedback_tag';
import { PAP_Click_DashAnswersFeedback } from '@mirage/analytics/events/types/click_dash_answers_feedback';
import { PAP_Click_DashAnswersSource } from '@mirage/analytics/events/types/click_dash_answers_source';
import { PAP_Click_DashAnswersTaggedFeedback } from '@mirage/analytics/events/types/click_dash_answers_tagged_feedback';
import { PAP_Copy_DashAnswers } from '@mirage/analytics/events/types/copy_dash_answers';
import {
  ANSWER_DWELL_END_REASONS,
  ANSWERS_SESSION_END_REASONS,
} from '@mirage/analytics/session/session-utils';
import {
  AnswersEmptyState,
  FullPageAnswersEmptyState,
  MessageSecondaryFeedback,
  SpinnerSystemMessage,
} from '@mirage/conversations';
import { AskQueryInput } from '@mirage/conversations/AskQueryInput/AskQueryInput';
import { ErrorBox } from '@mirage/conversations/ErrorBox/ErrorBox';
import { summaryQnaSecondaryFeedbackOptions } from '@mirage/conversations/FeedbackUtils/FeedbackUtils';
import { RailHeader } from '@mirage/conversations/RailHeaderV2/RailHeader';
import { Responses } from '@mirage/conversations/Responses/Responses';
import { Source } from '@mirage/conversations/SourceV2/Source';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { DynamicPanelPapProperties } from '@mirage/mosaics/DynamicPanel/atoms';
import {
  LoadingStatus,
  Role,
} from '@mirage/service-dbx-api/service/grpc/context_engine_apiv2/doc_summarization';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { tagged } from '@mirage/service-logging';
import { copyToClipboard, openURL } from '@mirage/service-platform-actions';
import { openResult } from '@mirage/service-result-actions';
import { DragBar } from '@mirage/shared/drag-bar/DragBar/DragBar';
import { usePageVisibility } from '@mirage/shared/hooks/usePageVisibility';
import { FeedbackOptions } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import styles from './SummaryQna.module.css';
import { useSummaryQna } from './useSummaryQna';
import { type SummarizableResult, SummaryQnAEnum } from './utils';

export { ChatEntryPoint };

const logger = tagged('SummaryQna');

type SummaryQnaProps = {
  onClose?: (closeChatType: CloseDashChatType) => void;
  isFullPage?: boolean;
  enableSuggestedQuestions?: boolean;
  papData?: DynamicPanelPapProperties;
  onOpenFeedbackSnackbar: () => void;
  onOpenCopyTextSnackbar: () => void;
  attachment?: SummarizableResult;
  isSummarize?: boolean;
  initialQuery?: string;
};

const enum PageModes {
  initial, // no query, null state
  conversation, // conversation is ready to display

  loading,
}

export const SummaryQna: React.FC<SummaryQnaProps> = ({
  onClose,
  isFullPage = false,
  onOpenFeedbackSnackbar,
  onOpenCopyTextSnackbar,
  attachment,
  isSummarize = false,
  papData,
  enableSuggestedQuestions,
  initialQuery,
}) => {
  const [pageMode, setPageMode] = useState<PageModes>(PageModes.initial);
  const [showSecondaryFeedbackModal, setShowSecondaryFeedbackModal] =
    useState(false);
  const [currentFeedback, setCurrentFeedback] = useState(-1);
  const actionSurfaceComponent = isFullPage ? undefined : 'ask_dialog';
  const setFocusRef = useRef<() => void>();
  const enableSummary =
    useFeatureFlagValue('dash_2024_06_24_search_summary') === 'ON';
  const enableMultimodalSummary =
    useFeatureFlagValue('dash_2024_09_03_search_summary_enable_multimodal') ===
    'ON';

  const {
    conversation,
    numQuestionsAsked,
    dashAnswerRequestId,
    resetConversation,
    resultSummaryQna,
    resultQuestion,
    resultStatus,
    resultId,
    setResultFeedback,
  } = useSummaryQna({
    attachment,
    initialQuery,
    papData,
    actionSurfaceComponent,
    enableSummary,
    enableMultimodalSummary,
  });

  const {
    reportPapEvent,
    explicitAnswersSessionManager,
    explicitAnswerDwellManager,
    searchSessionManager,
  } = useMirageAnalyticsContext();

  const currentResult = resultSummaryQna[resultId];

  useEffect(() => {
    // Focus the input when new result is set
    setFocusRef.current?.();
  }, [attachment]);

  useEffect(() => {
    explicitAnswersSessionManager.extendOrCreateSession('new_session');

    return () => {
      explicitAnswersSessionManager.endSession(
        ANSWERS_SESSION_END_REASONS.EXITED,
      );
      explicitAnswerDwellManager.endSession(ANSWER_DWELL_END_REASONS.EXITED);
    };
  }, []);

  useEffect(() => {
    explicitAnswersSessionManager.updateProperties({
      entryPoint: papData?.entryPoint,
      dashAnswerRequestId,
    });
  }, [papData?.entryPoint, dashAnswerRequestId]);

  const isPageVisible = usePageVisibility();
  useEffect(() => {
    if (isPageVisible && pageMode === PageModes.conversation) {
      // Start dwell again only if answer is in view
      explicitAnswerDwellManager.extendOrCreateSession();
      explicitAnswerDwellManager.updateProperties({
        entryPoint: papData?.entryPoint,
        actionSurfaceComponent,
      });
    } else if (!isPageVisible) {
      // User has switched tabs / minimized window / minimized app, we should end the sessions
      explicitAnswerDwellManager.endSession(
        ANSWER_DWELL_END_REASONS.LOST_FOCUS,
      );
    }
  }, [isPageVisible]);

  const fetchAndSummarize = async () => {
    setPageMode(PageModes.loading);
    setPageMode(PageModes.conversation);
  };

  useEffect(() => {
    if (attachment && isSummarize) {
      fetchAndSummarize();
    }
  }, [attachment, isSummarize]);

  const handleSubmit = async (query: string) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    setPageMode(PageModes.loading);
    resultQuestion({ role: Role.User, queryString: query });
    setPageMode(PageModes.conversation);
  };

  const handleAttachmentClick = async (attachment: SummarizableResult) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    logClickAnswersSource();
    if (
      attachment.launchResult !== null &&
      attachment.type === SummaryQnAEnum.TYPEAHEAD
    ) {
      attachment.launchResult('result_typeahead');
    } else {
      await openResult(attachment);
    }
  };

  const handleClickFeedback = (
    feedback: FeedbackOptions,
    answerIndex: number = 0,
  ) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    if (currentResult?.responses) {
      setCurrentFeedback(answerIndex);
      const response = currentResult?.responses[answerIndex];
      setResultFeedback(feedback, answerIndex);
      reportPapEvent(
        PAP_Click_DashAnswersFeedback({
          queryString: response.queryString,
          answerString: response.text,
          dashAnswerFeedback: feedback,
          answerId: response.id,
          numQuestionsAsked,
          entryPoint: papData?.entryPoint,
          dashAnswersSessionId:
            explicitAnswersSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashAnswerRequestId: response.requestId,
          actionSurfaceComponent,
          featureLine: 'answers',
        }),
      );
    } else {
      logger.warn(
        `handleClickFeedback - Response not found for index ${answerIndex}`,
      );
    }
    if (feedback === FeedbackOptions.Negative) {
      setShowSecondaryFeedbackModal(true);
    } else {
      setCurrentFeedback(-1);
      onOpenFeedbackSnackbar();
    }
  };

  const handleClickSecondaryFeedback = (
    feedback: DashAnswersFeedbackTag,
    otherFeedbackText: string | undefined,
  ) => {
    explicitAnswersSessionManager.extendOrCreateSession();
    if (currentFeedback >= 0 && currentResult?.responses) {
      const response = currentResult?.responses[currentFeedback];
      reportPapEvent(
        PAP_Click_DashAnswersTaggedFeedback({
          queryString: response.queryString,
          answerString: response.text,
          dashAnswersFeedbackTag: feedback,
          answerId: response.id,
          numQuestionsAsked,
          entryPoint: papData?.entryPoint,
          dashAnswersSessionId:
            explicitAnswersSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashAnswerRequestId: response.requestId,
          actionSurfaceComponent,
          featureLine: 'answers',
          dashAnswersFeedbackFreeformText: otherFeedbackText,
        }),
      );
      setCurrentFeedback(-1);
    } else {
      logger.warn(`handleClickSecondaryFeedback - no current feedback found`);
    }
    setShowSecondaryFeedbackModal(false);
    onOpenFeedbackSnackbar();
  };

  const handleCloseSecondaryFeedback = () => {
    setShowSecondaryFeedbackModal(false);
    onOpenFeedbackSnackbar();
  };

  const handleCopyText = (markdown: string, answerIndex: number) => {
    let copyFooter = '';
    if (attachment) {
      copyFooter = `\r\n\r\n[${attachment.title}](${attachment.url})\r\n[Generated using Dropbox Dash](https://dash.ai)`;
    }
    copyToClipboard(`${markdown}${copyFooter}`);
    onOpenCopyTextSnackbar();
    if (currentResult?.responses) {
      const response = currentResult?.responses[answerIndex];
      reportPapEvent(
        PAP_Copy_DashAnswers({
          entryPoint: papData?.entryPoint,
          queryString: response.queryString,
          answerString: response.text,
          answerId: response.id,
          numQuestionsAsked,
          dashAnswersSessionId:
            explicitAnswersSessionManager.getSessionIdOrUndefined(),
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
          dashAnswerRequestId: response.requestId,
          actionSurfaceComponent,
          featureLine: 'answers',
        }),
      );
    } else {
      logger.warn(
        `handleCopyText - Response not found for index ${answerIndex}`,
      );
    }
  };

  const handleReset = () => {
    explicitAnswersSessionManager.endSession(
      ANSWERS_SESSION_END_REASONS.CLEAR_CHAT,
    );
    resetConversation();
    setPageMode(PageModes.initial);
    setFocusRef.current?.();
  };

  const logClickAnswersSource = (isAttachment = false) => {
    const response = currentResult?.responses?.[0];
    reportPapEvent(
      PAP_Click_DashAnswersSource({
        queryString: response?.queryString,
        answerString: response?.text,
        answerId: response?.id,
        numQuestionsAsked,
        entryPoint: papData?.entryPoint,
        dashAnswersSessionId:
          explicitAnswersSessionManager.getSessionIdOrUndefined(),
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        dashAnswerRequestId: response?.requestId,
        actionSurfaceComponent,
        featureLine: 'answers',
        isAttachment,
      }),
    );
  };

  const showResetButton =
    enableSummary &&
    pageMode === PageModes.conversation &&
    conversation.length > 0;

  return (
    <>
      <div
        data-testid="AnswerContainer"
        className={classNames(styles.conversationContainer, {
          [styles.fullPageContainer]: isFullPage,
        })}
      >
        {onClose && (
          <div className={styles.header}>
            {EnvCtx.platform === 'darwin' && EnvCtx.surface === 'desktop' && (
              <DragBar height={32} roomForScroll={10} />
            )}
            <RailHeader
              openURL={openURL}
              onClose={onClose}
              onReset={showResetButton ? handleReset : undefined}
            />
            {attachment && (
              <Source
                key={attachment?.uuid}
                source={attachment}
                onClick={handleAttachmentClick}
                copyToClipboard={copyToClipboard}
              />
            )}
          </div>
        )}

        {showResetButton && isFullPage && (
          <div className={styles.resetContainer}>
            <Button
              variant="opacity"
              onClick={handleReset}
              withIconStart={<UIIcon src={RotateLeftLine} />}
            >
              {i18n.t('clear_chat')}
            </Button>
          </div>
        )}

        {pageMode === PageModes.initial ? (
          <EmptyState
            isFullPage={isFullPage}
            enableSuggestedQuestions={enableSuggestedQuestions}
            onSelectSuggestion={handleSubmit}
          />
        ) : (
          <div
            className={classNames(styles.contentWrapper, {
              [styles.fullPageContentWrapper]: isFullPage,
            })}
          >
            <>
              {!currentResult && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={styles.spinnerContainer}
                  width="100%"
                >
                  <Text
                    variant="label"
                    size="medium"
                    isBold
                    className={styles.contentText}
                  >
                    {i18n.t('doc_summary_qna_loading_summary')}
                  </Text>
                  <Box display="flex" alignItems="center">
                    <Spinner size="small" aria-valuetext={i18n.t('loading')} />
                  </Box>
                </Box>
              )}

              {currentResult?.status === LoadingStatus.error &&
                !currentResult.responses?.length &&
                'description' in currentResult && <ErrorBox />}
              {currentResult?.status !== LoadingStatus.error && (
                <Responses
                  responses={currentResult?.responses || []}
                  onClickFeedback={handleClickFeedback}
                  onCopyMessage={handleCopyText}
                  busy={resultStatus !== LoadingStatus.idle}
                  resultQuestion={resultQuestion}
                />
              )}
              {pageMode === PageModes.loading && <SpinnerSystemMessage />}
            </>
          </div>
        )}

        <div
          className={classNames(styles.contentWrapper, {
            [styles.fullPageContentWrapper]: isFullPage,
          })}
        >
          <div className={styles.queryInputContainer}>
            <AskQueryInput
              setFocusRef={setFocusRef}
              onSubmit={handleSubmit}
              loading={
                resultStatus !== LoadingStatus.idle ||
                currentResult?.status === LoadingStatus.error
              }
              attachment={attachment}
              onChange={() => {
                // If session times out, we'll start a new one with an appropriate start reason
                explicitAnswersSessionManager.extendOrCreateSession(
                  'type_query',
                );
                explicitAnswersSessionManager.updateProperties({
                  entryPoint: papData?.entryPoint,
                  dashAnswerRequestId,
                  actionSurfaceComponent: isFullPage ? undefined : 'ask_dialog',
                });
                explicitAnswerDwellManager.endSession(
                  ANSWER_DWELL_END_REASONS.NEW_QUESTION,
                );
              }}
              placeholder={i18n.t('doc_summary_qna_input_placeholder')}
            />
          </div>
        </div>
      </div>
      <MessageSecondaryFeedback
        isOpen={showSecondaryFeedbackModal}
        onClose={handleCloseSecondaryFeedback}
        feedbackOptions={summaryQnaSecondaryFeedbackOptions(
          currentResult?.responses?.[currentFeedback]?.role === Role.System &&
            currentResult?.responses[currentFeedback]?.queryString === 'Summary'
            ? ['irrelevant_answer']
            : undefined,
        )}
        onClickFeedback={handleClickSecondaryFeedback}
        actionSurfaceComponent="ask_dialog"
      />
    </>
  );
};

const EmptyState = ({
  isFullPage,
  onSelectSuggestion,
  enableSuggestedQuestions,
}: {
  isFullPage?: boolean;
  onSelectSuggestion: (text: string) => void;
  enableSuggestedQuestions?: boolean;
}) => {
  return (
    <div
      className={classNames(styles.contentWrapper, {
        [styles.fullPageContentWrapper]: isFullPage,
      })}
    >
      {isFullPage ? (
        <FullPageAnswersEmptyState
          onSelectSuggestion={onSelectSuggestion}
          enableSuggestedQuestions={enableSuggestedQuestions}
        />
      ) : (
        <AnswersEmptyState
          title={i18n.t('answers_empty_title')}
          subtitle={i18n.t('answers_empty_subtitle')}
        />
      )}
    </div>
  );
};
