// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3449
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';

// Event_name: 'succeed.unpublish_stack'
// Description: Fires when unpublishing stack by Team admin succeeds
// Owner: otc
export type Succeed_UnpublishStack = PAPEventGeneric<
  'stacks',
  'succeed',
  'unpublish_stack',
  {
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The stack id that represents the stack
    stackId?: string;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Succeed_UnpublishStack(
  properties?: Succeed_UnpublishStack['properties'],
): Succeed_UnpublishStack {
  return <Succeed_UnpublishStack>{
    class: 'stacks',
    action: 'succeed',
    object: 'unpublish_stack',
    properties,
  };
}
