import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { DESKTOP_LOCAL_FILE_CONNECTOR_ID } from '@mirage/shared/connectors';
import {
  ConnectorFilter,
  ContentTypeFilter,
  isConnectorFilter,
  isContentTypeFilter,
  isLastUpdatedFilter,
  isPersonFilter,
  LastUpdatedFilter,
  PersonFilter,
  SearchFilter,
} from '@mirage/shared/search/search-filters';

import type { dcs } from '@dropbox/api-v2-client';
import type {
  AuthorInfo,
  BrandedSiteInfo,
  ConnectorInfo,
  DataSource,
  FileTypeInfo,
  IconResource,
  ItemLocation,
  SearchResult,
} from '@mirage/shared/search/search-result';

// TODO: remove this and update all imports to use shared if we like the strategy
// having it here was causing circular dependencies, and boundary import issues
export {
  SearchResult,
  ConnectorInfo,
  IconResource,
  FileTypeInfo,
  BrandedSiteInfo,
  DataSource,
  ItemLocation,
  AuthorInfo,
};

import type { APIv2CallableWithHeaders } from '..';

export async function getSearchResults(
  api: APIv2CallableWithHeaders,
  query: string,
  filters: SearchFilter[] = [],
): Promise<SearchResult[]> {
  // form connector filters params
  const cfilters: Array<dcs.QueryFilter> = filters
    .filter(
      (filter): filter is ConnectorFilter =>
        isConnectorFilter(filter) &&
        filter.id !== DESKTOP_LOCAL_FILE_CONNECTOR_ID,
    )
    .map(({ parameters }) => ({
      filter: {
        '.tag': 'connector_filter',
        connector_id: parameters.connectorId,
      },
    }));

  // form last updated filter param
  const tfilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is LastUpdatedFilter =>
      isLastUpdatedFilter(filter),
    )
    .map(({ parameters }) => ({
      filter: {
        '.tag': 'time_range_filter',
        start_datetime: new Date(parameters.start).toISOString(),
        end_datetime: new Date(parameters.end).toISOString(),
      },
    }));

  const peopleFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is PersonFilter => isPersonFilter(filter))
    .map(({ parameters }) => ({
      filter: {
        '.tag': 'person_filter',
        email: parameters?.email || '',

        // We want our people filter to match on any of the creator fields
        match_creator: true,
        match_last_modifier: true,
        match_sender: true,
      },
    }));

  const contentTypeFilters: Array<dcs.QueryFilter> = filters
    .filter((filter): filter is ContentTypeFilter =>
      isContentTypeFilter(filter),
    )
    .map(({ parameters }) => ({
      filter: {
        '.tag': 'file_type_filter',
        file_type: parameters.key,
      },
    }));
  const appliedFilters = [
    ...cfilters,
    ...tfilters,
    ...peopleFilters,
    ...contentTypeFilters,
  ];

  // TODO: API error handling. Should probably define this at the boundary and return predictable errors
  // currently just bubbling up to the caller

  // TODO - remove after #sev-speedy-carnelian-sandpiper
  const response = await api('dcsSearch', {
    query_text: query,
    filters: appliedFilters,
  });

  const { headers, result } = response;

  if (result.results === undefined) {
    return [];
  }

  const searchRequestId = result?.search_request_id || '';
  const analyticsTraceId = headers.get('x-dropbox-request-id') || '';

  const mirageSearchResults = (result.results || [])
    .filter((r) => r.query_result && r.query_result['.tag'] === 'search_result')
    .map((result) =>
      transformDashResultToMirage(
        { ...result.query_result, relevance_score: result.relevance_score },
        searchRequestId,
        analyticsTraceId,
      ),
    )
    .filter((msr): msr is SearchResult => Boolean(msr));
  return mirageSearchResults;
}
