import { atom, useSetAtom } from 'jotai';
import { useEffect } from 'react';

export const mouseActivityAtom = atom<boolean>(true);

// Mouse hover can effect impact keyboard navigation, so we need to track
// when it's active and when it's not
export function useInitDetectMouseActivity() {
  // Store the activity state in an atom so components can read it without setting up redundant event listeners
  const setIsMouseActive = useSetAtom(mouseActivityAtom);

  useEffect(() => {
    function activateMouse() {
      setIsMouseActive(true);
    }
    function deactivateMouse(event: KeyboardEvent) {
      // The mouse can still be active if the user is holding down a modifier key
      if (
        event.key === 'Shift' ||
        event.key === 'Control' ||
        event.key === 'Alt' ||
        event.key === 'Meta'
      ) {
        return;
      }
      setIsMouseActive(false);
    }

    document.addEventListener('mousemove', activateMouse);
    document.addEventListener('keydown', deactivateMouse);

    return () => {
      document.removeEventListener('mousemove', activateMouse);
      document.removeEventListener('keydown', deactivateMouse);
    };
  }, [setIsMouseActive]);
}
