import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import { managedShortcuts } from '@mirage/service-typeahead-search/service/utils/managed-shortcuts';
import {
  extractParameterMatches,
  getUrl,
  isHotwordActive,
  requiredParameterCount,
  tokenizeTitle,
  toQueryParts,
} from '@mirage/service-typeahead-search/service/utils/url-shortcut-utils';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import * as rx from 'rxjs';

import type { ConnectorConnections } from '@mirage/service-connectors/service';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

export const search = wrappers.wrapped(SourceId.URLShortcuts, raw);

export function raw(
  query: string,
  _cache: TypeaheadCache,
  connections: ConnectorConnections,
): Observable<typeahead.TaggedResult> {
  return rx.defer(() => {
    const connectorTypes = connections.map((c) => c.connector?.id_attrs?.type);
    const connectorTypeSet = new Set(connectorTypes);

    const queryParts = toQueryParts(query);
    const [hotword, ...queryPartsExcludingHotword] = queryParts;

    // skip url shortcuts search on empty query
    if (query.length === 0) return rx.from([]);

    const wrapped = managedShortcuts
      // Filter out shortcuts that don't belong to any of the active connectors
      .filter((shortcut) => {
        if (!shortcut.supportedConnectors) return true;

        return shortcut.supportedConnectors?.some((connector) =>
          connectorTypeSet.has(connector),
        );
      })
      // Filter URL shortcuts where query matches hotword
      .filter((shortcut) =>
        shortcut.hotwords.some((hotword) => isHotwordActive(query, hotword)),
      )
      .map((shortcut) => {
        const required = requiredParameterCount(shortcut.urlTemplate);
        const activeHotword =
          shortcut.hotwords.find((hotword) =>
            isHotwordActive(query, hotword),
          ) || ''; // will always match because of filter above

        return {
          ...shortcut,
          parameters: {
            activeHotword,
            count: required,
            url:
              getUrl(shortcut.urlTemplate, queryPartsExcludingHotword) ||
              shortcut.urlTemplate,
            chunks: queryPartsExcludingHotword.length,
            matches:
              hotword === activeHotword
                ? extractParameterMatches(shortcut.urlTemplate, query)
                : [],
            highlightWords: queryPartsExcludingHotword,
            complete: queryPartsExcludingHotword.length >= required,
            matched: hotword === activeHotword,
            title: tokenizeTitle(
              shortcut.titleTemplate,
              activeHotword,
              queryParts,
            ),
          },
        };
      });

    return rx.from(
      wrapped.map((result) => primitives.urlShortcut(result.uuid, result)),
    );
  });
}
