// Hard-coding these into the client as they will infrequently change.
// Taken from server protos https://dropbox.sourcegraphcloud.com/github.com/dropbox-internal/server/-/blob/go/src/dropbox/dash/api/metadata/file_type/file_type.go?L18
// NOTE: Please update the PAP property when you add new values here
// NOTE: Please update local file os queries when adding new content types
// https://pap.pp.dropbox.com/#/event_property/1625

export const contentTypes = [
  {
    key: 'folder',
    label: 'Folder',
  },
  {
    key: 'document',
    label: 'Document',
  },
  {
    key: 'presentation',
    label: 'Presentation',
  },
  {
    key: 'spreadsheet',
    label: 'Spreadsheet',
  },
  {
    key: 'pdf',
    label: 'PDF',
  },
  {
    key: 'video',
    label: 'Video',
  },
  {
    key: 'image',
    label: 'Image',
  },
  {
    key: 'audio',
    label: 'Audio',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'event',
    label: 'Event',
  },
  {
    key: 'chat',
    label: 'Chat',
  },
] as const;
