import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import {
  MultiAnswerResponse,
  QuestionAndAnswerResponse,
  QuestionAndAnswerSource,
} from '@mirage/shared/answers/multi-answer';
import { v4 as uuid } from 'uuid';
import { SearchResult } from '../search';

import type { APIv2Callable } from '..';
import type { context_engine, dash } from '@dropbox/api-v2-client';

const NUM_SOURCES_SHOWN = 3;

export type AnswerResponse = {
  answer: string;
  sources: SearchResult[];
  searchRequestId?: string;
  answerId?: string;
  conversationId?: string;
};

function getClientContext(): dash.ClientContext {
  return {
    timezone_string: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
}

export async function getImplicitAnswer(
  api: APIv2Callable,
  query: string,
): Promise<AnswerResponse> {
  const result = await api('dcsSearch', {
    query_text: query,
    client_context: getClientContext(),
    type: {
      '.tag': 'answer_required',
    },
  });

  const results = result?.results || [];

  const searchRequestId = result?.search_request_id || '';
  // There should only be one answer returned
  // Find the first one in case there are unintended search results included
  const answerResult = results.find(
    (r) => r.query_result && r.query_result['.tag'] === 'answer',
  );
  const answerQueryResult =
    answerResult?.query_result as dash.query_result_unionAnswer;

  const dedupedSources = getDedupedSources(answerQueryResult?.sources || []);
  const answerSources = dedupedSources
    .map((dsr) => transformDashResultToMirage(dsr, searchRequestId))
    .filter((msr): msr is SearchResult => Boolean(msr));

  return {
    answer: answerQueryResult?.answer || '',
    sources: answerSources,
    searchRequestId,
    // TODO: add answer_id and conversation_id once included in API respose
  };
}

export async function getAnswersForQuery(
  api: APIv2Callable,
  query: string,
): Promise<MultiAnswerResponse> {
  const contextEngineExperimentSetting = await getCachedOrFetchFeatureValue(
    'context_engine_2024_08_19_experiment_setting',
  );
  const contextEngineExperimentSettingAsString =
    contextEngineExperimentSetting === undefined
      ? undefined
      : String(contextEngineExperimentSetting);
  const result = await api('contextEngineGetAnswersForQuery', {
    user_query: query,
    source: 'dash',
    client_context: getClientContext() as context_engine.ClientContext,
    experiment_setting: contextEngineExperimentSettingAsString || '',
  });

  const answers: QuestionAndAnswerResponse[] =
    result.answers
      ?.filter((contextEngineAnswer: context_engine.Answer) => {
        const model = contextEngineAnswer.answer?.output?.model?.items?.[0];
        return model?.['.tag'] !== 'context_user_error';
      })
      ?.map((contextEngineAnswer: context_engine.Answer, index: number) => {
        const model = contextEngineAnswer.answer?.output?.model?.items?.[0];

        let answerText: string | undefined;
        let sources: QuestionAndAnswerSource[] = [];
        switch (model?.['.tag']) {
          case 'answer_info':
            answerText = model?.answer;
            sources =
              model.sources?.map((documentInfo) => {
                return {
                  id3p: documentInfo.third_party_id ?? '',
                  brandedType: documentInfo.branded_type ?? '',
                  title: documentInfo.title ?? '',
                  url: documentInfo.url ?? '',
                  connectorName:
                    documentInfo.connector_info?.connector_name ?? '',
                  iconUrl: documentInfo.connector_info?.connector_icon_url,
                  connectorInfo: documentInfo.connector_info,
                };
              }) ?? [];
            break;
          case 'string_value':
            answerText = model?.string_value;
            // TODO: waiting for context engine API to return sources in this case
            break;
        }
        const answerId = contextEngineAnswer?.answer_id;

        const conversationId =
          contextEngineAnswer.conversation_id &&
          contextEngineAnswer.conversation_id !== ''
            ? contextEngineAnswer.conversation_id
            : uuid();
        return {
          position: index,
          question: contextEngineAnswer.query ?? '',
          answer: answerText ?? '',
          sources: sources.slice(0, NUM_SOURCES_SHOWN),
          conversationId,
          answerId,
        };
      })
      .filter((item): item is QuestionAndAnswerResponse => !!item) || [];
  return { answers, requestUuid: uuid() };
}

export type ConversationResponse = {
  messages: AnswerMessage[];
  requestId: string;
  conversationId: string;
};

export type AnswerMessage = {
  '.tag': 'answer';
  answer: string;
  sources: SearchResult[];
};

export async function getConversation(
  api: APIv2Callable,
  query: string,
  attachments?: dash.Attachment[],
  conversationId?: string,
): Promise<ConversationResponse> {
  const result = await api('dcsConversation', {
    query_text: query,
    attachments,
    client_context: getClientContext(),
    conversation_id: conversationId,
  });

  const messages = result?.messages || [];

  const mirageMessages: AnswerMessage[] = messages
    .map((message) => {
      // If it's an answer, we need to transform the sources into SearchResult
      if (message.contents && message.contents['.tag'] === 'answer') {
        return transformAnswerMessage(message.contents);
      }
    })
    .filter((message): message is AnswerMessage => message !== undefined);
  return {
    messages: mirageMessages,
    requestId: result.request_id || '',
    conversationId: result.conversation_id || '',
  };
}

// Turn answer sources from dash.searchResult -> SearchResult
const transformAnswerMessage = (
  message: dash.contents_unionAnswer,
): AnswerMessage => {
  const newMessage: AnswerMessage = {
    ...message,
    answer: message.answer || '',
    sources: [],
  };

  const dedupedSources = getDedupedSources(message.sources || []);
  newMessage.sources = dedupedSources
    .map((source) => transformDashResultToMirage(source))
    .filter((msr): msr is SearchResult => Boolean(msr));

  return newMessage;
};

// For recurring event sources we only want to show one instance of the event
// Long term this de-duping may live on server: https://jira.dropboxer.net/browse/OTCSI-274
// Short-term we'll do it here
const getDedupedSources = (sources: dash.SearchResult[]) => {
  const seenRecurringIds = new Set<string | undefined>();
  return sources.filter((source) => {
    const isEvent = source.record_type?.['.tag'] === 'event';

    // If not an event..
    if (!isEvent) {
      return true;
    }

    // Splitting on _R because some modified recurring event ids are in the form of `event_id_R{TIMESTAMP}`
    const recurringEventId = source.recurring_event_id?.split('_R')[0];
    // If not a recurring event..
    if (!recurringEventId) {
      return true;
    }

    // If a recurring event that we haven't tracked yet..
    if (!seenRecurringIds.has(recurringEventId)) {
      seenRecurringIds.add(recurringEventId);
      return true;
    }

    return false;
  });
};
