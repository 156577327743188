// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3446
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';

// Event_name: 'succeed.publish_stack'
// Description: Fires when admin successfully publishes a stack
// Owner: otc
export type Succeed_PublishStack = PAPEventGeneric<
  'stacks',
  'succeed',
  'publish_stack',
  {
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // The stack id that represents the stack
    stackId?: string;
  }
>;

export function PAP_Succeed_PublishStack(
  properties?: Succeed_PublishStack['properties'],
): Succeed_PublishStack {
  return <Succeed_PublishStack>{
    class: 'stacks',
    action: 'succeed',
    object: 'publish_stack',
    properties,
  };
}
