import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { RadioButton } from '@dropbox/dig-components/controls';
import { Menu } from '@dropbox/dig-components/menu';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoLine } from '@dropbox/dig-icons/dist/mjs/assets';
import {
  FeatureFlags,
  FeatureName,
} from '@mirage/service-experimentation/features';
import {
  FeatureRing,
  FeatureRingSettings,
} from '@mirage/service-feature-ring-settings/types';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import styles from './FeatureRingPicker.module.css';

const featureRingTestFlags: FeatureName[] = [
  'test_feature_ring_internal_team',
  'test_feature_ring_internal_dash',
  'test_feature_ring_internal_dbx',
  'test_feature_ring_external_beta',
  'test_feature_ring_lts',
];

export type FeatureRingProps = {
  featureFlags: FeatureFlags;
  featureRingSettings: FeatureRingSettings | undefined;
  onSetOverride: (value: FeatureRing) => void;
};

const featureRingDebugRow = (
  featureFlags: FeatureFlags,
  featureName: FeatureName,
) => {
  return (
    <div key={featureName} className={styles.featureRingDebugRow}>
      <RadioButton checked={featureFlags[featureName].value === true} />
      <Text monospace tagName={'label'} key={featureName}>
        {featureName}
      </Text>
    </div>
  );
};

export const FeatureRingPicker = ({
  featureFlags,
  featureRingSettings,
  onSetOverride,
}: FeatureRingProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box paddingTop="16" display="flex" style={{ gap: 16 }}>
        {featureRingSettings && (
          <Menu.Wrapper onSelection={onSetOverride}>
            {({ getContentProps, getTriggerProps }) => (
              <>
                <Button
                  {...getTriggerProps()}
                  variant="opacity"
                  withDropdownIcon
                >
                  {featureRingSettings.override?.displayName ??
                    featureRingSettings.default?.displayName}
                </Button>
                <Menu.Content {...getContentProps()}>
                  <Menu.Segment>
                    {featureRingSettings.rings.map((ring) => {
                      return (
                        <Menu.ActionItem
                          key={ring.id}
                          value={ring}
                          disabled={ring.id == featureRingSettings.override?.id}
                        >
                          {ring.displayName}
                        </Menu.ActionItem>
                      );
                    })}
                  </Menu.Segment>
                </Menu.Content>
              </>
            )}
          </Menu.Wrapper>
        )}

        <DigTooltip
          openDelay={0}
          title={
            <Box
              display="flex"
              flexDirection="column"
              paddingBottom="20"
              paddingLeft="12"
              paddingRight="12"
            >
              <Text size="medium" isBold>
                Growthbook Test Feature Flags
              </Text>
              <Text>
                These are features setup in Growthbook that assist in validating
                feature ring attributes are set. At least one of these should be
                enabled.
              </Text>
              <Box paddingTop="8">
                {featureRingTestFlags.map((featureName) =>
                  featureRingDebugRow(featureFlags, featureName),
                )}
              </Box>
            </Box>
          }
        >
          <IconButton variant="transparent">
            <UIIcon src={InfoLine} />
          </IconButton>
        </DigTooltip>
      </Box>
      <Text className={styles.defaultRingNote}>
        Your default feature ring is{' '}
        <Text isBold>{featureRingSettings?.default.displayName}</Text>.
      </Text>
    </Box>
  );
};
