import { Card, CardContentsProps } from '@dropbox/dash-component-library';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import classnames from 'classnames';
import { KeyboardEvent, MouseEvent } from 'react';
import styles from './ModuleCard.module.css';

export interface ModuleCardProps {
  children: React.ReactNode;
  breakout?: CardContentsProps['breakout'];
  className?: string;
  style?: React.CSSProperties;
  withHeader?: React.ReactNode;
  onClick?: (e: MouseEvent | KeyboardEvent) => void;
  isLink?: boolean;

  /**
   * The URL to show in the browser when hovering over the card
   * Will not be used for actual navigation
   */
  visibleUrl?: string;
}

export const ModuleCard = ({
  children,
  breakout = 'standard',
  className,
  style,
  withHeader,
  onClick,
  isLink,
  visibleUrl,
}: ModuleCardProps) => {
  const handleClick = (e: MouseEvent | KeyboardEvent) => {
    onClick?.(e);
    // Avoid the default behavior of the link
    e.preventDefault();
  };

  return (
    <Card
      variant="outline"
      style={style}
      isLink={isLink || !!onClick}
      className={classnames(
        styles.card,
        {
          [styles.hasHeader]: !!withHeader,
          [styles.isLink]: isLink || !!onClick,
        },
        className,
      )}
    >
      {withHeader}
      <Card.Contents
        breakout={breakout}
        className={classnames(styles.cardContents, {
          [styles.cardBreakoutSmall]: breakout === 'small',
          [styles.cardBreakoutXSmall]: breakout === 'xsmall',
          [styles.cardBreakoutStandard]: breakout === 'standard',
          [styles.cardBreakoutLarge]: breakout === 'large',
          [styles.cardBreakoutXLarge]: breakout === 'xlarge',
          [styles.cardLink]: !!onClick,
        })}
      >
        {onClick && (
          <Card.Link
            onClick={handleClick}
            onKeyDown={onKeyDownCommitFn(handleClick)}
            href={visibleUrl ?? '#'}
            className={styles.cardLink}
            isOverlay
          />
        )}
        {children}
      </Card.Contents>
    </Card>
  );
};
