import { isDefined } from '@mirage/shared/util/tiny-utils';
import { UrlMetadata } from '../types';

import type { dcs } from '@dropbox/api-v2-client/types';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

const isBlobContent = (
  content: dcs.blob_content_union,
): content is dcs.blob_content_unionRawContent => {
  return content?.['.tag'] === 'raw_content';
};

export const getMetadataForUrls = async (
  urls: string[],
  callApiV2: APIv2Callable,
): Promise<UrlMetadata[] | undefined> => {
  const dcsResponse = await callApiV2('dcsGetLinkMetadata', {
    url_or_uuids: urls.map((url) => {
      return {
        link_type: {
          '.tag': 'url',
          url,
        },
      };
    }),
  });
  let nonConnectorUrls = urls; // Responses where the error_code field is populated
  let dcsResponseValid: UrlMetadata[] = []; // Constructed URL metadata from where the response is valid
  if (dcsResponse.results) {
    dcsResponseValid = dcsResponse.results
      .filter((response) => response.error_code === '' && response.link)
      .map((response): UrlMetadata => {
        let thumbnailUrl = '';
        if (
          response?.body?.blob_content &&
          isBlobContent(response.body.blob_content)
        ) {
          thumbnailUrl = response.body.blob_content.raw || '';
        }

        const contentLastModifiedMs = response.last_modified_time
          ? new Date(response.last_modified_time).getTime()
          : null;

        return {
          url: response.link as string, // this cast is safe since it's checked in the filter
          title: response.title,
          thumbnailUrl,
          lastFetched: Date.now(),
          faviconUrl: '', // No favicon for DCS links
          contentLastModifiedMs, // Last modified time of the underlying content according to DCS
        };
      });

    const connectorUrls = dcsResponseValid.map((response) => response.url);
    nonConnectorUrls = urls.filter((url) => !connectorUrls.includes(url));
  }

  const stacksResponse = await callApiV2('stacksGetMetadataForUrls', {
    urls: nonConnectorUrls, // Call normal endpoint if not a connector
  })
    .then((result) => {
      const urlMetadata: UrlMetadata[] | undefined = result.url_metadata
        ?.map((metadata) => {
          if (!metadata.url) {
            return undefined;
          }
          return {
            url: metadata.url,
            title: metadata.title,
            faviconUrl: metadata.favicon_url,
            thumbnailUrl: metadata.thumbnail_url,
            lastFetched: Date.now(),
            // stacks/get_metadata_for_urls does not return contentLastModified currently
            contentLastModifiedMs: null,
          };
        })
        .filter(isDefined);
      return urlMetadata;
    })
    .catch(undefined);

  const allMetadata = stacksResponse
    ? dcsResponseValid.concat(stacksResponse)
    : dcsResponseValid;
  return allMetadata;
};
