import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { ThemeProvider } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import { PAP_Preview_DashStack } from '@mirage/analytics/events/types/preview_dash_stack';
import { PAP_Select_DashLearnMore } from '@mirage/analytics/events/types/select_dash_learn_more';
import { PAP_Select_DashLogin } from '@mirage/analytics/events/types/select_dash_login';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { getLoginPathWithReturnRedirectURLParam } from '@mirage/service-auth';
import { stackDerivePAPProps } from '@mirage/service-stacks/service/utils';
import { TwoColumnGrid } from '@mirage/shared/two-column-grid/TwoColumnGrid';
import { DASH_MARKETING_PAGE } from '@mirage/shared/urls';
import { BlurWithOverlay } from '@mirage/shared/util/BlurWithOverlay';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { activeStackAtom, activeStackItemsAtom } from '../ActiveStack/atoms';
import Actions from './Actions';
import { EmptyStateBox } from './Connectors/EmptyStateBox';
import { DndProvider } from './DragAndDrop/Provider';
import styles from './FullScreenStack.module.css';
import Header from './Header';
import { LoginCTA } from './LoginCTA';
import { SharingModule } from './Modules/SharingModule';
import { useSectionsNavigation } from './Sections/hooks';
import { Navigation } from './Sections/Navigation';
import { Sections } from './Sections/Sections';
import { SectionsHeader } from './Sections/SectionsHeader';

export default function FullScreenPublicStackV2() {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const triggerEditStackRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const stack = useAtomValue(activeStackAtom);
  const items = useAtomValue(activeStackItemsAtom);
  const { scrollToSection } = useSectionsNavigation();
  const goToLogin = useCallback(
    async (actionSurfaceComponent: ActionSurfaceComponent) => {
      if (stack) {
        await reportPapEvent(
          PAP_Select_DashLogin({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent,
            featureLine: 'stacks',
          }),
          true,
        );
      }

      const loginUrl = await getLoginPathWithReturnRedirectURLParam();
      navigate(loginUrl);
    },
    [stack, reportPapEvent, navigate],
  );

  const goToLearnMore = useCallback(
    async (actionSurfaceComponent: ActionSurfaceComponent) => {
      if (stack) {
        await reportPapEvent(
          PAP_Select_DashLearnMore({
            ...stackDerivePAPProps(stack),
            actionSurfaceComponent,
            featureLine: 'stacks',
          }),
          true,
        );
      }
      location.assign(DASH_MARKETING_PAGE);
    },
    [stack, reportPapEvent],
  );

  useEffect(() => {
    if (stack) {
      reportPapEvent(
        PAP_Preview_DashStack({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    }
  }, [stack, reportPapEvent]);

  return (
    <div className={styles.containerV2}>
      <DndProvider>
        <TwoColumnGrid
          headerChildren={
            <>
              <Header
                stack={stack}
                editData={{ canEdit: false }}
                triggerEditStackRef={triggerEditStackRef}
                defaultUserInfo={{ displayName: i18n.t('nobody') }}
                withImmediateAction={
                  <Actions
                    showShareLabeledIcon
                    shareButtonTooltipProps={{
                      title: i18n.t('public_stack_sign_in_to_share_cta'),
                    }}
                    stack={stack}
                    menuPlacement="bottom-end"
                  />
                }
              />
              <SectionsHeader
                namespaceId={stack?.namespace_id ?? ''}
                publicPreview
              />
            </>
          }
          sidebarChildren={
            <>
              <Navigation scrollToSection={scrollToSection} />
              {stack && (
                <BlurWithOverlay
                  overlay={
                    <LoginCTA
                      onClickLogin={() => goToLogin('overlay')}
                      onClickLearnMore={() => goToLearnMore('overlay')}
                    />
                  }
                  blurWhen="always"
                  overlayWhen="always"
                >
                  <SharingModule stack={stack} />
                </BlurWithOverlay>
              )}
            </>
          }
          mainChildren={
            <>
              <Sections
                namespaceId={stack?.namespace_id ?? ''}
                onEditItem={(_) => {}}
              />
              {!items?.length && (
                <BlurWithOverlay
                  overlay={
                    <LoginCTA
                      onClickLogin={() => goToLogin('overlay')}
                      onClickLearnMore={() => goToLearnMore('overlay')}
                    />
                  }
                  blurWhen="always"
                  overlayWhen="always"
                >
                  <EmptyStateBox hasWritePermissions={false} />
                </BlurWithOverlay>
              )}
            </>
          }
        />
      </DndProvider>
      <ThemeProvider theme="vis2023">
        {({ getInverseProps }) => (
          <div className={styles.publicPreviewFooter}>
            <div>
              <Text
                variant="label"
                size="large"
                className={classNames(getInverseProps().className)}
                isBold
              >
                {i18n.t('public_stack_footer_log_in')}
              </Text>
              <Text
                variant="label"
                size="large"
                className={classNames(getInverseProps().className)}
              >
                {i18n.t('public_stack_footer_suffix')}
              </Text>
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                variant="primary"
                size="large"
                className={getInverseProps().className}
                onClick={() => goToLogin('footer')}
                data-uxa-log={createUxaElementId('sign_in_button', {
                  actionSurfaceComponent: 'public_stack_preview_footer',
                  featureLine: 'stacks',
                })}
              >
                {i18n.t('signin_cta')}
              </Button>
              <Button
                variant="opacity"
                size="large"
                className={getInverseProps().className}
                onClick={() => goToLearnMore('footer')}
                data-uxa-log={createUxaElementId('learn_about_button', {
                  actionSurfaceComponent: 'public_stack_preview_footer',
                  featureLine: 'stacks',
                })}
              >
                {i18n.t('public_stack_learn_about_dash')}
              </Button>
            </div>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}
