import {
  AuthenticationData,
  Service as AuthService,
} from '@mirage/service-auth/service/index';
import { callApiV2 } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { nonEmpty } from '@mirage/shared/util/tiny-utils';

const logger = tagged('tokenExchange');

/**
 * Warning: A refresh token can only be exchanged once. If we use the same
 * refresh token to exchange a second time, the api call to
 * stacksGetDashOauthToken will succeed, but the subsequent call to
 * refreshAccessToken will fail. To work around this, we don't revoke the
 * token if it is an exchanged token.
 */
export async function tryExchangeOauthToken(
  targetApiAppId: number,
  authData: AuthenticationData,
  authService: AuthService,
) {
  try {
    const response = await callApiV2('stacksGetDashOauthToken', {
      target_api_app: targetApiAppId,
    });

    // Update refresh token only.
    await authService.migrateFromExternalManagement({
      ...authData,
      refreshToken: nonEmpty(response.refresh_token, 'refresh_token'),
      // Indicate this is an exchanged token so we don't revoke it upon logout.
      isExchangedToken: true,
    });

    // Update accessToken and accessTokenExpiresAt.
    await authService.refreshAccessToken();

    // Write log with the keyword "login" so that we can find this log easily.
    logger.info(`login: Successfully exchanged oauth token!`);

    return true; // succeeded
  } catch (e) {
    logger.warn(`login: Failed with error:`, e);
  }

  return false; // failed
}
