import { IconButton } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import {
  AiChatLine,
  ShuffleLine,
  ZoomInLine,
  ZoomOutLine,
} from '@dropbox/dig-icons/assets';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { tagged } from '@mirage/service-logging';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { $getSelection, $isRangeSelection } from 'lexical';
import {
  ComponentType,
  CSSProperties,
  memo,
  SVGAttributes,
  useEffect,
  useState,
} from 'react';
import { FormatToolbar } from './FormatToolbar';
import styles from './SelectionToolbar.module.css';

const logger = tagged('ComposeEditor/SelectionToolbar');

const TOOLBAR_WIDTH = 200;

export type SelectionAction = {
  type: 'rewrite' | 'make-longer' | 'make-shorter' | 'prompt';
  selectedText: string;
};
export interface SelectionToolbarPluginProps {
  offsetTop?: number;
  onTriggerSelectionAction: (action: SelectionAction) => void;
}
export const SelectionToolbarPlugin = memo(
  ({
    onTriggerSelectionAction,
    offsetTop = 0,
  }: SelectionToolbarPluginProps) => {
    const editorSelectionStates = useEditorSelectionStates();
    if (!editorSelectionStates) {
      return (
        <div className={styles.NoSelectionToolbar}>
          <FormatToolbar
            inverse={true}
            formatActions={[
              'bold',
              'underline',
              'italic',
              'divider',
              'h1',
              'h2',
            ]}
          />
        </div>
      );
    }
    const positionStyles = getPositionStyles(editorSelectionStates, offsetTop);
    const actions = getActions(editorSelectionStates.selectedText);
    return (
      <div className={styles.SelectionToolbar} style={positionStyles}>
        {actions.map((actionInfo, i) =>
          actionInfo.type === 'divider' ? (
            <div key={`divider-${i}`} className={styles.ActionsDivider} />
          ) : (
            <DigTooltip
              title={actionInfo.title}
              key={actionInfo.action.type}
              openDelay={0}
            >
              <IconButton
                variant="borderless"
                className={styles.SelectionActionButton}
                onClick={() => onTriggerSelectionAction(actionInfo.action)}
              >
                <UIIcon src={actionInfo.icon} />
              </IconButton>
            </DigTooltip>
          ),
        )}
        <div className={styles.ActionsDivider} />
        <FormatToolbar
          inverse={true}
          formatActions={['bold', 'link', 'divider', 'h1', 'h2']}
        />
      </div>
    );
  },
);
SelectionToolbarPlugin.displayName = 'SelectionToolbarPlugin';

interface EditorSelectionStates {
  selection: DOMRect; // relative to root element
  rootElement: DOMRect;
  selectedText: string;
}
function useEditorSelectionStates(): EditorSelectionStates | null {
  const [editor] = useLexicalComposerContext();
  const [selectionStates, setSelectionStates] =
    useState<EditorSelectionStates | null>(null);
  useEffect(() => {
    function handleSelectionChange() {
      editor.getEditorState().read(() => {
        const selection = $getSelection();
        if (
          !selection ||
          !$isRangeSelection(selection) ||
          selection.isCollapsed()
        ) {
          setSelectionStates(null);
          return;
        }

        const selectionPoints = selection.anchor.isBefore(selection.focus)
          ? [selection.anchor, selection.focus]
          : [selection.focus, selection.anchor];
        const domRange = document.createRange();
        const selectionElements = [
          editor.getElementByKey(selectionPoints[0].getNode().getKey()),
          editor.getElementByKey(selectionPoints[1].getNode().getKey()),
        ];
        if (!selectionElements[0] || !selectionElements[1]) {
          logger.error('selectionElements not found');
          return;
        }
        domRange.setStart(
          selectionElements[0].childNodes[0],
          selectionPoints[0].offset,
        );
        domRange.setEnd(
          selectionElements[1].childNodes[0],
          selectionPoints[1].offset,
        );
        const selectionBoundingRect = domRange.getBoundingClientRect();
        const rootElementBoundingRect = editor
          .getRootElement()!
          .parentElement!.getBoundingClientRect();
        const relativeRect = new DOMRect(
          selectionBoundingRect.x - rootElementBoundingRect.x,
          selectionBoundingRect.y - rootElementBoundingRect.y,
          selectionBoundingRect.width,
          selectionBoundingRect.height,
        );
        setSelectionStates({
          selection: relativeRect,
          rootElement: rootElementBoundingRect,
          selectedText: selection.getTextContent(),
        });
      });
    }
    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [editor]);
  return selectionStates;
}

type ActionInfo =
  | {
      type: 'action';
      icon: ComponentType<SVGAttributes<SVGElement>>;
      title: string;
      action: SelectionAction;
    }
  | { type: 'divider' };
function getActions(selectedText: string): ActionInfo[] {
  return [
    {
      type: 'action',
      icon: ShuffleLine,
      title: i18n.t('compose_editor_selection_action_rewrite'),
      action: { type: 'rewrite', selectedText },
    },
    {
      type: 'action',
      icon: ZoomInLine,
      title: i18n.t('compose_editor_selection_action_make_longer'),
      action: { type: 'make-longer', selectedText },
    },
    {
      type: 'action',
      icon: ZoomOutLine,
      title: i18n.t('compose_editor_selection_action_make_shorter'),
      action: { type: 'make-shorter', selectedText },
    },
    {
      type: 'divider',
    },
    {
      type: 'action',
      icon: AiChatLine,
      title: i18n.t('compose_editor_selection_action_prompt'),
      action: { type: 'prompt', selectedText },
    },
  ];
}

const MARGIN_TOP = 8;

function getPositionStyles(
  editorSelectionStates: EditorSelectionStates,
  offsetTop: number,
): CSSProperties {
  const positionStyles: CSSProperties = {
    top:
      editorSelectionStates.selection.top +
      editorSelectionStates.selection.height +
      MARGIN_TOP +
      offsetTop,
  };
  if (
    editorSelectionStates.selection.left + TOOLBAR_WIDTH <
    editorSelectionStates.rootElement.width
  ) {
    positionStyles.left = editorSelectionStates.selection.left;
  } else {
    positionStyles.right =
      editorSelectionStates.rootElement.width -
      (editorSelectionStates.selection.left +
        editorSelectionStates.selection.width);
  }
  return positionStyles;
}
