import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import {
  CheckmarkLine,
  ClockLine,
  FilterLine,
  LockLine,
  PersonMultipleLine,
} from '@dropbox/dig-icons/assets';
import { UIIcon } from '@dropbox/dig-icons/dist/mjs/ui_icon';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import { StackFilterOption } from '@mirage/service-settings/service/types';
import i18n from '@mirage/translations';
import * as React from 'react';

// Improvement: if we refactor the StackFilterChips, we could use the menu component and move into dash component library
export const StacksTabFilterButton = () => {
  const stacksFilterMenuOptions = [
    {
      key: StackFilterOption.ALL,
      label: 'Recently accessed',
      icon: ClockLine,
    },
    {
      key: StackFilterOption.MINE,
      label: i18n.t('created_by_me'),
      icon: LockLine,
    },
    {
      key: StackFilterOption.SHARED,
      label: i18n.t('shared_with_me'),
      icon: PersonMultipleLine,
    },
    // TODO: include From my Company Filter when that merges
    // {
    //   key: StackFilterOption.COMPANY,
    //   label: 'From my company',
    //   icon: TeamLine,
    // },
  ];
  const { startPageStackFilterPreference, setStartPageStackFilterPreference } =
    useStacksTabFilterPreference();
  const selectedFilter = startPageStackFilterPreference;
  const handleOnSelection = (key: StackFilterOption) => {
    setStartPageStackFilterPreference(key);
  };

  return (
    <Menu.Wrapper onSelection={handleOnSelection}>
      {({ getContentProps, getTriggerProps }) => {
        return (
          <>
            <IconButton
              variant="transparent"
              withDropdownIcon
              {...getTriggerProps()}
            >
              <UIIcon src={FilterLine} />
            </IconButton>
            <Menu.Content {...getContentProps()} minWidth={236}>
              <Menu.Segment withLabel={i18n.t('filter_option_label')}>
                {stacksFilterMenuOptions.map(({ key, label, icon }) => (
                  <Menu.ActionItem
                    key={key}
                    value={key}
                    withLeftAccessory={<UIIcon src={icon} />}
                    withRightAccessory={
                      selectedFilter === key && <UIIcon src={CheckmarkLine} />
                    }
                    style={{ alignItems: 'center' }}
                  >
                    {label}
                  </Menu.ActionItem>
                ))}
              </Menu.Segment>
            </Menu.Content>
          </>
        );
      }}
    </Menu.Wrapper>
  );
};
