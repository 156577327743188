import {
  CreateStackLine,
  SearchLine,
  StacksLine,
} from '@dropbox/dig-icons/assets';
import { DashGettingStartedChecklistItemAction } from '@mirage/analytics/events/enums/dash_getting_started_checklist_item_action';
import i18n from '@mirage/translations';

export const search = {
  moduleId: 'perform_dash_search_module',
  title: i18n.t('get_started_find_content_title'),
  message: i18n.t('get_started_find_content_message'),
  actions: [
    {
      label: i18n.t('get_started_find_content_cta'),
      iconStart: SearchLine,
    },
  ],
  infoLink: 'https://dash.dropbox.com/landing?destination=search-overview',
  milestoneKeys: ['perform_dash_search_milestone'],
};

export const getStartedStack = {
  moduleId: 'explore_get_started_stack_module',
  title: i18n.t('get_started_explore_getting_started_stack_title'),
  message: i18n.t('get_started_explore_getting_started_stack_message'),
  actions: [
    {
      iconStart: StacksLine,
      label: i18n.t('get_started_explore_getting_started_stack_cta'),
    },
  ],
  milestoneKeys: ['explore_get_started_stack_milestone'],
};

export const createStack = {
  moduleId: 'create_stack_module',
  title: i18n.t('get_started_create_stack_title'),
  message: i18n.t('get_started_create_stack_message'),
  actions: [
    {
      label: i18n.t('get_started_create_stack_cta'),
      iconStart: CreateStackLine,
    },
  ],
  infoLink: 'https://dash.dropbox.com/landing?destination=stacks-overview',
  milestoneKeys: ['create_stack_milestone'],
};

export const installApps = {
  moduleId: 'install_dash_desktop_and_extension_module',
  title: i18n.t('get_started_get_the_most_title'),
  message: i18n.t('get_started_get_the_most_message'),
  actions: [
    {
      key: 'download_extension' as DashGettingStartedChecklistItemAction,
      label: i18n.t('get_started_get_the_most_cta'),
    },
    {
      key: 'download_desktop_app' as DashGettingStartedChecklistItemAction,
      label: i18n.t('get_started_get_the_most_alt_cta'),
    },
  ],
  milestoneKeys: ['install_dash_desktop_and_extension_milestone'],
};

export const installDesktop = {
  moduleId: 'install_dash_desktop_and_extension_module',
  title: i18n.t('get_started_get_the_most_title'),
  message: i18n.t('get_started_get_the_most_message_desktop'),
  actions: [
    {
      key: 'download_desktop_app' as DashGettingStartedChecklistItemAction,
      label: i18n.t('get_started_get_the_most_alt_cta'),
    },
  ],
  milestoneKeys: ['install_dash_desktop_and_extension_milestone'],
};
