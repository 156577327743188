import { recommendation } from '@mirage/service-typeahead-search/service/primitives';
import { score } from '@mirage/service-typeahead-search/service/scoring';

import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import type { Recommendation } from '@mirage/shared/search/recommendation';

class RecommendationsCache implements MemoryCache<Recommendation> {
  private cache: Recommendation[] = [];

  all() {
    return this.cache;
  }

  // TODO: consider if we need to chunk or dedupe results
  addItems(items: Recommendation[] = []): Promise<Recommendation[]> {
    // TODO: dedupe by 3pid?

    const sorted = presortRecommendations(items);
    this.cache = sorted;

    return Promise.resolve(this.cache);
  }

  removeItem(item: Recommendation): Promise<Recommendation[]> {
    this.cache = this.cache.filter((cacheItem) => cacheItem.uuid != item.uuid);
    return Promise.resolve(this.cache);
  }

  clear() {
    this.cache = [];
  }

  count() {
    return this.cache.length;
  }
}

export default RecommendationsCache;

/**
 * Used to sort the recommendations returned from the server prior to storing them in
 * the cache so that we have a bias towards the top results when selecting the candidates
 * for search during typoMatch
 *
 * Reuses our existing scoring so it stays consistent with the search pipeline
 *
 */
function presortRecommendations(
  recommendations: Recommendation[],
): Recommendation[] {
  const scoreWithoutQuery = score('');

  const scoredRecommendations = recommendations.map((result) => {
    const tagged = recommendation(result.uuid, result, {
      history: result.clicks,
      mostRecentMs: result.lastClickedMs,
    });
    return scoreWithoutQuery(tagged);
  });

  const sortedRecommendations = scoredRecommendations
    .sort((a, b) => b.score - a.score)
    .map((tagged) => tagged.result as Recommendation);

  return sortedRecommendations;
}
