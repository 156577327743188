import { openURL } from '@mirage/service-platform-actions';
import { ConnectPrompt } from '@mirage/settings/components/connectors/ConnectPrompt';
import { UIConnector, UIWebExtension } from '@mirage/shared/types';
import { useState } from 'react';
import { FetchedAtom } from '../../../shared/atoms/types';
import { showSnackbar } from '../../../shared/snackbar';
import { AvailableConnector } from '../../components/connectors/AvailableConnector';
import { useConnectConnector } from '../../hooks/connectors/useConnectConnector';
import { Skeletons } from './Skeleton';

export type ConnectorsSectionProps = Readonly<{
  connectors: UIConnector[];
  browserExtensionData?: FetchedAtom<UIWebExtension[]>;
}>;

export function ConnectorsSection({
  connectors,
  browserExtensionData,
  ...props
}: ConnectorsSectionProps) {
  const connectConnector = useConnectConnector({
    eventProps: { featureLine: 'connector' },
  });
  const [useConnectPrompt, setUseConnectPrompt] = useState<UIConnector | null>(
    null,
  );

  function handleBrowserExtensionConnect(browserExtension: UIWebExtension) {
    openURL(browserExtension.installUrl);
  }

  async function handleConnectorConnect(connector: UIConnector) {
    if (connector?.auth_details?.prompt_configuration) {
      setUseConnectPrompt(connector);
    } else {
      const result = await connectConnector(connector);
      showSnackbar({ title: result.message });
    }
  }

  function onConnectPromptSubmit(
    connector: UIConnector | undefined,
    values: Record<string, string>,
  ): void {
    if (!connector) return;
    setUseConnectPrompt(null);
    connectConnector(connector, values);
  }

  if (browserExtensionData && !browserExtensionData.loaded) {
    return <Skeletons length={3} />;
  }

  const filterConnectedExtensions = (extension: UIWebExtension) =>
    !extension.connected;

  return (
    <div {...props}>
      {connectors.map((connector) => (
        <AvailableConnector
          key={connector.id_attrs?.id}
          item={connector}
          onConnect={() => handleConnectorConnect(connector)}
        />
      ))}
      {browserExtensionData?.data
        .filter(filterConnectedExtensions)
        .map((browserExtension) => (
          <AvailableConnector
            key={browserExtension.id}
            item={browserExtension}
            onConnect={() => {
              handleBrowserExtensionConnect(browserExtension);
            }}
          />
        ))}
      {useConnectPrompt && (
        <ConnectPrompt
          connector={useConnectPrompt}
          onSubmit={(values) => onConnectPromptSubmit(useConnectPrompt, values)}
          onCancel={() => setUseConnectPrompt(null)}
        />
      )}
    </div>
  );
}
