import { tagged } from '@mirage/service-logging';
import i18n from '@mirage/translations';
import { ONE_MINUTE_IN_MILLIS } from '../../shared/util/constants';

const logger = tagged('utils/oauth');

export const loadAndMonitorPopup = (
  popup: Window,
  url: string,
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (!popup) {
      reject(new Error(i18n.t('could_not_open_popup')));
      return;
    }

    popup.location.href = url;

    // Check whether popup is closed every 300ms, up to 5 minutes.
    const maxTime = 5 * ONE_MINUTE_IN_MILLIS;
    let currentTime = 0;
    const checkPopup = setInterval(() => {
      currentTime += 300;
      if (currentTime > maxTime) {
        clearInterval(checkPopup);
        popup?.close();
        resolve();
      } else if (!popup) {
        clearInterval(checkPopup);
        reject(new Error(i18n.t('could_not_open_popup')));
      } else if (popup.closed) {
        clearInterval(checkPopup);
        resolve();
      }
    }, 300);
  });
};

export const openPopup = (name: string): Window => {
  // Including noopener,noreferrer here prevents the oauth URL from being set on this window once we have it.
  // eslint-disable-next-line no-restricted-syntax
  const popup = window.open('', name, 'width=650,height=800');

  if (!popup) {
    throw new Error(i18n.t('could_not_open_popup'));
  }

  // Display loading message
  popup.window.document.body.innerHTML = `
  <div style="display: flex; justify-content: center; align-items: center; width: 100%; height: 90lvh">
    <h2 style='font-family: Atlas Grotesk Web, Atlas Grotesk, AtlasGrotesk, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";'>
    ${i18n.t('loading')}
    </h2>
  </div>`;

  return popup;
};

type OAuthPopupResult = {
  connectionId?: string;
  connectorId?: string;
  error?: string;
  errorDesc?: string;
};

export const OAUTH_POPUP_CLOSED = 'popup_closed';

export const monitorOAuthPopupBroadcastMessage = (
  popup: Window,
  origin: string,
): Promise<OAuthPopupResult> => {
  return new Promise((resolve) => {
    const bc = new BroadcastChannel('janus_auth_channel');
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== origin) return;
      if (!event.data.connection_id) {
        logger.log('monitorOAuthPopup: unexpected event', event);
        return;
      }

      clearInterval(interval);
      bc.removeEventListener('message', handleMessage);
      if (popup && !popup.closed) popup.close();

      resolve({
        connectionId: event.data.connector_id,
        connectorId: event.data.connection_id,
      });
    };
    bc.addEventListener('message', handleMessage);
    const interval = setInterval(() => {
      if (!popup.closed) return;
      clearInterval(interval);
      bc.removeEventListener('message', handleMessage);
      resolve({ error: OAUTH_POPUP_CLOSED });
    }, 300);
  });
};
