import { sleepMs } from "@mirage/shared/util/tiny-utils";
import { idbKvStore } from "@mirage/storage/large-kv-store/idb-kv-store";
import { LargeKvStore } from "@mirage/storage/large-kv-store/interface";
import * as Sentry from "@sentry/react";
import { v4 as uuid } from "uuid";

import { SharedWorkerMessage } from "./types";

class SharedWorkerDbInterface implements LargeKvStore {
  private worker: SharedWorker;
  private pendingRequests: Map<
    string,
    { resolve: (value?: any) => void; reject: (reason?: any) => void }
  >;

  constructor() {
    // Initialize the shared worker
    this.worker = new SharedWorker("/sharedIndexedDbWorker.js");
    this.pendingRequests = new Map();
    // Listen for messages from the shared worker
    this.worker.port.onmessage = this.handleWorkerMessage.bind(this);
  }

  private sendMessageToWorker(message: SharedWorkerMessage): Promise<any> {
    // If we don't hear back after 7 seconds, log a warning to Sentry.
    sleepMs(7000).then(() => {
      if (this.pendingRequests.has(message.requestId)) {
        Sentry.captureMessage(
          `Shared worker response never received after 7s for ${message.method}`,
          "warning",
        );
      }
    });

    // If we don't hear back after 20 seconds, log another warning to Sentry.
    sleepMs(20000).then(() => {
      if (this.pendingRequests.has(message.requestId)) {
        Sentry.captureMessage(
          `Shared worker response never received after 20s for ${message.method}`,
          "warning",
        );
      }
    });

    return new Promise((resolve, reject) => {
      // Store the resolve and reject functions in pendingRequests
      this.pendingRequests.set(message.requestId, { resolve, reject });

      // Send the message to the worker
      this.worker.port.postMessage(message);
    });
  }

  // Method to handle responses from the worker
  private handleResponse(response: any): void {
    const { requestId, result, error } = response;
    if (!requestId) {
      return;
    }

    const pendingRequest = this.pendingRequests.get(requestId);
    if (pendingRequest) {
      const { resolve, reject } = pendingRequest;
      if (error) {
        reject(error);
      } else {
        resolve(result);
      }
      // Remove the request from pendingRequests
      this.pendingRequests.delete(requestId);
    }
  }

  // Method to handle messages from the worker
  private handleWorkerMessage(event: MessageEvent): void {
    const message = event.data;
    this.handleResponse(message);
  }

  get(key: string): Promise<string> {
    return this.sendMessageToWorker({ method: "get", key, requestId: uuid() });
  }

  async multiGet(keys: string[]): Promise<string[]> {
    try {
      let values = await this.sendMessageToWorker({
        method: "multiGet",
        keys,
        requestId: uuid(),
      });

      if (!values) {
        Sentry.captureMessage("Shared worker multiGet fallback with null");
        values = await Promise.all(keys.map((key) => this.get(key)));
      }

      return values;
    } catch (e) {
      Sentry.captureMessage("Shared worker multiGet fallback");
      Sentry.captureException(e);

      return await Promise.all(keys.map((key) => this.get(key)));
    }
  }

  has(key: string): Promise<boolean> {
    return this.sendMessageToWorker({ method: "has", key, requestId: uuid() });
  }

  set(key: string, value: string, ttlSeconds: number): Promise<void> {
    return this.sendMessageToWorker({
      method: "set",
      key,
      value,
      ttlSeconds,
      requestId: uuid(),
    });
  }

  delete(key: string): Promise<void> {
    return this.sendMessageToWorker({
      method: "delete",
      key,
      requestId: uuid(),
    });
  }

  clear(): Promise<void> {
    return this.sendMessageToWorker({ method: "clear", requestId: uuid() });
  }

  size(): Promise<number> {
    return this.sendMessageToWorker({ method: "size", requestId: uuid() });
  }
}

const isAndroidChrome =
  navigator.userAgent.includes("Chrome") &&
  navigator.userAgent.includes("Android");

// Shared workers don't work on chrome + android specifically. In this case,
// fall back to the simple idbkv store that we had before.
// Also if it's running Meticulous test, fallback to the simple idbkv store.
export const sharedWorkerDatabase: LargeKvStore =
  isAndroidChrome ||
  (window as Window & { Meticulous?: { isRunningAsTest?: boolean } }).Meticulous
    ?.isRunningAsTest
    ? idbKvStore
    : new SharedWorkerDbInterface();
