import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { Split } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { InfoLine } from '@dropbox/dig-icons/assets';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { useCallback, useState } from 'react';
import { AvatarMember } from './Avatar';
import { PermissionsMenu } from './PermissionsMenu';
import styles from './ShareModal.module.css';
import {
  SharingMemberKeyWithPermission,
  SharingStackPermission,
  StackAccessLevel,
  StackInfo,
  StackMember,
  StackPermission,
} from './Types';
import {
  getSharingMemberIdentifier,
  hasStackWritePermissions,
  sharingMemberKeysMatch,
  textForPermission,
} from './Utils';

import type { stacks } from '@dropbox/api-v2-client';

const shouldShowPermissionsWarningForMember = (
  stackInfo: StackInfo,
  stackMember: StackMember,
): boolean => {
  if (stackInfo.accessLevel === StackAccessLevel.PUBLIC) {
    return (
      stackMember.permission === StackPermission.READ &&
      stackInfo.linkPermission === StackPermission.WRITE
    );
  }

  // If the stack access level is set to team, only show
  // the warning icon if they are on the same team as the owner
  if (stackInfo.accessLevel === StackAccessLevel.TEAM) {
    return (
      stackMember.permission === StackPermission.READ &&
      stackInfo.linkPermission === StackPermission.WRITE &&
      stackMember.isSameTeamAsOwner
    );
  }

  return false;
};

export const ShowMembersV2 = ({
  currentUserMember,
  stackInfo,
  handleShareStackWithMembers,
  handleRevokeStackMember,
  openSnackbar,
}: {
  currentUserMember: StackMember;
  stackInfo: StackInfo;
  handleShareStackWithMembers: (
    sendNotifications: boolean,
    notificationMessage: string,
    members: SharingMemberKeyWithPermission[],
    onFailure: () => void,
  ) => Promise<void>;
  handleRevokeStackMember: (
    member: StackMember,
  ) => Promise<stacks.RemoveStackSharingMembersResponse | undefined>;
  openSnackbar: (message: string) => void;
}) => {
  const [loadingIds, setLoadingIds] = useState<Set<string>>(new Set());

  const addLoadingId = useCallback(
    (id: string) => {
      setLoadingIds((loadingIds) => {
        return new Set(loadingIds.values()).add(id);
      });
    },
    [setLoadingIds],
  );

  const deleteLoadingId = useCallback(
    (id: string) => {
      setLoadingIds((loadingIds) => {
        const newSet = new Set(loadingIds.values());
        newSet.delete(id);
        return newSet;
      });
    },
    [setLoadingIds],
  );

  const updateSharingPermissionsOnMember = useCallback(
    async (stackMember: StackMember, newPermission: SharingStackPermission) => {
      if (newPermission === stackMember.permission) {
        return;
      }
      addLoadingId(getSharingMemberIdentifier(stackMember));
      await handleShareStackWithMembers(
        false,
        '',
        [{ memberKey: stackMember, permission: newPermission }],
        () => openSnackbar(i18n.t('failed_to_change_permissions')),
      );
      deleteLoadingId(getSharingMemberIdentifier(stackMember));
    },
    [addLoadingId, handleShareStackWithMembers, openSnackbar, deleteLoadingId],
  );

  const revokeStackMember = useCallback(
    async (stackMember: StackMember) => {
      const result = await handleRevokeStackMember(stackMember);
      if (!result) {
        openSnackbar(i18n.t('failed_to_remove_sharing_member'));
      }
      deleteLoadingId(getSharingMemberIdentifier(stackMember));
    },
    [handleRevokeStackMember, openSnackbar, deleteLoadingId],
  );

  return (
    <List>
      <List.Label
        paddingY="4px"
        withText={i18n.t('stack_share_who_has_access')}
      ></List.Label>
      {stackInfo.members.map((member) => (
        <MemberListItem
          key={getSharingMemberIdentifier(member)}
          {...{
            member,
            stackInfo,
            currentUserMember,
            loadingIds,
            updateSharingPermissionsOnMember,
            revokeStackMember,
          }}
        />
      ))}
    </List>
  );
};

const MemberListItem: React.FC<{
  member: StackMember;
  stackInfo: StackInfo;
  currentUserMember: StackMember;
  loadingIds: Set<string>;
  updateSharingPermissionsOnMember: (
    stackMember: StackMember,
    newPermission: SharingStackPermission,
  ) => Promise<void>;
  revokeStackMember: (member: StackMember) => Promise<void>;
  onSelection?: () => void;
  subtitleSuffix?: string;
}> = ({
  member,
  stackInfo,
  currentUserMember,
  loadingIds,
  updateSharingPermissionsOnMember,
  revokeStackMember,
  subtitleSuffix,
}) => {
  const onSelectSharePermissions = useCallback(
    (permissions: SharingStackPermission) =>
      void updateSharingPermissionsOnMember(member, permissions),
    [updateSharingPermissionsOnMember, member],
  );
  const onRevoke = useCallback(
    () => revokeStackMember(member),
    [revokeStackMember, member],
  );

  const subtitlePrefix =
    member['.tag'] === 'user'
      ? member.email
      : `${i18n.t('company_accesslevel')} · ${i18n.t('num_members', {
          count: member.memberCount,
        })}`;
  const subtitle = subtitleSuffix
    ? `${subtitlePrefix} · ${subtitleSuffix}`
    : subtitlePrefix;

  return (
    <List.Item paddingY="8px">
      <List.Accessory>
        <AvatarMember member={member} />
      </List.Accessory>
      <List.Content>
        <LabelGroup withText={member.displayName} withSubtext={subtitle} />
      </List.Content>
      <List.Accessory>
        {member.permission === StackPermission.OWNER ||
        !hasStackWritePermissions(stackInfo.permission) ? (
          // Only show the permission text if the stack member is the owner
          // or if the local user only has view permissions.
          <Text size="small" className={styles.faintText}>
            {textForPermission(member.permission)}
          </Text>
        ) : (
          // The user has write permissions, show the permissions menu.
          <Split gap="Micro XSmall" alignY="center">
            {shouldShowPermissionsWarningForMember(stackInfo, member) && (
              <Split.Item>
                <DigTooltip
                  title={i18n.t(
                    stackInfo.accessLevel === StackAccessLevel.TEAM
                      ? 'mismatched_permissions_warning_team'
                      : 'mismatched_permissions_warning_public',
                  )}
                  placement="bottom"
                >
                  <UIIcon src={InfoLine} className={styles.warningIcon} />
                </DigTooltip>
              </Split.Item>
            )}

            <Split.Item>
              <PermissionsMenu
                sharePermission={member.permission}
                onSelectSharePermissions={onSelectSharePermissions}
                withFaintStyle
                onRevoke={onRevoke}
                buttonProps={{
                  variant: 'transparent',
                  hasNoUnderline: true,
                  isLoading: loadingIds.has(getSharingMemberIdentifier(member)),
                }}
                isLocalUserMenu={sharingMemberKeysMatch(
                  currentUserMember,
                  member,
                )}
                dfb
              />
            </Split.Item>
          </Split>
        )}
      </List.Accessory>
    </List.Item>
  );
};
