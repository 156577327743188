import { Text } from '@dropbox/dig-components/typography';

export const ActivityLabelSubject = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Text variant="label" color="standard" isBold>
      {children}
    </Text>
  );
};
