import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddCircleLine, MagicWandLine } from '@dropbox/dig-icons/assets';
import { ConversationMessageRowMessageSourceRow } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import { AddSourcesModal } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/AddSourcesModal';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeSource,
  ContentTypeOption,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';
import styles from './ContextInputMessage.module.css';

interface ContextInputMessageWrapperProps {
  stepIndex: number;
  instruction: string;
  children: React.ReactNode;
}
export const ContextInputMessageWrapper = memo(
  ({ stepIndex, instruction, children }: ContextInputMessageWrapperProps) => {
    return (
      <div className={styles.ContextInputMessageWrapper}>
        <div className={styles.ContextInputMessageIconContainer}>
          <UIIcon
            src={MagicWandLine}
            size="small"
            className={styles.ContextInputMessageIcon}
          />
        </div>
        <div className={styles.ContextInputMessageWrapperContent}>
          <Text size="small" color="subtle" tagName="div">
            {i18n.t('compose_step_index', { stepIndex })}
          </Text>
          <Text size="medium" tagName="div">
            {instruction}
          </Text>
          <div>{children}</div>
        </div>
      </div>
    );
  },
);
ContextInputMessageWrapper.displayName = 'ContextInputMessageWrapper';

interface ContextInputContentTypeMessageProps {
  stepIndex: number;
  currentContentType: ContentTypeOption | undefined;
  onContentTypeChange: (contentType: ContentTypeOption | undefined) => void;
  didSelectContentType: boolean;
}
export const ContextInputContentTypeMessage = memo(
  ({
    stepIndex,
    currentContentType,
    onContentTypeChange,
    didSelectContentType,
  }: ContextInputContentTypeMessageProps) => {
    return (
      <ContextInputMessageWrapper
        stepIndex={stepIndex}
        instruction={i18n.t('compose_content_type_question')}
      >
        {CONTENT_TYPES_CHOICES.map((option) => (
          <Chip
            className={styles.ContextInputContentTypeMessageChip}
            key={option}
            onClick={() => onContentTypeChange(option)}
            isSelected={option === currentContentType}
          >
            <Chip.Content>{contentTypeOptionToString(option)}</Chip.Content>
          </Chip>
        ))}
        <Chip
          className={styles.ContextInputContentTypeMessageChip}
          key="other"
          onClick={() => onContentTypeChange(undefined)}
          isSelected={didSelectContentType && currentContentType === undefined}
        >
          <Chip.Content>
            {i18n.t('compose_context_form_content_type_none')}
          </Chip.Content>
        </Chip>
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputContentTypeMessage.displayName = 'ContextInputContentTypeMessage';

const CONTENT_TYPES_CHOICES: ContentTypeOption[] = [
  'email',
  'message',
  'report',
  'proposal',
];

interface ContextInputSourcesMessageProps {
  stepIndex: number;
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  onDone?: () => void;
}
export const ContextInputSourcesMessage = memo(
  ({
    stepIndex,
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onDone,
  }: ContextInputSourcesMessageProps) => {
    const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);
    const handleClickAdd = useCallback(() => {
      setIsAddSourceModalOpen(true);
    }, []);
    const handleCloseAddSourcesModal = useCallback(() => {
      setIsAddSourceModalOpen(false);
    }, []);
    const handleDoneAddSoucesModal = useCallback(() => {
      setIsAddSourceModalOpen(false);
      onDone?.();
    }, [onDone]);
    return (
      <ContextInputMessageWrapper
        stepIndex={stepIndex}
        instruction={i18n.t('compose_sources_question')}
      >
        <div className={styles.ContextInputSourcesChips}>
          {sources.map((source, i) => (
            <div
              className={styles.ContextInputSourcesChip}
              key={getSourceUUID(source) || i}
            >
              <ConversationMessageRowMessageSourceRow
                source={source}
                onRemoveSource={removeSource}
              />
            </div>
          ))}
        </div>
        <div className={styles.ContextInputSourcesActions}>
          <Chip
            className={styles.ContextInputAddSourceChip}
            onClick={handleClickAdd}
          >
            <Chip.IconAccessory>
              <UIIcon src={AddCircleLine} />
            </Chip.IconAccessory>
            <Chip.Content>{i18n.t('compose_add_source_action')}</Chip.Content>
          </Chip>
          {onDone && (
            <Button variant="borderless" onClick={onDone}>
              {sources.length > 0 ? i18n.t('done') : i18n.t('skip_button')}
            </Button>
          )}
        </div>
        {isAddSourceModalOpen && (
          <AddSourcesModal
            sources={sources}
            sourcesContentCache={sourcesContentCache}
            addSource={addSource}
            removeSource={removeSource}
            onRequestClose={handleCloseAddSourcesModal}
            onClickDone={handleDoneAddSoucesModal}
          />
        )}
      </ContextInputMessageWrapper>
    );
  },
);
ContextInputSourcesMessage.displayName = 'ContextInputSourcesMessage';

export function contentTypeOptionToString(option: ContentTypeOption): string {
  switch (option) {
    case 'email':
      return i18n.t('compose_context_form_content_type_email');
    case 'message':
      return i18n.t('compose_context_form_content_type_message');
    case 'report':
      return i18n.t('compose_context_form_content_type_report');
    case 'proposal':
      return i18n.t('compose_context_form_content_type_proposal');
    case 'social_media_post':
      return i18n.t('compose_context_form_content_type_social_media_post');
    case 'meeting_agenda':
      return i18n.t('compose_context_form_content_type_meeting_agenda');
    default:
      option satisfies never;
      throw new Error(`Unknown content type option: ${option}`);
  }
}
