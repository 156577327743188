/* eslint-disable @typescript-eslint/no-namespace */
import { HitTracking } from './common';

import type { stacks } from '@dropbox/api-v2-client';
import type { ConnectorConnections } from '@mirage/service-connectors/service';
import type { TypeaheadCache } from '@mirage/service-typeahead-search/service/typeahead-cache';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { SearchResult } from '@mirage/shared/search/search-result';
import type { StackItem } from '@mirage/shared/search/stack-item';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';
import type { Observable } from 'rxjs';

export namespace typeahead {
  export type Config = {
    isDropboxer: boolean;

    // filters
    isFilterOutEvents: boolean;
    isStackItemResultsEnabled: boolean;

    // Other behavior
    isNullStateUsesRecommendations: boolean;
    connections: ConnectorConnections;
  };

  export type CacheAnalysis = {
    hasRecentQueries: boolean;
  };

  export enum ResultType {
    Stack = 'stack',
    StackItem = 'stack-item',
    SearchResult = 'search-result',
    PreviousQuery = 'previous-query',
    SuggestedQuery = 'suggested-query',
    URLShortcut = 'url-shortcut',
    Recommendation = 'recommendation',
    MathCalculation = 'math-calculation',

    // native results
    DesktopFile = 'desktop-file',
    DesktopApplication = 'desktop-application',
  }

  export type PrimitiveMap = {
    [ResultType.Stack]: stacks.Stack;
    [ResultType.StackItem]: StackItem;
    [ResultType.SearchResult]: SearchResult;
    [ResultType.PreviousQuery]: PreviousQuery;
    [ResultType.SuggestedQuery]: SuggestedQuery;
    [ResultType.URLShortcut]: URLShortcut;
    [ResultType.Recommendation]: Recommendation;
    [ResultType.MathCalculation]: MathCalculation;

    // native results
    [ResultType.DesktopFile]: SearchResult;
    [ResultType.DesktopApplication]: SearchResult;
  };

  export type TypeToPrimitive<T extends ResultType> = PrimitiveMap[T];

  export type $Tagged<T extends ResultType> = {
    uuid: string;
    type: T;
    result: TypeToPrimitive<T>;
    metadata?: SourceMetadata;
    hits?: HitTracking;
  };

  export type SourceMetadata = {
    source: string; // name of search source that returned the result
    latency: number;
  };

  export type TaggedResult =
    | $Tagged<ResultType.Stack>
    | $Tagged<ResultType.StackItem>
    | $Tagged<ResultType.SearchResult>
    | $Tagged<ResultType.PreviousQuery>
    | $Tagged<ResultType.SuggestedQuery>
    | $Tagged<ResultType.URLShortcut>
    | $Tagged<ResultType.Recommendation>
    | $Tagged<ResultType.MathCalculation>
    | $Tagged<ResultType.DesktopFile>
    | $Tagged<ResultType.DesktopApplication>;

  export type Components = Array<[number, number]>;

  /**
   * null scores are used to indicate that the score is not applicable and
   * therefore the weight of that score should be set to 0 to avoid scores
   * that don't apply to an item from dragging it's score up or down
   */
  export type Scores = {
    titleMatchScore: number | null;
    lastClickedScore: number | null;
    frequentlyClickedScore: number | null;
    lastBrowserViewedScore: number | null;
    frequentlyBrowserViewedScore: number | null;
    fileTypeScore: number | null;
  };

  export const WEIGHT_KEYS = [
    'titleMatchScore',
    'lastClickedScore',
    'frequentlyClickedScore',
    'lastBrowserViewedScore',
    'frequentlyBrowserViewedScore',
    'fileTypeScore',
  ] as const;

  export type WeightKey = (typeof WEIGHT_KEYS)[number];

  export type Weights = {
    [key in WeightKey]: number;
  };

  export type $Scored<T extends ResultType> = $Tagged<T> & {
    score: number;
    scores: Scores;
    weights: Weights;
    components: Components;
  };

  export type ScoredStack = $Scored<ResultType.Stack>;
  export type ScoredStackItem = $Scored<ResultType.StackItem>;
  export type ScoredSearchResult = $Scored<ResultType.SearchResult>;
  export type ScoredPreviousQuery = $Scored<ResultType.PreviousQuery>;
  export type ScoredSuggestedQuery = $Scored<ResultType.SuggestedQuery>;
  export type ScoredURLShortcut = $Scored<ResultType.URLShortcut>;
  export type ScoredRecommendation = $Scored<ResultType.Recommendation>;
  export type ScoredMathCalculation = $Scored<ResultType.MathCalculation>;
  export type ScoredDesktopFile = $Scored<ResultType.DesktopFile>;
  export type ScoredDesktopApplication = $Scored<ResultType.DesktopApplication>;

  export type ScoredResult =
    | ScoredStack
    | ScoredStackItem
    | ScoredSearchResult
    | ScoredPreviousQuery
    | ScoredSuggestedQuery
    | ScoredURLShortcut
    | ScoredRecommendation
    | ScoredMathCalculation
    | ScoredDesktopFile
    | ScoredDesktopApplication;

  export type PredicateFunction = (
    query: string,
    config: Config,
    cacheAnalysis: CacheAnalysis,
  ) => boolean;

  export type TaggedSearch = (
    query: string,
    cache: TypeaheadCache,
    connections: ConnectorConnections,
  ) => Observable<typeahead.TaggedResult>;

  export enum SourceId {
    RecentQueries = 'recent-queries',
    Recommendations = 'recommendations',
    SuggestedQueries = 'suggested-queries',
    URLShortcuts = 'url-shortcuts',
    Stacks = 'stacks',
    StackItems = 'stack-items',
    MathCalculations = 'math-calculations',

    // Desktop only
    DesktopApplications = 'desktop-applications',
    RecentFiles = 'recent-files',
  }

  export type Source = {
    id: SourceId;
    search: TaggedSearch;
    predicate: PredicateFunction;
  };
}

export type SuggestedQuery = {
  uuid: string;
  query: string;
};

export type MathCalculation = {
  uuid: string;
  query: string;
  answer: string;
};

// Export enum for easier runtime access
export const { ResultType: ResultType, SourceId: SourceId } = typeahead;
