import { Resizable } from 're-resizable';
import { memo } from 'react';
import styles from './ComposeAssistantPageLayout.module.css';

interface ComposeAssistantPageWrapperProps {
  children: React.ReactNode;
}
export const ComposeAssistantPageWrapper = memo(
  ({ children }: ComposeAssistantPageWrapperProps) => {
    return <div className={styles.wrapper}>{children}</div>;
  },
);
ComposeAssistantPageWrapper.displayName = 'ComposeAssistantPageWrapper';

interface ComposeAssistantGridProps {
  sideBar: React.ReactNode;
  sidePane: React.ReactNode;
  editorPane: React.ReactNode;
}
export const ComposeAssistantGrid = memo(
  ({ sideBar, sidePane, editorPane }: ComposeAssistantGridProps) => {
    return (
      <div className={styles.ComposeAssistantGrid}>
        <div className={styles.ComposeAssistantGridSideBar}>{sideBar}</div>
        <Resizable
          defaultSize={{ width: 374, height: '100%' }}
          minWidth={280}
          enable={{ right: true }}
          className={styles.ComposeAssistantGridSidePane}
        >
          {sidePane}
        </Resizable>
        <div className={styles.ComposeAssistantGridEditorPane}>
          {editorPane}
        </div>
      </div>
    );
  },
);
ComposeAssistantGrid.displayName = 'ComposeAssistantGrid';
