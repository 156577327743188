import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { listConnections } from '@mirage/service-connectors';
import { useReauthenticateConnection } from '@mirage/settings/hooks/connections/useReauthenticateConnection';
import { fetchConnectorsWithVisibilityFilters } from '@mirage/settings/utils/connectorMetadataService';
import {
  connectedBrowserExtensionsAtom,
  useBrowserExtensionData,
} from '@mirage/shared/atoms/browserExtensionData';
import {
  sortedConnectionsAtom,
  useConnections,
} from '@mirage/shared/atoms/connections';
import { connectorsByTypeAtom } from '@mirage/shared/atoms/connectors';
import { UIConnection, UIConnector } from '@mirage/shared/types';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { ConnectedConnector } from '../../components/connectors/ConnectedConnector';
import { useDisconnectConnection } from '../../hooks/connections/useDisconnectConnection';
import styles from './Section.module.css';
import { Skeletons } from './Skeleton';

export function ConnectionsSection(
  props: React.HTMLAttributes<HTMLDivElement>,
) {
  const connections = useConnections();
  const disconnectConnection = useDisconnectConnection();
  const reauthenticateConnection = useReauthenticateConnection();
  const browserExtensionData = useBrowserExtensionData();
  const connectedExtensions = useAtomValue(connectedBrowserExtensionsAtom);
  const sortedConnections = useAtomValue(sortedConnectionsAtom);
  const connectorsByType = useAtomValue(connectorsByTypeAtom);

  const loading = connections.loading || browserExtensionData.loading;

  const count = connections.data.length + connectedExtensions.length;
  const title =
    !loading && count === 0
      ? i18n.t('connectors_section_connected')
      : i18n.t('connectors_section_connected_with_counter', { count });

  const handleDisconnect = async (connection: UIConnection) => {
    await disconnectConnection(connection);
  };

  const handleReauthenticate = async (
    connection: UIConnection,
    connector: UIConnector,
  ) => {
    const resp = await reauthenticateConnection(connection, connector);
    if (resp) {
      await Promise.all([
        listConnections(),
        fetchConnectorsWithVisibilityFilters(),
      ]);
    }
  };

  const titleBox = (
    <Box display="block" paddingBottom="16">
      <Text size="medium" isBold>
        {title}
      </Text>
      <hr className={styles.horizontalSeparator} />
    </Box>
  );

  if (loading) {
    return (
      <div {...props}>
        {titleBox}
        <Skeletons length={3} />
      </div>
    );
  }

  return (
    <div {...props}>
      {titleBox}
      {connections.data.length === 0 && connectedExtensions?.length === 0 && (
        <div className={styles.emptyState}>
          <Text size="medium" isBold>
            {i18n.t('connectors_section_no_connections_title')}
          </Text>
          <Text size="small">
            {i18n.t('connectors_section_no_connections_message')}
          </Text>
        </div>
      )}
      {sortedConnections.map((connection) => (
        <ConnectedConnector
          connector={
            connectorsByType[connection.id_attributes?.connector?.type ?? '']
          }
          key={connection.id_attributes?.id ?? ''}
          item={connection}
          onDisconnect={handleDisconnect}
          onReauthenticate={handleReauthenticate}
        />
      ))}
      {connectedExtensions.map((extension) => (
        <ConnectedConnector
          key={extension.id}
          item={extension}
          onDisconnect={() => Promise.resolve()}
          onReauthenticate={() => Promise.resolve()}
        />
      ))}
    </div>
  );
}
