import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Add_DashSearchToStack } from '@mirage/analytics/events/types/add_dash_search_to_stack';
import { PAP_Copy_DashSearch } from '@mirage/analytics/events/types/copy_dash_search';
import { PAP_Open_DashSearchResult } from '@mirage/analytics/events/types/open_dash_search_result';
import { PAP_Shown_DashSearchResult } from '@mirage/analytics/events/types/shown_dash_search_result';
import { stackDerivePAPProps } from '@mirage/service-stacks';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import {
  getSearchFilterNamesForLogging,
  SearchFilter,
} from '@mirage/shared/search/search-filters';
import { useCallback } from 'react';
import useSearchQueryId from './useSearchQueryId';

import type { Recommendation } from '../search/recommendation';
import type { StackItem } from '../search/stack-item';
import type { PAPEvent } from '@mirage/analytics/events/base/event';
import type { ActionSurfaceComponent } from '@mirage/analytics/events/enums/action_surface_component';
import type { DashSourceType } from '@mirage/analytics/events/enums/dash_source_type';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { TypeaheadResultType } from '@mirage/analytics/events/enums/typeahead_result_type';
import type { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { SearchResult } from '@mirage/service-dbx-api';
import type { MathCalculation } from '@mirage/service-typeahead-search/service/types';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { URLShortcut } from '@mirage/shared/search/url-shortcut';

type DefaultProps = {
  query: string;
  filters: Array<SearchFilter>;
  results: SearchResult[] | typeahead.ScoredResult[];

  // Optional Params
  actionSurfaceComponent?: ActionSurfaceComponent;
};

export type SearchResultDefaultProps = DefaultProps & {
  searchResult: SearchResult;
};

export type TypeaheadResultDefaultProps = DefaultProps & {
  result: typeahead.ScoredResult;
};

export type StackResultDefaultProps = DefaultProps & {
  uuid: string;
  stack: stacks.Stack;
};

export type StackItemResultDefaultProps = DefaultProps & {
  stackItem: StackItem;
};

export type PreviousQueryDefaultProps = DefaultProps & {
  previousQuery: PreviousQuery;
};

export type RecommendationDefaultProps = DefaultProps & {
  recommendation: Recommendation;
};

export type URLShortcutDefaultProps = DefaultProps & {
  urlShortcut: URLShortcut;
};

export type MathCalculationDefaultProps = DefaultProps & {
  mathCalculation: MathCalculation;
};

export type DesktopFileDefaultProps = DefaultProps & {
  desktopFile: SearchResult;
};

export type DesktopApplicationDefaultProps = DefaultProps & {
  desktopApplication: SearchResult;
};

type DashResultDefaultParams = {
  resultPosition: number;
  resultPositionNoCta: number;
  rightRailOpened: boolean;
  resultCount: number;
  isTypeahead: boolean;

  /**
   * If the result is selected via typeahead
   *
   * content: Search Result is acted upon
   * filter: Inline filter is acted upon
   * query_suggestion: Suggested query is acted upon
   * serp_cta: It shows the query itself and clicking this opens the search result page.
   */
  typeaheadResultType?: TypeaheadResultType;
  launchMethod?: LaunchMethod;
  dashSourceType?: DashSourceType;
};

type DashSearchResultParams = SearchResultDefaultProps &
  DashResultDefaultParams;
type StackResultParams = StackResultDefaultProps & DashResultDefaultParams;
type StackItemResultParams = StackItemResultDefaultProps &
  DashResultDefaultParams;
type PreviousQueryParams = PreviousQueryDefaultProps & DashResultDefaultParams;
type RecommendationResultParams = RecommendationDefaultProps &
  DashResultDefaultParams;
type URLShortcutResultParams = URLShortcutDefaultProps &
  DashResultDefaultParams;
type MathCalculationParams = MathCalculationDefaultProps &
  DashResultDefaultParams;
type DesktopFileParams = DesktopFileDefaultProps & DashResultDefaultParams;
type DesktopApplicationParams = DesktopApplicationDefaultProps &
  DashResultDefaultParams;

export default function useDashSearchResultLogger() {
  const { reportPapEvent, searchAttemptSessionManager, searchSessionManager } =
    useMirageAnalyticsContext();
  const { searchQueryUuid } = useSearchQueryId();

  const getActiveFilters = useCallback((filters: Array<SearchFilter>) => {
    if (!filters) return '';
    const filterNames = getSearchFilterNamesForLogging(filters);
    return (filterNames || []).join(',');
  }, []);
  const reportEvent = useCallback(
    (event: PAPEvent) => {
      if (!event) return;
      const defaultProperties = {
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        searchAttemptId: searchAttemptSessionManager.getSessionIdOrUndefined(),
      };

      const combinedProperties = {
        ...defaultProperties,
        ...event.properties,
      };

      reportPapEvent({ ...event, properties: combinedProperties });
    },
    [reportPapEvent, searchAttemptSessionManager, searchSessionManager],
  );

  const getOpenDashSearchResult = useCallback(
    (params: DashSearchResultParams) => {
      const {
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        typeaheadResultType,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
      } = params;
      const searchResult = params.searchResult;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: searchResult.uuid,
        connector: searchResult?.connectorInfo?.connectorName,
        activeFilters,
        queryString: query,
        title: searchResult.title ? searchResult.title : '',
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchRequestId: searchResult.searchRequestId,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        resultUpdatedAtMs: searchResult.updatedAtMs ?? undefined,
        rightRailOpened,
        totalScore: searchResult.score ?? undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: searchResult.searchResultSource,
        fromUpstream: searchResult.searchResultSource === 'upstream',
        isPinned: false,
        latency: searchResult.latency ?? undefined,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
        serpFinalRanking: searchResult.serpFinalRanking,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logSearchResultOpen = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const params: DashSearchResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        actionSurfaceComponent,
        typeaheadResultType,
        dashSourceType,
      };

      const event = getOpenDashSearchResult(params);

      reportEvent(event);
    },
    [getOpenDashSearchResult, reportEvent],
  );

  const getOpenStackResult = useCallback(
    (params: StackResultParams) => {
      const {
        uuid,
        stack,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;
      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Open_DashSearchResult({
        title: stack?.stack_data?.name,
        resultUuid: uuid,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        isPinned: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logStackResultOpen = useCallback(
    (
      defaultProps: StackResultDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;

      // The core Stack type (`defaultProps.result.result`) doesn't have uuid,
      // only namespace_id. Use locally-generated uuid for finding
      // resultPosition.
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result?.uuid === defaultProps.uuid,
      );

      const params: StackResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getOpenStackResult(params);

      reportEvent(event);
    },
    [getOpenStackResult, reportEvent],
  );

  const getOpenStackItemResult = useCallback(
    (params: StackItemResultParams) => {
      const {
        stackItem,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;
      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Open_DashSearchResult({
        title: stackItem.name,
        resultUuid: stackItem.uuid,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        isPinned: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logStackItemResultOpen = useCallback(
    (
      defaultProps: StackItemResultDefaultProps,
      isTypeahead: boolean,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result?.uuid === defaultProps.stackItem.uuid,
      );

      const params: StackItemResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getOpenStackItemResult(params);

      reportEvent(event);
    },
    [getOpenStackItemResult, reportEvent],
  );

  const getOpenPreviousResult = useCallback(
    (params: PreviousQueryParams) => {
      const {
        previousQuery,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Open_DashSearchResult({
        title: previousQuery.query,
        activeFilters,
        queryString: query,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        rightRailOpened,
        hitCount: undefined,
        resultCount,
        fromUpstream: false,
        isPinned: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        actionSurfaceComponent,
        searchQueryUuid,
        featureLine: 'search',
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logPreviousQueryOpen = useCallback(
    (
      defaultProps: PreviousQueryDefaultProps,
      rightRailOpened: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.previousQuery.uuid,
      );
      const params: PreviousQueryParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead: true,
        launchMethod,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getOpenPreviousResult(params);

      reportEvent(event);
    },
    [getOpenPreviousResult, reportEvent],
  );

  const getOpenRecommendationResult = useCallback(
    (params: RecommendationResultParams) => {
      const {
        recommendation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
        dashSourceType,
      } = params;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: recommendation.uuid,
        connector: recommendation?.connectorInfo?.connectorName,
        activeFilters,
        queryString: query,
        title: recommendation.title ? recommendation.title : '',
        dashSearchResultType: recommendation.recordType?.['.tag'],
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        isTypeahead: true,
        typeaheadResultType: 'recommendation',
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logRecommendationOpen = useCallback(
    (
      defaultProps: RecommendationDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.recommendation.uuid,
      );
      const params: RecommendationResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        actionSurfaceComponent,
        typeaheadResultType,
        dashSourceType,
      };

      const event = getOpenRecommendationResult(params);

      reportEvent(event);
    },
    [getOpenRecommendationResult, reportEvent],
  );

  const getOpenOpenURLShortcutResult = useCallback(
    (params: URLShortcutResultParams) => {
      const {
        urlShortcut,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
      } = params;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: urlShortcut.uuid,
        connector: undefined,
        activeFilters,
        queryString: query,
        title: urlShortcut.parameters.activeHotword,
        dashSearchResultType: 'url_shortcut',
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'url_shortcut',
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        isTypeahead: true,
        typeaheadResultType: 'url_shortcut',
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logURLShortcutOpen = useCallback(
    (
      defaultProps: URLShortcutDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      dashSourceType: DashSourceType = 'connector',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.urlShortcut.uuid,
      );
      const params: URLShortcutResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        actionSurfaceComponent,
        typeaheadResultType,
        dashSourceType,
      };

      const event = getOpenOpenURLShortcutResult(params);

      reportEvent(event);
    },
    [getOpenOpenURLShortcutResult, reportEvent],
  );

  const getOpenMathCalculationResult = useCallback(
    (params: MathCalculationParams) => {
      const {
        mathCalculation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        launchMethod,
        actionSurfaceComponent,
      } = params;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Open_DashSearchResult({
        resultUuid: mathCalculation.uuid,
        connector: undefined,
        activeFilters,
        queryString: query,
        title: mathCalculation.answer,
        dashSearchResultType: 'math',
        searchRequestId: undefined,
        resultPosition: resultPosition >= 0 ? resultPosition : undefined,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'local',
        resultUpdatedAtMs: undefined,
        rightRailOpened,
        totalScore: undefined,
        hitCount: undefined,
        resultCount,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        latency: undefined,
        hasQuery,
        isTypeahead: true,
        typeaheadResultType: 'math_calculation',
        launchMethod,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logMathCalculationOpen = useCallback(
    (
      defaultProps: MathCalculationDefaultProps,
      rightRailOpened: boolean,
      isTypeahead: boolean,
      launchMethod: LaunchMethod,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
      dashSourceType: DashSourceType = 'local',
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.mathCalculation.uuid,
      );
      const params: MathCalculationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened,
        resultCount,
        isTypeahead,
        launchMethod,
        actionSurfaceComponent,
        typeaheadResultType,
        dashSourceType,
      };

      const event = getOpenMathCalculationResult(params);

      reportEvent(event);
    },
    [getOpenMathCalculationResult, reportEvent],
  );

  const getShownDashSearchResult = useCallback(
    (params: DashSearchResultParams) => {
      const {
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        actionSurfaceComponent,
        typeaheadResultType,
      } = params;
      const searchResult = params.searchResult;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: searchResult.title,
        resultUuid: searchResult.uuid,
        connector: searchResult?.connectorInfo?.connectorName,
        totalScore: searchResult.score ?? 0,
        sourceScore: searchResult.score ?? 0,
        searchResultSource: searchResult.searchResultSource,
        fromUpstream: searchResult.searchResultSource === 'upstream',
        resultUpdatedAtMs: searchResult.providerUpdateAtMs || undefined,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchRequestId: searchResult.searchRequestId,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        actionSurfaceComponent,
        featureLine: 'search',
        searchQueryUuid,
        serpFinalRanking: searchResult.serpFinalRanking,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logDocumentShown = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const params: DashSearchResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead,
        actionSurfaceComponent,
        typeaheadResultType,
      };

      const event = getShownDashSearchResult(params);

      reportEvent(event);
    },
    [reportEvent, getShownDashSearchResult],
  );

  const getShownRecommendationResult = useCallback(
    (params: RecommendationResultParams) => {
      const {
        recommendation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        actionSurfaceComponent,
        dashSourceType,
      } = params;
      const activeFilters = getActiveFilters(filters);
      const hasQuery = query.length > 0;

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: recommendation.title ? recommendation.title : '',
        resultUuid: recommendation.uuid,
        connector: recommendation?.connectorInfo?.connectorName,
        searchResultSource: 'local_cache',
        fromUpstream: false,
        isPinned: false,
        resultUpdatedAtMs: undefined,
        dashSearchResultType: recommendation.recordType?.['.tag'],
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType,
        hasQuery,
        isTypeahead: true,
        typeaheadResultType: 'recommendation',
        actionSurfaceComponent,
        featureLine: 'search',
        searchQueryUuid,
        searchRequestId: undefined,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logRecommendationShown = useCallback(
    (
      defaultProps: RecommendationDefaultProps,
      isTypeahead: boolean,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.recommendation.uuid,
      );
      const params: RecommendationResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead,
        actionSurfaceComponent,
        typeaheadResultType,
      };

      const event = getShownRecommendationResult(params);

      reportEvent(event);
    },
    [getShownRecommendationResult, reportEvent],
  );

  const logDocumentCopy = useCallback(
    (defaultProps: SearchResultDefaultProps) => {
      const searchResult = defaultProps.searchResult as SearchResult;
      const { query, results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.searchResult.uuid,
      );
      const dashConnectorId = searchResult.connectorInfo.connectorId;
      const event = PAP_Copy_DashSearch({
        queryString: query,
        resultRank: resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultCount,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchResultSource: searchResult.searchResultSource,
        searchRequestId: searchResult.searchRequestId,
        resultUuid: searchResult.uuid,
        searchQueryUuid,
        featureLine: 'search',
      });

      reportEvent(event);
    },
    [reportEvent, searchQueryUuid],
  );
  const logTypeaheadResultCopy = useCallback(
    (
      defaultProps: TypeaheadResultDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { result, query, results } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === defaultProps.result.uuid,
      );
      const event = PAP_Copy_DashSearch({
        queryString: query,
        resultRank: resultPosition,
        resultPositionNoCta,
        resultCount,
        resultUuid: result.uuid,
        searchQueryUuid,
        featureLine: 'search',
        isTypeahead: true,
        actionSurfaceComponent,
        typeaheadResultType,
      });

      reportEvent(event);
    },
    [reportEvent, searchQueryUuid],
  );

  const logAddDocumentToStack = useCallback(
    (
      defaultProps: SearchResultDefaultProps,
      stack?: stacks.Stack,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { query, filters, results } = defaultProps;
      const searchResult = defaultProps.searchResult as SearchResult;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === searchResult.uuid,
      );
      const dashConnectorId = searchResult.connectorInfo.connectorId;
      const stackProps = stack ? { ...stackDerivePAPProps(stack) } : {};
      const activeFilters = getActiveFilters(filters);

      const event = PAP_Add_DashSearchToStack({
        queryString: query,
        resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultUuid: searchResult.uuid,
        connector: searchResult.connectorInfo?.connectorName,
        dashSearchResultType: searchResult.recordType?.['.tag'],
        searchResultSource: searchResult.searchResultSource,
        searchRequestId: searchResult.searchRequestId,
        resultCount,
        activeFilters,
        featureLine: 'search',
        dashSourceType: 'connector',
        isSuccess: !!stack,
        actionSurfaceComponent,
        searchQueryUuid,
        ...stackProps,
      });

      reportEvent(event);
    },
    [getActiveFilters, reportEvent, searchQueryUuid],
  );
  const logTypeaheadAddToStack = useCallback(
    (
      defaultProps: TypeaheadResultDefaultProps,
      stack?: stacks.Stack,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { query, filters, results, result: scoredResult } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === scoredResult.uuid,
      );
      let dashConnectorId, connector, dashSearchResultType;

      if (
        scoredResult.type === typeahead.ResultType.SearchResult ||
        scoredResult.type === typeahead.ResultType.Recommendation
      ) {
        dashConnectorId = scoredResult.result.connectorInfo.connectorId;
        connector = scoredResult.result.connectorInfo.connectorName;
        dashSearchResultType = scoredResult.result.recordType?.['.tag'];
      }

      const stackProps = stack ? { ...stackDerivePAPProps(stack) } : {};
      const activeFilters = getActiveFilters(filters);

      const event = PAP_Add_DashSearchToStack({
        queryString: query,
        resultPosition,
        resultPositionNoCta,
        dashConnectorId,
        resultUuid: scoredResult.uuid,
        connector,
        dashSearchResultType,
        resultCount,
        activeFilters,
        featureLine: 'search',
        dashSourceType: 'connector',
        isSuccess: !!stack,
        actionSurfaceComponent,
        searchQueryUuid,
        ...stackProps,
      });

      reportEvent(event);
    },
    [getActiveFilters, reportEvent, searchQueryUuid],
  );

  const getShownStackResult = useCallback(
    (params: StackResultParams) => {
      const {
        stack,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: stack.stack_data?.name,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logStackResultShown = useCallback(
    (
      defaultProps: StackResultDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results, uuid } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === uuid,
      );
      const params: StackResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getShownStackResult(params);
      reportEvent(event);
    },
    [getShownStackResult, reportEvent],
  );

  const getShownStackItemResult = useCallback(
    (params: StackItemResultParams) => {
      const {
        stackItem,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: stackItem.name,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'stack',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logStackItemResultShown = useCallback(
    (
      defaultProps: StackItemResultDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results, stackItem } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === stackItem.uuid,
      );
      const params: StackItemResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getShownStackItemResult(params);
      reportEvent(event);
    },
    [getShownStackItemResult, reportEvent],
  );

  const getShownPreviousQuery = useCallback(
    (params: PreviousQueryParams) => {
      const {
        previousQuery,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: previousQuery.query,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logPreviousQueryShown = useCallback(
    (
      defaultProps: PreviousQueryDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
      typeaheadResultType?: TypeaheadResultType,
    ) => {
      const { results, previousQuery } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === previousQuery.uuid,
      );
      const params: PreviousQueryParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType,
        actionSurfaceComponent,
      };

      const event = getShownPreviousQuery(params);
      reportEvent(event);
    },
    [getShownPreviousQuery, reportEvent],
  );

  const getShownURLShortcut = useCallback(
    (params: URLShortcutResultParams) => {
      const {
        urlShortcut,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: urlShortcut.parameters.activeHotword,
        resultUuid: urlShortcut.uuid,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        dashSourceType: 'connector',
        searchResultSource: 'local_cache',
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logURLShortcutShown = useCallback(
    (
      defaultProps: URLShortcutDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { results, urlShortcut } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === urlShortcut.uuid,
      );
      const params: URLShortcutResultParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType: 'url_shortcut',
        actionSurfaceComponent,
      };

      const event = getShownURLShortcut(params);
      reportEvent(event);
    },
    [getShownURLShortcut, reportEvent],
  );

  const getShownMathCalculation = useCallback(
    (params: MathCalculationParams) => {
      const {
        mathCalculation,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: mathCalculation.answer,
        resultUuid: mathCalculation.uuid,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logMathCalculationShown = useCallback(
    (
      defaultProps: MathCalculationDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { results, mathCalculation } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === mathCalculation.uuid,
      );

      const params: MathCalculationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType: 'math_calculation',
        actionSurfaceComponent,
      };

      const event = getShownMathCalculation(params);
      reportEvent(event);
    },
    [getShownMathCalculation, reportEvent],
  );

  const getShownDesktopFile = useCallback(
    (params: DesktopFileParams) => {
      const {
        desktopFile,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: desktopFile.title,
        resultUuid: desktopFile.uuid,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logDesktopFileShown = useCallback(
    (
      defaultProps: DesktopFileDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { results, desktopFile } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === desktopFile.uuid,
      );

      const params: DesktopFileParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType: 'local_file',
        actionSurfaceComponent,
      };

      const event = getShownDesktopFile(params);
      reportEvent(event);
    },
    [getShownDesktopFile, reportEvent],
  );

  const getShownDesktopApplication = useCallback(
    (params: DesktopApplicationParams) => {
      const {
        desktopApplication,
        query,
        filters,
        resultPosition,
        resultPositionNoCta,
        resultCount,
        rightRailOpened,
        isTypeahead,
        typeaheadResultType,
        actionSurfaceComponent,
      } = params;

      const hasQuery = query.length > 0;
      const activeFilters = getActiveFilters(filters);

      return PAP_Shown_DashSearchResult({
        activeFilters,
        queryString: query,
        title: desktopApplication.title,
        resultUuid: desktopApplication.uuid,
        resultCount,
        rightRailOpened,
        resultPosition,
        resultPositionNoCta:
          resultPositionNoCta >= 0 ? resultPositionNoCta : resultPosition,
        fromUpstream: false,
        hasQuery,
        isTypeahead,
        typeaheadResultType: isTypeahead ? typeaheadResultType : undefined,
        featureLine: 'search',
        actionSurfaceComponent,
        searchQueryUuid,
      });
    },
    [getActiveFilters, searchQueryUuid],
  );
  const logDesktopApplicationShown = useCallback(
    (
      defaultProps: DesktopApplicationDefaultProps,
      actionSurfaceComponent?: ActionSurfaceComponent,
    ) => {
      const { results, desktopApplication } = defaultProps;
      const resultCount = results.length;
      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        results,
        (result) => result.uuid === desktopApplication.uuid,
      );

      const params: DesktopApplicationParams = {
        ...defaultProps,
        resultPosition,
        resultPositionNoCta,
        rightRailOpened: false,
        resultCount,
        isTypeahead: true,
        typeaheadResultType: 'local_app',
        actionSurfaceComponent,
      };

      const event = getShownDesktopApplication(params);
      reportEvent(event);
    },
    [getShownDesktopApplication, reportEvent],
  );

  return {
    // Open
    logDocumentOpen: logSearchResultOpen,
    logRecommendationOpen,
    logURLShortcutOpen,
    logStackResultOpen,
    logStackItemResultOpen,
    logPreviousQueryOpen,
    logMathCalculationOpen,
    // Shown
    logStackResultShown,
    logStackItemResultShown,
    logDocumentShown,
    logRecommendationShown,
    logPreviousQueryShown,
    logMathCalculationShown,
    logDesktopFileShown,
    logDesktopApplicationShown,
    logTypeaheadResultCopy,
    logURLShortcutShown,
    // Copy
    logDocumentCopy,
    // Misc
    logAddDocumentToStack,
    logTypeaheadAddToStack,
  };
}

export function calculatePapPositions<
  T extends DefaultProps['results'] | (TypeaheadResult | undefined)[],
>(
  results: T,
  findCb: (result: T[number]) => boolean,
): { resultPosition: number; resultPositionNoCta: number } {
  const resultsWithoutCta = results.filter((result) =>
    result && 'type' in result // typeahead.ScoredResult
      ? result.type !== typeahead.ResultType.SuggestedQuery
      : // SearchResult
        true,
  );

  return {
    resultPosition: results.findIndex(findCb),
    resultPositionNoCta: resultsWithoutCta.findIndex(findCb),
  };
}
