import { stacks } from '@dropbox/api-v2-client';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { DashCardType } from '@mirage/analytics/events/enums/dash_card_type';
import { PAP_Collapse_DashCard } from '@mirage/analytics/events/types/collapse_dash_card';
import { PAP_Expand_DashCard } from '@mirage/analytics/events/types/expand_dash_card';
import { PAP_Move_DashLink } from '@mirage/analytics/events/types/move_dash_link';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { dispatchElementClicked } from '@mirage/analytics/uxa/dispatchElementClicked';
import { ContentIcon } from '@mirage/dash-component-library/components/Icon';
import { LinkList } from '@mirage/link-list';
import { FavIcon } from '@mirage/link-list/Favicon/Favicon';
import { Link, ListItemSize } from '@mirage/link-list/types';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { CollapsedCardKey } from '@mirage/service-settings/service/types';
import { useCollapsedCardSettings } from '@mirage/service-settings/useCollapsedCardSettings';
import { useStackItemCollapseDescriptionSettings } from '@mirage/service-settings/useStackItemByIdCollapseDescriptionSettings';
import { updateStackItem } from '@mirage/service-stacks';
import {
  DEFAULT_SECTION_ID,
  stackDerivePAPProps,
  stackItemGetName,
} from '@mirage/service-stacks/service/utils';
import {
  CardHeaderType,
  TwoColumnGridCard,
} from '@mirage/shared/two-column-grid/TwoColumnGridCard';
import { faviconSrcForSrcUrl } from '@mirage/shared/util/favicon';
import { getStackItemMetadata } from '@mirage/shared/util/stack-item-metadata';
import { coerceSortKey, nonNil } from '@mirage/shared/util/tiny-utils';
import {
  activeStackAtom,
  activeStackHasWritePermissionsAtom,
  activeStackLinkSectionsMapAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { useCopyStackItemUrlToClipboard } from '@mirage/stacks/CopyStackUrl';
import { useSortedStacks } from '@mirage/stacks/hooks';
import classNames from 'classnames';
import { generateKeyBetween } from 'fractional-indexing';
import { useAtomValue } from 'jotai';
import { useCallback, useState } from 'react';
import { useStackItemCollapseSummarySettings } from '../../../service-settings/useStackItemByIdCollapseSummarySettings';
import {
  StackItemSummary,
  StackItemSummaryButton,
} from '../../../shared/stack-item-summary/StackItemSummary';
import { isSummarySupported } from '../../../shared/stack-item-summary/types';
import { DragDroppableSectionLinkComponent } from '../DragAndDrop/DragDroppableSectionLinkComponent';
import {
  DraggableAccessoryIcon,
  DraggableStackLink,
} from '../DragAndDrop/DraggableStackLink';
import { DroppableEmptySection } from '../DragAndDrop/DroppableEmptySection';
import { useEditStates } from '../hooks';
import { Header } from './Header';
import styles from './Section.module.css';
import { StackItemActionMenu } from './StackItemActionMenu';
import {
  StackItemDescription,
  StackItemDescriptionButton,
} from './StackItemDescription';

export const Section = ({
  section,
  items,
  onEditItem,
  augustRevision,
}: {
  section: stacks.Section;
  items: stacks.StackItemShortcut[];
  onEditItem: (item: stacks.StackItemShortcut) => void;
  augustRevision: boolean;
}) => {
  const [actionMenuOpenForItemId, setActionMenuOpenForItemId] = useState<
    string | null
  >(null);
  const stack = useAtomValue(activeStackAtom);
  const allStacks = useSortedStacks();
  const onCopyLink = useCopyStackItemUrlToClipboard(stack);
  const { allSections: sections } = useAtomValue(activeStackSectionsAtom);
  const linkSectionMap = useAtomValue(activeStackLinkSectionsMapAtom);
  const hasWritePermissions = useAtomValue(activeStackHasWritePermissionsAtom);

  const settingId: CollapsedCardKey = `stack_section:${section.id}`;
  const { isCollapsed, setCollapsed } = useCollapsedCardSettings(settingId);

  const isNewStack = !stack?.namespace_id;
  const { reportPapEvent } = useMirageAnalyticsContext();

  const featureLine = 'stacks';
  const dashCardType: DashCardType = 'stack_section';

  const [descriptionEditStates, toggleDescriptionEditState] = useEditStates(
    items.length,
  );

  const account = useDropboxAccount();
  const userProfilePhotoUrl = account?.profile_photo_url;
  const userGivenName = account?.name?.given_name ?? '';

  const dashLinkDescriptionFlag = useFeatureFlagValue('dash_link_description');
  const dashStackItemSummaryFlag =
    useFeatureFlagValue('dash_2024_06_10_stack_item_summary') === 'ON';

  const { isDescriptionCollapsed, setCollapseDescription, isReady } =
    useStackItemCollapseDescriptionSettings();

  const { isSummaryCollapsed, setCollapseSummary } =
    useStackItemCollapseSummarySettings();

  const onExpandCollapseClick = () => {
    if (isCollapsed) {
      reportPapEvent(
        PAP_Expand_DashCard({
          featureLine,
          dashCardType,
        }),
      );
    } else {
      reportPapEvent(
        PAP_Collapse_DashCard({
          featureLine,
          dashCardType,
        }),
      );
    }
    setCollapsed(!isCollapsed);
  };

  const onMoveToSection = useCallback(
    async (sectionId: string, item: stacks.StackItemShortcut) => {
      if (isNewStack || !sections) {
        return;
      }
      const itemLinkIndex = items.findIndex(
        (itemLink) => itemLink.api_file_id === item.api_file_id,
      );
      if (itemLinkIndex === -1) {
        return;
      }

      const dstSectionLinks = linkSectionMap?.get(sectionId) || [];
      const dstLastLink = dstSectionLinks[dstSectionLinks.length - 1];

      const itemLink = items[itemLinkIndex];
      itemLink.sort_key = generateKeyBetween(
        coerceSortKey(dstLastLink?.sort_key),
        null,
      );
      itemLink.parent_section_id = sectionId;
      items[itemLinkIndex] = itemLink;

      reportPapEvent(
        PAP_Move_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
          moveDashLinkType: 'different_section',
          moveDashObjectMethod: 'menu',
        }),
      );
      await updateStackItem(stack.namespace_id ?? '', itemLink);
    },
    [isNewStack, items, reportPapEvent, sections, stack, linkSectionMap],
  );

  const sectionId = section.id ?? DEFAULT_SECTION_ID;
  const isDefaultSection = sectionId === DEFAULT_SECTION_ID;

  const openLink = useCallback(() => {
    if (stack) {
      reportPapEvent(
        PAP_Open_DashLink({
          ...stackDerivePAPProps(stack),
          featureLine: 'stacks',
        }),
      );
    }
    dispatchElementClicked(
      createUxaElementId('stack_item', {
        featureLine: 'stacks',
      }),
    );
  }, [reportPapEvent, stack]);

  if ((items?.length === 0 && isDefaultSection) || !isReady) {
    return null;
  }

  return (
    <TwoColumnGridCard
      settingId={settingId}
      showDividerLine={false}
      showHeader={!isDefaultSection}
      isCollapsedOverride={isCollapsed}
      // Need to set this to avoid empty render from TwoColumnGridCard
      isCollapsedInitially={isCollapsed}
      // For empty sections, add extra padding
      className={classNames(
        items.length === 0 &&
          (augustRevision
            ? styles.augustRevisionEmptySectionContainer
            : styles.emptySectionContainer),
        augustRevision && styles.augustRevisionSectionContainer,
      )}
      cardTypeProps={{
        cardType: CardHeaderType.CUSTOM,
        customHeader: (
          <>
            <Header
              section={section}
              linkCount={items.length}
              isCollapsed={isCollapsed || items.length === 0}
              setIsCollapsed={onExpandCollapseClick}
            />
          </>
        ),
      }}
    >
      {items.length === 0 ? (
        <DroppableEmptySection
          sectionId={sectionId}
          index={0}
          mustCreateNewSection={false}
          // since mustCreateNewSection is false, this is not used
          isHoveringForMoment={false}
          // since mustCreateNewSection is false, this is not used
          setIsHoveringForMoment={() => {}}
        />
      ) : (
        <LinkList
          listItemSize={ListItemSize.XLarge}
          skipOrdering
          className={styles.linkList}
        >
          {items.map((item, index) => {
            const link: Link = {
              id: item.api_file_id,
              title: stackItemGetName(item, allStacks),
              url: nonNil(item.url, 'item.url'),
              sortKey: item.sort_key,
            };

            return (
              <DragDroppableSectionLinkComponent
                listItemSize={
                  augustRevision ? ListItemSize.Large : ListItemSize.XLarge
                }
                forceContentRowEnabled={augustRevision}
                sectionId={sectionId}
                link={link}
                shouldShowHoverState={
                  actionMenuOpenForItemId === item.api_file_id
                }
                onOpenLink={openLink}
                metadata={getStackItemMetadata(item)}
                key={item.api_file_id ?? index}
                index={index}
                item={item}
                icon={
                  augustRevision ? (
                    <ContentIcon
                      baseIcon={
                        <FavIcon
                          src={faviconSrcForSrcUrl(link.url)}
                          size={ListItemSize.Small}
                        />
                      }
                      size={ListItemSize.Large}
                    />
                  ) : undefined
                }
                accessoryComponent={
                  <>
                    <StackItemActionMenu
                      key={item.api_file_id}
                      stack={stack}
                      item={item}
                      setActionMenuOpen={(isOpen) =>
                        setActionMenuOpenForItemId(
                          isOpen ? item.api_file_id ?? null : null,
                        )
                      }
                      onCopyLink={onCopyLink}
                      onEditItem={onEditItem}
                      onMoveToSection={onMoveToSection}
                      currentSection={section}
                      sections={sections || []}
                      hasWritePermissions={hasWritePermissions || false}
                    />
                    {!augustRevision && dashLinkDescriptionFlag && (
                      <StackItemDescriptionButton
                        item={item}
                        toggleDescriptionEditStates={toggleDescriptionEditState}
                        index={index}
                        collapseDescription={isDescriptionCollapsed(
                          item.api_file_id,
                        )}
                        setCollapseDescription={setCollapseDescription}
                        hasWritePermissions={hasWritePermissions || false}
                      />
                    )}
                    {dashStackItemSummaryFlag &&
                      isSummarySupported(item.url) && (
                        <StackItemSummaryButton
                          apiFileId={item.api_file_id}
                          isSummaryCollapsed={isSummaryCollapsed(
                            item.api_file_id,
                          )}
                          setCollapseSummary={setCollapseSummary}
                        />
                      )}
                    <DraggableStackLink
                      sectionId={sectionId}
                      item={item}
                      index={index}
                    >
                      <DraggableAccessoryIcon subtle />
                    </DraggableStackLink>
                  </>
                }
              >
                {!augustRevision &&
                dashLinkDescriptionFlag &&
                !isDescriptionCollapsed(item.api_file_id) ? (
                  <StackItemDescription
                    descriptionEditStates={descriptionEditStates}
                    toggleDescriptionEditStates={toggleDescriptionEditState}
                    userProfilePhotoUrl={userProfilePhotoUrl}
                    index={index}
                    namespace_id={stack?.namespace_id}
                    stackItem={item}
                    stack={stack}
                  />
                ) : (
                  <></>
                )}
                {dashStackItemSummaryFlag &&
                !isSummaryCollapsed(item.api_file_id) ? (
                  <StackItemSummary
                    url={item.url || ''}
                    userProfilePhotoUrl={userProfilePhotoUrl}
                    userGivenName={userGivenName}
                  />
                ) : (
                  <></>
                )}
              </DragDroppableSectionLinkComponent>
            );
          })}
        </LinkList>
      )}
    </TwoColumnGridCard>
  );
};
