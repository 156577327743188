import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { firstValueFrom } from 'rxjs';

import type { LoginSyncMessage } from './service/types';
import type { Service } from '@mirage/service-login-sync-v1/service';
import type { Observable } from 'rxjs';

const service = services.get<Service>(ServiceId.LOGIN_SYNC_V1);

export function fetchServiceLoginState(): Promise<
  LoginSyncMessage | undefined
> {
  return firstValueFrom(service.fetchServiceLoginState());
}

export function sendLoginStateToService(message: LoginSyncMessage) {
  return firstValueFrom(service.sendLoginStateToService(message));
}

export function sendLoginStateToSubscribers(
  message: LoginSyncMessage,
): Promise<LoginSyncMessage | undefined> {
  return firstValueFrom(service.sendLoginStateToSubscribers(message));
}

export function listenForServiceLoginState(): Observable<LoginSyncMessage> {
  return service.listenForServiceLoginState();
}
