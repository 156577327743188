import { ActivityLink } from '../../../ActivityLink/ActivityLink';

export const ActivityLabelLink = ({
  children,
  href,
}: {
  children?: React.ReactNode;
  href: string;
}) => {
  return (
    <ActivityLink href={href} isBold color="standard">
      {children}
    </ActivityLink>
  );
};
