import {
  PAPEvent,
  PAPEventDefaultProperties,
} from '@mirage/analytics/events/base/event';
import { reportPapEvent } from '@mirage/service-product-logging';
import { getDashActionSurface } from '@mirage/shared/hooks/useDashActionSurface';
import { getRoutePathname } from '@mirage/shared/util/route-pathname';
import { dashAppDwellManager } from './sessionManagers/sessionManagers';

export function reportEvent(
  event: PAPEvent,
  flush = false,
  defaultProperties: PAPEventDefaultProperties = {},
) {
  const pathname = getRoutePathname();
  const dashActionSurface = getDashActionSurface(pathname);
  if (dashActionSurface) {
    // Double-logging both dashActionSurface and actionSurface to avoid skewing metrics.
    // We should move to just using dashActionSurface
    defaultProperties.dashActionSurface = dashActionSurface;
    defaultProperties.actionSurface = dashActionSurface;
  }

  const dashSessionId = dashAppDwellManager.getSessionIdOrUndefined();
  if (dashSessionId) {
    defaultProperties.dashSessionId = dashSessionId;
  }

  const combinedProperties = {
    // Preserve actual event trigger time if not already given.
    startTimeMs: Date.now(),
    ...defaultProperties,
    ...event.properties,
  };

  reportPapEvent({ ...event, properties: combinedProperties }, flush);
}
