import { Backoff } from './backoff';
import { sleepMs } from './tiny-utils';

export async function runWithRetries<T>(
  fn: (attemptNum: number, numAttempts: number) => Promise<T>,
  options?: {
    numAttempts?: number;
    backoff?: Backoff;
    logError?: (
      e: unknown,
      attemptNum: number,
      numAttempts: number,
      backoffMs: number,
    ) => void;
    isRetryableError?(e: unknown): boolean; // Default is to retry on all errors
    logFinalError?: (e: unknown, numAttempts: number) => void;
  },
) {
  const numAttempts = options?.numAttempts ?? 4;

  // Use relatively quick retries to minimize user wait time.
  const backoff = options?.backoff ?? new Backoff(600, 2000);

  const logError = options?.logError;
  const logFinalError = options?.logFinalError;

  let lastError = undefined;

  for (let attemptNum = 1; attemptNum <= numAttempts; attemptNum++) {
    try {
      return await fn(attemptNum, numAttempts);
    } catch (e) {
      if (options?.isRetryableError && !options.isRetryableError(e)) {
        logFinalError?.(lastError, numAttempts);
        throw e;
      }

      lastError = e;
      backoff.stepUp();

      // Call logError for last error only if logFinalError is not given.
      if (attemptNum < numAttempts || !logFinalError) {
        logError?.(e, attemptNum, numAttempts, backoff.millis);
      }

      if (attemptNum < numAttempts) {
        await sleepMs(backoff.millis);
      }
    }
  }

  // Throw the last error when all attempts are exhausted.
  logFinalError?.(lastError, numAttempts);
  throw lastError;
}
