import { useComposeSessionsContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeSessionsContext';
import {
  SourcesContentCache,
  useComposeSourcesCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import {
  PostUserMessageParams,
  usePostMessageHandlers,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionPostMessage';
import {
  composeCurrentSessionReducer,
  newSession,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionStates';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantDraftConfig,
  ComposeSession,
  ComposeSource,
  InputContext,
} from '@mirage/shared/compose/compose-session';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

export interface ComposeCurrentSessionContextInterface {
  // persisted
  messagesHistory: ComposeAssistantConversationMessage[];
  sources: ComposeSource[];
  markdownContent: string;
  draftConfig: ComposeAssistantDraftConfig;

  // transient
  sourcesContentCache: SourcesContentCache;
  inputContext: InputContext | undefined;
  isWaitingForResponse: boolean;
  currentSessionID: string;

  // methods
  postUserMessage: (messageParams: PostUserMessageParams) => void;
  postUpdateDraftWithVoice: (voiceID: string) => void;
  addRawMessage: (message: ComposeAssistantConversationMessage) => void;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  setMarkdownContent: (markdownContent: string) => void;
  setDraftConfig: (config: ComposeAssistantDraftConfig) => void;
  setInputContext: (context: InputContext | undefined) => void;
  newSession: (history: ComposeAssistantConversationMessage[]) => void;
  loadSession: (sessionID: string) => void;
}
export const ComposeCurrentSessionContext =
  createContext<ComposeCurrentSessionContextInterface | null>(null);
export const useComposeCurrentSessionContext = () => {
  const context = useContext(ComposeCurrentSessionContext);
  if (!context) {
    throw new Error(
      'useComposeCurrentSessionContext must be used within a ComposeCurrentSessionContextProvider',
    );
  }
  return context;
};

interface ComposeCurrentSessionContextProviderProps {
  children: React.ReactNode;
}
export const ComposeCurrentSessionContextProvider = ({
  children,
}: ComposeCurrentSessionContextProviderProps) => {
  const { voices } = useComposeVoicesContext();
  const { sessions, saveSession } = useComposeSessionsContext();
  const prevSessionsRef = useRef(sessions);

  const [state, dispatch] = useReducer(
    composeCurrentSessionReducer,
    undefined,
    () => {
      return {
        currentSession: newSession([]),
        inputContext: undefined,
        isWaitingForResponse: false,
      };
    },
  );
  const sourcesContents = useComposeSourcesCache(state.currentSession.sources);
  const currentVoice = useMemo(() => {
    return voices?.find(
      (v) => v.id === state.currentSession.draftConfig.voiceID,
    );
  }, [state.currentSession.draftConfig.voiceID, voices]);
  const voiceSourceContents = useComposeSourcesCache(
    currentVoice?.sources || [],
  );

  useEffect(() => {
    // save current session when modified, but only if there is user-entered data
    if (isEmptySession(state.currentSession)) {
      return;
    }
    saveSession(state.currentSession);
  }, [state.currentSession, saveSession]);

  useEffect(() => {
    // if current session is deleted, reset to a new session
    if (isEmptySession(state.currentSession)) {
      return; // ignore unsaved sessions
    }
    if (prevSessionsRef.current === sessions) {
      return; // ignore cases where sessions didn't change
    }
    const deletedSessions = prevSessionsRef.current?.filter(
      (s) => !sessions?.find((ss) => ss.id === s.id),
    );
    prevSessionsRef.current = sessions;

    if (deletedSessions?.find((s) => s.id === state.currentSession.id)) {
      dispatch({
        type: 'newSession',
        history: [],
      });
    }
  }, [sessions, state.currentSession]);

  const { postUserMessage, postUpdateDraftWithVoice } = usePostMessageHandlers(
    state,
    dispatch,
    voices,
    sourcesContents,
    voiceSourceContents,
  );

  const context: ComposeCurrentSessionContextInterface = {
    messagesHistory: state.currentSession.messagesHistory,
    sources: state.currentSession.sources,
    markdownContent: state.currentSession.markdownContent,
    draftConfig: state.currentSession.draftConfig,
    sourcesContentCache: sourcesContents,
    inputContext: state.inputContext,
    isWaitingForResponse: state.isWaitingForResponse,
    currentSessionID: state.currentSession.id,

    postUserMessage,
    postUpdateDraftWithVoice,
    addRawMessage: useCallback(
      (message: ComposeAssistantConversationMessage) => {
        dispatch({
          type: 'addMessage',
          message,
          requiresResponse: false,
        });
      },
      [],
    ),
    addSource: useCallback((source: ComposeSource) => {
      dispatch({
        type: 'addSource',
        source,
      });
    }, []),
    removeSource: useCallback((source: ComposeSource) => {
      dispatch({
        type: 'removeSource',
        source,
      });
    }, []),
    setMarkdownContent: useCallback((markdownContent: string) => {
      dispatch({
        type: 'setMarkdownContent',
        content: markdownContent,
      });
    }, []),
    setDraftConfig: useCallback((config: ComposeAssistantDraftConfig) => {
      dispatch({
        type: 'setDraftConfig',
        config,
      });
    }, []),
    setInputContext: useCallback((context: InputContext | undefined) => {
      dispatch({
        type: 'setInputContext',
        context,
      });
    }, []),
    newSession: useCallback(
      (history: ComposeAssistantConversationMessage[]) => {
        dispatch({
          type: 'newSession',
          history,
        });
      },
      [],
    ),
    loadSession: useCallback(
      (sessionID: string) => {
        const session = sessions?.find((s) => s.id === sessionID);
        if (!session) {
          return;
        }
        dispatch({
          type: 'loadSession',
          session,
        });
      },
      [sessions],
    ),
  };
  return (
    <ComposeCurrentSessionContext.Provider value={context}>
      {children}
    </ComposeCurrentSessionContext.Provider>
  );
};

function isEmptySession(session: Omit<ComposeSession, 'lastUpdated'>): boolean {
  return (
    session.messagesHistory.filter((m) => m.type !== 'instruction').length ===
      0 &&
    session.sources.length === 0 &&
    session.markdownContent === '' &&
    (session.draftConfig.contentDescription || '').trim().length === 0
  );
}
