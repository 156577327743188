import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkCircleLine,
  FailLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { sendResetConnectionToService } from '@mirage/service-browser-history-v2';
import { useExtensionConnectionId } from '@mirage/settings/utils/webExtensionsHelpers';
import Sentry from '@mirage/shared/sentry';
import { showSnackbar } from '@mirage/shared/snackbar';
import i18n from '@mirage/translations';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { clearBrowserHistorySuccessAtom } from './atoms';

export const ClearBrowsingHistory = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const connectionId = useExtensionConnectionId();
  const [clearSuccess, setClearSuccess] = useAtom(
    clearBrowserHistorySuccessAtom,
  );

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSubmit = async () => {
    if (connectionId) {
      try {
        setLoading(true);
        await sendResetConnectionToService(connectionId);
        setClearSuccess(true);

        // Reload upon success to get the new connection ID
        window.location.reload();
        return;
      } catch (e) {
        Sentry.captureException(e, {
          data: {
            message: 'Failed to reset connection',
            connectionId,
          },
        });
        showSnackbar({
          accessory: <UIIcon src={FailLine} />,
          title: i18n.t('settings_clear_history_error'),
          buttons: [
            {
              label: i18n.t('reload'),
              onClick: () => window.location.reload(),
            },
          ],
        });
      }

      setOpen(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Display the success snackbar upon reload
    if (!loading && clearSuccess) {
      showSnackbar({
        accessory: <UIIcon src={CheckmarkCircleLine} />,
        title: i18n.t('settings_clear_history_success'),
      });
      setClearSuccess(false);
    }
  }, [loading, clearSuccess, setClearSuccess]);

  return (
    <>
      <Button variant="transparent" onClick={handleClick}>
        {i18n.t('settings_clear_history_button')}
      </Button>
      <Modal
        open={open}
        isCentered
        withCloseButton={i18n.t('close')}
        onRequestClose={handleClick}
      >
        <Modal.Header>
          <Modal.Title size="medium">
            {/* Use padding to force line break before title gets too close to Close button */}
            <Box paddingRight="24">
              {i18n.t('settings_clear_history_confirm_title')}
            </Box>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{i18n.t('settings_clear_history_confirm_text')}</Modal.Body>
        <Modal.Footer>
          <Button variant="opacity" size="medium" onClick={handleClick}>
            {i18n.t('cancel')}
          </Button>
          <Button
            variant="primary"
            size="medium"
            tone="destructive"
            onClick={handleSubmit}
            isLoading={loading}
          >
            {i18n.t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
