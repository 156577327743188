import { callApiV2 } from '@mirage/service-dbx-api';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { tagged } from '@mirage/service-logging';
import { addDisplayStat, namespace } from '@mirage/service-operational-metrics';
import { getBrowserType } from '@mirage/shared/util/browser';
import { getReadableTime } from '@mirage/shared/util/tiny-utils';
import debounce from 'lodash/debounce';

import type { ReportHandler } from 'web-vitals';

const logger = tagged('vortexMetrics');

export type ClientMetric = {
  '.tag': 'generic_perf_histogram';
  metric_name:
    | 'FCP'
    | 'LCP'
    | 'FID'
    | 'TTFB'
    | 'CLS'
    | 'TTDC'
    | 'INP'
    | 'login';
  surface: 'web' | 'extension' | 'desktop';
  // Overload LoginType in nav_type since nav_type is not needed for logins.
  nav_type?: ClientNavType | LoginType;
  // Overload PairedUserType in path since path is not needed for logins.
  path: ClientPath | PairedUserType;
  value: number;
};

type ClientNavType =
  | 'navigate'
  | 'reload'
  | 'prerender'
  | 'script'
  | 'back_forward';

export type LoginType = 'normal' | 'catapult';

type ClientPath =
  | '/'
  | '/chat'
  | '/stacks'
  | '/stacks/:shareId'
  | '/search_results'
  | '/settings'
  | '/authconfirm'
  | 'unknown';

export type PairedUserType = 'paired' | 'nonpaired';

const queue: ClientMetric[] = [];

// Function used for testing only.
export function setQueueForTesting(q: ClientMetric[]) {
  queue.splice(0, queue.length);
  q.forEach((item) => queue.push(item));
}

const ns = namespace('performance');

// Export for testing only.
export async function flush() {
  if (!queue.length) return;

  const metrics = queue.splice(0, queue.length);

  // TODO: Stop sending the old metrics later.
  try {
    await callApiV2('stacksReportClientMetrics', {
      tags: {
        channel: EnvCtx.buildChannel,
        platform: { '.tag': EnvCtx.surface },
        environment: { '.tag': getBrowserType() ?? 'other_browser' },
      },
      metrics: metrics.map((metric) => ({ metric })),
    });
  } catch (e) {
    logger.debug('failed to report metrics', e);
  }

  try {
    for (const metric of metrics) {
      ns.stats(metric.metric_name, metric.value, {
        ...(metric.nav_type ? { nav_type: metric.nav_type } : {}),
        path: metric.path,
        surface: metric.surface,
      });
    }
  } catch (e) {
    logger.debug('failed to report metrics', e);
  }
}

// Flush metrics when the page is hidden to avoid metrics being lost.
addEventListener('visibilitychange', flush);

// Flush metrics when there is no more metric for 5 seconds.
const debouncedFlush = debounce(flush, 5000);

export function logPerfMetric(metric: ClientMetric) {
  queue.push(metric);
  logger.debug(
    `${getReadableTime()}: ${Date.now()}: logPerfMetric: ${JSON.stringify(
      metric,
    )}`,
  );

  debouncedFlush();
}

function getNavigationType(): NavigationTimingType | undefined {
  return (
    performance.getEntriesByType(
      'navigation',
    )?.[0] as PerformanceNavigationTiming
  )?.type;
}

export const webVitalsVortexReporter: ReportHandler = (metric) => {
  const { name, value } = metric;
  const pathname = window.location.pathname;

  logPerfMetric({
    '.tag': 'generic_perf_histogram',
    metric_name: name,
    surface: EnvCtx.surface,
    path: getClientPath(pathname),
    nav_type: getNavigationType(),
    value,
  });

  addDisplayStat(name, value);
};

export function getClientPath(pathname: string): ClientPath {
  // Allow paths like `/stacks/` to match.
  if (pathname.length > 1 && pathname.endsWith('/')) {
    pathname = pathname.slice(0, pathname.length - 1);
  }

  if (pathname === '/' || pathname === '') {
    return '/';
  } else if (pathname.startsWith('/chat')) {
    return '/chat';
  } else if (pathname === '/stacks' || pathname === '/stacks/') {
    return '/stacks';
  } else if (pathname.startsWith('/stacks/')) {
    return '/stacks/:shareId';
  } else if (pathname.startsWith('/search_results')) {
    return '/search_results';
  } else if (pathname === '/settings' || pathname.startsWith('/settings/')) {
    return '/settings';
  } else {
    // There are some paths that we intentionally don't support, so just log
    // at info level here.
    logger.info(`Unknown path for vortex logging: ${pathname}`);
    return 'unknown';
  }
}
