import { dash_feed, users } from '@dropbox/api-v2-client';
import { DocumentActivityObject } from './document/DocumentActivityObject';
import { StackActivityObject } from './stack/StackActivityObject';

export type ActivityObjectProps = {
  activity: dash_feed.ActivityItem;
  currentAccount?: users.FullAccount;
  index?: number;
  enableSummary?: boolean;
};

export const ActivityObject = ({
  activity,
  index,
  enableSummary = false,
}: ActivityObjectProps) => {
  if (!activity.object) {
    return null;
  }

  const objectDetails = activity.object.details?.object_details;
  switch (objectDetails?.['.tag']) {
    case 'full_stack':
      return (
        <StackActivityObject
          activity={activity}
          index={index}
          enableSummary={enableSummary}
          stack={objectDetails}
          stackData={objectDetails.stack_data!}
        />
      );
    case 'document':
      return (
        <DocumentActivityObject
          activity={activity}
          index={index}
          enableSummary={enableSummary}
          document={objectDetails}
        />
      );
    default:
      return null;
  }
};
