import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { ONE_DAY_IN_MILLIS } from '@mirage/shared/util/constants';
import { exponentialDecayScore } from './utils';

export function lastBrowserViewedScore(result: typeahead.TaggedResult): number {
  if (result.type !== typeahead.ResultType.Recommendation) return 0.0;
  if (!result.result.lastBrowserViewMs) return 0.0;
  const lastBrowserViewMs = result.result.lastBrowserViewMs;

  const delta = Date.now() - lastBrowserViewMs;

  const score = exponentialDecayScore(delta / ONE_DAY_IN_MILLIS, {
    maxScore: 0.995,
    minScore: 0.0001,
    exp: -0.15, // a curve that lines up to about .38 at 1 week
  });

  return score;
}
