import { UIConnection } from '@mirage/shared/types';
import { atom, useAtomValue, useSetAtom } from 'jotai';

/**
 * note: This is a temporary solution which will be superseded by OTCMM-671 once unblocked. The goal is to
 * only show "Sync in progress" for a new connection once. For now, this will enable us to show that a connection
 * was just established, and only show that status until the page is refreshed.
 */

const RecentConnectionIds: string[] = [];

export const recentConnectionIds = atom(RecentConnectionIds);

export const useSetRecentConnectionIds = () => useSetAtom(recentConnectionIds);
export const useConnectionRecentlyEstablished = () => {
  const connectionIds = useAtomValue(recentConnectionIds);

  return (connection: UIConnection) => {
    return connectionIds.some((id: string) => {
      return id === connection.id_attributes?.id;
    });
  };
};
