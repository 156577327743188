import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { getCombineAsyncRequestsFunc } from '@mirage/shared/util/combine-async-requests';
import * as rx from 'rxjs';
import { RedirectState } from './types';

import type { DropboxOptions, users } from '@dropbox/api-v2-client';
import type { AuthenticationData, Service } from '@mirage/service-auth/service';

export type {
  AuthenticationError,
  DropboxAuthQuery,
  Service,
} from '@mirage/service-auth/service';

export { AuthenticationStatus } from '@mirage/service-auth/service';

const service = services.get<Service>(ServiceId.DASH_AUTH);

export function authenticate(
  redirectState: RedirectState | null,
): Promise<void> {
  return rx.firstValueFrom(service.authenticate(redirectState));
}

export function exchangeCodeForToken(
  code: string,
  codeVerifier?: string | null,
): Promise<boolean> {
  return rx.firstValueFrom(service.exchangeCodeForToken(code, codeVerifier));
}

export function getAccessToken(): Promise<string | undefined> {
  return rx.firstValueFrom(service.getAccessToken());
}

export function resetAuthenticationData(): Promise<void> {
  return rx.firstValueFrom(service.resetAuthenticationData());
}

export function getAuthenticationData(): Promise<
  AuthenticationData | undefined
> {
  return rx.firstValueFrom(service.getAuthenticationData());
}

export function refreshAccessToken(): Promise<boolean> {
  return rx.firstValueFrom(service.refreshAccessToken());
}

export function checkAndRefreshAccessToken(): Promise<boolean> {
  return rx.firstValueFrom(service.checkAndRefreshAccessToken());
}

export function getCurrentAccount(
  refresh = false,
): Promise<users.FullAccount | undefined> {
  return rx.firstValueFrom(service.getCurrentAccount(refresh));
}

export function getDropboxAppAuthInitializationParameters(): Promise<DropboxOptions> {
  return rx.firstValueFrom(service.getDropboxAppAuthInitializationParameters());
}

export function getDropboxInitializationParameters(): Promise<DropboxOptions> {
  return rx.firstValueFrom(service.getDropboxInitializationParameters());
}

// The install id never changes, so just cache it for good.
let installId: string | undefined;

const getInstallIdCombined = getCombineAsyncRequestsFunc(
  (): Promise<string> => {
    return rx.firstValueFrom(service.getInstallId());
  },
);

export async function getInstallId(): Promise<string> {
  return installId || (installId = await getInstallIdCombined());
}

export function getSessionId(): Promise<string> {
  return rx.firstValueFrom(service.getSessionId());
}

export function getUpdaterId(): Promise<string | undefined> {
  return rx.firstValueFrom(service.getUpdaterId());
}

export function setInstallId(installId: string): Promise<void> {
  return rx.firstValueFrom(service.setInstallId(installId));
}

/**
 * Use external access token management at your own risk.
 *
 * The service is designed and makes assumptions around managing the entire
 * lifecycle of the access token so that dependents always receive an up-to-date
 * and valid access token when possible.
 */
export function setManagedAccessToken(accessToken: string): Promise<void> {
  return rx.firstValueFrom(service.setManagedAccessToken(accessToken));
}

/**
 * Use this method to mirgate externally-managed auth tokens into the auth
 * service. When using this avoid manually refreshing the token outside the
 * context of the auth service as it will work to keep it up to date.
 */
export function migrateFromExternalManagement(authData: AuthenticationData) {
  return rx.firstValueFrom(service.migrateFromExternalManagement(authData));
}

export function logout(): Promise<void> {
  return rx.firstValueFrom(service.logout());
}

export const listen = service.listen;
export const listenForAccount = service.listenForAccount;
export const listenForAccountIds = service.listenForAccountIds;

export function getShouldUseStageBackend(): Promise<boolean> {
  return rx.firstValueFrom(service.getShouldUseStageBackend());
}

export function getShouldTraceRequests(): Promise<boolean> {
  return rx.firstValueFrom(service.getShouldTraceRequests());
}

export function setShouldUseStageBackend(
  shouldUseStageBackend: boolean,
): Promise<boolean> {
  return rx.firstValueFrom(
    service.setShouldUseStageBackend(shouldUseStageBackend),
  );
}

export function setShouldTraceRequests(
  shouldTraceRequests: boolean,
): Promise<boolean> {
  return rx.firstValueFrom(service.setShouldTraceRequests(shouldTraceRequests));
}

export function isFreshLogin(): Promise<boolean> {
  return rx.firstValueFrom(service.isFreshLogin());
}

export function setIsFreshLogin(value: boolean): Promise<void> {
  return rx.firstValueFrom(service.setIsFreshLogin(value));
}

export function getLoginPathWithReturnRedirectURLParam(): Promise<string> {
  return rx.firstValueFrom(service.getLoginPathWithReturnRedirectURLParam());
}
