// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3401
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashEligibilityCheckOutcome } from '../enums/dash_eligibility_check_outcome';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'shown.dash_eligibility_modal'
// Description: Event fired when the Dash Eligibility modal is displayed
// Owner: otc
export type Shown_DashEligibilityModal = PAPEventGeneric<
  'dash',
  'shown',
  'dash_eligibility_modal',
  {
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // A unique identifier associated with a specific installation of a specific product. This is unique per install but not per user, as a user may have multiple installations of a given product (reinstalls, multiple devices). Per drl/data_classification, this is L2 Data ("Dropbox-assigned device ID").
    installId?: string;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
    // Should map to lower case value of DashEligibilityCheckOutcome enum
    dashEligibilityCheckOutcome?: DashEligibilityCheckOutcome;
  }
>;

export function PAP_Shown_DashEligibilityModal(
  properties?: Shown_DashEligibilityModal['properties'],
): Shown_DashEligibilityModal {
  return <Shown_DashEligibilityModal>{
    class: 'dash',
    action: 'shown',
    object: 'dash_eligibility_modal',
    properties,
  };
}
