import { TypeaheadResult } from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import { typeahead } from '@mirage/service-typeahead-search/service/types';

export function getAddToStackParams(
  scoredResult: typeahead.ScoredResult,
): { url: string; title: string } | undefined {
  const { type, result } = scoredResult;

  if (!('url' in result) || !result.url) {
    return undefined;
  }

  if (type === typeahead.ResultType.StackItem) {
    return { url: result.url, title: result.name };
  }

  if (
    type === typeahead.ResultType.SearchResult ||
    type === typeahead.ResultType.Recommendation
  ) {
    return { url: result.url, title: result.title };
  }

  return undefined;
}

export function showAddToStackShortcut(result?: TypeaheadResult) {
  if (!result) return false;
  return (
    result.type === typeahead.ResultType.StackItem ||
    result.type === typeahead.ResultType.SearchResult ||
    result.type === typeahead.ResultType.Recommendation
  );
}

export function showSummarizeShortcut(result?: TypeaheadResult) {
  if (!result) return false;
  return result.summarizable === 'yes_summarizable';
}
