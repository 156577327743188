import { dash_feed, stacks, users } from '@dropbox/api-v2-client';
import { Trans } from '@mirage/translations';
import { ActivityLabelLink } from '../ActivityLabel/ActivityLabelLink';
import { ActivityLabelSubject } from '../ActivityLabel/ActivityLabelSubject';

type StackActivityLabelProps = {
  activity: dash_feed.ActivityItem;
  stack: stacks.StackData;
  currentAccount?: users.FullAccount;
};

export const StackActivityLabel = ({ activity }: StackActivityLabelProps) => {
  const action = activity.action?.object_action;
  switch (action?.['.tag']) {
    case 'edit':
      return <EditStackActivityAction activity={activity} action={action} />;
    case 'share':
      return <ShareStackActivityAction activity={activity} action={action} />;
    case 'add_child':
      return <AddStackItemActivityAction activity={activity} action={action} />;
    case 'remove_child':
      return (
        <RemoveStackItemActivityAction activity={activity} action={action} />
      );
    default:
      return null;
  }
};

type EditStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionEdit;
};

export const EditStackActivityAction = ({
  activity,
  action,
}: EditStackActivityActionProps) => {
  if (!action.updates || action.updates.length == 0) {
    return null;
  }

  const firstUpdate = action.updates[0].update;
  if (firstUpdate?.['.tag'] == 'stack_update') {
    const updatedField = firstUpdate.field;
    switch (updatedField?.['.tag']) {
      case 'name':
        return (
          <EditStackNameActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      case 'description':
        return (
          <EditStackDescriptionActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      case 'is_archived':
        return (
          <ArchiveStackActivityAction
            activity={activity}
            update={updatedField}
          />
        );
      default:
        return null;
    }
  }

  return null;
};

type EditStackNameActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionName;
};

const EditStackNameActivityAction = ({
  activity,
}: EditStackNameActivityActionProps) => {
  const actorName = activity.actor!.name;

  // John Doe changed the title
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_edit_stack_name_owner"
      values={{ actorName }}
      components={{
        subject: <ActivityLabelSubject />,
      }}
    />
  );
};

type EditStackDescriptionActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionDescription;
};

const EditStackDescriptionActivityAction = ({
  activity,
}: EditStackDescriptionActivityActionProps) => {
  const actorName = activity.actor!.name;

  // John Doe changed the description of your Project Planning stack from “ABC Description” to “XYZ Description”
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_edit_stack_description_owner"
      values={{ actorName }}
      components={{
        subject: <ActivityLabelSubject />,
      }}
    />
  );
};

type ArchiveStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  update: dash_feed.field_unionIsArchived;
};

const ArchiveStackActivityAction = ({
  activity,
  update,
}: ArchiveStackActivityActionProps) => {
  const actorName = activity.actor!.name;
  const isArchived = update.new_value;

  if (isArchived) {
    // John Doe changed the title of your “Old Title” stack to “New Title”
    return (
      <Trans
        i18nKey="activity_feed_expanded_label_archive_stack_owner"
        values={{ actorName }}
        components={{
          subject: <ActivityLabelSubject />,
        }}
      />
    );
  } else {
    // we currently don't display activities if they are not archived
    return null;
  }
};

type ShareStackActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionShare;
};

export const ShareStackActivityAction = ({
  activity,
}: ShareStackActivityActionProps) => {
  const actorName = activity.actor!.name;

  // John Doe shared Project Planning stack with you
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_share_stack_with_you"
      values={{ actorName }}
      components={{
        subject: <ActivityLabelSubject />,
      }}
    />
  );
};

type AddStackItemActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionAddChild;
};

export const AddStackItemActivityAction = ({
  activity,
  action,
}: AddStackItemActivityActionProps) => {
  const actorName = activity.actor?.name;
  const addedItemName = action.name;

  return (
    <Trans
      i18nKey="activity_feed_expanded_label_add_stack_item_owner"
      values={{ actorName, addedItemName }}
      components={{
        subject: <ActivityLabelSubject />,
        addedItemLink: <ActivityLabelLink href={action.url!} />,
      }}
    />
  );
};

type RemoveChildActivityActionProps = {
  activity: dash_feed.ActivityItem;
  action: dash_feed.object_action_unionRemoveChild;
};

export const RemoveStackItemActivityAction = ({
  activity,
  action,
}: RemoveChildActivityActionProps) => {
  const actorName = activity.actor?.name;
  const removedItemName = action.name;

  // John Doe removed Klien Project Planning from your Project Planning stack
  return (
    <Trans
      i18nKey="activity_feed_expanded_label_remove_stack_item_owner"
      values={{ actorName, removedItemName }}
      components={{
        subject: <ActivityLabelSubject />,
        removedItemLink: <ActivityLabelLink href={action.url!} />,
      }}
    />
  );
};
