import { Toggle } from '@dropbox/dig-components/controls';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import React from 'react';
import styles from './ShareModal.module.css';

export const InviteForm: React.FC<{
  shareNotificationEnabled: boolean;
  setShareNotificationEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  notificationNoteText: string;
  setNotificationNoteText: React.Dispatch<React.SetStateAction<string>>;
}> = ({
  shareNotificationEnabled,
  setShareNotificationEnabled,
  notificationNoteText,
  setNotificationNoteText,
}) => {
  return (
    <>
      <div className={styles.notificationToggleContainer}>
        <Toggle
          isToggled={shareNotificationEnabled}
          onChange={() =>
            setShareNotificationEnabled(!shareNotificationEnabled)
          }
        />
        <Text className={styles.standardText}>
          {i18n.t('send_email_notification')}
        </Text>
      </div>
      {shareNotificationEnabled && (
        <TextArea
          className={styles.noteTextArea}
          placeholder={i18n.t('add_a_note')}
          value={notificationNoteText}
          onChange={(e) => {
            setNotificationNoteText(e.currentTarget.value);
          }}
        />
      )}
    </>
  );
};
