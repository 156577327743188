import { stacks } from '@dropbox/api-v2-client';
import { DragDropListItem } from '@mirage/drag-and-drop/DragDropList';
import { upsertStack } from '@mirage/service-stacks';
import { nonNil } from '@mirage/shared/util/tiny-utils';
import {
  activeStackAtom,
  activeStackMutationRequestIdAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useMemo } from 'react';
import { asStackUpsertId } from '../utils';

export function useSectionsDragDrop() {
  const [{ otherSections, defaultSection }, setSections] = useAtom(
    activeStackSectionsAtom,
  );
  const stack = useAtomValue(activeStackAtom);
  const mutationId = useAtomValue(activeStackMutationRequestIdAtom);

  const updateStackSections = useCallback(
    async (newSections: stacks.Section[]) => {
      const update: stacks.StackDataUpdate = {
        field: {
          '.tag': 'section_data_update',
          sections: newSections,
        },
      };
      await upsertStack(asStackUpsertId(stack?.namespace_id, mutationId), [
        update,
      ]);
      setSections(newSections);
    },
    [setSections, stack, mutationId],
  );

  const sectionById = useMemo(() => {
    const map = new Map<string, stacks.Section>();
    for (const section of otherSections ?? []) {
      // Ok to use empty string for default section.
      map.set(section.id ?? '', section);
    }
    return map;
  }, [otherSections]);

  const onDrop = useCallback(
    (newItems: DragDropListItem[]) => {
      const newSections = defaultSection ? [defaultSection] : [];

      newItems.map((item) => {
        const itemId = item.key ?? item.id;
        newSections.push(nonNil(sectionById.get(itemId), `section ${itemId}`));
      });

      updateStackSections(newSections);
    },
    [defaultSection, sectionById, updateStackSections],
  );

  return { defaultSection, otherSections, onDrop };
}
