import { Button } from '@dropbox/dig-components/buttons';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkCircleFill } from '@dropbox/dig-icons/assets';
import { FeatureBanner } from '@mirage/growth/components/FeatureBanner';
import { getFirstLoginTime } from '@mirage/service-onboarding';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import { openURL } from '@mirage/service-platform-actions';
import {
  isExtensionAvailableForBrowser,
  openExtensionLinkForCurrentBrowser,
  useIsExtensionInstalled,
} from '@mirage/settings/utils/webExtensionsHelpers';
import { DESKTOP_DOWNLOAD_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './SettingsBanner.module.css';

const primaryAsset =
  'https://assets.dropbox.com/images/dashweb/platform-large.png';
const mobileAsset =
  'https://assets.dropbox.com/images/dashweb/platform-small-v2.png';

type ActionButtonProps = {
  text: string;
  complete: boolean;
  onClick: () => void;
};

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  complete,
  onClick,
}) => {
  return (
    <Button
      variant="outline"
      onClick={onClick}
      withIconStart={
        complete ? (
          <UIIcon
            src={CheckmarkCircleFill}
            color="var(--dig-color__success__base--state-1)"
            className={styles.checkmarkIcon}
          />
        ) : undefined
      }
    >
      {text}
    </Button>
  );
};

export const SettingsBanner: React.FC = () => {
  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('3d'), []);
  const { dismissed, setDismissed } = useDismissableModule(
    'dismissedSettingsBanner',
    dismissBehavior,
  );
  // Use optimistic values to avoid flashes of the banner before we know
  // if the desktop app or browser extension is installed.
  const [optimisticAppInstalled, setOptimisticAppInstalled] = useState(true);
  const extensionAvailable = isExtensionAvailableForBrowser();
  const extensionInstalled = useIsExtensionInstalled();

  useEffect(() => {
    const getInstalledDesktop = async () => {
      const hasInstalledDesktop = !!(await getFirstLoginTime('desktop'));
      setOptimisticAppInstalled(hasInstalledDesktop);
    };
    getInstalledDesktop();
  }, []);

  const handleInstallDesktopApp = () => {
    openURL(DESKTOP_DOWNLOAD_URL);
  };

  const handleInstallBrowserExtension = () => {
    openExtensionLinkForCurrentBrowser();
  };

  // Don't show the banner if:
  // - the user has dismissed it; or
  // - the app and extension are installed; or
  // - the app is installed and the extension is not available.
  if (
    dismissed ||
    (optimisticAppInstalled && extensionInstalled) ||
    (optimisticAppInstalled && !extensionAvailable)
  )
    return null;

  return (
    <FeatureBanner
      className={styles.banner}
      title={i18n.t('settings_banner_title')}
      description={i18n.t('settings_banner_message')}
      onDismiss={() => setDismissed(true)}
      primaryAsset={
        <img
          src={primaryAsset}
          aria-label={i18n.t('settings_banner_alt')}
          className={styles.primaryAsset}
        />
      }
      mobileAsset={
        <img src={mobileAsset} aria-label={i18n.t('settings_banner_alt')} />
      }
      actions={
        <>
          {extensionAvailable && (
            <ActionButton
              text={i18n.t('extension_call_to_action')}
              complete={extensionInstalled}
              onClick={handleInstallBrowserExtension}
            />
          )}
          <ActionButton
            text={i18n.t('desktop_app_call_to_action')}
            complete={optimisticAppInstalled}
            onClick={handleInstallDesktopApp}
          />
        </>
      }
    />
  );
};
