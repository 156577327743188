import { Title } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import { Skeletons } from '@mirage/settings/components/connectors/Skeleton';
import { useConnectGroupConnector } from '@mirage/settings/hooks/connectors/useConnectGroupConnector';
import {
  groupConnectedByGroupIdAtom,
  groupConnectorsAtom,
} from '@mirage/shared/atoms/groupConnectors';
import { showSnackbar } from '@mirage/shared/snackbar';
import { GroupConnector, UIWebExtension } from '@mirage/shared/types';
import { useAtomValue } from 'jotai';
import { Connector } from './Connector';

type Props = Readonly<{
  browserExtensionData?: UIWebExtension[];
  title: string;
}>;

export const EcosystemConnectors = ({ browserExtensionData, title }: Props) => {
  const groupConnectedByGroupId = useAtomValue(groupConnectedByGroupIdAtom);
  const groupConnectors = useAtomValue(groupConnectorsAtom);
  const connectGroupConnector = useConnectGroupConnector({
    eventProps: { featureLine: 'onboarding' },
  });

  const handleConnectorClick = async (groupConnector: GroupConnector) => {
    const result = await connectGroupConnector(groupConnector);
    showSnackbar({ title: result.message });
  };

  const header = title && (
    <Box display="block">
      <Title size="small">{title}</Title>
    </Box>
  );

  if (!groupConnectors || !browserExtensionData) {
    return (
      <>
        {header}
        <Skeletons length={3} />
      </>
    );
  }

  return (
    <>
      {header}
      <Stack gap="16px">
        {groupConnectors.map((connectorGroup) => {
          return (
            <Connector
              connected={!!groupConnectedByGroupId[connectorGroup.groupId]}
              description={connectorGroup.description}
              key={connectorGroup.groupId}
              label={connectorGroup.title}
              leftAccessory={connectorGroup.icon}
              loading={
                !connectorGroup.connectors ||
                connectorGroup.connectors.some((c) => c.loading)
              }
              onClick={() => handleConnectorClick(connectorGroup)}
              type={connectorGroup.groupId}
            />
          );
        })}
      </Stack>
    </>
  );
};
