import { Text } from '@dropbox/dig-components/typography';
import { BoxEmptySpot } from '@dropbox/dig-illustrations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import i18n from '@mirage/translations';
import { useEffect, useRef } from 'react';
import styles from './PublishedContentEmptyState.module.css';

export const PublishedContentEmptyState = () => {
  const loggedExposure = useRef(false);
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    if (!loggedExposure.current) {
      loggedExposure.current = true;
      reportPapEvent(
        PAP_Shown_DashEmptyStateComponent({
          dashActionSurface: 'published_content_page',
          actionSurfaceComponent: 'published_content',
        }),
      );
    }
  }, [reportPapEvent]);

  return (
    <div className={styles.container}>
      <div className={styles.assetContainer}>
        <BoxEmptySpot height="200px" />
      </div>
      <div className={styles.textContentContainer}>
        <div>
          <Text className={styles.message} size="medium" tagName="p">
            {i18n.t('published_content_empty_state_text')}
          </Text>
        </div>
      </div>
    </div>
  );
};
