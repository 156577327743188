import { dash_feed, stacks } from '@dropbox/api-v2-client';
import { ActivityListItem } from '../ActivityList/ActivityListItem';

const DASH_IMG =
  'https://cfl.dropboxstatic.com/static/go/src/dropbox/dash/connectors/metadata/icons/dash.svg';

type WelcomeStackActivityProps = {
  stack: stacks.Stack;
};

export const WelcomeStackActivity = ({ stack }: WelcomeStackActivityProps) => {
  const dashImg = DASH_IMG;

  const activity: dash_feed.ActivityItem = {
    ts: stack.created_time_utc_ms,
    actor: {
      name: 'Dropbox Dash',
      avatar_img: dashImg,
    },
    action: {
      object_action: {
        '.tag': 'share',
      },
    },
    object: {
      url: stack.sharing_data?.shared_link?.url,
      name: stack.stack_data?.name,
      details: {
        object_details: {
          '.tag': 'full_stack',
          ...stack,
        },
      },
    },
  };
  return <ActivityListItem activity={activity} />;
};
