import {
  buildBrandedSiteInfo,
  buildFileTypeInfo,
  buildIconResource,
} from '@mirage/service-dbx-api/service/utils';
import { ONE_DAY_IN_MILLIS } from '@mirage/shared/util/constants';
import { isDefined } from '@mirage/shared/util/tiny-utils';
import { v4 as uuid } from 'uuid';

import type { RecentBrowserContent, RecentConnectorContent } from '../types';
import type { dash, dcs } from '@dropbox/api-v2-client';
import type { APIv2Callable } from '@mirage/service-dbx-api/service';

export const getRecentContent = async (
  callApiV2: APIv2Callable,
): Promise<RecentBrowserContent[] | undefined> => {
  return callApiV2('stacksGetRecentActivity', {
    // Only show activities from the last 7 days - this avoids showing
    // _really_ old activities that confuse the user (months ago, etc.); this
    // has been a source of some feedback.
    // NB: This value is in seconds, not milliseconds!
    activity_cutoff_timestamp: Math.round(
      (Date.now() - ONE_DAY_IN_MILLIS * 7) / 1000,
    ),
    minimum_activity_time_sec: 5,
    activity_filters: [
      { '.tag': 'activity_filter_skip_low_value_sites' },
      { '.tag': 'activity_filter_skip_duplicate_urls' },
      { '.tag': 'activity_filter_skip_likely_duplicate_titles' },
    ],
    limit: 30, // by default it's 10 on server side.
  })
    .then((r) => {
      return (
        r.recent_activities
          ?.map((activity) => {
            if (
              activity['.tag'] === 'webpage_visit' &&
              activity.url &&
              activity.page_title &&
              activity.visit_timestamp_ms
            ) {
              return {
                url: activity.url,
                title: activity.page_title,
                visit_ms: activity.visit_timestamp_ms,
                connectorInfo: { type: 'browser_history' } as const,
                connectionId: 'browser_history',
                uuid: uuid(),
              } as RecentBrowserContent;
            }
          })
          .filter(isDefined) ?? []
      );
    })
    .catch(() => undefined);
};

const searchResultToRecentConnectorContent = (
  result: dash.SearchResult,
  timestampKey: keyof dash.SearchResult = 'provider_updated_at_ms',
  sortKey: keyof dash.SearchResult | number = 'provider_updated_at_ms',
): RecentConnectorContent | undefined => {
  if (
    result.url &&
    result.title &&
    result.uuid &&
    result[timestampKey] &&
    result.connector_info?.connector_name &&
    result.connector_info?.display_name &&
    result.connector_info?.connector_icon_url
    // data_source is currently not returned by dcs/browse
    // result.data_source?.connection_id
  ) {
    return {
      uuid: result.uuid,
      url: result.url,
      title: result.title,
      visit_ms: result[timestampKey],
      connectorInfo: {
        type: 'connector',
        displayName: result?.connector_info?.display_name,
        connectorName: result?.connector_info?.connector_name,
        connectorIconUrl: result?.connector_info?.connector_icon_url,
        icon: buildIconResource(result?.connector_info?.icon),
      } as const,
      connectionId: result?.data_source?.connection_id,
      fileTypeInfo: buildFileTypeInfo(result.file_type_info),
      brandedSiteInfo: buildBrandedSiteInfo(result.branded_site_info),
      sortValue: typeof sortKey === 'string' ? result[sortKey] : sortKey,
    } as RecentConnectorContent;
  }
};

export const getRecents = async (
  cursor_ms = 0,
  callApiV2: APIv2Callable,
): Promise<
  { cursor_ms?: number; content: RecentConnectorContent[] } | undefined
> => {
  return callApiV2('dashGetRecents', { cursor_ms })
    .then((r) => {
      return {
        cursor_ms: r.has_more ? r.next_cursor_ms : undefined,
        content:
          r.results
            ?.map((r) => searchResultToRecentConnectorContent(r))
            .filter(isDefined) ?? [],
      };
    })
    .catch(() => undefined);
};

/**  Caveats to dcsBrowse
 *     - Only uuid is returned when field_names are not given
 *     - Sort by only works for modified_at_ms and start_time_ms. (returns 500 otherwise)
 *     - connector_info and file_type_info are populated if branded_type and record_type
 *       are specified
 *     - data_source is not provided currently. Thus connection_id is not provided
 */
export const browse = async (
  callApiV2: APIv2Callable,
  filters: dcs.QueryFilter[] = [],
): Promise<RecentConnectorContent[]> => {
  return callApiV2('dcsBrowse', {
    response_size: 100,
    filters,
    sort_by: {
      type: {
        '.tag': 'by_field',
        field_names: [{ '.tag': 'field_name_es_modified_at_ms' }],
        order: { '.tag': 'sort_by_order_desc' },
      },
    },
    field_names: [
      { '.tag': 'field_name_es_uuid' },
      { '.tag': 'field_name_es_url' },
      { '.tag': 'field_name_es_title' },
      { '.tag': 'field_name_es_record_type' },
      { '.tag': 'field_name_es_provider_updated_at_ms' },
      { '.tag': 'field_name_es_modified_at_ms' },
      { '.tag': 'field_name_es_id_3_p' },
      { '.tag': 'field_name_es_branded_type' },
      { '.tag': 'field_name_es_record_type' },
    ],
  })
    .then(
      (r) =>
        r.results
          ?.map((r) => searchResultToRecentConnectorContent(r))
          .filter(isDefined) ?? [],
    )
    .catch(() => []);
};

export const dcsGetRecents = async (
  callApiV2: APIv2Callable,
): Promise<RecentConnectorContent[]> => {
  return callApiV2('dcsGetRecents', {
    limit: 20,
  })
    .then((r) => {
      const length = r.results?.length ?? 0;
      return (
        r.results
          ?.map((r, i) =>
            searchResultToRecentConnectorContent(
              r,
              'updated_at_ms',
              length - i,
            ),
          )
          .filter(isDefined) ?? []
      );
    })
    .catch(() => []);
};
