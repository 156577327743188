import { Menu } from '@dropbox/dig-components/menu';
import { createUxaElementId } from '@mirage/analytics/uxa';
import i18n from '@mirage/translations';
import { type OnlyMineMenuSelection } from './FiltersMenu';

type OnlyMineFilterMenuSegmentProps = {
  isOnlyMine: boolean;
};

export const OnlyMineFilterMenuSegment = ({
  isOnlyMine,
}: OnlyMineFilterMenuSegmentProps) => {
  const segmentKey = 'onlyMine';

  const options = [
    {
      key: 'only_me',
      label: i18n.t('activity_feed_filters_by_person_me'),
      value: true,
      isSelected: isOnlyMine,
    },
    {
      key: 'everyone',
      label: i18n.t('activity_feed_filters_by_person_everyone'),
      value: false,
      isSelected: !isOnlyMine,
    },
  ];
  return (
    <Menu.Segment withLabel={i18n.t('activity_feed_filters_by_person_label')}>
      {options.map((option) => (
        <Menu.SelectItem
          key={option.key}
          value={
            { segmentKey, isOnlyMine: option.value } as OnlyMineMenuSelection
          }
          selected={option.isSelected}
          data-uxa-log={createUxaElementId(`people_filter_${option.key}`, {
            actionSurfaceComponent: 'activity_feed',
            featureLine: 'activity_feed',
          })}
        >
          {option.label}
        </Menu.SelectItem>
      ))}
    </Menu.Segment>
  );
};
