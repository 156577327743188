import styles from './DragBar.module.css';

/**
 * Dragbar is used in macOS desktop to add draggable areas to the top of
 * the window to allow the user to move the window.
 *
 * Not needed in Windows because of the faux title bar
 */

type DragBarProps = {
  height: number;
  roomForScroll?: number;
};

export const DragBar = ({ height, roomForScroll = 0 }: DragBarProps) => {
  return (
    <div
      style={{ height, minWidth: `calc(100% - ${roomForScroll}px)` }}
      className={styles.dragbar}
    />
  );
};
