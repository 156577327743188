import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';
import { isStagingBuildChannel } from '@mirage/shared/util/tiny-utils';
import { useCallback, useEffect, useState } from 'react';
import { getShouldUseStageBackend, setShouldUseStageBackend } from './index';

export default function useShouldUseStageBackend() {
  const [shouldUseStageBackend, setState] = useState(false);
  const shouldUseStageBackendFromGrowthbook = convertFeatureValueToBool(
    useFeatureFlagValue('dash_web_2024_09_17_use_stage_backend'),
  );
  const isStaging = isStagingBuildChannel(EnvCtx.buildChannel);

  useEffect(() => {
    getShouldUseStageBackend().then(setState);
  }, []);

  const onChange = useCallback((useStage: boolean) => {
    setState(useStage);
    setShouldUseStageBackend(useStage);
  }, []);

  useEffect(() => {
    // Currently we only want OTC users to use staging backend
    if (shouldUseStageBackendFromGrowthbook && isStaging) {
      onChange(shouldUseStageBackendFromGrowthbook);
    }
  }, [shouldUseStageBackendFromGrowthbook, isStaging]);

  return [shouldUseStageBackend, onChange] as const;
}
