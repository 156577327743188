import { getStacks } from '@mirage/service-stacks';
import * as primitives from '@mirage/service-typeahead-search/service/primitives';
import { twoStageSearch } from '@mirage/service-typeahead-search/service/search/two-stage-search';
import { SourceId } from '@mirage/service-typeahead-search/service/types';
import * as wrappers from '@mirage/service-typeahead-search/service/utils/wrappers';
import Sentry from '@mirage/shared/sentry';
import * as rx from 'rxjs';
import * as op from 'rxjs/operators';
import { v5 as uuidv5 } from 'uuid';

import type { TypeaheadCache } from '../typeahead-cache';
import type { typeahead } from '@mirage/service-typeahead-search/service/types';
import type { Observable } from 'rxjs';

const STACK_RESULT_NAMESPACE = 'de19e086-03f2-11ef-bbd8-325096b39f47';

const SOURCE_RESULT_LIMIT = 50;

export const search = wrappers.wrapped(SourceId.Stacks, raw);

export function raw(
  query: string,
  cache: TypeaheadCache,
): Observable<typeahead.TaggedResult> {
  return rx
    .from(_internalSearch(query))
    .pipe(op.mergeMap((result) => result))
    .pipe(
      op.map((result) => {
        // Stacks don't have a `uuid` field, they only have a `namespace_id`
        // field. We need a `uuid` field to handle the results in the
        // front-end and PAP, so create a uuid out of `namespace_id`
        const uuid = uuidv5(
          result?.namespace_id || 'stack',
          STACK_RESULT_NAMESPACE,
        );
        return primitives.stack(uuid, result, cache.getHits(uuid));
      }),
    );
}

// exported for testing only
export async function _internalSearch(query: string) {
  try {
    const stacks = await getStacks();

    // handle potential undefined response
    if (!stacks) return [];

    const validStacks = stacks
      // filter out invalid stacks
      .filter(
        (stack) =>
          // we can't launch without namespace_id as it's needed to form the route
          !!stack.namespace_id &&
          // we shouldn't show archived stacks
          !stack.stack_data?.archive_data?.is_archived &&
          // we shouldn't show stacks without a name
          !!stack.stack_data?.name,
      );

    // box the stacks into a SearchableResults array
    const searchableResultCandidates = validStacks.map((stack) => ({
      searchableStrings: [
        stack?.stack_data?.name,
        stack?.stack_data?.description,
      ].filter((s) => s != null),
      originalResult: stack,
    }));

    // and let twoStageSearch handle the actual searching!
    const matchingSearchableResults = twoStageSearch(
      query,
      searchableResultCandidates,
      SOURCE_RESULT_LIMIT,
    );

    // then unbox back to stacks
    return matchingSearchableResults.map(
      (searchableResult) => searchableResult.originalResult,
    );
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
