import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import {
  getSyncService,
  Service,
} from '@mirage/service-result-actions/service';
import { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';
import * as rx from 'rxjs';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api/service/search';

const service = services.get<Service>(ServiceId.RESULT_ACTIONS);

/**
 * Webapp: This has to be performed synchronously, otherwise the action will
 * get blocked by the pop-up blocker in mobile browsers.
 * Electron: This has to be performed in the background.
 */
export function openResult(
  item: SearchResult | QuestionAndAnswerSource | SummarizableResult,
) {
  if (!item.url) {
    return Promise.resolve();
  }
  const s = getSyncService();
  return s
    ? s.openResult(item.url)
    : rx.firstValueFrom(service.openResult(item.url));
}

export function joinMeeting(searchResult: SearchResult) {
  const s = getSyncService();
  return s
    ? s.joinMeeting(searchResult)
    : rx.firstValueFrom(service.joinMeeting(searchResult));
}
