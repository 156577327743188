import { dash_feed } from '@dropbox/api-v2-client';
import { ContentIcon } from '@dropbox/dash-component-library';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { EditLine, StacksLine } from '@dropbox/dig-icons/assets';
import {
  ContentRowAvatar,
  ContentRowIcon,
} from '@mirage/dash-component-library/components/ContentRow';
import { isStackActivityObject } from '@mirage/service-feed/util/helpers';
import { getInitialsFromDisplayName } from '@mirage/shared/account';

type ActivityThumbnailProps = {
  activity: dash_feed.ActivityItem;
};

export const ActivityThumbnail = ({ activity }: ActivityThumbnailProps) => {
  let avatar = null;
  if (activity.actor) {
    avatar = (
      <ContentRowAvatar src={activity.actor.avatar_img}>
        {getInitialsFromDisplayName(activity.actor.name!)}
      </ContentRowAvatar>
    );
  } else {
    avatar = <ContentRowAvatar isGuest={!activity.actor} />;
  }

  return (
    <ContentRowIcon
      icon={avatar}
      badge={<ActivityActionBadge activity={activity} />}
    />
  );
};

const ActivityActionBadge = ({ activity }: ActivityThumbnailProps) => {
  const isStack = isStackActivityObject(activity);
  return (
    <ContentIcon
      size="small"
      shapeVariant="circle"
      hasBackground
      icon={
        <Box
          as={UIIcon}
          backgroundColor="Background Base"
          color="Text Base"
          src={isStack ? StacksLine : EditLine}
        />
      }
    />
  );
};
