import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { Service } from '@mirage/service-browser-history-v2/service';
import { firstValueFrom } from 'rxjs';

const service = services.get<Service>(ServiceId.BROWSER_HISTORY_V2);

export function sendResetConnectionToService(connection_id: string) {
  return firstValueFrom(service.sendResetConnectionToService(connection_id));
}
