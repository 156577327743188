import { ReportPapEventFn } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Finish_DashOnboarding } from '@mirage/analytics/events/types/finish_dash_onboarding';
import { tagged } from '@mirage/service-logging';
import {
  isUsersFirstLogin,
  markDashUserFirstUseTimestamp,
  markDashUserOnboardingCompleteIfNecessary,
} from '@mirage/service-onboarding';
import { RoutePath } from '@mirage/webapp/routeTypes';
import { useNavigate } from 'react-router-dom';

import type { OnboardingVariant } from '@mirage/analytics/events/enums/onboarding_variant';

const logger = tagged('onboarding');

export const markUserDoneOnboarding = async (
  reportPapEvent: ReportPapEventFn,
  variant: string,
) => {
  try {
    await reportPapEvent(
      PAP_Finish_DashOnboarding({
        onboardingVariant: variant.toLowerCase() as OnboardingVariant,
      }),
      true,
    );
    await markDashUserOnboardingCompleteIfNecessary(variant);
  } catch (e) {
    logger.error('Failed to mark user done onboarding: ', e);
  }
};

export const useFinishOnboarding = (
  reportPapEvent: ReportPapEventFn,
  onboardingVariant: string,
) => {
  const navigate = useNavigate();

  return async () => {
    await markUserDoneOnboarding(reportPapEvent, onboardingVariant);
    const firstTimeUser = await isUsersFirstLogin();
    if (firstTimeUser) await markDashUserFirstUseTimestamp();
    navigate(RoutePath.ROOT);
  };
};
