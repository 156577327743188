import * as authService from '@mirage/service-auth';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { isDropboxerAccount } from '@mirage/shared/account';
import { exposeSurfaceSentryInstance } from '@mirage/shared/sentry';
import { isDevBuildChannel } from '@mirage/shared/util/tiny-utils';
import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { DBID_KEY, setURLParamInLocalStorage, UID_KEY } from './readUrlParam';

const SENTRY_DSN =
  'https://46375641387b571221bdb2569b66d47d@o4506146950676480.ingest.sentry.io/4506634449715200';

export async function start() {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
    environment: EnvCtx.buildChannel,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    release: EnvCtx.version,
    ignoreErrors: [
      'TypeError: Failed to fetch', // Not useful and we have separate error handlers for API calls
      'TypeError: NetworkError when attempting to fetch resource.', // The same case as above but only for Safari
      'TypeError: Load failed', // The same case as above but only for Apple devices
      'DropboxResponseError', // Not useful and we have separate error handlers for API calls
      'Response failed with a 409 code', // Mostly non-actionable errors
      'Response failed with a 401 code', // Non-actionable errors and we already have handlers for these
      'Missing access token', // non-actionable error for now it mostly does not have user impacts
      'DOMException', // Mostly non-actionable errors
      // TODO: "login-sync-v1" missing is not an error since this service might
      // not always be available to all clients. Can remove this if condition
      // around Dec 2024 or so after broad rollout. This service is rolled out
      // first to the webapp, but the extension rollout takes more time.
      'Did not receive a connection response from "login-sync-v1"',
    ],
    enabled: !isDevBuildChannel(EnvCtx.buildChannel),
  });

  exposeSurfaceSentryInstance(Sentry);

  Sentry.setTag('dash_app', 'web');
  Sentry.setTag('install_id', await authService.getInstallId());

  Sentry.setUser({ ip_address: '{{auto}}' }); // Set IP address for investigating Sev.

  // `?dbid=<dbx-account-id>` can be passed into the webapp.
  // This is useful to identify the user before login.
  const dbid = setURLParamInLocalStorage(DBID_KEY);
  const uid = localStorage.getItem(UID_KEY);
  if (dbid || uid) {
    Sentry.setUser({ id: uid ? Number(uid) : undefined, dbid });
  }

  authService.listenForAccount().subscribe((account) => {
    if (!account) {
      return;
    }

    localStorage.setItem(DBID_KEY, account.account_id);
    localStorage.setItem(UID_KEY, String(account.uid));

    Sentry.setUser({
      id: account.uid,
      dbid: account.account_id,
    });

    Sentry.setTag('isDropboxer', isDropboxerAccount(account));
  });
}

//
// Use Sentry.captureException(err); to report a caught error
// Use Sentry.captureMessage("Something went wrong"); to report any message
