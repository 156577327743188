// TODO:
// 1. Ensure text is all i18n/not hard-coded
// 2. create storybook

import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { WrapperCloseMenuFn } from '@dropbox/dig-components/menu';
import { Typeahead } from '@dropbox/dig-components/typeahead';
import { UIIcon } from '@dropbox/dig-icons';
import { CloseLine, SendLine } from '@dropbox/dig-icons/assets';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { normalizeQuestion } from '@mirage/mosaics/AnswersConversation/utils';
import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import styles from './QueryInput.module.css';
import { Source } from './SourceV2/Source';
import { TypeaheadRow } from './TypeaheadRow';
import {
  getDropdownRowIconSrc,
  getDropdownTitle,
  getTypeaheadMenuPosition,
  MentionMode,
  VirtualTrigger,
} from './TypeaheadUtils';

import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
import type { SearchResult } from '@mirage/service-dbx-api';

const DEFAULT_VIRTUAL_TRIGGER = new VirtualTrigger();
const DEFAULT_MIN_HEIGHT = 40;
const DEFAULT_MAX_HEIGHT = 160;
const DEFAULT_OPEN_KEYS = ['@'];

type QueryInputProps = {
  onSubmit: (query: string) => void;
  onChange?: (query: string) => void;
  loading: boolean;
  placeholder?: string;
  mode?: 'default' | 'typeahead';
  value?: string;
  minHeight?: number;
  maxHeight?: number;
  setFocusRef?: MutableRefObject<(() => void) | undefined>;
  attachment?: SearchResult | SummarizableResult;
  onAttachmentClick?: (attachment: SearchResult | SummarizableResult) => void;
  isFullPage?: boolean;
  cancel?: () => void;
  reset?: () => void;

  // Typeahead props
  typeaheadResultMarkup?: React.ReactElement;
  typeaheadOpenKeys?: string[];
  typeaheadMentionMode?: MentionMode;
  typeaheadResults?: string[];
  typeaheadMaxResults?: number;
  typeaheadLatestMentionText?: string;
  onTypeaheadSelection?: (
    selectedValue: string,
    textAreaRef: React.RefObject<HTMLTextAreaElement>,
  ) => void;
  onTypeaheadQueryChange?: (
    value: string,
    textAreaRef: React.RefObject<HTMLTextAreaElement>,
    closeMenu: WrapperCloseMenuFn,
    triggerRef: React.RefObject<VirtualTrigger>,
  ) => void;
};

export const QueryInput: React.FC<QueryInputProps> = ({
  onSubmit,
  onChange,
  loading,
  value,
  placeholder,
  mode = 'default',
  minHeight = DEFAULT_MIN_HEIGHT,
  maxHeight = DEFAULT_MAX_HEIGHT,
  setFocusRef,
  attachment,
  onAttachmentClick,
  isFullPage,
  cancel,
  reset,

  // Typeahead props
  typeaheadOpenKeys = DEFAULT_OPEN_KEYS,
  typeaheadMentionMode,
  typeaheadResults = [''],
  typeaheadMaxResults,
  typeaheadLatestMentionText = '',
  onTypeaheadSelection,
  onTypeaheadQueryChange,
}) => {
  const [internalValue, setInternalValue] = useState('');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const triggerRef = useRef(DEFAULT_VIRTUAL_TRIGGER);
  const isSearchSummaryEnabled =
    useFeatureFlagValue('dash_2024_06_24_search_summary') === 'ON';

  useLayoutEffect(() => {
    // Init ref on first render.
    triggerRef.current = new VirtualTrigger();
  }, []);

  // Allow value to be controlled from parent component
  useEffect(() => {
    if (value !== undefined) {
      setInternalValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (setFocusRef) {
      setFocusRef.current = () => textAreaRef.current?.focus();
    }
  }, [setFocusRef]);

  // Allow textarea to expand to max height as user adds characters/lines
  const updateTextAreaHeight = useCallback(
    (height: number) => {
      if (textAreaRef.current) {
        const newHeight = Math.max(minHeight, Math.min(height, maxHeight));
        textAreaRef.current.style.height = newHeight + 'px';
      }
    },
    [maxHeight, minHeight],
  );

  const handleSubmit = () => {
    if (!internalValue.trim().length || loading) {
      return;
    }
    textAreaRef.current?.focus(); // Return focus to textarea
    onSubmit(normalizeQuestion(internalValue));
    setInternalValue(''); // reset state
    updateTextAreaHeight(minHeight); // reset textarea height
  };

  const handleCancel = () => {
    setInternalValue('');
    updateTextAreaHeight(minHeight);
    cancel && cancel();
  };

  const handleReset = () => {
    setInternalValue('');
    updateTextAreaHeight(minHeight);
    reset && reset();
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
      dispatchElementClicked(
        createUxaElementId('send_button', {
          actionSurfaceComponent: isFullPage ? undefined : 'ask_dialog',
          featureLine: 'answers',
        }),
      );
    }
  };

  const onTypeaheadOpen = () => {
    if (textAreaRef?.current) {
      // Update the position of the typeahead component to where trigger was typed
      const { top, left } = getTypeaheadMenuPosition(textAreaRef.current);
      triggerRef.current.position = [left, top];
    }
  };

  // Auto-resize the textarea when the text is changed programmatically
  // instead of manually by the user.
  useEffect(() => {
    onChange?.(internalValue);

    if (textAreaRef.current) {
      updateTextAreaHeight(textAreaRef.current.scrollHeight);
    }

    // We need to keep onChange out of the deps here. We don't want this firing unless an actual change occurred
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalValue, updateTextAreaHeight]);

  const handleQueryChange = (
    e: React.FormEvent<HTMLTextAreaElement>,
    closeMenu: WrapperCloseMenuFn,
    triggerRef: React.RefObject<VirtualTrigger>,
  ) => {
    const text = e.currentTarget.value;

    setInternalValue(text);

    if (mode === 'typeahead') {
      onTypeaheadQueryChange?.(text, textAreaRef, closeMenu, triggerRef);
    }
  };

  const renderTypeaheadRow = (result: string) => (
    <TypeaheadRow
      mentionText={typeaheadLatestMentionText}
      rowText={result}
      iconSrc={getDropdownRowIconSrc(typeaheadMentionMode as MentionMode)}
    />
  );

  return (
    <Typeahead.Wrapper
      closeOnSelection
      onSelection={(val) => onTypeaheadSelection?.(val, textAreaRef)}
      inlineProps={{
        customOpenKey: { keys: typeaheadOpenKeys },
        onCustomOpenKeyTrigger: onTypeaheadOpen,
      }}
      openMenuOnFocus={false}
    >
      {({ getTriggerProps, getContentProps, closeMenu }) => (
        <>
          <div
            className={classNames(styles.textareaWrapper, {
              [styles.textareaWrapperSummary]: isSearchSummaryEnabled,
            })}
          >
            {!isSearchSummaryEnabled && attachment && (
              <div className={styles.attachmentContainer}>
                <Source
                  source={attachment as SummarizableResult}
                  onClick={onAttachmentClick}
                  sourceMaxWidth="350px"
                />
              </div>
            )}
            <textarea
              className={classNames(styles.styledTextArea, {
                [styles.styledTextAreaSummary]: isSearchSummaryEnabled,
              })}
              placeholder={placeholder}
              value={internalValue}
              aria-label={i18n.t('enter_query')}
              style={{ height: minHeight + 'px' }}
              {...getTriggerProps({
                onChange: (event) =>
                  handleQueryChange(
                    event as React.FormEvent<HTMLTextAreaElement>,
                    closeMenu,
                    triggerRef,
                  ),
                onKeyDown: handleKeyDown,
              })}
              ref={textAreaRef}
              data-uxa-log={createUxaElementId('answers_input', {
                actionSurfaceComponent: isFullPage ? undefined : 'ask_dialog',
                featureLine: 'answers',
              })}
              data-uxa-interactions="click change"
            />
            {mode === 'typeahead' && (
              <Typeahead.Container
                {...getContentProps()}
                triggerRef={triggerRef}
              >
                <Typeahead.Results
                  title={getDropdownTitle(typeaheadMentionMode)}
                  results={typeaheadResults}
                  maxResults={typeaheadMaxResults}
                  renderRow={renderTypeaheadRow}
                />
              </Typeahead.Container>
            )}
            {!isSearchSummaryEnabled && (
              <IconButton
                className={styles.styledButton}
                variant="filled"
                onClick={handleSubmit}
                disabled={loading || !internalValue}
                aria-label={i18n.t('aria_answers_question_send')}
                data-uxa-log={createUxaElementId('send_button', {
                  actionSurfaceComponent: isFullPage ? undefined : 'ask_dialog',
                  featureLine: 'answers',
                })}
              >
                <UIIcon src={SendLine} />
              </IconButton>
            )}
            {isSearchSummaryEnabled && !!internalValue && (
              <IconButton
                variant="transparent"
                onClick={handleReset}
                aria-label={i18n.t('doc_summary_qna_question_reset')}
              >
                <UIIcon src={CloseLine} />
              </IconButton>
            )}
          </div>
          {isSearchSummaryEnabled && (
            <div className={styles.buttonContainer}>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={loading || !internalValue}
              >
                {i18n.t('ask')}
              </Button>
              {!!cancel && !internalValue && (
                <Button
                  variant="transparent"
                  onClick={handleCancel}
                  data-uxa-log={createUxaElementId('send_button', {
                    actionSurfaceComponent: isFullPage
                      ? undefined
                      : 'ask_dialog',
                    featureLine: 'answers',
                  })}
                >
                  {i18n.t('cancel')}
                </Button>
              )}
            </div>
          )}
        </>
      )}
    </Typeahead.Wrapper>
  );
};
