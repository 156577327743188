import { Chip } from '@dropbox/dig-components/chip';
import { Title } from '@dropbox/dig-components/typography';
import i18n from '@mirage/translations';
import { memo } from 'react';
import styles from './SidePanelContainer.module.css';

export interface SidePanelContainerProps {
  leftTitleActions?: React.ReactNode;
  children: React.ReactNode;
}
export const SidePanelContainer = memo(
  ({ leftTitleActions, children }: SidePanelContainerProps) => {
    return (
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          {leftTitleActions && (
            <div className={styles.titleActions}>{leftTitleActions}</div>
          )}
          <div className={styles.titleAndChip}>
            <Title size="small" className={styles.title}>
              {i18n.t('compose_assistant_title')}
            </Title>
            <Chip size="small" className={styles.titleChip}>
              {i18n.t('beta')}
            </Chip>
          </div>
        </div>
        {children}
      </div>
    );
  },
);
SidePanelContainer.displayName = 'SidePanelContainer';
