import { Button } from '@dropbox/dig-components/buttons';
import { FeatureBanner } from '@mirage/growth/components/FeatureBanner';
import { useIsUsersDay0 } from '@mirage/service-onboarding/hooks';
import {
  DismissAfterAccountAge,
  useDismissableModule,
} from '@mirage/service-onboarding/useDismissableModule';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './StacksBanner.module.css';

const STACKS_BANNER_ONBOARDING_KEY = 'dismissedStacksBanner';
const primaryAsset =
  'https://assets.dropbox.com/images/dashweb/stacks-large.png';
const mobileAsset =
  'https://assets.dropbox.com/images/dashweb/stacks-small.png';

export type Props = {
  className: string;
};

export const StacksBanner: React.FC<Props> = ({ className }) => {
  const isDay0 = useIsUsersDay0();
  const navigate = useNavigate();
  const dismissBehavior = useMemo(() => new DismissAfterAccountAge('3d'), []);
  const { dismissed, setDismissed } = useDismissableModule(
    STACKS_BANNER_ONBOARDING_KEY,
    dismissBehavior,
  );

  const handleDismissed = () => {
    setDismissed(true);
  };

  const handleCreateAStack = () => {
    // Navigate to Create Stack page
    navigate('/stacks/new');
  };

  if (dismissed || !isDay0) return null;

  return (
    <FeatureBanner
      className={classnames(styles.banner, className)}
      title={i18n.t('stacks_banner_title')}
      description={i18n.t('stacks_banner_message')}
      onDismiss={handleDismissed}
      primaryAsset={
        <img
          src={primaryAsset}
          aria-label={i18n.t('stacks_banner_alt')}
          className={styles.primaryAsset}
        />
      }
      mobileAsset={
        <img src={mobileAsset} aria-label={i18n.t('stacks_banner_alt')} />
      }
      actions={
        <>
          <Button variant="outline" onClick={handleCreateAStack}>
            {i18n.t('stacks_banner_call_to_action')}
          </Button>
        </>
      }
    />
  );
};
