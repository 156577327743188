import { useTheme } from '@dropbox/dig-foundations';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_DashEmptyStateComponent } from '@mirage/analytics/events/types/shown_dash_empty_state_component';
import { StackFilterOption } from '@mirage/service-settings/service/types';
import i18n from '@mirage/translations';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { stackSearchStacksAtom } from '../atoms';
import ArchivedStacksEmptyState from './ArchivedStacksEmptyState';
import SharedStacksEmptyState from './SharedStacksEmptyState';
import StacksEmptyState from './StacksEmptyState';

const BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/all_stacks_empty_state.png';
const DARK_BACKGROUND_IMAGE =
  'https://assets.dropbox.com/images/dashweb/emptystates/all_stacks_empty_state_dark.png';

export default function AllStacksEmptyState({
  className,
  filterPreference,
}: {
  className?: string;
  filterPreference?: StackFilterOption;
}) {
  const searchResultStacks = useAtomValue(stackSearchStacksAtom);
  const { reportPapEvent } = useMirageAnalyticsContext();
  const navigate = useNavigate();
  const { mode } = useTheme();
  const isDarkMode = mode === 'dark';

  useEffect(() => {
    reportPapEvent(
      PAP_Shown_DashEmptyStateComponent({
        dashActionSurface: 'stacks_page',
        actionSurfaceComponent: 'no_stacks',
        featureLine: 'stacks',
      }),
    );
  }, [reportPapEvent, filterPreference]);

  const handleCreateStack = () => {
    // Navigate to Create Stack page
    navigate('/stacks/new');
  };

  // If search result is empty, show empty state
  if (searchResultStacks?.length === 0) {
    return (
      <StacksEmptyState
        className={className}
        title={i18n.t('no_search_results_title')}
        message={i18n.t('no_stacks_search_results_subtitle')}
        imgSrc={isDarkMode ? DARK_BACKGROUND_IMAGE : BACKGROUND_IMAGE}
      />
    );
  }

  if (filterPreference === StackFilterOption.ARCHIVED) {
    return <ArchivedStacksEmptyState className={className} />;
  } else if (filterPreference === StackFilterOption.SHARED) {
    return <SharedStacksEmptyState className={className} />;
  }

  return (
    <StacksEmptyState
      className={className}
      title={i18n.t('stacks_empty_state_title')}
      message={i18n.t('stacks_empty_state_message')}
      callToActionLabel={i18n.t('create_stack')}
      callToAction={handleCreateStack}
      imgSrc={isDarkMode ? DARK_BACKGROUND_IMAGE : BACKGROUND_IMAGE}
    />
  );
}
