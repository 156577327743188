import { DashCardProps } from '@mirage/dash-component-library/components/DashCard';
import { ContentRowProps } from './types';

export const convertPaddingSizeToBreakoutSize = (
  paddingSize: ContentRowProps['paddingSize'],
) => {
  let breakoutSize: DashCardProps['breakout'] = 'xsmall';
  switch (paddingSize) {
    case 'medium':
      breakoutSize = 'small';
      break;
    case 'large':
      breakoutSize = 'xsmall';
      break;
  }
  return breakoutSize;
};

export const convertPaddingSizeToPaddingX = (
  paddingSize: ContentRowProps['paddingSize'],
) => {
  let paddingX: 'Micro XSmall' | 'Micro Medium' = 'Micro XSmall';
  switch (paddingSize) {
    case 'medium':
      paddingX = 'Micro XSmall';
      break;
    case 'large':
      paddingX = 'Micro Medium';
      break;
  }
  return paddingX;
};
