import {
  RecentQueriesCache,
  RecommendationsCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import TypeaheadControlCache from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches/typeahead-control-cache';
import { HitRecord } from '@mirage/shared/search/hit-record';
import { generateClickMap } from '@mirage/shared/search/scoring/clicks';
import WithDefaults from '@mirage/storage/with-defaults';
import { v5 as uuidv5 } from 'uuid';
import { HitTracking } from '../types/common';
import HitTrackingCache from './subcaches/hit-tracking';

import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';
import type { TypeaheadControlCacheShape } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches/typeahead-control-cache';
import type { PreviousQuery } from '@mirage/shared/search/cache-result';
import type { Recommendation } from '@mirage/shared/search/recommendation';
import type { KVStorage } from '@mirage/storage';

const TYPEAHEAD_SEARCH_NAMESPACE = 'b7549bdc-035a-11ef-be37-325096b39f47';

/**
 * Full cache for all types of typeahead results, encapsulates the search relevancy logic between the different types
 * also handles saving in-memory cache to persistent storage when items are added
 */
interface TypeaheadCache {
  // read
  all(key: CacheKey): Readonly<CacheKeyToValueMap[CacheKey][]>;
  getHits(uuid: string): HitTracking | undefined;

  // write
  cacheRecentQueries(queries: string[]): Promise<void>;
  registerHit(uuid: string, epochDate: number): Promise<void>;

  // delete
  clear(key: CacheKey): Promise<void>;
  tearDown(): Promise<void>;
  remove(previousQuery: PreviousQuery): Promise<void>;
}

export enum CacheKey {
  ControlCache = 'cache-metadata',
  RecentQueries = 'recent-queries',
  Recommendations = 'recommendations',
  HitTracking = 'hit-tracking',
}

// source of truth for cached values and internal cache representation
type CacheKeyToValueMap = {
  [CacheKey.ControlCache]: TypeaheadControlCacheShape;
  [CacheKey.RecentQueries]: PreviousQuery;
  [CacheKey.Recommendations]: Recommendation;
  [CacheKey.HitTracking]: HitRecord;
};

type Subcaches = {
  [Property in keyof CacheKeyToValueMap]: MemoryCache<
    CacheKeyToValueMap[Property]
  >;
};

export type PersistentCacheShape = {
  [Property in keyof CacheKeyToValueMap]: CacheKeyToValueMap[Property][];
};

/**
 *
 * Full Cache Implementation
 *
 */
const defaults: PersistentCacheShape = {
  [CacheKey.ControlCache]: [{}],
  [CacheKey.RecentQueries]: [],
  [CacheKey.Recommendations]: [],
  [CacheKey.HitTracking]: [{}],
};

export class TypeaheadCacheImpl implements TypeaheadCache {
  private wrappedStorage: WithDefaults<PersistentCacheShape>;

  private subcaches: Subcaches = {
    [CacheKey.ControlCache]: new TypeaheadControlCache(),
    [CacheKey.RecentQueries]: new RecentQueriesCache(),
    [CacheKey.Recommendations]: new RecommendationsCache(),
    [CacheKey.HitTracking]: new HitTrackingCache(),
  };

  constructor(adapter: KVStorage<PersistentCacheShape>) {
    this.wrappedStorage = new WithDefaults<PersistentCacheShape>(
      adapter,
      defaults,
    );

    // hydrate in-memory subcaches from disk
    this.wrappedStorage.get(CacheKey.ControlCache).then((queries) => {
      this.subcaches[CacheKey.ControlCache].addItems(queries);
    });

    this.wrappedStorage.get(CacheKey.RecentQueries).then((queries) => {
      this.subcaches[CacheKey.RecentQueries].addItems(queries);
    });

    this.wrappedStorage.get(CacheKey.Recommendations).then((records) => {
      this.subcaches[CacheKey.Recommendations].addItems(records);
    });

    this.wrappedStorage.get(CacheKey.HitTracking).then((records) => {
      this.subcaches[CacheKey.HitTracking].addItems(records);
    });

    // clear out storage for old caches which are no longer used
    this.wrappedStorage.set('recently-seen' as CacheKey, []);
    this.wrappedStorage.set('recently-launched' as CacheKey, []);
  }

  all(key: CacheKey) {
    return this.subcaches[key].all();
  }

  async getLastRecommendationsSyncMs() {
    return this.subcaches[CacheKey.ControlCache].all()[0]
      .lastRecommendationsSyncMs;
  }

  async saveRecommendationsSyncedMs() {
    const previousMetadata = this.subcaches[CacheKey.ControlCache].all()[0];
    const newMetadata = await this.subcaches[CacheKey.ControlCache].addItems([
      {
        ...previousMetadata,
        lastRecommendationsSyncMs: Date.now(),
      },
    ]);
    await this.wrappedStorage.set(CacheKey.ControlCache, newMetadata);
  }

  async cacheRecommendations(items: Recommendation[]) {
    const recs = await this.subcaches[CacheKey.Recommendations].addItems(items);
    await this.wrappedStorage.set(CacheKey.Recommendations, recs);
  }

  async clear(key: CacheKey) {
    this.wrappedStorage.set(key, []);
    this.subcaches[key].clear();
  }

  async tearDown() {
    this.wrappedStorage.clear();
  }

  async cacheRecentQueries(queries: string[]): Promise<void> {
    const searchQueries = queries.map((query) => ({
      uuid: uuidv5(query, TYPEAHEAD_SEARCH_NAMESPACE),
      query: query,
    }));

    return this.subcaches[CacheKey.RecentQueries]
      .addItems(searchQueries)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.RecentQueries, records),
      );
  }

  async remove(previousQuery: PreviousQuery) {
    return this.subcaches[CacheKey.RecentQueries]
      .removeItem(previousQuery)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.RecentQueries, records),
      );
  }

  async registerHit(uuid: string, epochDate: number): Promise<void> {
    const cache = this.subcaches[CacheKey.HitTracking] as HitTrackingCache;
    return cache
      .registerHit(uuid, epochDate)
      .then((records) =>
        this.wrappedStorage.set(CacheKey.HitTracking, records),
      );
  }

  getHits(uuid: string): HitTracking | undefined {
    const hits =
      this.subcaches[CacheKey.HitTracking].all()[0][uuid]?.hits ?? [];

    if (hits.length == 0) {
      return undefined;
    }

    return {
      history: generateClickMap(hits),
      mostRecentMs: hits[hits.length - 1],
    };
  }

  __debug__() {
    const DEBUG = false;
    DEBUG satisfies false; // ensure we don't accidentally commit this as true

    return {
      [CacheKey.RecentQueries]: this.subcaches[CacheKey.RecentQueries].count(),
      [CacheKey.Recommendations]:
        this.subcaches[CacheKey.Recommendations].count(),
      [CacheKey.HitTracking]: this.subcaches[CacheKey.HitTracking].count(),
      [`DEBUG_${CacheKey.RecentQueries}`]: DEBUG
        ? this.subcaches[CacheKey.RecentQueries].all()
        : undefined,
      [`DEBUG_${CacheKey.Recommendations}`]: DEBUG
        ? this.subcaches[CacheKey.Recommendations].all()
        : undefined,
      [`DEBUG_${CacheKey.HitTracking}`]: DEBUG
        ? this.subcaches[CacheKey.HitTracking].all()
        : undefined,
    };
  }
}
