import { Text } from '@dropbox/dig-components/typography';
import { Box, Stack } from '@dropbox/dig-foundations';
import {
  DashCard,
  getTooltipInCardProps,
} from '@mirage/dash-component-library/components/DashCard';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import classnames from 'classnames';
import { CSSProperties, ReactNode, useState } from 'react';
import styles from './TileCard.module.css';

interface TileCardProps {
  icon: ReactNode;

  /**
   * Title can be a string or a ReactNode. If a string, it will be
   * truncated with an ellipsis if it exceeds the available space.
   * ReactNode should only be used for Skeleton loader states.
   */
  title: string | ReactNode;
  titleLinesBeforeTruncate?: number;
  metadata: ReactNode;

  /**
   * An optional icon/button/action to be rendered in the top right
   * corner of the card.  It will sit above the card contents and can
   * be interactive if `withInteractiveAccessory` is true.
   */
  withAccessory?: ReactNode;
  withInteractiveAccessory?: boolean;
  interactiveAccessoryClassName?: string;

  /**
   * Defines the behavior when the card is clicked. Expected to be
   * navigation along with analytics event tracking
   */
  onClick?: () => void;
  /**
   * The URL to show in the browser when hovering over the card
   * Will not be used for actual navigation
   */
  visibleUrl?: string;

  /**
   * If true, the card will be hidden from the DOM using visibility: hidden
   * and opacity: 0. This allows it to still occupy space for animations
   * while removing it from the tab order and screen readers.
   */
  hidden?: boolean;

  className?: string;
  style?: CSSProperties;

  children?: never;
}

export const TileCard = ({
  icon,
  title,
  titleLinesBeforeTruncate = 1,
  metadata,
  withAccessory,
  withInteractiveAccessory = false,
  interactiveAccessoryClassName,
  onClick,
  visibleUrl,
  hidden = false,
  className,
  style,
}: TileCardProps) => {
  const titleStyle = {
    // In order to set a minimum height, define the number of lines before
    // truncation to enforce a consistent height for all cards,
    // and allow metadata to bottom align in the card
    '--dash-tile-card__title__linecount': titleLinesBeforeTruncate,
  } as CSSProperties;
  const [isTruncated, setIsTruncated] = useState(false);
  const tooltipTextProps = getTooltipInCardProps(
    isTruncated,
    onClick ?? (() => {}),
    titleStyle,
  );

  return (
    <DashCard
      onClick={onClick}
      visibleUrl={visibleUrl}
      style={style}
      className={classnames(styles.card, className, {
        [styles.hidden]: hidden,
      })}
      breakout="xsmall"
    >
      <Stack gap="Micro Medium">
        <div>{icon}</div>
        <Stack gap="Micro XSmall">
          {typeof title === 'string' ? (
            <Text
              size="medium"
              isBold
              tagName="div"
              // Duplicate the onClick event because this will sit above the link
              // to allow for the tooltip to be triggered by hover
              className={styles.title}
              {...tooltipTextProps}
            >
              <DashTruncateWithTooltip
                lines={titleLinesBeforeTruncate}
                tooltipProps={{
                  title,
                }}
                onBeforeTruncate={setIsTruncated}
              >
                {title}
              </DashTruncateWithTooltip>
            </Text>
          ) : (
            <Box style={titleStyle} className={styles.title}>
              {title}
            </Box>
          )}
          {metadata}
        </Stack>
      </Stack>
      {withAccessory && (
        <div
          className={classnames(
            styles.accessoryButton,
            {
              [styles.interactiveAccessory]: withInteractiveAccessory,
            },
            interactiveAccessoryClassName,
          )}
        >
          {withAccessory}
        </div>
      )}
    </DashCard>
  );
};
