import { stacks } from '@dropbox/api-v2-client';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { StackSortOption } from '@mirage/service-settings/service/types';
import { useStackByIdSortSettings } from '@mirage/service-settings/useStackByIdSortSettings';
import { DEFAULT_SECTION_ID } from '@mirage/service-stacks/service/utils';
import {
  activeStackLinkSectionsMapAtom,
  activeStackSearchItemsAtom,
  activeStackSectionsAtom,
} from '@mirage/stacks/ActiveStack/atoms';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { DroppableEmptySection } from '../DragAndDrop/DroppableEmptySection';
import { SearchResultsStackItemsSection } from './SearchResultsStackItemsSection';
import { Section } from './Section';
import { SortedStackItemsSection } from './SortedStackItemsSection';

interface SectionsProps {
  namespaceId: string;
  onEditItem: (item: stacks.StackItemShortcut) => void;
}

export const Sections: React.FC<SectionsProps> = ({
  namespaceId,
  onEditItem,
}) => {
  const augustRevision = useStackPageAugustRevisionEnabled();
  const { defaultSection, otherSections } = useAtomValue(
    activeStackSectionsAtom,
  );
  const { sortPreference } = useStackByIdSortSettings(namespaceId);
  const searchResults = useAtomValue(activeStackSearchItemsAtom);

  if (augustRevision && searchResults) {
    return (
      <SearchResultsStackItemsSection
        items={searchResults}
        onEditItem={onEditItem}
      />
    );
  }

  if (
    sortPreference !== undefined &&
    sortPreference.option !== StackSortOption.SECTION
  ) {
    return <SortedStackItemsSection onEditItem={onEditItem} />;
  }

  return (
    <>
      {defaultSection && (
        <SectionArea section={defaultSection} onEditItem={onEditItem} />
      )}
      {otherSections?.map((section) => (
        <SectionArea
          key={section.id}
          section={section}
          onEditItem={onEditItem}
        />
      ))}
    </>
  );
};

interface SectionAreaProps {
  section: stacks.Section;
  onEditItem: (item: stacks.StackItemShortcut) => void;
}

const SectionArea: React.FC<SectionAreaProps> = ({ section, onEditItem }) => {
  const augustRevision = useStackPageAugustRevisionEnabled();
  const linkSectionMap = useAtomValue(activeStackLinkSectionsMapAtom);

  const sectionId = section.id ?? DEFAULT_SECTION_ID;
  const items = linkSectionMap ? linkSectionMap.get(sectionId) ?? [] : [];

  /**
   * Keep track of the DroppableEmptySection that the user is currently hovering over.
   * The DND library seems to not always fire when a user has stopped hovering over
   * a DroppableEmptySection, so we are keeping track of this state in the parent
   * to ensure that only one DroppableEmptySection is highlighted at a time.
   */
  const [emptySectionIdHoveringOver, setEmptySectionIdHoveringOver] = useState<
    string | null
  >(null);
  return (
    <>
      <Section
        section={section}
        items={items}
        onEditItem={onEditItem}
        augustRevision={augustRevision}
      />
      <DroppableEmptySection
        sectionId={sectionId}
        index={0}
        isHoveringForMoment={emptySectionIdHoveringOver === sectionId}
        setIsHoveringForMoment={(hovering: boolean) => {
          if (hovering) {
            setEmptySectionIdHoveringOver(sectionId);
          } else {
            setEmptySectionIdHoveringOver(null);
          }
        }}
        mustCreateNewSection={
          // when we have an empty default section, use this "empty space" to fill
          // the default section with items instead of creating a new section
          !(sectionId === DEFAULT_SECTION_ID && items.length === 0)
        }
      />
    </>
  );
};
