import { users } from "@dropbox/api-v2-client";
import { getAuthenticationData } from "@mirage/service-auth";
import { tagged } from "@mirage/service-logging";
import { sendLoginStateToService } from "@mirage/service-login-sync-v1";
import { isExtensionInstalled } from "@mirage/settings/utils/webExtensionsHelpers";
import { runWithTimeLimit } from "@mirage/shared/util/tiny-utils";

const logger = tagged("OAuth");

/** Returns true if the auth data was sent to the extension. */
export async function syncSignInToExtension(
  account: users.FullAccount | undefined,
): Promise<boolean> {
  if (!isExtensionInstalled()) {
    logger.debug(`Extension not installed => no need to send auth data`);
    return false;
  }

  if (!account) {
    logger.warn(`Account missing => cannot send auth data`);
    return false;
  }

  const authData = await getAuthenticationData();
  if (!authData) {
    logger.warn(`Auth data missing => cannot send auth data`);
    return false;
  }

  logger.info(`Sending auth data to extension for auto-login`);

  // Old versions of the browser extension will not have this service, so we
  // need to add a time limit here.
  await runWithTimeLimit(
    sendLoginStateToService({
      type: "login",
      email: account.email,
      ...authData,
    }),
    2000,
  );

  logger.info(`Sent auth data to extension for auto-login`);

  return true;
}
