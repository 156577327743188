import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import { Text } from '@dropbox/dig-components/typography';
import { ComposeSources } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSources';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { DashThemeProvider } from '@mirage/service-settings/theming/DashThemeProvider';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import KeyCap from '@mirage/shared/keyboard-shortcuts/KeyCap';
import i18n from '@mirage/translations';
import { memo } from 'react';
import styles from './AddSourcesModal.module.css';

interface AddSourcesModalProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  addSource: (source: ComposeSource) => void;
  removeSource: (source: ComposeSource) => void;
  onRequestClose: () => void;
  onClickDone: () => void;
}
export const AddSourcesModal = memo(
  ({
    sources,
    sourcesContentCache,
    addSource,
    removeSource,
    onRequestClose,
    onClickDone,
  }: AddSourcesModalProps) => {
    return (
      <Modal open width={640} isCentered onRequestClose={onRequestClose}>
        <DashThemeProvider>
          <Modal.Body className={styles.ModalBody}>
            <div className={styles.ModalBodyContent}>
              <ComposeSources
                sources={sources}
                sourcesContentCache={sourcesContentCache}
                onAddSource={addSource}
                onRemoveSource={removeSource}
              />
            </div>
            <Modal.Footer className={styles.ModalFooter}>
              <div>
                <Text
                  size="small"
                  color="subtle"
                  monospace
                  className={styles.sourceCountLabel}
                >
                  {i18n.t('compose_add_sources_count', {
                    count: sources.length,
                  })}
                </Text>
              </div>
              <div>
                <KeyCap casing="capitalize">Esc</KeyCap>
                <Text
                  monospace
                  className={styles.keyCapLabel}
                  color="subtle"
                  size="small"
                >
                  {i18n.t('close')}
                </Text>
                <Button
                  size="medium"
                  variant="primary"
                  onClick={onClickDone}
                  className={styles.submitButton}
                >
                  {i18n.t('save')}
                </Button>
              </div>
            </Modal.Footer>
          </Modal.Body>
        </DashThemeProvider>
      </Modal>
    );
  },
);
AddSourcesModal.displayName = 'AddSourcesModal';
