import { ml_capabilities } from '@dropbox/api-v2-client/types/dropbox_types';
import { callApiV2 } from '@mirage/service-dbx-api';

export interface LLMFunctionDefinition {
  name: string;
  description: string;
  parameters: {
    type: string;
    properties: {
      [key: string]: LLMFunctionProperty;
    };
    required: string[];
  };
}

export interface LLMFunctionProperty {
  type: string;
  description?: string;
  items?: LLMFunctionProperty;
  properties?: {
    [key: string]: LLMFunctionProperty;
  };
}

export interface LLMResponse {
  responseMessage: ml_capabilities.ChatMessageAssistantMessage;
  responseText: string;
  toolCalls: ml_capabilities.ToolCall[];
}

export async function callLLMApi({
  messages,
  tools,
  toolChoice,
}: {
  messages: ml_capabilities.ChatMessage[];
  tools: LLMFunctionDefinition[];
  toolChoice: 'auto' | 'required';
}) {
  const toolsJSON = JSON.stringify(
    tools.map((tool) => ({ type: 'function', function: tool })),
  );
  const response = await callApiV2('mlCapabilitiesChatCompletion', {
    model_info: {
      model_name: 'gpt-4o-mini',
      model_source: 'openai',
    },
    dialogs: [
      {
        messages,
      },
    ],
    capabilities_config: {
      feature: 'compose',
      api_key: {
        key: 'OPENAI_API_KEY_ML_FOUNDATIONS',
        provider: {
          '.tag': 'secret_sauce',
        },
      },
    },
    model_params: {
      max_tokens: {
        config_type: {
          '.tag': 'int32_config',
          int32_config: 16000,
        },
      },
      tool_choice: {
        config_type: {
          '.tag': 'str_config',
          str_config: toolChoice,
        },
      },
      tools: {
        config_type: {
          '.tag': 'str_config',
          str_config: toolsJSON,
        },
      },
    },
  });
  const responseMessage = response.results![0].generations![0].chat_message;
  switch (responseMessage?.['.tag']) {
    case 'assistant_message':
      {
        const responseText = responseMessage.content?.text || '';
        const toolCalls = responseMessage.tool_calls || [];
        return { responseMessage, responseText, toolCalls };
      }
      break;
    default:
      throw new Error(
        `Unexpected response message type: ${responseMessage?.['.tag']}`,
      );
  }
}
