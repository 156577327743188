import { List } from '@dropbox/dig-components/list';
import { Skeleton } from '@dropbox/dig-components/skeleton';
import { Text } from '@dropbox/dig-components/typography';
import { Box, Cluster, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import { SyncingLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Shown_CalendarEmptyState } from '@mirage/analytics/events/types/shown_calendar_empty_state';
import {
  LottieAnimation,
  LottieAnimationType,
} from '@mirage/shared/lottie-animations/LottieAnimation';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useEffect } from 'react';
import styles from './CalendarEmptyStates.module.css';
import { EMPTY_STATE_LOGGING_DELAY } from './constants';

export const CalendarEventsEmpty = () => {
  const { reportPapEvent } = useMirageAnalyticsContext();

  useEffect(() => {
    const timeout = setTimeout(() => {
      reportPapEvent(
        PAP_Shown_CalendarEmptyState({
          featureLine: 'calendar',
          calendarEmptyStateType: 'no_events',
        }),
      );
    }, EMPTY_STATE_LOGGING_DELAY);

    return () => clearTimeout(timeout);
  }, [reportPapEvent]);

  return (
    <Stack gap="Micro Small" className={styles.noEvents} padding="Micro Small">
      <Box className={styles.noEventsListItem}>
        <Box
          className={styles.illustrationContainer}
          display="flex"
          justifyContent="center"
        >
          <LottieAnimation
            animationType={LottieAnimationType.HIGH_FIVE}
            autoplay
            loop={false}
            alt={i18n.t('no_meetings_all_done')}
            className={styles.allDoneIllustration}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          className={styles.textContainer}
        >
          <Stack gap="Micro XSmall">
            <Box display="flex" justifyContent="center">
              <Text variant="label">{i18n.t('no_meetings_all_done')}</Text>
            </Box>
            <Box display="flex" justifyContent="center">
              <Text color="subtle">{i18n.t('no_meetings_breaktime')}</Text>
            </Box>
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export const CalendarEventsLoading = ({
  reason,
}: {
  reason: 'loading' | 'syncing';
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  useEffect(() => {
    const timeout = setTimeout(() => {
      reportPapEvent(
        PAP_Shown_CalendarEmptyState({
          featureLine: 'calendar',
          calendarEmptyStateType: reason,
        }),
      );
    }, EMPTY_STATE_LOGGING_DELAY);

    return () => clearTimeout(timeout);
  }, [reportPapEvent, reason]);

  const loadingMessage =
    reason === 'loading'
      ? i18n.t('no_meetings_loading')
      : i18n.t('no_meetings_syncing');

  return (
    <Stack gap="Micro Small" className={styles.syncing}>
      <Box padding="Micro Small" color="Text Subtle">
        <Cluster gap="Micro Small" alignY="center">
          <Cluster.Item className={styles.iconContainer}>
            <UIIcon
              src={SyncingLine}
              size="small"
              style={{ display: 'block' }}
            />
          </Cluster.Item>
          <Cluster.Item>
            <Text variant="label" size="small" color="inherit">
              {loadingMessage}
            </Text>
          </Cluster.Item>
        </Cluster>
      </Box>
      <List paddingY="0">
        <Stack gap="Micro XSmall">
          {Array.from({ length: 3 }).map((_, key) => (
            <List.Item key={key} className={styles.skeletonListItem}>
              <List.Content>
                <div className={styles.skeletonTextBox}>
                  <Stack gap="Micro Small">
                    <Skeleton.Text
                      size="small"
                      withBorderRadius="xsmall"
                      width={120}
                      className={classNames(styles.top)}
                    />
                    <Skeleton.Text
                      size="small"
                      withBorderRadius="xsmall"
                      width={240}
                      className={classNames(styles.bottom)}
                    />
                  </Stack>
                </div>
              </List.Content>
            </List.Item>
          ))}
        </Stack>
      </List>
    </Stack>
  );
};
