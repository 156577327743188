import titleTokenizer from '@mirage/shared/search/tokenizers/title-tokenizer';

type TokenizerFn = (value: string) => string[];

export default function tokenize(
  word: string,
  tokenizer: TokenizerFn = titleTokenizer,
) {
  return tokenizer(word);
}

export { titleTokenizer };
