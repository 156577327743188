/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { TextInput } from '@dropbox/dig-components/text_fields';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
  CloseLine,
} from '@dropbox/dig-icons/dist/mjs/assets';
import { clearDisplayStats } from '@mirage/service-operational-metrics';
import { useDisplayStatsByName } from '@mirage/service-operational-metrics/hooks';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { Fragment, useMemo } from 'react';
import styles from './OperationalMetrics.module.css';

const isCollapsedAtom = atomWithStorage<boolean>('opMetricsCollapsed', false);
const filterTextAtom = atomWithStorage<string>('opMetricsFilterText', '');

export const OperationalMetrics: React.FC = () => {
  const [isCollapsed, setIsCollapsed] = useAtom(isCollapsedAtom);
  const [filterText, setFilterText] = useAtom(filterTextAtom);
  const statsByName = useDisplayStatsByName();

  const entries = useMemo(() => {
    return statsByName ? Array.from(Object.entries(statsByName)) : [];
  }, [statsByName]);

  const filteredEntries = useMemo(() => {
    if (filterText.length === 0) {
      return entries;
    }

    // Ignore case.
    const filterTextLC = filterText.toLocaleLowerCase();
    return entries.filter(([metricName, _]) => {
      return metricName.toLocaleLowerCase().indexOf(filterTextLC) >= 0;
    });
  }, [entries, filterText]);

  return (
    <Box width={'100%'}>
      <Box display="block" paddingBottom="16">
        <Text size="medium" isBold>
          Operational Metrics &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="opacity"
            onClick={() => {
              clearDisplayStats();
            }}
          >
            Clear Data
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="opacity"
            onClick={() => {
              setIsCollapsed(!isCollapsed);
            }}
          >
            {isCollapsed ? (
              <UIIcon src={ChevronDownLine} />
            ) : (
              <UIIcon src={ChevronUpLine} />
            )}
          </Button>
        </Text>
        {isCollapsed || <hr className={styles.horizontalSeparator} />}
      </Box>
      {isCollapsed || (
        <>
          <Box paddingBottom="16">
            <TextInput
              value={filterText}
              onChange={(event) => {
                setFilterText(event.currentTarget.value);
              }}
              placeholder={'Filter'}
              withRightAccessory={
                <IconButton variant="transparent" size="small">
                  <UIIcon src={CloseLine} onClick={() => setFilterText('')} />
                </IconButton>
              }
            />
          </Box>
          <div className={styles.grid}>
            <div>
              <Text isBold>Metric</Text>
            </div>
            <div>
              <Text isBold>Type</Text>
            </div>
            <div>
              <Text isBold>Value</Text>
            </div>
            {filteredEntries.map(([metricName, { count, stats }]) => {
              return (
                <Fragment key={metricName}>
                  <Text>{metricName}</Text>
                  <Text>
                    {count !== undefined && 'count'}
                    {stats !== undefined && 'time'}
                  </Text>
                  <Text size="small" monospace>
                    {count !== undefined && count}
                    {stats !== undefined &&
                      stats.map((s) => Number(s).toFixed(1)).join(', ')}
                  </Text>
                </Fragment>
              );
            })}
          </div>
        </>
      )}
    </Box>
  );
};
