import * as dbxApiService from '@mirage/service-dbx-api';
import {
  buildConnectorInfo,
  buildFileTypeInfo,
} from '@mirage/service-dbx-api/service/utils';
import { tagged } from '@mirage/service-logging';

import type { TypeaheadResult } from '../SearchBarWithTypeahead/useConvertToTypeaheadResults';
import type { dash, dash_feed } from '@dropbox/api-v2-client';
import type { LaunchMethod } from '@mirage/analytics/events/enums/launch_method';
import type { QuestionAndAnswerSource } from '@mirage/shared/answers/multi-answer';
import type {
  ConnectorInfo,
  FileTypeInfo,
  SearchResult,
  SurfaceSpecificRecordType,
} from '@mirage/shared/search/search-result';
// import type { StackItem } from '@mirage/shared/search/stack-item';

// TODO OSE-4166 Move this file and tests to it's own package

const logger = tagged('SummaryQnaPanel/utils');

export type SummarizableTypes =
  | dash_feed.ActivityObject
  | QuestionAndAnswerSource
  | SearchResult
  // | StackItem
  | TypeaheadResult;

export enum SummaryQnAEnum {
  ACTIVITY = 'summarizable-activity',
  QNA = 'summarizable-qna',
  SEARCH = 'summarizable-search',
  // STACK = 'summarizable-stack',
  TYPEAHEAD = 'summarizable-typeahead',
}

// import type { SummarizableResult } from '@mirage/mosaics/SummaryQnaPanel/utils';
export type SummarizableResult = {
  type: SummaryQnAEnum;
  summarizableTag?: dash.SummarizableType['.tag'] | null;
  uuid: string;
  url: string | null;
  title: JSX.Element | string;
  connectorInfo: ConnectorInfo | null;
  fileTypeInfo: FileTypeInfo | null;
  icon: JSX.Element | string | null;
  launchResult: ((launchMethod: LaunchMethod) => void) | null;
  recordType?: RecordTypeTag;
  searchRequestId?: string;
};

export type NormalizeResultInputType =
  | dash_feed.ActivityObject
  | QuestionAndAnswerSource
  | SearchResult
  | TypeaheadResult;
// | StackItem

export const normalizeResultForSummary = (
  result: NormalizeResultInputType,
  summaryQnAType: SummaryQnAEnum,
): SummarizableResult | null => {
  switch (summaryQnAType) {
    case SummaryQnAEnum.ACTIVITY:
      return {
        type: summaryQnAType,
        summarizableTag:
          (result as dash_feed.ActivityObject).summarizable?.['.tag'] || null,
        uuid: (result as dash_feed.ActivityObject).uuid || '',
        url: (result as dash_feed.ActivityObject).url || null,
        title: (result as dash_feed.ActivityObject).name || '',
        connectorInfo:
          buildConnectorInfo(
            (result as dash_feed.ActivityObject).connector_info,
          ) || null,
        fileTypeInfo:
          buildFileTypeInfo(
            (result as dash_feed.ActivityObject).file_type_info,
          ) || null,
        icon: null,
        launchResult: null,
      };
    case SummaryQnAEnum.QNA:
      return {
        type: summaryQnAType,
        summarizableTag: null,
        uuid: (result as QuestionAndAnswerSource).id3p,
        url: (result as QuestionAndAnswerSource).url || null,
        title: (result as QuestionAndAnswerSource).title,
        connectorInfo:
          buildConnectorInfo({
            ...(result as QuestionAndAnswerSource).connectorInfo,
            connector_type: { '.tag': 'other' },
            platform: undefined,
          }) || null,
        fileTypeInfo: null,
        icon: (result as QuestionAndAnswerSource).iconUrl || null,
        launchResult: null,
      };
    case SummaryQnAEnum.SEARCH:
      return {
        type: summaryQnAType,
        summarizableTag: (result as SearchResult).summarizable || null,
        uuid: (result as SearchResult).uuid,
        url: (result as SearchResult).url || null,
        title: (result as SearchResult).title,
        recordType: getRecordType(result as SearchResult),
        connectorInfo: (result as SearchResult).connectorInfo || null,
        fileTypeInfo: (result as SearchResult).fileTypeInfo || null,
        icon: null,
        launchResult: null,
        searchRequestId: (result as SearchResult).searchRequestId || '',
      };
    // case SummaryQnAEnum.STACK:
    //   return {
    //     type: summaryQnAType,
    //     summarizableTag: (result as StackItem).summarizable?.['.tag'] || null,
    //     uuid: (result as StackItem).uuid,
    //     url: (result as StackItem).url || null,
    //     title: (result as StackItem).name,
    //     connectorInfo: null,
    //     fileTypeInfo: null,
    //     icon: null,
    //     launchResult: null,
    //   };
    case SummaryQnAEnum.TYPEAHEAD:
      return {
        type: summaryQnAType,
        summarizableTag: (result as TypeaheadResult).summarizable || null,
        uuid: (result as TypeaheadResult).uuid,
        url: null,
        title: (result as TypeaheadResult).title,
        connectorInfo: null,
        fileTypeInfo: null,
        icon: (result as TypeaheadResult).icon || null,
        launchResult: (result as TypeaheadResult).launchResult || null,
      };
    default:
      logger.error('Unsupported data type', result);
      return null;
  }
};

type RecordTypeTag =
  | 'unknown_record_type'
  | 'document'
  | 'contact'
  | 'event'
  | 'file'
  | 'cloud_app'
  | 'workspace'
  | 'dash_desktop_application'
  | 'dash_desktop_local_file'
  | 'browser_tab'
  | 'other'
  | 'user'
  | 'webpage'
  | 'relationship';

// exported for testing

export const isRecordTypeTag = (
  tag: dash.RecordType['.tag'] | SurfaceSpecificRecordType['.tag'],
): tag is RecordTypeTag => {
  const validTags: RecordTypeTag[] = [
    'unknown_record_type',
    'document',
    'contact',
    'event',
    'file',
    'cloud_app',
    'workspace',
    'dash_desktop_application',
    'dash_desktop_local_file',
    'webpage',
    'other',
  ];
  return validTags.includes(tag);
};

export const getRecordType = (
  result: dbxApiService.SearchResult,
): RecordTypeTag | undefined => {
  if (result.recordType && result.recordType['.tag']) {
    const tag = result.recordType['.tag'];
    if (isRecordTypeTag(tag)) {
      return tag;
    }
  }
  return undefined;
};
