import { Text } from '@dropbox/dig-components/typography';
import {
  type SummarizableResult,
  SummaryQnAEnum,
} from '@mirage/mosaics/SummaryQnaPanel/utils';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import { cloneElement } from 'react';
import { AnswersActionComponent } from '../types';
import styles from './Source.module.css';

export type SourceProps = {
  source: SummarizableResult;
  onClick?: (source: SummarizableResult) => void;
  sourceMaxWidth?: string;
  sourceWidth?: string;
  actionSurfaceComponent?: AnswersActionComponent;
  onQASourceClick?: (source: SummarizableResult) => void;
  copyToClipboard?: (text: string) => void;
};

export const Source = ({
  source,
  onClick,
  sourceMaxWidth = '100%',
  sourceWidth = 'auto',
  onQASourceClick,
  copyToClipboard,
}: SourceProps) => {
  const isQASource = source.type === SummaryQnAEnum.QNA;
  const isTHSource = source.type === SummaryQnAEnum.TYPEAHEAD;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.metaKey || event.ctrlKey) {
      copyToClipboard && copyToClipboard(source.uuid);
      return;
    }
    if (!isQASource && onClick) {
      onClick(source);
    }
    if (isQASource && onQASourceClick) {
      onQASourceClick(source);
    }
  };

  // typeahead source.icon is a FileContentIcon component
  const thSourceIcon =
    isTHSource &&
    source.icon &&
    typeof source.icon !== 'string' &&
    cloneElement(source.icon, { hasBackground: false });

  return (
    <div className={styles.source}>
      <DigTooltip
        key={source.uuid}
        title={source.title}
        isPortaled
        openDelay={400}
      >
        <button
          className={styles.sourceButton}
          onClick={handleClick}
          style={{ maxWidth: sourceMaxWidth, width: sourceWidth }}
          aria-label={typeof source.title === 'string' ? source.title : ''}
          type="button"
        >
          {isTHSource && thSourceIcon}
          {isQASource && (
            <img
              className={styles.iconContainer}
              alt={i18n.t('answers_source_logo_alt', {
                connectorName: source.connectorInfo?.connectorName,
              })}
              src={
                (typeof source.icon === 'string' && source.icon) || undefined
              }
            />
          )}
          {!isQASource && !isTHSource && (
            <FileContentIcon hasBackground={false} content={source} />
          )}
          <Text className={styles.sourceTitle} size="small" isBold>
            {source.title}
          </Text>
        </button>
      </DigTooltip>
    </div>
  );
};
