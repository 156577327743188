import { typoMatch } from '@mirage/service-typeahead-search/service/scoring/utils/typo-match';
import tokenize from '@mirage/shared/search/tokenizers';

/**
 * A generic boxed type for search results where the searchable strings are extracted and listed separately.
 *
 * This allows the two stage search algorithm to search through the strings regardless of where they come from or how many there are per item.
 */
type SearchableResults<T> = {
  /**
   * The list of strings that are associated to this result to search through.
   *
   * This is a list because some results (like stack items) can have both a
   * title and description (and possibly more) that we want to search through
   **/
  searchableStrings: string[];
  /** the original result used to unbox the matching results after the search is complete */
  originalResult: T;
};

/**
 * Performs a generic "two stage" search.
 *
 * Stage 1: exact substring matches.
 *   If the query is a substring of any searchable string, the result is included.
 *   These are always listed first in the returned array of matched results.
 *
 * Stage 2: token similarity matches.
 *   This tokenizes the searchable strings by words and uses a jaro winkler
 *   similarity algorithm to compare the query tokens to the searchable tokens
 *   in the typoMatch function.
 *
 * For performance, this will stop searching once the limit is hit, so best
 * results will return when the searchableResults are pre-sorted in a way so
 * that more likely matches are at the beginning of the array. However this is
 * not required.
 */
export function twoStageSearch<OriginalSearchResultType>(
  query: string,
  searchableResults: SearchableResults<OriginalSearchResultType>[],
  limit: number,
): SearchableResults<OriginalSearchResultType>[] {
  const results = new Set<SearchableResults<OriginalSearchResultType>>();
  const lcQuery = query.toLowerCase();

  // first stage: exact substring matches
  for (const searchableItemIndex in searchableResults) {
    const searchableItem = searchableResults[searchableItemIndex];

    if (
      searchableItem.searchableStrings.some((searchableString) =>
        searchableString.toLowerCase().includes(lcQuery),
      )
    ) {
      // it's a substring match!
      results.add(searchableItem);
    }

    // For optimal performance, stop searching once we hit the limit
    if (results.size === limit) {
      return Array.from(results);
    }
  }

  // tokenize query
  const qTokens = tokenize(lcQuery);

  // second stage: token similarity matches
  for (const searchableItem of searchableResults) {
    // skip any items that are already in the results from exact matches
    if (results.has(searchableItem)) continue;

    if (
      searchableItem.searchableStrings.some((searchableString) => {
        const tTokens = tokenize(searchableString.toLowerCase());
        return typoMatch(qTokens, tTokens);
      })
    ) {
      results.add(searchableItem);
    }

    // For optimal performance, stop searching once we hit the limit
    if (results.size === limit) {
      return Array.from(results);
    }
  }

  return Array.from(results);
}
