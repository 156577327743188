import type { Runtime } from 'webextension-polyfill';

/**
 * Wrapper function that allows us to use the chrome runtime port as a message
 * port for service discovery.
 */
export function browserExtensionPortAdapter(
  runtimePort: Runtime.Port | chrome.runtime.Port,
): MessagePort {
  // Create message channel as a proxy to the runtime port.
  const { port1, port2 } = new MessageChannel();

  runtimePort.onMessage.addListener((message) => {
    port1.postMessage(message);
  });

  port1.onmessage = (event) => {
    runtimePort.postMessage(event.data);
  };

  runtimePort.onDisconnect.addListener(() => {
    port1.close();
    port2.close();
  });

  return port2;
}
