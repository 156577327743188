import { useFeatureFlagValue } from '@mirage/service-experimentation/useFeatureFlagValue';
import { convertFeatureValueToBool } from '@mirage/service-experimentation/util';

export const useIsActivityFeedEnabled = (): boolean => {
  const value = useFeatureFlagValue('dash_2024_06_13_activity_feed');
  return convertFeatureValueToBool(value);
};

export const useAreActivityFeedFiltersEnabled = (): boolean => {
  const value = useFeatureFlagValue('dash_2024_08_01_activity_feed_filters');
  return convertFeatureValueToBool(value);
};

export const useIsActivityFeedApplicationsFilterEnabled = (): boolean => {
  const value = useFeatureFlagValue(
    'dash_2024_08_01_activity_feed_filter_applications',
  );
  return convertFeatureValueToBool(value);
};

export const useIsActivityFeedPeopleFilterEnabled = (): boolean => {
  const value = useFeatureFlagValue(
    'dash_2024_08_01_activity_feed_filter_people',
  );
  return convertFeatureValueToBool(value);
};

export const useIsActivityFeedOnlyMineFilterEnabled = (): boolean => {
  const value = useFeatureFlagValue(
    'dash_2024_08_01_activity_feed_filter_only_mine',
  );
  return convertFeatureValueToBool(value);
};

export const useIsExpandedActivityItemsEnabled = (): boolean => {
  const value = useFeatureFlagValue(
    'dash_2024_09_12_activity_feed_expanded_items',
  );
  return convertFeatureValueToBool(value);
};
