import { typeahead } from '@mirage/service-typeahead-search/service/types';
import { BROWSER_VIEW_KEYS } from '@mirage/shared/search/scoring/browser-views';
import { exponentialDecayScore } from './utils';

export function frequentlyBrowserViewedScore(
  result: typeahead.TaggedResult,
): number {
  if (result.type !== typeahead.ResultType.Recommendation) return 0.0;
  if (!result.result.browserViewsHistory) return 0.0;
  const browserViews = result.result.browserViewsHistory;

  // TODO: The time falloff for 30d is very strong, should we make the slope
  // less aggressive?
  const weightDecayParams = {
    maxScore: 0.995,
    minScore: 0.0001,
    // Less severe falloff for browser views because they include a 30d bucket
    exp: -0.005,
  };

  const keys = [
    { key: BROWSER_VIEW_KEYS.LAST_1_HOUR, days: 1 / 24, maxBrowserViews: 1 },
    { key: BROWSER_VIEW_KEYS.LAST_4_HOURS, days: 4 / 24, maxBrowserViews: 3 },
    { key: BROWSER_VIEW_KEYS.LAST_8_HOURS, days: 8 / 24, maxBrowserViews: 5 },
    { key: BROWSER_VIEW_KEYS.LAST_1_DAY, days: 1, maxBrowserViews: 7 },
    { key: BROWSER_VIEW_KEYS.LAST_3_DAYS, days: 3, maxBrowserViews: 9 },
    { key: BROWSER_VIEW_KEYS.LAST_7_DAYS, days: 7, maxBrowserViews: 11 },
    // WARN: Server gives 30d value for browser views, but not for clicks!
    { key: BROWSER_VIEW_KEYS.LAST_30_DAYS, days: 30, maxBrowserViews: 11 },
  ] as const;

  let rawScore = 0;

  for (const { key, days, maxBrowserViews } of keys) {
    const browserViewsForPeriod = browserViews[key] || 0;
    const normalizedBrowserViews =
      Math.min(browserViewsForPeriod, maxBrowserViews) / maxBrowserViews;
    const periodWeight = exponentialDecayScore(days, weightDecayParams);
    const periodScore = normalizedBrowserViews * periodWeight;
    rawScore += periodScore;
  }

  // Normalize the score to be between 0.0 and 1.0
  let maxPossibleScore = 0;
  for (const { days } of keys) {
    maxPossibleScore += exponentialDecayScore(days, weightDecayParams);
  }
  const score = Math.min(rawScore / maxPossibleScore, 1.0);

  return score;
}
