import styled from '@emotion/styled';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { useTheme } from '@mirage/service-settings/theming/useTheme';
import classnames from 'classnames';
import React from 'react';
import styles from './LoadingResult.module.css';

export const LoadingResult: React.FC<{
  delayMs?: number;
}> = ({ delayMs = 0 }) => {
  const isDarkMode = useTheme();
  const isDesktop = EnvCtx.surface === 'desktop';

  return (
    <ResultContainer
      isDarkMode={isDarkMode}
      className={styles.loadingResultcontainer}
      style={{ animationDelay: `${delayMs}ms` }}
    >
      <div className={styles.contentContainer}>
        <div
          className={classnames(styles.headerContainer, {
            [styles.desktop]: isDesktop,
          })}
        >
          <IconShimmer size={isDesktop ? '32px' : '48px'} />
          <div className={styles.titleContainer}>
            <TextShimmer height={isDesktop ? '14px' : '16px'} width="300px" />
            <TextShimmer height={isDesktop ? '12px' : '16px'} width="88px" />
          </div>
        </div>
        <div
          className={classnames(styles.textContainer, {
            [styles.desktop]: isDesktop,
          })}
        >
          <TextShimmer height="14px" width="400px" />
          <TextShimmer height="14px" width="350px" />
        </div>
      </div>
    </ResultContainer>
  );
};

export const LoadingTypeaheadResult: React.FC = () => {
  const isDarkMode = useTheme();
  return (
    <TypeaheadResultContainer isDarkMode={isDarkMode}>
      <IconShimmer size="24px" />
      <TextShimmer height="14px" width="100%" />
    </TypeaheadResultContainer>
  );
};

export const LoadingResultList = ({
  count = 10,
  staggerMs = 0, // Stagger each result by X milliseconds
}: {
  count: number;
  staggerMs?: number;
}) => {
  const loadingList = React.useMemo(
    () =>
      Array.from({ length: count }, (v, i) => (
        <LoadingResult delayMs={i * staggerMs} key={`loading-result-${i}`} /> // Make sure to provide a unique key prop
      )),
    [count],
  );

  return <>{loadingList}</>;
};

const ShimmerListItem = styled.li<{ isDarkMode: boolean }>`
  position: relative;
  @keyframes shimmer {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent,
      ${(props) =>
        props.isDarkMode
          ? 'rgba(36, 33, 33, 0.2)'
          : 'rgba(255, 255, 255, 0.3)'},
      transparent
    );
    animation: shimmer 1.5s infinite;
  }
`;

const ResultContainer = styled(ShimmerListItem)`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 12px 16px;
  gap: 20px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const TypeaheadResultContainer = styled(ShimmerListItem)`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const IconShimmer = styled.div<{ size?: string }>`
  flex-shrink: 0;
  width: ${({ size }) => size ?? '48px'};
  height: ${({ size }) => size ?? '48px'};
  border-radius: 4px;
  background-color: var(--dig-color__opacity__surface);
`;

const TextShimmer = styled.div<{ height: string; width: string }>`
  max-width: 100%;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  background-color: var(--dig-color__opacity__surface);
  border-radius: 4px;
`;

LoadingResult;
