import { ComposeEditor } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeEditor';
import { SelectionAction } from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { VoiceSettingsModal } from '@mirage/mosaics/ComposeAssistant/components/settings/VoiceSettingsModal';
import { useComposeVoicesContext } from '@mirage/mosaics/ComposeAssistant/data/ComposeVoicesContext';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { getSelectionActionPromptString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

interface VoiceSettingsModalConfig {
  type: 'open';
  voiceID?: string;
}

export const ComposeEditorPane = memo(() => {
  const {
    isWaitingForResponse,
    markdownContent,
    setMarkdownContent,
    draftConfig,
    setDraftConfig,
    postUpdateDraftWithVoice,
  } = useComposeCurrentSessionContext();
  const { voices, saveVoice, deleteVoice } = useComposeVoicesContext();
  const [openVoiceSettingsConfig, setOpenVoiceSettingsConfig] = useState<
    VoiceSettingsModalConfig | undefined
  >();

  const handleTriggerSelectionAction = useHandleTriggerSelectionAction();
  const handleChangeVoiceID = useCallback(
    (voiceID: string) => {
      postUpdateDraftWithVoice(voiceID);
    },
    [postUpdateDraftWithVoice],
  );
  const handleCreateNewVoice = useCallback(() => {
    const voice: ComposeVoice = {
      id: uuid4(),
      name: i18n.t('compose_new_style'),
      sources: [],
    };
    saveVoice(voice)
      .then(() => {
        setDraftConfig({
          ...draftConfig,
          voiceID: voice.id,
        });
        setOpenVoiceSettingsConfig({ type: 'open', voiceID: voice.id });
        return;
      })
      .catch((error) => {
        throw error;
      });
  }, [draftConfig, saveVoice, setDraftConfig]);
  const handleOpenVoiceSettings = useCallback((voiceID: string | undefined) => {
    setOpenVoiceSettingsConfig({ type: 'open', voiceID });
  }, []);
  const handleCloseVoicesSettings = useCallback(() => {
    setOpenVoiceSettingsConfig(undefined);
  }, []);

  return (
    <>
      <ComposeEditor
        isWaitingForResponse={isWaitingForResponse}
        markdownContent={markdownContent}
        onChangeContent={setMarkdownContent}
        onTriggerSelectionAction={handleTriggerSelectionAction}
        currentVoiceID={draftConfig.voiceID}
        customVoices={voices}
        onChangeVoiceID={handleChangeVoiceID}
        onCreateNewVoice={handleCreateNewVoice}
        onOpenVoiceSettings={handleOpenVoiceSettings}
      />
      {openVoiceSettingsConfig && (
        <VoiceSettingsModal
          voices={voices || []}
          saveVoice={saveVoice}
          deleteVoice={deleteVoice}
          initialVoiceID={openVoiceSettingsConfig.voiceID}
          onRequestClose={handleCloseVoicesSettings}
        />
      )}
    </>
  );
});
ComposeEditorPane.displayName = 'ComposeEditorPane';

function useHandleTriggerSelectionAction() {
  const { setInputContext, postUserMessage } =
    useComposeCurrentSessionContext();
  return useCallback(
    (action: SelectionAction) => {
      switch (action.type) {
        case 'make-longer':
        case 'make-shorter':
        case 'rewrite':
          postUserMessage({
            text: `${getSelectionActionPromptString(action.type)}:
------------
${action.selectedText}
------------
`,
            mustGenerateDraft: false,
            mustIncludeSourceContents: false,
          });
          break;
        case 'prompt':
          setInputContext({
            type: 'selection',
            selectedText: action.selectedText,
          });
          break;
        default:
          action.type satisfies never;
          throw new Error(`Unknown action type: ${action}`);
      }
    },
    [postUserMessage, setInputContext],
  );
}
