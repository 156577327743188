import { useEffect, useState } from 'react';
import { getIsDfbUser } from '..';

/**
 * Used for metrics tags in the useSearch pipeline
 * @returns 'true' | 'false' | 'unknown'
 */
export function useIsDfbUserTag() {
  const [isDfbUser, setIsDfbUser] = useState<string>('unknown');

  async function getTagVal() {
    const isDfb = await getIsDfbUser();
    setIsDfbUser(isDfb.toString());
  }

  useEffect(() => {
    getTagVal();
  }, []);

  return isDfbUser;
}
