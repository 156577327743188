import { Button, IconButton } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CloseLine,
  MagicWandLine,
  SummarizeLine,
} from '@dropbox/dig-icons/assets';
import { Markdown } from '@mirage/conversations/Markdown/Markdown';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { tagged } from '@mirage/service-logging';
import { openURL } from '@mirage/service-platform-actions';
import {
  ComposeAssistantConversationMessage,
  ComposeAssistantConversationMessageInstruction,
  ComposeAssistantConversationMessageMessage,
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import { SearchResultIcon } from '@mirage/shared/icons';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, useEffect, useRef } from 'react';
import styles from './ConversationMessages.module.css';

const logger = tagged('ConversationMessages');

interface ConversationMessagesProps {
  startingMessageNode?: React.ReactNode;
  messages: ComposeAssistantConversationMessage[];
  isWaitingForResponse: boolean;
  onClickFollowUpSuggestion: (suggestion: string) => void;
  onRemoveSource: (source: ComposeSource) => void;
}
export const ConversationMessages = memo(
  ({
    startingMessageNode,
    messages,
    isWaitingForResponse,
    onClickFollowUpSuggestion,
    onRemoveSource,
  }: ConversationMessagesProps) => {
    const messagesDivRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      if (messagesDivRef.current) {
        messagesDivRef.current.scrollTo(0, messagesDivRef.current.scrollHeight);
      }
    }, [messages]);
    return (
      <div className={styles.ConversationMessages} ref={messagesDivRef}>
        {startingMessageNode}
        {messages.map((message, i) => (
          <ConversationMessageRow
            key={i}
            message={message}
            showFollowUpSuggestions={i === messages.length - 1}
            onClickFollowUpSuggestion={onClickFollowUpSuggestion}
            onRemoveSource={onRemoveSource}
          />
        ))}
        {isWaitingForResponse && (
          <div className={styles.LoadingMessageRow}>
            <Spinner size="small" />
          </div>
        )}
      </div>
    );
  },
);
ConversationMessages.displayName = 'ConversationMessages';

interface ConversationMessageRowProps {
  message: ComposeAssistantConversationMessage;
  showFollowUpSuggestions: boolean;
  onClickFollowUpSuggestion: (suggestion: string) => void;
  onRemoveSource: (source: ComposeSource) => void;
}
export const ConversationMessageRow = memo(
  ({
    message,
    showFollowUpSuggestions,
    onClickFollowUpSuggestion,
    onRemoveSource,
  }: ConversationMessageRowProps) => {
    switch (message.type) {
      case 'message':
        return (
          <ConversationMessageRowMessage
            message={message}
            showFollowUpSuggestions={showFollowUpSuggestions}
            onClickFollowUpSuggestion={onClickFollowUpSuggestion}
            onRemoveSource={onRemoveSource}
          />
        );
      case 'instruction':
        return <ConversationMessageRowInstruction message={message} />;
      default:
        message satisfies never;
        throw new Error(`Unknown message type: ${message}`);
    }
  },
);
ConversationMessageRow.displayName = 'ConversationMessageRow';

interface ConversationMessageRowMessageProps {
  message: ComposeAssistantConversationMessageMessage;
  showFollowUpSuggestions: boolean;
  onClickFollowUpSuggestion: (suggestion: string) => void;
  onRemoveSource: (source: ComposeSource) => void;
}
export const ConversationMessageRowMessage = memo(
  ({
    message,
    showFollowUpSuggestions,
    onClickFollowUpSuggestion,
    onRemoveSource,
  }: ConversationMessageRowMessageProps) => {
    return (
      <>
        <div
          className={classNames(styles.ConversationMessageRow, {
            [styles.ConversationMessageRowUser]: message.role === 'user',
          })}
        >
          <ConversationMessageRowActionAndText message={message} />
        </div>
        {message.referencingSources &&
          message.referencingSources.length > 0 && (
            <div
              className={classNames(styles.ConversationMessageSources, {
                [styles.ConversationMessageSourcesUser]:
                  message.role === 'user',
              })}
            >
              {message.referencingSources.map((source, i) => (
                <ConversationMessageRowMessageSourceRow
                  key={getSourceUUID(source) || i}
                  source={source}
                  onRemoveSource={onRemoveSource}
                />
              ))}
            </div>
          )}
        {showFollowUpSuggestions && message.followUpSuggestions && (
          <div className={styles.ConversationMessageFollowUpSuggestions}>
            <Text color="subtle" size="small">
              {i18n.t('compose_message_follow_up_suggestions')}
            </Text>
            {message.followUpSuggestions.map((suggestion, i) => (
              <Button
                key={`follow-up-${i}`}
                className={styles.ConversationMessageFollowUpSuggestionButton}
                withIconStart={<UIIcon src={SummarizeLine} />}
                variant="outline"
                onClick={() => onClickFollowUpSuggestion(suggestion)}
              >
                <Truncate
                  tooltipControlProps={{ auto: true, placement: 'top' }}
                >
                  {suggestion}
                </Truncate>
              </Button>
            ))}
          </div>
        )}
      </>
    );
  },
);
ConversationMessageRowMessage.displayName = 'ConversationMessageRowMessage';

interface ConversationMessageRowInstructionProps {
  message: ComposeAssistantConversationMessageInstruction;
}
export const ConversationMessageRowInstruction = memo(
  ({ message }: ConversationMessageRowInstructionProps) => {
    return (
      <div className={styles.ConversationMessageRow}>
        <Text isBold tagName="div">
          {message.title}
        </Text>
        <Text tagName="div" color="subtle">
          {message.subtitle}
        </Text>
      </div>
    );
  },
);
ConversationMessageRowInstruction.displayName =
  'ConversationMessageRowInstruction';

interface ConversationMessageRowMessageSourceRowProps {
  source: ComposeSource;
  onRemoveSource: (source: ComposeSource) => void;
}
export const ConversationMessageRowMessageSourceRow = memo(
  ({ source, onRemoveSource }: ConversationMessageRowMessageSourceRowProps) => {
    const mirageResult =
      source.type === 'dash_search_result'
        ? transformDashResultToMirage(source.searchResult)
        : source.recommendation;
    if (!mirageResult) {
      return null;
    }
    const handleClick = () => {
      const url = mirageResult.url;
      if (url) {
        openURL(url);
      } else {
        logger.error('No URL found for source', source);
      }
    };

    const handleRemoveSource = (e: React.MouseEvent) => {
      e.stopPropagation();
      onRemoveSource(source);
    };

    return (
      <div
        className={styles.ConversationMessageSourceRow}
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyUp={onKeyDownCommitFn(handleClick)}
      >
        <div className={styles.ConversationMessageSourceIcon}>
          <SearchResultIcon result={mirageResult} size={16} />
        </div>
        <Text size="small" className={styles.ConversationMessageSourceTitle}>
          <Truncate lines={1}>{mirageResult.title}</Truncate>
        </Text>
        <DigTooltip
          title={i18n.t('compose_source_row_remove_button_label')}
          placement="top"
        >
          <IconButton
            variant="opacity"
            className={styles.ComposeSourceActionButton}
            size="small"
            shape="standard"
            onClick={handleRemoveSource}
          >
            <UIIcon src={CloseLine} />
          </IconButton>
        </DigTooltip>
      </div>
    );
  },
);
ConversationMessageRowMessageSourceRow.displayName =
  'ConversationMessageRowMessageSourceRow';

interface ConversationMessageRowActionAndTextProps {
  message: ComposeAssistantConversationMessageMessage;
}
export const ConversationMessageRowActionAndText = memo(
  ({ message }: ConversationMessageRowActionAndTextProps) => {
    const body =
      message.role === 'user' ? (
        message.text
      ) : (
        <div className={styles.ConversationMessageRowMarkdown}>
          <Markdown body={message.text} />
        </div>
      );
    return (
      <div className={styles.ConversationMessageRowMessageActionText}>
        {message.role !== 'user' && (
          <div
            className={styles.ConversationMessageRowMessageActionIconContainer}
          >
            <UIIcon
              src={MagicWandLine}
              size="small"
              className={styles.ConversationMessageRowMessageActionIcon}
            />
          </div>
        )}
        <Text color="subtle" isBold size="small">
          {body}
        </Text>
      </div>
    );
  },
);
ConversationMessageRowActionAndText.displayName =
  'ConversationMessageRowActionAndText';
