import {
  getComposeSourcesCache,
  SourcesContentCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeCurrentSessionAction,
  ComposeCurrentSessionState,
} from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionStates';
import { getAssistantResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm';
import { getVoiceRewriteDraftPromptMessageString } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import { AssistantResponse } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import { tagged } from '@mirage/service-logging';
import {
  ComposeAssistantConversationMessage,
  DEFAULT_PRECONFIGURED_VOICE_ID,
  isPreConfiguredVoiceID,
} from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { Dispatch, useCallback } from 'react';

const logger = tagged('ComposeCurrentSessionPostMessage');

export interface PostUserMessageParams {
  text: string;
  rawPromptText?: string;
  mustGenerateDraft?: boolean;
  mustIncludeSourceContents?: boolean;
}

export function usePostMessageHandlers(
  state: ComposeCurrentSessionState,
  dispatch: Dispatch<ComposeCurrentSessionAction>,
  voices: ComposeVoice[] | undefined,
  sourcesContents: SourcesContentCache,
  voiceSourceContents: SourcesContentCache,
) {
  const postUserMessage = useCallback(
    (messageParams: PostUserMessageParams) => {
      postMessage(
        messageParams,
        state.currentSession.messagesHistory,
        state.currentSession.markdownContent,
        sourcesContents,
        state.currentSession.draftConfig.voiceID ||
          DEFAULT_PRECONFIGURED_VOICE_ID,
        voiceSourceContents,
        dispatch,
        state.currentSession.id,
      );
    },
    [
      dispatch,
      sourcesContents,
      state.currentSession.draftConfig.voiceID,
      state.currentSession.id,
      state.currentSession.markdownContent,
      state.currentSession.messagesHistory,
      voiceSourceContents,
    ],
  );

  const postUpdateDraftWithVoice = useCallback(
    async (voiceID) => {
      let rewriteSourceContents = getVoiceRewriteSourceContents(
        voiceID,
        voices || [],
        state.currentSession.draftConfig.voiceID,
        voiceSourceContents,
      );
      if (hasPendingSourceContents(rewriteSourceContents)) {
        logger.log('Waiting for voice source contents to load');
        dispatch({
          type: 'markWaitingForResponse',
        });
        rewriteSourceContents = await waitForPendingSourceContents(
          rewriteSourceContents,
        );
        logger.log('Done waiting for voice source contents to load');
      }
      if (state.currentSession.markdownContent.trim().length > 0) {
        const text = getVoiceRewriteUserMessageString(voiceID, voices || []);
        postUserMessage({
          text,
          rawPromptText: getVoiceRewriteDraftPromptMessageString(
            voiceID,
            rewriteSourceContents,
          ),
          mustGenerateDraft: true,
        });
      }
      dispatch({
        type: 'setDraftConfig',
        config: {
          ...state.currentSession.draftConfig,
          voiceID,
        },
      });
    },
    [
      dispatch,
      postUserMessage,
      state.currentSession.draftConfig,
      state.currentSession.markdownContent,
      voiceSourceContents,
      voices,
    ],
  );

  return { postUserMessage, postUpdateDraftWithVoice };
}

async function postMessage(
  {
    text,
    rawPromptText,
    mustGenerateDraft,
    mustIncludeSourceContents,
  }: PostUserMessageParams,
  messagesHistory: ComposeAssistantConversationMessage[],
  markdownContent: string,
  sourcesContents: SourcesContentCache,
  voiceID: string,
  voiceSourceContents: SourcesContentCache,
  dispatch: Dispatch<ComposeCurrentSessionAction>,
  currentSessionID: string,
) {
  const newMessage: ComposeAssistantConversationMessage = {
    type: 'message',
    role: 'user',
    text,
    ts: Date.now(),
    rawPromptText,
  };
  dispatch({
    type: 'addMessage',
    message: newMessage,
    requiresResponse: true,
  });

  if (hasPendingSourceContents(sourcesContents)) {
    logger.log('Waiting for source contents to load');
    dispatch({
      type: 'markWaitingForResponse',
    });
    sourcesContents = await waitForPendingSourceContents(sourcesContents);
    logger.log('Done waiting for source contents to load');
  }
  if (hasPendingSourceContents(voiceSourceContents)) {
    logger.log('Waiting for voice source contents to load');
    dispatch({
      type: 'markWaitingForResponse',
    });
    // Disable formatting here to avoid conflict between eslint and prettier
    // eslint-disable-next-line prettier/prettier
    voiceSourceContents =
      await waitForPendingSourceContents(voiceSourceContents);
    logger.log('Done waiting for voice source contents to load');
  }

  function handleResponse(response: AssistantResponse) {
    switch (response.type) {
      case 'write_doc':
        {
          dispatch({
            type: 'setMarkdownContent',
            content: response.content,
          });
        }
        break;
      case 'message':
      case 'read_source':
        break;
      default:
        response satisfies never;
        throw new Error(`Unknown response type: ${response}`);
    }
    const message = getMessageForAssistantResponse(response);
    dispatch({
      type: 'addMessage',
      message,
      requiresResponse: false,
    });
  }
  getAssistantResponse(
    {
      messagesHistory,
      newMessage,
      markdownContent,
      sourcesContents,
      voiceSourceContents,
      voiceID,
      mustGenerateDraft: mustGenerateDraft || false,
      mustIncludeSourceContents: mustIncludeSourceContents || false,
    },
    handleResponse,
  )
    .catch((e) => {
      logger.error('Failed to get response from assistant', e);
      dispatch({
        type: 'addMessage',
        message: {
          type: 'message',
          role: 'system',
          text: i18n.t('compose_generic_error'),
          ts: Date.now(),
          action: 'error',
        },
        requiresResponse: false,
      });
    })
    .finally(() => {
      dispatch({
        type: 'markMessageResponded',
        toSessionID: currentSessionID,
      });
    });
}

function getMessageForAssistantResponse(
  response: AssistantResponse,
): ComposeAssistantConversationMessage {
  switch (response.type) {
    case 'write_doc': {
      let rawPromptText = `[Message truncated for size] Draft generated: write_doc called with ${response.content.length} characters.`;
      if (response.responseText) {
        rawPromptText += `\n${response.responseText}`;
      }
      let text = 'Draft generated';
      if (response.responseText) {
        text = `${text}\n${response.responseText}`;
      }
      return {
        type: 'message',
        role: 'assistant',
        text,
        rawPromptText,
        ts: Date.now(),
        action: 'done',
        followUpSuggestions: response.followUpSuggestions,
      };
    }
    case 'message': {
      return {
        type: 'message',
        role: 'assistant',
        text: response.responseText || 'Unable to get response from assistant',
        ts: Date.now(),
        action: response.responseText ? 'done' : 'error',
      };
    }
    case 'read_source': {
      return {
        type: 'message',
        role: 'assistant',
        text: `Reading source document…`,
        ts: Date.now(),
        referencingSources: [response.source.source],
        action: 'reading',
      };
    }
    default:
      response satisfies never;
      throw new Error(`Unknown response type: ${response}`);
  }
}

function hasPendingSourceContents(sourceContents: SourcesContentCache) {
  return Object.values(sourceContents).some(
    (content) => content.state === 'loading',
  );
}

async function waitForPendingSourceContents(
  sourceContents: SourcesContentCache,
) {
  const completedSourceContents = { ...sourceContents };
  const pendingPromises: Promise<void>[] = [];
  for (const [uuid, content] of Object.entries(sourceContents)) {
    if (content.state === 'loading') {
      pendingPromises.push(
        content.loadingPromise.then((completedState) => {
          completedSourceContents[uuid] = completedState;
          return;
        }),
      );
    }
  }
  await Promise.all(pendingPromises);
  return completedSourceContents;
}

function getVoiceRewriteUserMessageString(
  voiceID: string,
  voices: ComposeVoice[],
): string {
  if (isPreConfiguredVoiceID(voiceID)) {
    switch (voiceID) {
      case 'preset_informative':
        return i18n.t('compose_rewrite_message_style_informative');
      case 'preset_persuasive':
        return i18n.t('compose_rewrite_message_style_persuasive');
      case 'preset_instructional':
        return i18n.t('compose_rewrite_message_style_instructional');
      case 'preset_narrative':
        return i18n.t('compose_rewrite_message_style_narrative');
      case 'preset_informal':
        return i18n.t('compose_rewrite_message_style_informal');
      case 'preset_analytical':
        return i18n.t('compose_rewrite_message_style_analytical');
      default:
        voiceID satisfies never;
        throw new Error(`Unsupported voice type: ${voiceID}`);
    }
  }
  const voice = voices.find((voice) => voice.id === voiceID);
  if (!voice) {
    throw new Error(`rewriting for unknown voice id: ${voiceID}`);
  }
  return i18n.t('compose_rewrite_message_style_custom', {
    styleName: voice.name,
  });
}

function getVoiceRewriteSourceContents(
  voiceID: string,
  voices: ComposeVoice[],
  currentVoiceID: string | undefined,
  currentSourceContents: SourcesContentCache,
): SourcesContentCache {
  if (isPreConfiguredVoiceID(voiceID)) {
    return {};
  }
  if (voiceID === currentVoiceID) {
    return currentSourceContents;
  }
  const voice = voices.find((voice) => voice.id === voiceID);
  if (!voice) {
    throw new Error(`rewriting for unknown voice id: ${voiceID}`);
  }
  // user just switched to a different voice, so we need to fetch new source contents
  return getComposeSourcesCache(voice.sources);
}
