import { dash } from '@dropbox/api-v2-client';
import { Metadata } from '@dropbox/dash-component-library';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Open_DashLink } from '@mirage/analytics/events/types/open_dash_link';
import { ContentRow } from '@mirage/dash-component-library/components/ContentRow';
import { openURL } from '@mirage/service-platform-actions';
import { toActivityResult } from '@mirage/shared/feed/activity-result';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { getTimeAgoString } from '@mirage/shared/util/time';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { ActivityActionBar } from '../ActivityActionBar';
import { type ActivityObjectProps } from '../ActivityObject';
import { PersonMetadata } from '../PersonMetadata';

type DocumentActivityObjectProps = {
  document: dash.SearchResult;
} & ActivityObjectProps;

export const DocumentActivityObject = ({
  activity,
  index,
  enableSummary,
  document,
}: DocumentActivityObjectProps) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const onClickRow = useCallback(() => {
    reportPapEvent(
      PAP_Open_DashLink({
        actionSurfaceComponent: 'activity_feed',
        featureLine: 'activity_feed',
      }),
    );
    openURL(document.url);
  }, [document.url, reportPapEvent]);

  return (
    <ContentRow
      paddingSize="medium"
      onClick={onClickRow}
      visibleUrl={document.url}
      withStartAccessory={
        <DocumentActivityObjectIcon activity={activity} document={document} />
      }
      withLabel={activity.object?.name}
      withMeta={
        <DocumentActivityObjectMetadata
          activity={activity}
          document={document}
        />
      }
      withActions={
        <ActivityActionBar
          activity={activity}
          index={index}
          enableSummary={enableSummary}
        />
      }
    />
  );
};

const DocumentActivityObjectMetadata = ({
  document,
}: DocumentActivityObjectProps) => {
  const creator = document.creator
    ? {
        displayName: document.creator.display_name,
        email: document.creator.email,
        profilePhotoUrl: document.creator.profile_image_url,
      }
    : undefined;
  return (
    <Metadata size="medium">
      <Metadata.Item>
        <Metadata.Label>
          {i18n.t('updated_ago', {
            timeAgo: getTimeAgoString(document.provider_updated_at_ms!),
          })}
        </Metadata.Label>
      </Metadata.Item>
      {creator && (
        <Metadata.Item>
          <Metadata.Label>
            <PersonMetadata i18nKey="result_created_by" person={creator} />
          </Metadata.Label>
        </Metadata.Item>
      )}
    </Metadata>
  );
};

const DocumentActivityObjectIcon = ({
  activity,
}: DocumentActivityObjectProps) => {
  const result = toActivityResult(activity);
  if (!result) {
    return null;
  }

  return <FileContentIcon size="large" content={result} />;
};
