import { Badge } from '@dropbox/dig-components/badge';
import { Button } from '@dropbox/dig-components/buttons';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text, Title } from '@dropbox/dig-components/typography';
import { Split, Stack } from '@dropbox/dig-foundations';
import { TrafficRoadBlockedSpot } from '@dropbox/dig-illustrations';
import {
  DashGlyph,
  DashWordmark,
  GlyphLogo,
  WordmarkLogo,
} from '@dropbox/dig-logos';
import { useQueryParams } from '@mirage/search/hooks/useQueryParams';
import { completeJanusOauthConnection } from '@mirage/settings/hooks/connectors/useConnectConnector';
import i18n from '@mirage/translations';
import { Suspense, useEffect, useState } from 'react';
import styles from './AuthConfirmPage.module.css';

export function AuthConfirmPage() {
  const params = useQueryParams();
  const request_id = params.get('request_id');
  const [response, setResponse] = useState<React.ReactNode>();

  const completeConnection = async () => {
    try {
      if (request_id) {
        const { connection_id, connector_id } =
          await completeJanusOauthConnection(request_id);
        const bc = new BroadcastChannel('janus_auth_channel');
        bc.postMessage({
          connection_id: connection_id,
          connector_id: connector_id,
        });
        setResponse(
          <AuthConfirmationComponent
            illustration={<GlyphLogo size={80} src={DashGlyph} />}
            title={i18n.t('connector_connected_title')}
            description={i18n.t('connector_connected')}
          />,
        );
        // Automatically close window after 2 seconds if connection was successful:
        // dash connectors page will automatically check for the updated connection
        setTimeout(() => {
          window.close();
        }, 2000);
      }
    } catch (e) {
      setResponse(
        <AuthConfirmationComponent
          illustration={<TrafficRoadBlockedSpot />}
          title={i18n.t('failed_to_connect_generic_title')}
          description={i18n.t('failed_to_connect_generic')}
        />,
      );
      throw new Error('Unable to complete connection');
    }
  };
  useEffect(() => {
    completeConnection();
  }, []);

  return (
    <div>
      <Stack padding="60">
        <Split alignX="center">
          <Split.Item>
            {response ? (
              response
            ) : (
              <Spinner
                aria-valuetext={i18n.t('connector_loading')}
                data-testid="loading-indicator"
              />
            )}
          </Split.Item>
        </Split>
      </Stack>
    </div>
  );
}

interface AuthConfirmationComponentProps {
  illustration: React.ReactNode;
  title: string;
  description: string;
}

const AuthConfirmationComponent = ({
  illustration,
  title,
  description,
}: AuthConfirmationComponentProps) => {
  return (
    <Suspense fallback={<></>}>
      <Stack display="flex" align="center" gap={'Macro XLarge'}>
        <Split alignY="center">
          <Split.Item>
            <WordmarkLogo
              src={DashWordmark}
              size={40}
              color="var(--dig-color__text__base)"
            />
          </Split.Item>
          <Split.Item>
            <Badge>Beta</Badge>
          </Split.Item>
        </Split>
        <div className={styles.illustrationContainer}>{illustration}</div>
        <Stack
          gap={'Micro Large'}
          display="flex"
          justify="center"
          align="center"
        >
          <Title size="medium" weightVariant="emphasized">
            {title}
          </Title>
          <Text size="xlarge" color="faint">
            {description}
          </Text>
        </Stack>
        <Button
          variant="filled"
          fullWidth
          size="xlarge"
          onClick={() => window.close()}
        >
          {i18n.t('connector_close_window')}
        </Button>
      </Stack>
    </Suspense>
  );
};
