import type { ConnectorName } from '../connectors';

export type TitleToken =
  | { type: 'string'; value: string }
  | { type: 'parameter'; value: string; matched: boolean };

export type CachedURLShortcut = {
  uuid: string;
  hotwords: string[];
  urlTemplate: string;
  titleTemplate: string;
  dropboxersOnly: boolean;
  icon?: {
    lightUrl: string;
    darkUrl: string;
  };
  // Only show the shortcut if the user has one of these connectors
  supportedConnectors?: ConnectorName[];
};

export type URLShortcut = CachedURLShortcut & {
  parameters: {
    url: string;
    count: number;
    chunks: number;
    matches: string[];
    highlightWords: string[];
    complete: boolean;
    matched: boolean;
    title: TitleToken[];
    activeHotword: string;
  };
};

export const CREATE_STACK_ACTION_UUID = '59bb3fac-59f3-445e-b0d9-2374d2860d07';
export const CREATE_WORD_ACTION_UUID = 'a09dc613-0b98-46c2-923a-a4a5ca110da9';
export const CREATE_EXCEL_ACTION_UUID = '198a4fea-5683-44dd-9840-96e29c967dea';
export const CREATE_POWERPOINT_ACTION_UUID =
  '719625b9-f5ae-42d3-ac03-ab2f382184df';
