import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { ClickableLinkPlugin } from '@lexical/react/LexicalClickableLinkPlugin';
import {
  InitialConfigType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { ComposeContentUpdaterPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/ComposeContentUpdaterPlugin';
import { ExportButton } from '@mirage/mosaics/ComposeAssistant/components/editor/ExportButton';
import {
  SelectionAction,
  SelectionToolbarPlugin,
} from '@mirage/mosaics/ComposeAssistant/components/editor/SelectionToolbar';
import { StaticToolbarPlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/StaticToolbarPlugin';
import { VoiceSelector } from '@mirage/mosaics/ComposeAssistant/components/editor/VoiceSelector';
import { WaitingForResponsePlugin } from '@mirage/mosaics/ComposeAssistant/components/editor/WaitingForResponsePlugin';
import { tagged } from '@mirage/service-logging';
import { DEFAULT_PRECONFIGURED_VOICE_ID } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import i18n from '@mirage/translations';
import { memo } from 'react';
import styles from './ComposeEditor.module.css';

const logger = tagged('ComposeEditor');

const STATIC_TOOLBAR_HEIGHT = 58;
const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith('http') ? fullMatch : `https://${fullMatch}`,
    };
  },
];

interface ComposeEditorProps {
  isWaitingForResponse: boolean;
  markdownContent: string;
  onChangeContent: (markdownContent: string) => void;
  onTriggerSelectionAction: (action: SelectionAction) => void;
  customVoices: ComposeVoice[] | undefined;
  currentVoiceID: string | undefined;
  onChangeVoiceID: (voiceID: string) => void;
  onCreateNewVoice: () => void;
  onOpenVoiceSettings: (voiceID: string | undefined) => void;
}
export const ComposeEditor = memo(
  ({
    isWaitingForResponse,
    markdownContent,
    onChangeContent,
    onTriggerSelectionAction,
    customVoices,
    currentVoiceID,
    onChangeVoiceID,
    onCreateNewVoice,
    onOpenVoiceSettings,
  }: ComposeEditorProps) => {
    return (
      <LexicalComposer initialConfig={ComposeInitialConfig}>
        <StaticToolbarPlugin
          additionalButtons={
            <>
              <VoiceSelector
                currentVoiceID={
                  currentVoiceID || DEFAULT_PRECONFIGURED_VOICE_ID
                }
                customVoices={customVoices}
                onChangeVoiceID={onChangeVoiceID}
                onCreateNewVoice={onCreateNewVoice}
                onOpenVoiceSettings={onOpenVoiceSettings}
              />
              <div className={styles.spacer} />
              <ExportButton />
            </>
          }
        />
        <RichTextPlugin
          contentEditable={
            <div className={styles.contentEditableScroller}>
              <ContentEditable className={styles.contentEditable} />
            </div>
          }
          placeholder={
            <div className={styles.placeholderWrapper}>
              <div className={styles.placeholder}>
                <h2>{i18n.t('compose_editor_placeholder_title')}</h2>
                <p>{i18n.t('compose_editor_placeholder_body')}</p>
              </div>
            </div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <HistoryPlugin />
        <ListPlugin />
        <LinkPlugin />
        <AutoLinkPlugin matchers={MATCHERS} />
        <ClickableLinkPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <ComposeContentUpdaterPlugin
          markdownContent={markdownContent}
          onChangeContent={onChangeContent}
        />
        <SelectionToolbarPlugin
          offsetTop={STATIC_TOOLBAR_HEIGHT}
          onTriggerSelectionAction={onTriggerSelectionAction}
        />
        <WaitingForResponsePlugin isWaitingForResponse={isWaitingForResponse} />
      </LexicalComposer>
    );
  },
);
ComposeEditor.displayName = 'ComposeEditor';

const ComposeInitialConfig: InitialConfigType = {
  namespace: 'ComposeNewPost',
  onError: logger.error,
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
  ],
  theme: {
    text: {
      bold: styles.editorTextBold,
      italic: styles.editorTextItalic,
      strikethrough: styles.editorTextStrikethrough,
      underline: styles.editorTextUnderline,
      underlineStrikethrough: styles.editorTextUnderlineStrikethrough,
    },
  },
};
