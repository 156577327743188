import { Button } from '@dropbox/dig-components/buttons';
import { TextArea, TextAreaProps } from '@dropbox/dig-components/text_fields';
import { Text, Title } from '@dropbox/dig-components/typography';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Cancel_StacksDescription } from '@mirage/analytics/events/types/cancel_stacks_description';
import { PAP_Cancel_StacksName } from '@mirage/analytics/events/types/cancel_stacks_name';
import { PAP_Click_StacksDescription } from '@mirage/analytics/events/types/click_stacks_description';
import { PAP_Click_StacksName } from '@mirage/analytics/events/types/click_stacks_name';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { dispatchElementClicked } from '@mirage/analytics/uxa/dispatchElementClicked';
import { useStackPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { showSnackbar } from '@mirage/shared/snackbar';
import {
  MAX_STACK_DESCRIPTION_LENGTH,
  MAX_STACK_NAME_LENGTH,
} from '@mirage/shared/util/constants';
import { DashTruncateWithTooltip } from '@mirage/shared/util/DashTruncateWithTooltip';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { atom, useAtom, useAtomValue } from 'jotai';
import React, {
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { activeStackHasWritePermissionsAtom } from '../ActiveStack/atoms';
import { useStackDetailsTour } from '../StackDetailsTour/useStackDetailsTour';
import styles from './EditStackDetails.module.css';
import { StackDetailsTourTooltip } from './StackDetailsTourTooltip';
import { StackDerivedPAPPropsWithFeature } from './types';
import { sanitizedStackName } from './utils';

// Data attribute to prevent saving the field when the user clicks on a related editor
const FIELD_EDITOR_RELATED_BLUR = 'data-field-editor-related-blur' as const;

interface EditStackDetailsProps {
  triggerEditStackRef: Ref<HTMLInputElement> | undefined;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  stackName: string;
  stackDescription: string;
  isNewStack: boolean;
  withStackInfo?: ReactNode;
  updateStackNameAndDescription: (name: string, description: string) => void;
  hasWritePermissionsOverride?: boolean;
}

export const EditStackDetails: React.FC<EditStackDetailsProps> = ({
  triggerEditStackRef,
  stackDerivedPAPPropsWithFeature,
  stackName,
  stackDescription,
  isNewStack,
  withStackInfo,
  updateStackNameAndDescription,
  hasWritePermissionsOverride,
}) => {
  const stackNameRef = useRef<FieldEditorRef>(null);
  const stackDescriptionRef = useRef<FieldEditorRef>(null);

  /**
   * Uses useImperativeHandle to enable the edit state within the name and
   * description editor components
   */
  const handleEditButtonClick = () => {
    stackNameRef.current?.enableEditing(false);
    stackDescriptionRef.current?.enableEditing(false);
  };

  return (
    <div>
      <StackNameEditor
        stackName={stackName}
        isNewStack={isNewStack}
        fieldEditorRef={stackNameRef}
        updateStackNameAndDescription={updateStackNameAndDescription}
        stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
      />
      {withStackInfo}
      <StackDescriptionEditor
        stackDescription={stackDescription}
        isNewStack={isNewStack}
        fieldEditorRef={stackDescriptionRef}
        updateStackNameAndDescription={updateStackNameAndDescription}
        stackDerivedPAPPropsWithFeature={stackDerivedPAPPropsWithFeature}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
      />
      <input
        type="hidden"
        ref={triggerEditStackRef}
        onClick={handleEditButtonClick}
      />
    </div>
  );
};

const newStackNameAtom = atom<string>('');
const newStackDescriptionAtom = atom<string>('');

export interface FieldEditorRef {
  enableEditing: (focus: boolean) => void;
}

interface FieldEditorProps {
  fieldName: string;
  submitFieldChange: () => boolean;
  placeholder: string;
  maxLength: number;
  mode: 'name' | 'description';
  fieldEditorRef?: RefObject<FieldEditorRef>;
  fieldInputRef?: RefObject<HTMLTextAreaElement>;
  additionalStyles?: string;
  onFocused?: () => void;
  onCancel?: () => void;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  dataTestId?: string;
  resizable?: TextAreaProps['resizable'];
  rows?: number;
  focused?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const FieldEditor: React.FC<FieldEditorProps> = ({
  fieldName: fieldNameProp,
  submitFieldChange,
  placeholder,
  maxLength,
  mode,
  fieldEditorRef,
  fieldInputRef,
  additionalStyles,
  onFocused,
  onCancel,
  enableEditMode = false,
  hasWritePermissionsOverride,
  dataTestId,
  resizable,
  rows,
  onEditStatusUpdate,
  focused = false,
}) => {
  const [isEditingField, setIsEditingField] = useState(enableEditMode);
  const [focus, setFocus] = useState(focused);
  const [newStackName, setNewStackName] = useAtom(newStackNameAtom);
  const [newStackDescription, setNewStackDescription] = useAtom(
    newStackDescriptionAtom,
  );
  const fieldName = mode === 'name' ? newStackName : newStackDescription;
  const setFieldName =
    mode === 'name' ? setNewStackName : setNewStackDescription;

  // We consider empty fieldName as changed to support setting a title,
  // clicking into the description, and then out of the description.
  // `updateStackNameAndDescription` will ensure that the stack does not
  // get created when both fields are empty.
  const hasFieldChanged =
    fieldName.trim() !== fieldNameProp.trim() || fieldName === '';
  const hasWritePermissionsForStack = useAtomValue(
    activeStackHasWritePermissionsAtom,
  );
  const hasWritePermissions =
    hasWritePermissionsOverride ?? hasWritePermissionsForStack;

  const handleStartEditing = useCallback(
    (focus: boolean) => {
      if (!hasWritePermissions) {
        return;
      }
      setFocus(focus);
      setIsEditingField(true);
    },
    [hasWritePermissions],
  );

  useEffect(() => {
    setFieldName(fieldNameProp);
  }, [fieldNameProp, setFieldName]);

  useEffect(() => {
    if (isEditingField && focus && fieldInputRef?.current) {
      fieldInputRef.current.focus();
    }
  }, [isEditingField, focus, fieldInputRef]);

  useEffect(() => {
    onEditStatusUpdate?.(isEditingField);
  }, [isEditingField, onEditStatusUpdate]);

  const resetInitialValues = useCallback(() => {
    setIsEditingField(enableEditMode);
  }, [enableEditMode]);

  const handleCancelEditing = useCallback(() => {
    onCancel && onCancel();
    resetInitialValues();
  }, [resetInitialValues, onCancel]);

  const handleBlur = useCallback(
    (relatedTarget: HTMLElement | null) => {
      if (relatedTarget?.getAttribute(FIELD_EDITOR_RELATED_BLUR) === 'true') {
        // If the user clicked on another related editor, don't save the field
        return;
      }

      if (hasFieldChanged) {
        const success = submitFieldChange();
        if (success || fieldName === '') {
          handleCancelEditing();
        } else {
          setFieldName(fieldNameProp);
          fieldInputRef?.current?.focus();
        }
      } else {
        handleCancelEditing();
      }
    },
    [
      hasFieldChanged,
      submitFieldChange,
      fieldName,
      handleCancelEditing,
      setFieldName,
      fieldNameProp,
      fieldInputRef,
    ],
  );

  useImperativeHandle(fieldEditorRef, () => ({
    enableEditing(focus: boolean) {
      handleStartEditing(focus);
    },
  }));

  useEffect(() => {
    resetInitialValues();
  }, [resetInitialValues]);

  if (!isEditingField) {
    if (mode === 'name') {
      return (
        <ReadOnlyModeName
          stackName={fieldName}
          onClick={() => handleStartEditing(true)}
        />
      );
    }
    return (
      <ReadOnlyModeDescription
        stackDescription={fieldName}
        onClick={() => handleStartEditing(true)}
      />
    );
  }

  return (
    <EditingMode
      {...{ [FIELD_EDITOR_RELATED_BLUR]: 'true' }}
      field={fieldName}
      setField={setFieldName}
      stackFieldInputRef={fieldInputRef}
      onFocusEvent={onFocused || (() => {})}
      handleStackFieldBlur={handleBlur}
      placeholder={placeholder}
      maxLength={maxLength}
      data-testid={dataTestId}
      classnames={additionalStyles}
      resizable={resizable}
      rows={rows}
    />
  );
};

const useUpdateStackNameAndDescription = (
  isNewStack: boolean,
  updateStackNameAndDescription: (name: string, description: string) => void,
): (() => boolean) => {
  const { getCurrentStep, nextStep } = useStackDetailsTour();
  const [newStackName, setNewStackName] = useAtom(newStackNameAtom);
  const [newStackDescription, setNewStackDescription] = useAtom(
    newStackDescriptionAtom,
  );

  return useCallback(() => {
    const trimmedStackName = newStackName.trim();
    const finalDescription = newStackDescription.trim().replace(/\n+/gi, '\n');

    // Empty stack, no-op
    if (trimmedStackName === '' && finalDescription === '' && isNewStack) {
      return false;
    }

    const sanitizedName = sanitizedStackName(trimmedStackName);
    if (sanitizedName !== trimmedStackName) {
      showSnackbar({
        title: i18n.t('illegal_characters_warning'),
      });
      return false;
    }
    const finalStackName =
      sanitizedName.length === 0
        ? i18n.t('untitled_stack')
        : sanitizedName.replace(/\n+/gi, '\n');

    if (finalStackName.length > MAX_STACK_NAME_LENGTH) {
      showSnackbar({
        title: i18n.t('input_too_long'),
      });
      return false;
    }
    setNewStackName(finalStackName);

    if (finalDescription.length > MAX_STACK_DESCRIPTION_LENGTH) {
      showSnackbar({
        title: i18n.t('input_too_long'),
      });
      return false;
    }
    setNewStackDescription(finalDescription);

    updateStackNameAndDescription(finalStackName, finalDescription);
    if (getCurrentStep()?.key === 'name-stack') nextStep();
    return true;
  }, [
    newStackName,
    newStackDescription,
    isNewStack,
    setNewStackName,
    setNewStackDescription,
    updateStackNameAndDescription,
    getCurrentStep,
    nextStep,
  ]);
};

interface StackNameEditorProps {
  stackName: string;
  isNewStack: boolean;
  updateStackNameAndDescription: (name: string, description: string) => void;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  fieldEditorRef?: RefObject<FieldEditorRef>;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const StackNameEditor: React.FC<StackNameEditorProps> = ({
  stackName,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  fieldEditorRef,
  enableEditMode,
  hasWritePermissionsOverride,
  onEditStatusUpdate,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRev = useStackPageAugustRevisionEnabled();
  const fieldInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = useUpdateStackNameAndDescription(
    isNewStack,
    updateStackNameAndDescription,
  );

  return (
    <>
      <FieldEditor
        fieldName={stackName}
        fieldEditorRef={fieldEditorRef}
        fieldInputRef={fieldInputRef}
        mode={'name'}
        rows={isAugustRev ? 1 : undefined}
        focused={isAugustRev && (enableEditMode || isNewStack)}
        enableEditMode={enableEditMode || isNewStack}
        hasWritePermissionsOverride={hasWritePermissionsOverride}
        submitFieldChange={handleSubmit}
        placeholder={i18n.t('stack_title_placeholder')}
        resizable="auto"
        maxLength={MAX_STACK_NAME_LENGTH}
        onEditStatusUpdate={onEditStatusUpdate}
        onFocused={() => {
          reportPapEvent(PAP_Click_StacksName(stackDerivedPAPPropsWithFeature));
          dispatchElementClicked(
            createUxaElementId('stack_title', {
              featureLine: 'stacks',
            }),
          );
        }}
        onCancel={() => {
          reportPapEvent(
            PAP_Cancel_StacksName({
              featureLine: 'stacks',
              ...stackDerivedPAPPropsWithFeature,
            }),
          );
        }}
        additionalStyles={
          isAugustRev ? styles.stackNameInputAugRev : styles.stackNameInput
        }
        dataTestId={'textFill'}
      />
      {isAugustRev && (enableEditMode || isNewStack) && (
        <StackDetailsTourTooltip
          stepKey="name-stack"
          triggerRef={fieldInputRef}
        />
      )}
    </>
  );
};

interface StackDescriptionEditorProps {
  stackDescription: string;
  isNewStack: boolean;
  updateStackNameAndDescription: (name: string, description: string) => void;
  stackDerivedPAPPropsWithFeature: StackDerivedPAPPropsWithFeature;
  fieldEditorRef?: RefObject<FieldEditorRef>;
  enableEditMode?: boolean;
  hasWritePermissionsOverride?: boolean;
  onEditStatusUpdate?: (status: boolean) => void;
}

export const StackDescriptionEditor: React.FC<StackDescriptionEditorProps> = ({
  stackDescription,
  isNewStack,
  updateStackNameAndDescription,
  stackDerivedPAPPropsWithFeature,
  fieldEditorRef,
  enableEditMode,
  hasWritePermissionsOverride,
  onEditStatusUpdate,
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const isAugustRev = useStackPageAugustRevisionEnabled();
  const fieldInputRef = useRef<HTMLTextAreaElement>(null);

  const handleSubmit = useUpdateStackNameAndDescription(
    isNewStack,
    updateStackNameAndDescription,
  );

  return (
    <FieldEditor
      fieldName={stackDescription}
      fieldEditorRef={fieldEditorRef}
      fieldInputRef={fieldInputRef}
      enableEditMode={enableEditMode || isNewStack}
      mode="description"
      rows={isAugustRev ? 1 : undefined}
      hasWritePermissionsOverride={hasWritePermissionsOverride}
      submitFieldChange={handleSubmit}
      placeholder={i18n.t('stack_description_placeholder')}
      maxLength={MAX_STACK_DESCRIPTION_LENGTH}
      onEditStatusUpdate={onEditStatusUpdate}
      onFocused={() => {
        reportPapEvent(
          PAP_Click_StacksDescription(stackDerivedPAPPropsWithFeature),
        );
        dispatchElementClicked(
          createUxaElementId('stack_description', {
            featureLine: 'stacks',
          }),
        );
      }}
      onCancel={() => {
        reportPapEvent(
          PAP_Cancel_StacksDescription(stackDerivedPAPPropsWithFeature),
        );
      }}
      additionalStyles={
        isAugustRev ? styles.stackDescriptionInputAugRev : undefined
      }
      resizable={isAugustRev ? 'auto' : 'vertical'}
    />
  );
};

interface ExpandProps {
  isExpanded: boolean;
  setIsExpanded: (expanded: (prev: unknown) => boolean) => void;
}

const Expand: React.FC<ExpandProps> = ({ isExpanded, setIsExpanded }) => {
  return (
    <div>
      <Button
        size="small"
        variant="transparent"
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        {isExpanded ? i18n.t('see_less') : i18n.t('see_more')}
      </Button>
    </div>
  );
};

interface ReadOnlyModeNameProps {
  onClick: () => void;
  stackName: string;
}

const ReadOnlyModeName: React.FC<ReadOnlyModeNameProps> = ({
  onClick,
  stackName,
}) => {
  const [isNameExpanded, setIsNameExpanded] = useState(false);
  const [isNameExpandable, setIsNameExpandable] = useState(false);
  const isAugRev = useStackPageAugustRevisionEnabled();

  if (isAugRev) {
    return (
      <Title
        className={classNames(styles.stackNameAugRev, styles.alignSeeMore)}
        onClick={onClick}
        size="large"
        weightVariant="emphasized"
      >
        {stackName}
      </Title>
    );
  }

  return (
    <>
      <Title
        className={classNames(styles.stackName, styles.alignSeeMore)}
        onClick={onClick}
        size="large"
        weightVariant="emphasized"
      >
        {isNameExpanded ? (
          stackName
        ) : (
          <DashTruncateWithTooltip
            lines={3}
            onBeforeTruncate={setIsNameExpandable}
          >
            {stackName}
          </DashTruncateWithTooltip>
        )}
      </Title>
      {(isNameExpanded || isNameExpandable) && (
        <Expand isExpanded={isNameExpanded} setIsExpanded={setIsNameExpanded} />
      )}
    </>
  );
};

interface ReadOnlyModeDescriptionProps {
  onClick: () => void;
  stackDescription: string;
}

const ReadOnlyModeDescription: React.FC<ReadOnlyModeDescriptionProps> = ({
  onClick,
  stackDescription,
}) => {
  const isDescriptionEmpty = stackDescription.trim() === '';
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isDescriptionExpandable, setIsDescriptionExpandable] = useState(false);
  const isAugRev = useStackPageAugustRevisionEnabled();

  return (
    <>
      {isDescriptionEmpty ? (
        <div>
          <Text
            className={isAugRev ? styles.stackDescriptionAugRev : undefined}
            color="faint"
            onClick={onClick}
            aria-label="edit-description"
          >
            {i18n.t('stack_description_placeholder')}
          </Text>
        </div>
      ) : (
        <div>
          <Text
            className={
              isAugRev ? styles.stackDescriptionAugRev : styles.stackDescription
            }
            onClick={onClick}
          >
            {isDescriptionExpanded ? (
              stackDescription
            ) : (
              <DashTruncateWithTooltip
                lines={4}
                onBeforeTruncate={setIsDescriptionExpandable}
              >
                {stackDescription}
              </DashTruncateWithTooltip>
            )}
          </Text>
          {(isDescriptionExpanded || isDescriptionExpandable) && (
            <Expand
              isExpanded={isDescriptionExpanded}
              setIsExpanded={setIsDescriptionExpanded}
            />
          )}
        </div>
      )}
    </>
  );
};

interface EditingModeProps extends TextAreaProps {
  field: string;
  setField: (name: string) => void;
  onFocusEvent: () => void;
  stackFieldInputRef?: RefObject<HTMLTextAreaElement>;
  handleStackFieldBlur: (relatedTarget: HTMLElement | null) => void;
  classnames?: string;
}

const EditingMode: React.FC<EditingModeProps> = ({
  field,
  setField,
  onFocusEvent,
  stackFieldInputRef,
  handleStackFieldBlur,
  classnames,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  ...rest
}) => {
  const hasSentFieldIsFocused = useRef(false);
  const isAugRev = useStackPageAugustRevisionEnabled();
  const handleFieldIsFocused = useCallback(
    (e: React.FocusEvent<HTMLTextAreaElement>) => {
      if (isAugRev) {
        // Move cursor to the end of the input field
        const value = e.currentTarget.value;
        e.target.setSelectionRange(value.length, value.length);
      }

      if (hasSentFieldIsFocused.current) {
        return;
      }
      hasSentFieldIsFocused.current = true;
      onFocusEvent();
    },
    [onFocusEvent, isAugRev],
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement> | null) => {
      const relatedTarget = event?.relatedTarget as HTMLElement | null;

      handleStackFieldBlur(relatedTarget);
    },
    [handleStackFieldBlur],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && event.shiftKey === false) {
        event.preventDefault(); // Prevent the default action to avoid a newline being added
        handleBlur(null);
      }
    },
    [handleBlur],
  );

  return (
    <>
      <TextArea
        {...rest}
        ref={stackFieldInputRef}
        value={field}
        onBlur={(e) => {
          handleBlur(e);
          onBlur?.(e);
        }}
        onChange={(e) => {
          setField(e.currentTarget.value);
          onChange?.(e);
        }}
        onFocus={(e) => {
          handleFieldIsFocused(e);
          onFocus?.(e);
        }}
        onKeyDown={(e) => {
          handleKeyDown(e);
          onKeyDown?.(e);
        }}
        className={classNames(styles.inlineTextArea, classnames)}
      />
    </>
  );
};
