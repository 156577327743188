/*
 * Note: This is non-end-user facing code so no need for i18n strings.
 */

import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import styles from './InternalTests.module.css';

export const InternalTests: React.FC = () => {
  return (
    <div>
      <Box display="block" paddingBottom="16">
        <Text size="medium" isBold>
          Local Validations
        </Text>
        <hr className={styles.horizontalSeparator} />
      </Box>

      <div className={styles.grid}>
        <div>
          <Text isBold>Test</Text>
        </div>
        <div>
          <Text isBold>Description</Text>
        </div>
        <Text>
          <a href="http://127.0.0.1">Try internal route</a>
        </Text>
        <Text>
          This link attempts to route internally which should always be blocked.
          Clicking the link should show a dialog in dev, log an error, and open
          the route externally.
        </Text>
        <Button
          variant="opacity"
          onClick={() => {
            throw new Error('Intentional ERROR created from Dash');
          }}
        >
          Create Error in Sentry
        </Button>
        <div>
          <Text>Sends an ERROR to Sentry labeled</Text>
          <Text isBold> Intentional ERROR created from Dash </Text>
          <Text>for validation</Text>
        </div>
      </div>
    </div>
  );
};
