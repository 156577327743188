import type { MemoryCache } from '@mirage/service-typeahead-search/service/typeahead-cache/subcaches';

export type TypeaheadControlCacheShape = {
  lastRecommendationsSyncMs?: number | undefined;
};

export default class TypeaheadControlCache
  implements MemoryCache<TypeaheadControlCacheShape>
{
  // this is awkward, but without redoing how the whole storage system works
  // to make it easier, I'm just going with the flow here and storing this as
  // an array of 1 object
  private cache: TypeaheadControlCacheShape[] = [{}];

  all() {
    return this.cache;
  }

  clear() {
    this.cache = [{}];
  }

  count() {
    return this.cache.length;
  }

  addItems(
    items: TypeaheadControlCacheShape[],
  ): Promise<TypeaheadControlCacheShape[]> {
    if (items.length == 1) {
      this.clear();
      this.cache = items;
    }
    return Promise.resolve(this.cache);
  }

  removeItem(
    _item: TypeaheadControlCacheShape,
  ): Promise<TypeaheadControlCacheShape[]> {
    throw new Error('Method not implemented.');
  }
}
