import { Text, Title } from '@dropbox/dig-components/typography';
import { CommentFill } from '@dropbox/dig-icons/assets';
import { Link } from '@mirage/link/Link';
import { AI_LEARN_MORE_URL } from '@mirage/shared/urls';
import i18n from '@mirage/translations';
import styles from './AnswersEmptyState.module.css';

type AnswersEmptyStateProps = {
  title: string;
  subtitle: string;
};

export const AnswersEmptyState = ({
  title,
  subtitle,
}: AnswersEmptyStateProps) => {
  return (
    <div className={styles.scrollContainer}>
      <div className={styles.container}>
        <CommentFill className={styles.spotIcon} />
        <div className={styles.titleContainer}>
          <Title className={styles.title}>{title}</Title>
        </div>
        <Text color="faint" className={styles.subtitle}>
          {subtitle}
        </Text>
        <Text className={styles.disclaimerText} color="faint" size="small">
          {i18n.t('answers_disclaimer_text')}
          &nbsp;
          <Link variant="monochromatic" href={AI_LEARN_MORE_URL}>
            {i18n.t('learn_more')}
          </Link>
        </Text>
      </div>
    </div>
  );
};
