import {
  loadComposeSessions,
  saveComposeSessions,
} from '@mirage/service-compose';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

const logger = tagged('ComposeSessionsContext');

export interface ComposeSessionsContextInterface {
  sessions: ComposeSession[] | undefined;
  saveSession: (session: Omit<ComposeSession, 'lastUpdated'>) => void;
  deleteSession: (sessionID: string) => void;
}
export const ComposeSessionsContext =
  createContext<ComposeSessionsContextInterface | null>(null);
export const useComposeSessionsContext = () => {
  const context = useContext(ComposeSessionsContext);
  if (!context) {
    throw new Error(
      'useComposeSessionsContext must be used within a ComposeSessionsContextProvider',
    );
  }
  return context;
};

interface ComposeSessionsContextProviderProps {
  children: React.ReactNode;
}
export const ComposeSessionsContextProvider = ({
  children,
}: ComposeSessionsContextProviderProps) => {
  const [sessions, setSessions] = useState<ComposeSession[] | undefined>(
    undefined,
  );
  useEffect(() => {
    loadComposeSessions().then(setSessions);
  }, []);

  const saveSession = useCallback(
    (session: Omit<ComposeSession, 'lastUpdated'>) => {
      setSessions((prevSessions) => {
        if (prevSessions === undefined) {
          logger.warn('attempted to save session before loading completes');
          return prevSessions;
        }

        const updated: ComposeSession[] = [];
        let found = false;
        for (const s of prevSessions) {
          if (s.id === session.id) {
            found = true;
            if (!areSessionsDataEqual(s, session)) {
              updated.push({ ...session, lastUpdated: Date.now() });
            } else {
              updated.push(s);
            }
          } else {
            updated.push(s);
          }
        }
        if (!found) {
          updated.push({ ...session, lastUpdated: Date.now() });
        }
        throttledSaveComposeSessions(updated);
        return updated;
      });
    },
    [],
  );
  const deleteSession = useCallback((sessionID: string) => {
    setSessions((prevSessions) => {
      if (prevSessions === undefined) {
        logger.warn('attempted to remove session before loading completes');
        return prevSessions;
      }
      const updated = prevSessions.filter((s) => s.id !== sessionID);
      throttledSaveComposeSessions(updated);
      return updated;
    });
  }, []);

  return (
    <ComposeSessionsContext.Provider
      value={{ sessions, saveSession, deleteSession }}
    >
      {children}
    </ComposeSessionsContext.Provider>
  );
};

function areSessionsDataEqual(
  a: Omit<ComposeSession, 'lastUpdated'>,
  b: Omit<ComposeSession, 'lastUpdated'>,
): boolean {
  return (
    a.id === b.id &&
    isEqual(a.messagesHistory, b.messagesHistory) &&
    isEqual(a.sources, b.sources) &&
    a.markdownContent === b.markdownContent &&
    isEqual(a.draftConfig, b.draftConfig)
  );
}

const SAVE_THROTTLE_MS = 100;
const throttledSaveComposeSessions = throttle(
  saveComposeSessions,
  SAVE_THROTTLE_MS,
  { trailing: true },
);
