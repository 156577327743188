import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { memo, useEffect } from 'react';
import styles from './ComposeEditor.module.css';

interface WaitingForResponsePluginProps {
  isWaitingForResponse: boolean;
}
export const WaitingForResponsePlugin = memo(
  ({ isWaitingForResponse }: WaitingForResponsePluginProps) => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
      editor.setEditable(!isWaitingForResponse);
    }, [editor, isWaitingForResponse]);
    if (!isWaitingForResponse) {
      return null;
    }
    return (
      <div className={styles.WaitingForResponseIndicator}>
        <Spinner />
      </div>
    );
  },
);
WaitingForResponsePlugin.displayName = 'WaitingForResponsePlugin';
