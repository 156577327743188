import { openURL } from '@mirage/service-platform-actions';

export const DROPBOX_ADMIN_URL =
  'https://www.dropbox.com/team/admin/settings/dash/overview?return_to=dash';

export const useAdminUI = () => {
  const openAdminSettings = () => {
    openURL(DROPBOX_ADMIN_URL);
  };

  return {
    adminUrl: DROPBOX_ADMIN_URL,
    openAdminSettings,
  };
};
