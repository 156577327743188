import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { RecentsTabPanel } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/RecentsTabPanel';
import { StacksTabPanel } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StacksTabPanel';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import * as React from 'react';
import { forwardRef, Ref } from 'react';
import styles from './TabbedModule.module.css';

export const TabbedModule = ({
  maxCardsPerRow,
}: {
  maxCardsPerRow: number;
}) => {
  const [selectedTab, setSelectedTab] = React.useState('recents-tab'); // Should probably pass initial active tab in as a prop

  // We use a forward ref on the TabHeader so we can save the div that we use to render accessory buttons.
  // We pass that down into each TabPanel which will use react's portal to render any buttons associated with
  //    that panel/module. This keeps the logic cleaner & more contained
  const [headerButtonSlot, setHeaderButtonSlot] = React.useState(null);
  const accessoryButtonRef = React.useRef(null);
  React.useEffect(() => {
    setHeaderButtonSlot(accessoryButtonRef.current);
  }, []);

  return (
    <Box>
      {/*  TODO create a context or jotai for storing tabs & unread state */}
      <TabHeader
        ref={accessoryButtonRef}
        selectedTab={selectedTab}
        onTabSelection={setSelectedTab}
      />

      <RecentsTabPanel
        isActive={selectedTab === 'recents-tab'}
        headerButtonSlot={
          selectedTab === 'recents-tab' ? headerButtonSlot : null
        }
      />

      <StacksTabPanel
        isActive={selectedTab === 'stacks-tab'}
        maxCardsPerRow={maxCardsPerRow}
        headerButtonSlot={
          selectedTab === 'stacks-tab' ? headerButtonSlot : null
        }
      />
    </Box>
  );
};

const TabHeader = forwardRef(
  (
    {
      selectedTab,
      onTabSelection,
    }: {
      selectedTab: string;
      onTabSelection: (tabId: string) => void;
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const tabLabels = [
      {
        label: i18n.t('recents'),
        tabId: 'recents-tab',
      },
      {
        label: i18n.t('stacks'),
        tabId: 'stacks-tab',
      },
    ];

    return (
      <div className={styles.tabHeaderContainer}>
        {tabLabels.map(({ label, tabId }) => (
          <div
            role="tablist"
            aria-labelledby={label}
            tabIndex={0}
            className={styles.tabLabelWrapper}
            onClick={() => onTabSelection(tabId)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onTabSelection(tabId);
              }
            }}
            key={tabId}
          >
            <div
              className={classNames(styles.tabLabelUnderline, {
                [styles.isSelected]: tabId === selectedTab,
              })}
            >
              <Text isBold color={selectedTab === tabId ? 'standard' : 'faint'}>
                {label}
              </Text>
            </div>
          </div>
        ))}

        {/* Target div used for portal-ing accessory buttons*/}
        <Box marginLeft="auto" display="flex" alignItems="center" ref={ref} />
      </div>
    );
  },
);
TabHeader.displayName = 'TabHeader';
