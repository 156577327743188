import { dash_feed } from '@dropbox/api-v2-client';
import { IconButton } from '@dropbox/dig-components/buttons';
import { Menu } from '@dropbox/dig-components/menu';
import { Box } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CreateStackLine,
  LinkLine,
  MoreHorizontalLine,
} from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import { SummarizeAction } from '@mirage/mosaics/SummaryQnaPanel/SummarizeAction';
import { ChatEntryPoint } from '@mirage/mosaics/SummaryQnaPanel/SummaryQna';
import { SummaryQnAEnum } from '@mirage/mosaics/SummaryQnaPanel/utils';
import { copyToClipboard } from '@mirage/service-platform-actions';
import { showSnackbar } from '@mirage/shared/snackbar';
import { useShowStackChooserModal } from '@mirage/stacks/StackChooser/StackChooser';
import i18n from '@mirage/translations';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type ActivityActionBarProps = {
  activity: dash_feed.ActivityItem;
  index?: number;
  enableSummary?: boolean;
};

export const ActivityActionBar = ({
  activity,
  index,
  enableSummary,
}: ActivityActionBarProps) => {
  return (
    <Box>
      {enableSummary && (
        <SummarizeAction
          result={activity.object}
          summaryQnAType={SummaryQnAEnum.ACTIVITY}
          entrypoint={ChatEntryPoint.activity_feed_summary}
          resultPosition={index!}
          // the SERP CTA is not shown in the feed so we don't need to worry about this here
          resultPositionNoCta={index!}
          summarizable={activity.object?.summarizable?.['.tag']}
        />
      )}
      <ActivityOverflowMenuAction activity={activity} />
    </Box>
  );
};

enum ActivityOverflowAction {
  COPY_LINK = 'copy_link',
  ADD_TO_STACK = 'add_to_stack',
}

const ActivityOverflowMenuAction = ({ activity }: ActivityActionBarProps) => {
  const navigate = useNavigate();
  const showStackChooserModal = useShowStackChooserModal(navigate);

  const title = activity.object?.name || '';
  const url = activity.object?.url || '';

  const addToStack = useCallback(() => {
    showStackChooserModal({
      metadata_title: title,
      url: url,
      callback: () => {
        // TODO: pap event?
      },
    });
  }, [title, url, showStackChooserModal]);

  const copyLink = useCallback(() => {
    copyToClipboard(url);
    showSnackbar({ title: i18n.t('copied_link_to_clipboard') });
  }, [url]);

  // using onSelection instead of onClick inside each menu item to get free handling of keyboard events
  const onSelection = useCallback(
    (value: ActivityOverflowAction) => {
      switch (value) {
        case ActivityOverflowAction.COPY_LINK:
          copyLink();
          break;
        case ActivityOverflowAction.ADD_TO_STACK:
          addToStack();
          break;
      }
    },
    [copyLink, addToStack],
  );

  return (
    <Menu.Wrapper
      shouldPropagateClickOutsideMouseEvents
      closeOnSelection={true}
      onSelection={onSelection}
    >
      {({ getContentProps, getTriggerProps }) => (
        <>
          <IconButton
            {...getTriggerProps()}
            tone="neutral"
            variant="borderless"
            data-uxa-log={createUxaElementId(
              'activity_feed_actions_overflow_menu_toggle',
              {
                actionSurfaceComponent: 'activity_feed',
                featureLine: 'activity_feed',
              },
            )}
          >
            <Box color="Text Subtle" as={UIIcon} src={MoreHorizontalLine} />
          </IconButton>
          <Menu.Content {...getContentProps()}>
            <Menu.Segment>
              <Menu.ActionItem
                value={ActivityOverflowAction.COPY_LINK}
                withTitle={i18n.t('copy_link')}
                withLeftAccessory={<UIIcon src={LinkLine} />}
                data-uxa-log={createUxaElementId(
                  'activity_feed_actions_copy_link',
                  {
                    actionSurfaceComponent: 'activity_feed',
                    featureLine: 'activity_feed',
                  },
                )}
              />
              <Menu.ActionItem
                value={ActivityOverflowAction.ADD_TO_STACK}
                withTitle={i18n.t('add_to_stack')}
                withLeftAccessory={<UIIcon src={CreateStackLine} />}
                onClick={addToStack}
                data-uxa-log={createUxaElementId(
                  'activity_feed_actions_add_to_stack',
                  {
                    actionSurfaceComponent: 'activity_feed',
                    featureLine: 'activity_feed',
                  },
                )}
              />
            </Menu.Segment>
          </Menu.Content>
        </>
      )}
    </Menu.Wrapper>
  );
};
