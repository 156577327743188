import { Connector } from '@mirage/service-dbx-api';

export const enum SearchFilterType {
  Connector = 'connector',
  LastUpdated = 'lastUpdated',
  Person = 'person',
  ContentType = 'contentType',
}

export type ConnectorFilter = {
  id: string;
  type: SearchFilterType.Connector;
  parameters: {
    connectorId: string;
  };
};

export type LastUpdatedFilter = {
  id: string;
  type: SearchFilterType.LastUpdated;
  parameters: {
    start: Date;
    end: Date;
  };
};

export type PersonObject = {
  email: string;
  displayName?: string;
  profilePhotoUrl?: string;
  sortKey?: string;
  type?: 'anyone';
  score?: number;
};

export type PersonFilter = {
  id: string;
  type: SearchFilterType.Person;
  parameters?: {
    email: string;
    displayName?: string;
    profilePhotoUrl?: string;
  };
};

export type ContentTypeFilter = {
  id: string;
  type: SearchFilterType.ContentType;
  parameters: {
    key: string;
    label: string;
  };
};

export type SearchFilter =
  | ConnectorFilter
  | LastUpdatedFilter
  | PersonFilter
  | ContentTypeFilter;

export const getSearchFilterNamesForLogging = (
  newFilters: SearchFilter[],
): string[] => {
  const filterNames: string[] = [];
  newFilters.forEach((filter) => {
    switch (filter.type) {
      case SearchFilterType.Connector:
        filterNames.push(filter.parameters.connectorId);
        break;
      case SearchFilterType.LastUpdated:
        {
          const dateFilter = `date_${filter.id.replace(/-/g, '_')}`;
          if (dateFilter) {
            filterNames.push(dateFilter);
          }
        }
        break;
      case SearchFilterType.ContentType:
        filterNames.push(filter.parameters.key);
        break;
      case SearchFilterType.Person:
        filterNames.push('person');
        break;
      default:
        filter satisfies never;
        return undefined as never;
    }
  });

  return filterNames;
};

export function isConnectorFilter(
  filter: SearchFilter,
): filter is ConnectorFilter {
  return filter.type === SearchFilterType.Connector;
}

export function isLastUpdatedFilter(
  filter: SearchFilter,
): filter is LastUpdatedFilter {
  return filter.type === SearchFilterType.LastUpdated;
}

export function isPersonFilter(filter: SearchFilter): filter is PersonFilter {
  return filter.type === SearchFilterType.Person;
}

export function isContentTypeFilter(
  filter: SearchFilter,
): filter is ContentTypeFilter {
  return filter.type === SearchFilterType.ContentType;
}

export const getConnectorFilters = (
  filters: SearchFilter[],
): ConnectorFilter[] => {
  return filters.filter(isConnectorFilter);
};

export const getLastUpdatedFilter = (
  filters: SearchFilter[],
): LastUpdatedFilter | undefined => {
  return filters.find(isLastUpdatedFilter);
};

export const getPersonFilter = (
  filters: SearchFilter[],
): PersonFilter | undefined => {
  return filters.find(isPersonFilter);
};

export const getContentTypeFilters = (
  filters: SearchFilter[],
): ContentTypeFilter[] => {
  return filters.filter(isContentTypeFilter);
};

export const merge = (
  connectorFilters: ConnectorFilter[],
  lastUpdatedFilter: LastUpdatedFilter | undefined,
  personFilter: PersonFilter | undefined,
  contentTypeFilter: ContentTypeFilter[],
): SearchFilter[] => {
  const result: SearchFilter[] = [...connectorFilters, ...contentTypeFilter];
  if (lastUpdatedFilter) {
    result.push(lastUpdatedFilter);
  }
  if (personFilter) {
    result.push(personFilter);
  }
  return result;
};

export function sortDisplayFilters<T extends { label: string }>(filters: T[]) {
  return filters.sort((a, b) => {
    const aSantizited = a.label.replace('*', '').trim().toLowerCase();
    const bSantizited = b.label.replace('*', '').trim().toLowerCase();

    return aSantizited.localeCompare(bSantizited);
  });
}

// HACK: Temporary solution to filter out legacy paper connector
// https://jira.dropboxer.net/browse/OSE-4283
const DROPBOX_PAPER_TYPE = 'dropbox_paper';
export const filterOutLegacyPaperConnector = (connector: Connector) => {
  return connector.id_attrs?.type !== DROPBOX_PAPER_TYPE;
};
