import { IconButton } from '@dropbox/dig-components/buttons';
import { LabelGroup } from '@dropbox/dig-components/combinations';
import { List } from '@dropbox/dig-components/list';
import { Spinner } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { AddLine, CheckmarkLine } from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Select_DashConnectAppPageConnector } from '@mirage/analytics/events/types/select_dash_connect_app_page_connector';
import { useConnectQueryParam } from '@mirage/settings/hooks/useConnectQueryParam';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import i18n from '@mirage/translations';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import styles from './Connector.module.css';

interface Props {
  connected: boolean;
  description?: string;
  label: string;
  // Either icon_src or leftAccessory should be provided
  icon_src?: string;
  leftAccessory?: React.ReactNode;
  loading: boolean;
  onClick: () => void;
  type: string;
}

export const Connector = ({
  onClick,
  connected,
  description,
  label,
  icon_src,
  leftAccessory,
  loading,
  type,
}: Props) => {
  const [autoConnectOpen, setAutoConnectOpen] = useState(false);
  const { connect, removeConnect } = useConnectQueryParam();
  const { reportPapEvent } = useMirageAnalyticsContext();

  const launchAutoConnect = !autoConnectOpen && connect === type;

  const onConnectClick = useCallback(async () => {
    if (!connected) {
      reportPapEvent(
        PAP_Select_DashConnectAppPageConnector({ appName: label }),
      );
      onClick();
    }
  }, [connected, label, onClick, reportPapEvent]);

  useEffect(() => {
    if (launchAutoConnect) {
      onConnectClick();
      setAutoConnectOpen(true);
      removeConnect();
    }
  }, [launchAutoConnect, setAutoConnectOpen, onConnectClick, removeConnect]);

  const tooltipText = connected ? '' : i18n.t('add');

  return (
    <List.Item
      className={classnames(styles.listItem, connected && styles.connected)}
      verticalAlign="center"
    >
      <List.Content role="button" onClick={onConnectClick}>
        <LabelGroup
          withLeftAccessory={
            <div className={styles.iconMarginRight}>
              {/* If icon_src exist, we use it, and fallback to leftAccessory */}
              {icon_src ? (
                <img
                  key={`${type}-icon`}
                  src={icon_src}
                  width="24px"
                  height="24px"
                  alt={label}
                />
              ) : (
                leftAccessory
              )}
            </div>
          }
          withSubtext={description && <Text color="subtle">{description}</Text>}
          withText={<Text isBold>{label}</Text>}
        />
      </List.Content>
      <List.Accessory className={styles.flexCenter}>
        {loading ? (
          <Spinner size="small" />
        ) : (
          <DigTooltip title={tooltipText}>
            <IconButton
              variant="transparent"
              size="standard"
              shape="circular"
              disabled={connected}
              className={classnames(
                styles.iconButton,
                connected && styles.connected,
              )}
              onClick={(e: React.MouseEvent): void => {
                e.stopPropagation();
                onConnectClick();
              }}
            >
              {connected ? (
                <UIIcon
                  src={CheckmarkLine}
                  aria-label={i18n.t('connected')}
                  color="var(--dig-color__success__base)"
                />
              ) : (
                <UIIcon src={AddLine} aria-label={i18n.t('connect')} />
              )}
            </IconButton>
          </DigTooltip>
        )}
      </List.Accessory>
    </List.Item>
  );
};
