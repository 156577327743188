import { fetchTypeaheadRecommendations } from '@mirage/service-dbx-api';
import { tagged } from '@mirage/service-logging';
import { namespace } from '@mirage/service-operational-metrics';
import {
  CacheKey,
  TypeaheadCache,
} from '@mirage/service-typeahead-search/service/typeahead-cache';
import {
  ONE_MINUTE_IN_MILLIS,
  ONE_SECOND_IN_MILLIS,
} from '@mirage/shared/util/constants';
import { register, unregister } from '@mirage/shared/util/jobs';

const logger = tagged('typeahead/sync-recommendations');

const SYNC_RECOMMENDATIONS_JOB_NAME = 'recommendations-sync';
const SYNC_INTERVAL_MS = ONE_MINUTE_IN_MILLIS * 10;

const DEBUG = false;
DEBUG satisfies false;

export function start(cache: TypeaheadCache) {
  register(SYNC_RECOMMENDATIONS_JOB_NAME, SYNC_INTERVAL_MS, true, () =>
    sync(cache),
  );
}

export function cancel() {
  unregister(SYNC_RECOMMENDATIONS_JOB_NAME);
}

// sync, clear, and insert logic
const metrics = namespace('typeahead');
export async function sync(cache: TypeaheadCache) {
  try {
    const lastRecommendationsSyncMs =
      await cache.getLastRecommendationsSyncMs();

    if (shouldSyncRecommendations(lastRecommendationsSyncMs)) {
      const recommendations = await fetchTypeaheadRecommendations();

      metrics.stats('sync/count', recommendations.length);
      metrics.counter('sync/status', 1, {
        status: 'success',
      });

      if (DEBUG) {
        logger.debug(
          `fetchTypeaheadRecommendations`,
          recommendations.slice(0, 3),
        );
      }

      await cache.clear(CacheKey.Recommendations);
      await cache.cacheRecommendations(recommendations);
      await cache.saveRecommendationsSyncedMs();
    }
  } catch (e) {
    logger.error('sync failed', e);
    metrics.counter('sync/status', 1, {
      status: 'error',
    });
    throw e;
  }
}

function shouldSyncRecommendations(
  lastRecommendationsSyncMs: number | undefined,
): boolean {
  if (lastRecommendationsSyncMs == null) {
    logger.debug('No last sync timestamp found, syncing recommendations');
    return true;
  }

  // make the ttl a bit shorter than the sync interval so perfectly timed syncs don't get skipped
  const cacheTTL = SYNC_INTERVAL_MS - ONE_SECOND_IN_MILLIS;
  const ageMs = Date.now() - lastRecommendationsSyncMs;
  const syncedRecently = ageMs < cacheTTL;

  if (ageMs < 0) {
    logger.warn(
      'Last sync timestamp is in the future, syncing recommendations',
    );
    return true;
  } else if (syncedRecently) {
    logger.debug(
      `Skipping Recommendations sync, last sync was only ${ageMs}ms ago`,
    );
    return false;
  }

  return true;
}
