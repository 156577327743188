import { ComponentType, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { ONBOARDING_VERSION } from "@mirage/growth/onboarding/flows/constants";
import { ComposeAssistantPage } from "@mirage/mosaics/ComposeAssistant/containers/ComposeAssistantPage";
import { NewStackPage } from "@mirage/mosaics/NewStackPage";
import { PublishedContentPage } from "@mirage/mosaics/PublishedContentPage";
import { SearchResultsPage } from "@mirage/mosaics/SearchResultsPage";
import { AuthConfirmPage } from "@mirage/mosaics/SettingsPage";
import { StackPage } from "@mirage/mosaics/StackPage";
import { StacksPage } from "@mirage/mosaics/StacksPage";
import { NotFound } from "@mirage/webapp/pages/NotFound";
import { Settings } from "@mirage/webapp/pages/Settings";
import {
  AppRoute,
  PageType,
  RouteDef,
  RoutePath,
} from "@mirage/webapp/routeTypes";

import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { OAuth } from "./pages/OAuth";
import { Onboarding } from "./pages/Onboarding";
import { UserResearch } from "./pages/UserResearch";

const SettingsWithTab: React.FC = () => {
  const {
    params: { tab },
  } = useRoute();

  return <Settings selectedTab={tab} />;
};

const StackWithShareId: React.FC = () => {
  const {
    params: { shareId },
  } = useRoute();

  return <StackPage shareId={shareId ?? ""} />;
};

const SetupWithVersion: React.FC = () => {
  const {
    params: { version },
  } = useRoute();

  return <Onboarding version={(version as ONBOARDING_VERSION) ?? ""} />;
};

const SetupSurvey: React.FC = () => {
  const { params } = useRoute();
  return (
    <Onboarding
      version={ONBOARDING_VERSION.SURVEY_JOBS_TO_BE_DONE}
      routeParams={params}
    />
  );
};

export const ROUTES: Record<PageType, RouteDef> = {
  [PageType.HOME]: {
    path: RoutePath.ROOT,
    requiresAuthentication: false,
    Component: Home,
  },
  [PageType.LOGIN]: {
    path: RoutePath.LOGIN,
    requiresAuthentication: false,
    Component: Login,
  },
  [PageType.OAUTH]: {
    path: RoutePath.OAUTH,
    requiresAuthentication: false,
    Component: OAuth,
  },
  [PageType.LOGOUT]: {
    path: RoutePath.LOGOUT,
    // This allows the user to clear the storage, so auth is not really needed.
    requiresAuthentication: false,
    Component: Logout,
  },
  [PageType.NOT_FOUND]: {
    path: RoutePath.NOT_FOUND,
    requiresAuthentication: false,
    Component: NotFound,
  },
  [PageType.STACKS]: {
    path: RoutePath.STACKS,
    requiresAuthentication: true,
    Component: StacksPage,
  },
  [PageType.SETTINGS]: {
    path: RoutePath.SETTINGS,
    requiresAuthentication: true,
    Component: Settings,
  },
  [PageType.SETTINGS_TAB]: {
    path: RoutePath.SETTINGS_TAB,
    requiresAuthentication: true,
    Component: SettingsWithTab,
  },
  [PageType.AUTHCONFIRM]: {
    path: RoutePath.AUTHCONFIRM,
    requiresAuthentication: true,
    wholePage: true,
    Component: AuthConfirmPage,
  },
  // Must be before PAGE_TYPE.STACK
  [PageType.NEW_STACK]: {
    path: RoutePath.NEW_STACK,
    requiresAuthentication: true,
    Component: NewStackPage,
  },
  [PageType.STACK]: {
    path: RoutePath.STACKS + "/:shareId",
    requiresAuthentication: false,
    Component: StackWithShareId,
  },
  [PageType.SEARCH_RESULTS]: {
    path: RoutePath.SEARCH_RESULTS,
    requiresAuthentication: true,
    // SearchResultsPage takes an optional prop, so just ignore it.
    Component: SearchResultsPage as ComponentType,
  },
  [PageType.COMPOSE]: {
    path: RoutePath.COMPOSE,
    requiresAuthentication: true,
    Component: ComposeAssistantPage,
    hideTopNav: true,
  },
  [PageType.UXR]: {
    path: RoutePath.UXR,
    requiresAuthentication: false,
    wholePage: true,
    Component: UserResearch,
  },
  [PageType.SETUP]: {
    path: RoutePath.SETUP + "/:version",
    requiresAuthentication: true,
    wholePage: true,
    Component: SetupWithVersion,
  },
  [PageType.SETUP_SURVEY]: {
    path: RoutePath.SETUP_SURVEY,
    requiresAuthentication: true,
    wholePage: true,
    Component: SetupSurvey,
  },
  [PageType.PUBLISHED_CONTENT]: {
    path: RoutePath.PUBLISHED_CONTENT,
    requiresAuthentication: true,
    Component: PublishedContentPage,
  },
};

// Fix the order so it can be safely used by the hook.
const pageTypes = Object.keys(ROUTES) as ReadonlyArray<PageType>;

// This hook acts in the same way as a switch, but returns more information.
export function useRoute(): AppRoute {
  const location = useLocation();

  /**
   * Only reprocess match if our location changes. Ideally this would only trigger
   * if the path changed, but we need to include the full location object in our
   * return object
   */
  const route = useMemo(() => {
    for (const pageType of pageTypes) {
      const { Component, requiresAuthentication, path, wholePage, hideTopNav } =
        ROUTES[pageType];

      const match = matchPath(path, location.pathname);

      if (match) {
        return {
          Component,
          pageType: PageType[pageType],
          pattern: path,
          params: match.params,
          location,
          requiresAuthentication,
          wholePage,
          hideTopNav,
        };
      }
    }

    return {
      Component: ROUTES[PageType.NOT_FOUND].Component,
      pageType: PageType.NOT_FOUND,
      requiresAuthentication: ROUTES[PageType.NOT_FOUND].requiresAuthentication,
      pattern: RoutePath.NOT_FOUND,
      params: {},
      location,
    };
  }, [location]);

  return route;
}
