import { Button } from '@dropbox/dig-components/buttons';
import { Chip } from '@dropbox/dig-components/chip';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import {
  CheckmarkLine,
  ChevronDownLine,
  FileLine,
} from '@dropbox/dig-icons/assets';
import { Dropdown } from '@mirage/search/General/Dropdown';
import { FeatureValue } from '@mirage/service-experimentation/features';
import {
  ContentTypeFilter,
  SearchFilterType,
} from '@mirage/shared/search/search-filters';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { FilterProps } from '../types';
import { contentTypes } from './content-types';
import styles from './ContentTypeFilterDropdown.module.css';
import { getContentTypeFilterTitle } from './utils';

const ANY_OPTION_KEY = 'file_type_any';

const getFilterDefinition = (key: string, label: string) => {
  const filterDefinition = {
    id: key,
    type: SearchFilterType.ContentType,
    parameters: {
      key,
      label,
    },
  } as ContentTypeFilter;
  return filterDefinition;
};

export type ContentTypeDropdownVariant = 'single_select' | 'multi_select';
export const getContentTypeDropdownVariant = (
  variant: FeatureValue,
): ContentTypeDropdownVariant => {
  switch (variant) {
    case 'V2':
      return 'multi_select';
    case 'V1':
    default:
      return 'single_select';
  }
};

type Props = {
  onDeselectAll: () => void;
  onSelect: (selection: ContentTypeFilter | undefined) => void;
  activeFilters: ContentTypeFilter[];
  selected: boolean;
  variant: ContentTypeDropdownVariant;
  onOpenDropdown: () => void;
};

export const ContentTypeFilterDropdown = ({
  onDeselectAll,
  onSelect,
  activeFilters,
  selected,
  variant = 'single_select',
  onOpenDropdown,
}: Props) => {
  const activeFilterCount = activeFilters.length;

  const getMultiSelectFilterOptions = () => {
    return contentTypes.map(({ key, label }) => {
      const filterDefinition = getFilterDefinition(key, label);
      const isSelected = activeFilters.some((afilter) => afilter.id === key);

      return {
        key,
        label,
        selected: isSelected,
        onSelect: () => onSelect(filterDefinition),
        onKeyDown: (e: React.KeyboardEvent) => {
          switch (e.key) {
            case KeyCodes.enter:
            case KeyCodes.space:
              onSelect(filterDefinition);
              break;
          }
        },
      };
    });
  };

  const getSingleSelectFilterOptions = () => {
    // Option for "any", used to deselect other options
    const anyOption = {
      key: ANY_OPTION_KEY,
      title: i18n.t('file_type_filter_any_option'),
      leftAccessory:
        activeFilters.length === 0 ? <UIIcon src={CheckmarkLine} /> : <></>,
      onClick: () => onSelect(undefined),
    };

    const options = contentTypes.map(({ key, label }) => {
      const filterDefinition = getFilterDefinition(key, label);
      const isSelected = activeFilters.some((afilter) => afilter.id === key);

      return {
        key,
        title: label,
        leftAccessory: isSelected ? <UIIcon src={CheckmarkLine} /> : <></>,
        onClick: () => onSelect(filterDefinition),
      };
    });

    return [anyOption, ...options];
  };

  if (variant === 'multi_select') {
    return (
      <Menu.Wrapper
        closeOnSelection={false}
        onToggle={({ isOpen }) => isOpen && onOpenDropdown()}
      >
        {({ getContentProps, getTriggerProps, closeMenu }) => (
          <>
            <Chip
              {...getTriggerProps()}
              isSelected={selected}
              selectedStyle="stroke"
            >
              <Chip.IconAccessory>
                <UIIcon src={FileLine} />
              </Chip.IconAccessory>
              <Chip.Content>
                {getContentTypeFilterTitle(activeFilters)}
              </Chip.Content>
              <Chip.IconAccessory>
                <UIIcon src={ChevronDownLine} />
              </Chip.IconAccessory>
            </Chip>

            <Menu.Content {...getContentProps()}>
              <Menu.Segment
                withLabel={
                  <>
                    {i18n.t('content_type_filter_title')}
                    {activeFilterCount > 0 && (
                      <Button
                        size="small"
                        variant="transparent"
                        onClick={() => {
                          closeMenu({});
                          onDeselectAll();
                        }}
                      >
                        {i18n.t('deselect_all')}
                      </Button>
                    )}
                  </>
                }
              >
                {getMultiSelectFilterOptions().map(
                  (filter: FilterProps, index) => (
                    <Menu.ActionItem
                      className={styles.menuItem}
                      key={index}
                      value={filter.selected}
                      role="menuitemcheckbox"
                      onClick={filter.onSelect}
                      onSubmit={filter.onSelect}
                      onKeyDown={(a) => {
                        filter.onKeyDown?.(a);
                      }}
                      aria-checked={filter.selected}
                      withLeftAccessory={
                        <div className={styles.leftAccessory}>
                          <Checkbox
                            checked={filter.selected}
                            readOnly
                            tabIndex={-1}
                          />
                          {filter.icon}
                        </div>
                      }
                    >
                      {filter.label}
                    </Menu.ActionItem>
                  ),
                )}
              </Menu.Segment>
            </Menu.Content>
          </>
        )}
      </Menu.Wrapper>
    );
  }

  return (
    <Dropdown
      minWidth={200}
      onToggle={(isOpen) => isOpen && onOpenDropdown()}
      anchor={
        <Chip
          withDropdownIcon={true}
          isSelected={selected}
          selectedStyle="stroke"
        >
          <Chip.IconAccessory>
            <UIIcon src={FileLine} />
          </Chip.IconAccessory>
          <Chip.Content>
            {getContentTypeFilterTitle(activeFilters)}
          </Chip.Content>
        </Chip>
      }
      placement={'bottom-start'}
      config={{
        sections: [getSingleSelectFilterOptions()],
      }}
      closeOnMouseLeave={true}
    />
  );
};
