import { ContextInputMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ContextInputMessages';
import { ConversationInput } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationInput';
import { ConversationMessages } from '@mirage/mosaics/ComposeAssistant/components/chat/ConversationMessages';
import { useComposeCurrentSessionContext } from '@mirage/mosaics/ComposeAssistant/data/current-session/ComposeCurrentSessionContext';
import { ComposeAssistantConversationMessage } from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import { memo, useCallback } from 'react';

export const ComposeConversation = memo(() => {
  const {
    messagesHistory,
    sources,
    addSource,
    removeSource,
    draftConfig,
    setDraftConfig,
    sourcesContentCache,
    addRawMessage,
    postUserMessage,
    inputContext,
    setInputContext,
    currentSessionID,
    isWaitingForResponse,
  } = useComposeCurrentSessionContext();
  const handleCompleteContextInputs = useCallback(() => {
    if (messagesHistory.length > 0) {
      // no-op if already started talking
      return;
    }
    const text = draftConfig.contentType
      ? i18n.t('compose_assistant_message_with_content_type', {
          contentType: draftConfig.contentType,
        })
      : i18n.t('compose_assistant_message_generic');
    addRawMessage({
      type: 'message',
      role: 'assistant',
      text,
      ts: Date.now(),
    });
  }, [addRawMessage, draftConfig.contentType, messagesHistory.length]);
  const contextInputMessages = (
    <ContextInputMessages
      key={currentSessionID} // reset inputs when session changes
      sources={sources}
      sourcesContentCache={sourcesContentCache}
      addSource={addSource}
      removeSource={removeSource}
      draftConfig={draftConfig}
      setDraftConfig={setDraftConfig}
      onCompleteInputs={
        messagesHistory.length === 0 ? handleCompleteContextInputs : undefined
      }
    />
  );
  return (
    <>
      <ConversationMessages
        startingMessageNode={contextInputMessages}
        messages={messagesHistory}
        isWaitingForResponse={isWaitingForResponse}
        onClickFollowUpSuggestion={(suggestion) => {
          postUserMessage({
            text: suggestion,
          });
        }}
        onRemoveSource={removeSource}
      />
      <ConversationInput
        onSubmit={(text) => {
          if (getUserMessages(messagesHistory).length === 0) {
            postUserMessage({
              text,
              mustGenerateDraft: true,
              mustIncludeSourceContents: true,
            });
          } else {
            postUserMessage({
              text,
            });
          }
        }}
        inputContext={inputContext}
        setInputContext={setInputContext}
      />
    </>
  );
});
ComposeConversation.displayName = 'ComposeConversation';

function getUserMessages(
  messages: ComposeAssistantConversationMessage[],
): ComposeAssistantConversationMessage[] {
  return messages.filter((message) => message.role === 'user');
}
