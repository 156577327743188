import { IconButton } from '@dropbox/dig-components/buttons';
import { ProgressBar } from '@dropbox/dig-components/progress_indicators';
import { Text } from '@dropbox/dig-components/typography';
import { Link } from '@mirage/link/Link';
import { DefaultMetadata } from '@mirage/search/SearchResults/Metadata/DefaultMetadata';
import { transformDashResultToMirage } from '@mirage/service-dbx-api/service/utils';
import { ComposeSource } from '@mirage/shared/compose/compose-session';
import { FileContentIcon } from '@mirage/shared/icons/FileContentIcon';
import { DigTooltip } from '@mirage/shared/util/DigTooltip';
import { onKeyDownCommitFn } from '@mirage/shared/util/on-key-down';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';
import styles from './ComposeSourceRow.module.css';

export interface ComposeSourceRowProps {
  source: ComposeSource;
  stateIcon?: ReactNode;
  action?: {
    icon: ReactNode;
    variant: 'circle' | 'small';
    onClick: () => void;
    label: string;
  };
  loadState?: 'loading' | 'loaded' | 'error';
  variant?: 'default' | 'small';
}
export const ComposeSourceRow = memo(
  ({
    source,
    stateIcon,
    action,
    loadState,
    variant = 'default',
  }: ComposeSourceRowProps) => {
    const mirageResult =
      source.type === 'dash_search_result'
        ? transformDashResultToMirage(source.searchResult)
        : source.recommendation;
    if (!mirageResult) {
      return null;
    }

    const handleActionClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      action?.onClick();
    };

    return (
      <div
        className={classNames(styles.ComposeSourceRow, {
          [styles.ComposeSourceRowSmall]: variant === 'small',
        })}
        onClick={action?.onClick}
        onKeyUp={onKeyDownCommitFn(action?.onClick)}
        role="button"
        tabIndex={0}
      >
        <div className={styles.ComposeSourceIcon}>
          <FileContentIcon content={mirageResult!} size="medium" />
        </div>
        <div className={styles.ComposeSourceLabels}>
          <Link
            className={styles.ComposeSourceTitle}
            variant="monochromatic"
            href={mirageResult.url || ''}
            target="_blank"
            rel="noreferrer"
            hasNoUnderline
          >
            {mirageResult.title}
          </Link>
          {loadState === 'error' ? (
            <Text
              size="small"
              className={styles.ComposeSourceSubtitle}
              color="error"
            >
              {i18n.t('compose_source_content_load_error')}
            </Text>
          ) : (
            <Text
              size="small"
              className={styles.ComposeSourceSubtitle}
              color="subtle"
            >
              <DefaultMetadata result={mirageResult!} />
            </Text>
          )}
        </div>
        {stateIcon}
        {action && (
          <DigTooltip title={action.label} placement="top">
            <IconButton
              variant={action.variant === 'circle' ? 'opacity' : 'borderless'}
              className={styles.ComposeSourceActionButton}
              size={action.variant === 'circle' ? 'medium' : 'small'}
              shape={action.variant === 'circle' ? 'circular' : 'standard'}
              onClick={handleActionClick}
            >
              {action.icon}
            </IconButton>
          </DigTooltip>
        )}
        {loadState === 'loading' && (
          <div className={styles.ComposeSourceLoadProgress}>
            <ProgressBar in isIndeterminate />
          </div>
        )}
      </div>
    );
  },
);
ComposeSourceRow.displayName = 'ComposeSourceRow';
