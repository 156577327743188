import { Button } from '@dropbox/dig-components/buttons';
import { List } from '@dropbox/dig-components/list';
import { Text } from '@dropbox/dig-components/typography';
import { Split, Stack } from '@dropbox/dig-foundations';
import { UIIcon } from '@dropbox/dig-icons';
import {
  ChevronDownLine,
  ChevronUpLine,
  OpenLine,
} from '@dropbox/dig-icons/assets';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { PAP_Collapse_CalendarItemList } from '@mirage/analytics/events/types/collapse_calendar_item_list';
import { PAP_Expand_CalendarItemList } from '@mirage/analytics/events/types/expand_calendar_item_list';
import { PAP_Select_CalendarExternalLink } from '@mirage/analytics/events/types/select_calendar_external_link';
import { PAP_Shown_CalendarModule } from '@mirage/analytics/events/types/shown_calendar_module';
import { DashCard } from '@mirage/dash-component-library/components/DashCard';
import { useConnectedCalendars } from '@mirage/mosaics/WorkingSet/Calendar/useConnectedCalendars';
import { openURL } from '@mirage/service-platform-actions';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import { ModuleHeader } from '@mirage/shared/two-column-grid/ModuleHeader';
import i18n from '@mirage/translations';
import { CalendarEmptyStateV2 } from '@mirage/working-set/CalendarEventWorkingSetCard/CalendarEmptyStateV2';
import { useAtomValue } from 'jotai';
import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styles from './CalendarModule.module.css';
import { useCalendarEventSections } from './hooks';

const CALENDAR_URL_GOOGLE = 'https://calendar.google.com/';
const CALENDAR_URL_OUTLOOK = 'https://outlook.live.com/calendar/';

export const CalendarModule = ({
  onDismiss,
}: {
  onDismiss: (dismissed: boolean) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const { connections } = useConnectedCalendars();
  const connectorName = connections?.[0]?.connector?.id_attrs?.type;
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const onCalendarLinkClick = useCallback(() => {
    reportPapEvent(
      PAP_Select_CalendarExternalLink({
        actionSurfaceComponent: 'calendar',
        featureLine: 'calendar',
        connectorName,
      }),
    );
    switch (connectorName) {
      case 'googleCalendar':
        openURL(CALENDAR_URL_GOOGLE);
        break;
      case 'outlook':
        openURL(CALENDAR_URL_OUTLOOK);
        break;
    }
  }, [reportPapEvent, connectorName]);

  return (
    <Split direction="vertical" gap="Micro Small">
      <Split.Item>
        <ModuleHeader
          title={i18n.t('upcoming_events')}
          actions={
            connectorName && (
              <IconButtonWithTooltip
                variant="borderless"
                onClick={onCalendarLinkClick}
                tooltipProps={{ title: i18n.t('open_calendar') }}
              >
                <UIIcon src={OpenLine} />
              </IconButtonWithTooltip>
            )
          }
        />
      </Split.Item>
      <Split.Item>
        <Blur isBlurred={privacyModeEnabled} blurAmount="high">
          <CalendarCard onDismiss={onDismiss} />
        </Blur>
      </Split.Item>
    </Split>
  );
};

export const CalendarCard = ({
  onDismiss,
}: {
  onDismiss: (dismissed: boolean) => void;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const [showAllDayEvents, setShowAllDayEvents] = useState<boolean>(false);
  const [showAllStandardEvents, setShowAllStandardEvents] =
    useState<boolean>(false);
  const [expandedEventId, setExpandedEventId] = useState<string>('');

  const {
    connectorSyncState,
    events,
    allDayEvents,
    standardEvents,
    numHiddenEvents,
    eventSections,
  } = useCalendarEventSections({
    showAllDayEvents,
    showAllStandardEvents,
    expandedEventId,
    setExpandedEventId,
  });

  useEffect(() => {
    if (connectorSyncState !== undefined) {
      reportPapEvent(
        PAP_Shown_CalendarModule({
          actionSurfaceComponent: 'sidebar',
          featureLine: 'calendar',
          connectionSyncState: connectorSyncState,
        }),
      );
    }
  }, [reportPapEvent, connectorSyncState]);

  // Expand the first event by default
  useEffect(() => {
    if (
      !events?.length ||
      !standardEvents?.length ||
      (expandedEventId &&
        events.some((event) => event.uuid === expandedEventId))
    ) {
      // No events or already expanded
      return;
    }

    // Find the first non-all-day event
    setExpandedEventId(standardEvents[0].uuid);
  }, [events, standardEvents, expandedEventId, setExpandedEventId]);

  if (connectorSyncState === 'disconnected') {
    return <CalendarEmptyStateV2 onDismiss={onDismiss} />;
  }

  return (
    <DashCard>
      {allDayEvents.length > 0 && (
        <AllDayEventsHeader
          numAllDayEvents={allDayEvents.length}
          {...{ showAllDayEvents, setShowAllDayEvents }}
        />
      )}
      <Stack gap="Micro Large">
        {eventSections}
        {numHiddenEvents > 0 && (
          <SeeAllEventsButton
            {...{
              numHiddenEvents,
              showAllStandardEvents,
              setShowAllStandardEvents,
            }}
          />
        )}
      </Stack>
    </DashCard>
  );
};

const AllDayEventsHeader = ({
  numAllDayEvents,
  showAllDayEvents,
  setShowAllDayEvents,
}: {
  numAllDayEvents: number;
  showAllDayEvents: boolean;
  setShowAllDayEvents: Dispatch<SetStateAction<boolean>>;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  const onClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      e.preventDefault();

      setShowAllDayEvents((prev) => {
        const papEvent = prev
          ? PAP_Collapse_CalendarItemList
          : PAP_Expand_CalendarItemList;
        reportPapEvent(
          papEvent({
            actionSurfaceComponent: 'calendar',
            featureLine: 'calendar',
            calendarItemCategory: 'all_day',
          }),
        );
        return !prev;
      });
    },
    [reportPapEvent, setShowAllDayEvents],
  );
  return (
    <div className={styles.allDayHeader}>
      <List paddingY="0" isSelectable>
        <List.Item className={styles.allDayEventListItem} onClick={onClick}>
          <Split alignY="center" className={styles.split}>
            <Split.Item marginRight="auto" className={styles.splitItem}>
              <div className={styles.textContainer}>
                <Text color="subtle">
                  {i18n.t('all_day_events', { count: numAllDayEvents })}
                </Text>{' '}
              </div>
            </Split.Item>
            <Split.Item className={styles.splitItem}>
              <IconButtonWithTooltip
                variant="borderless"
                tooltipProps={{
                  title: showAllDayEvents
                    ? i18n.t('hide_all_day_events')
                    : i18n.t('show_all_day_events'),
                }}
                size="small"
                onClick={onClick}
                className={styles.iconButton}
              >
                <UIIcon
                  src={showAllDayEvents ? ChevronUpLine : ChevronDownLine}
                />
              </IconButtonWithTooltip>
            </Split.Item>
          </Split>
        </List.Item>
      </List>
    </div>
  );
};

const SeeAllEventsButton = ({
  numHiddenEvents,
  showAllStandardEvents,
  setShowAllStandardEvents,
}: {
  numHiddenEvents: number;
  showAllStandardEvents: boolean;
  setShowAllStandardEvents: Dispatch<SetStateAction<boolean>>;
}) => {
  const { reportPapEvent } = useMirageAnalyticsContext();
  return (
    <div className={styles.seeAllButtonContainer}>
      <Button
        variant="transparent"
        size="small"
        onClick={() =>
          setShowAllStandardEvents((prev) => {
            const papEvent = prev
              ? PAP_Collapse_CalendarItemList
              : PAP_Expand_CalendarItemList;
            reportPapEvent(
              papEvent({
                actionSurfaceComponent: 'calendar',
                featureLine: 'calendar',
                calendarItemCategory: 'standard',
              }),
            );
            return !prev;
          })
        }
      >
        {showAllStandardEvents
          ? i18n.t('show_fewer')
          : i18n.t('show_more_with_count', { count: numHiddenEvents })}
      </Button>
    </div>
  );
};
