import { Theme } from '@mirage/service-settings/theming/types';
import { LOCAL_FILES_NAMESPACE } from '@mirage/shared/local-files';
import { v5 as uuidv5 } from 'uuid';
import { CustomizableModuleSettings } from './customize';

export const enum StackSortOption {
  RECENT,
  ALPHA,
  SECTION,
  VIEWED,
}

export const enum StackSortDirection {
  ASC,
  DESC,
}

export const enum StackFilterOption {
  ALL,
  MINE,
  SHARED,
  ARCHIVED,
  COMPANY,
}

// Card types that can be collapsed that we want to track
// These cards we want to track by individual id
type CollapsedCardByIdKeyType =
  | 'stack'
  | 'connector'
  | 'stack_sections'
  | 'stack_sharing'
  | 'stack_suggestions'
  | 'stack_section';

type CollapsedCardByIdKey = `${CollapsedCardByIdKeyType}:${string}`;

// These are the generic cards that we want to track by type
type CollapsedCardByType =
  | 'for_you'
  | 'recents'
  | 'suggested_stacks'
  | 'calendar'
  | 'empty-state'
  | 'create-stack'
  | 'connect-apps'
  | 'stack_action_bar'
  | 'allstacks_action_bar'
  | 'no_sections_stack_items'
  | 'get-to-know-dash';

export type CollapsedCardKey = CollapsedCardByIdKey | CollapsedCardByType;

export type StackSortPreference = {
  option: StackSortOption;
  direction?: StackSortDirection;
};

export type UISettings = {
  stackPageSortPreference: StackSortPreference;
  stackPageFilterPreference: StackFilterOption;
  collapsedCards: { [key in CollapsedCardKey]?: boolean };
  stackByIdSortPreference: {
    [key: string]: StackSortPreference;
  };
  stackItemByIdCollapseDescription: {
    [key: string]: boolean;
  };
  stackItemByIdCollapseSummary: {
    [key: string]: boolean;
  };
};

export const defaultStackPageSortPreference = {
  option: StackSortOption.VIEWED,
  direction: StackSortDirection.DESC,
};

export type AvatarSettings = {
  appDebug: 0 | 1;
  appShortcut: string;
  appToNearestWindow: 0 | 1;
  disableAutoUpdate: string;
  enableDarkMode: Theme;
  enableTips: 0 | 1;
  hideAppOnStartup: 0 | 1;
  isDevTools: 0 | 1;
  showDashInDock: 0 | 1;
  annotationMode: 0 | 1;
};

export type LocalFileDirectory = {
  id: string; // do we need to make this a uuid?
  path: string;
  displayName: string;
  enabled: boolean;
};

export type LocalFileSettings = {
  enabled: boolean;
  systemDirectories: LocalFileDirectory[];
  customDirectories: LocalFileDirectory[];
};

const desktopDirectory: LocalFileDirectory = {
  id: uuidv5('desktop', LOCAL_FILES_NAMESPACE),
  path: '~/Desktop', // in the search itself, we'll replace ~ with the user's home directory
  displayName: 'Desktop',
  enabled: false,
};
const documentsDirectory: LocalFileDirectory = {
  id: uuidv5('documents', LOCAL_FILES_NAMESPACE),
  path: '~/Documents', // in the search itself, we'll replace ~ with the user's home directory
  displayName: 'Documents',
  enabled: false,
};
const downloadsDirectory: LocalFileDirectory = {
  id: uuidv5('downloads', LOCAL_FILES_NAMESPACE),
  path: '~/Downloads', // in the search itself, we'll replace ~ with the user's home directory
  displayName: 'Downloads',
  enabled: false,
};

export const defaultLocalFileSettings: LocalFileSettings = {
  enabled: false,
  systemDirectories: [desktopDirectory, documentsDirectory, downloadsDirectory], // configure these win vs mac
  customDirectories: [],
};

export type Settings = UISettings &
  AvatarSettings &
  Partial<CustomizableModuleSettings> & { localFiles: LocalFileSettings };
