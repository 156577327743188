import { TextInput } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import {
  BoldLine,
  H1Line,
  H2Line,
  ItalicLine,
  LinkLine,
  StrikethroughLine,
  UnderlineLine,
} from '@dropbox/dig-icons/assets';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import { $setBlocksType } from '@lexical/selection';
import { mergeRegister } from '@lexical/utils';
import { IconButtonWithTooltip } from '@mirage/shared/icons/IconButtonWithTooltip';
import i18n from '@mirage/translations';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import {
  ComponentType,
  memo,
  SVGAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styles from './FormatToolbar.module.css';

interface FormattingStates {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikethrough: boolean;
  headingLevel: number;
}

export type FormatAction =
  | 'bold'
  | 'italic'
  | 'underline'
  | 'strikethrough'
  | 'h1'
  | 'h2'
  | 'link'
  | 'list'
  | 'divider';
type ActionInfo = {
  title: string;
  icon: ComponentType<SVGAttributes<SVGElement>>;
  action: () => void;
};

const DEFAULT_COMMAND_PRIORITY = 1;

interface FormatToolbarProps {
  inverse: boolean;
  formatActions: FormatAction[];
}
export const FormatToolbar = memo(
  ({ inverse, formatActions }: FormatToolbarProps) => {
    const [editor] = useLexicalComposerContext();
    const [formattingStates, setFormattingStates] = useState<FormattingStates>({
      isBold: false,
      isItalic: false,
      isUnderline: false,
      isStrikethrough: false,
      headingLevel: 0,
    });
    const [showLinkInput, setShowLinkInput] = useState<boolean>(false);
    const [linkInput, setLinkInput] = useState<string>('');

    const applyHeading = useCallback((level: 0 | 1 | 2) => {
      editor.update(() => {
        const selection = $getSelection();
        if (!selection) {
          return;
        }

        if (level === 0) {
          $setBlocksType(selection, () => $createParagraphNode());
        } else {
          $setBlocksType(selection, () => $createHeadingNode(`h${level}`));
        }
      });
    }, []);

    const toggleHeading = useCallback(
      (level: 1 | 2) => {
        formattingStates.headingLevel === level
          ? applyHeading(0)
          : applyHeading(level);
      },
      [formattingStates, applyHeading],
    );

    const refreshSelectionFormattingStates = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const parent = selection.anchor.getNode().getParent();
        const headingTagMatch = $isHeadingNode(parent)
          ? parent.__tag.match(/^h(\d+)$/)
          : null;
        setFormattingStates({
          isBold: selection.hasFormat('bold'),
          isItalic: selection.hasFormat('italic'),
          isUnderline: selection.hasFormat('underline'),
          isStrikethrough: selection.hasFormat('strikethrough'),
          headingLevel: headingTagMatch ? parseInt(headingTagMatch[1], 10) : 0,
        });
      }
    }, []);

    const toggleLink = useCallback(() => {
      editor.update(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) {
          return;
        }

        const parent = selection.anchor.getNode().getParent();
        if ($isLinkNode(parent)) {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        } else {
          setShowLinkInput(true);
        }
      });
    }, []);

    const actionMap = new Map<FormatAction, ActionInfo>([
      [
        'bold',
        {
          title: i18n.t('compose_editor_toolbar_action_format_bold'),
          icon: BoldLine,
          action: () => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          },
        },
      ],
      [
        'italic',
        {
          title: i18n.t('compose_editor_toolbar_action_format_italic'),
          icon: ItalicLine,
          action: () => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          },
        },
      ],
      [
        'underline',
        {
          title: i18n.t('compose_editor_toolbar_action_format_underline'),
          icon: UnderlineLine,
          action: () => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          },
        },
      ],
      [
        'strikethrough',
        {
          title: i18n.t('compose_editor_toolbar_action_format_strikethrough'),
          icon: StrikethroughLine,
          action: () => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
          },
        },
      ],
      [
        'h1',
        {
          title: i18n.t('compose_editor_toolbar_action_format_heading1'),
          icon: H1Line,
          action: () => {
            toggleHeading(1);
          },
        },
      ],
      [
        'h2',
        {
          title: i18n.t('compose_editor_toolbar_action_format_heading2'),
          icon: H2Line,
          action: () => {
            toggleHeading(2);
          },
        },
      ],
      [
        'link',
        {
          title: i18n.t('compose_editor_toolbar_action_format_link'),
          icon: LinkLine,
          action: () => {
            toggleLink();
          },
        },
      ],
    ]);

    useEffect(() => {
      // Get initial state
      editor.getEditorState().read(() => {
        refreshSelectionFormattingStates();
      });

      // Listen for changes
      return mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(refreshSelectionFormattingStates);
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          (_payload, _newEditor) => {
            refreshSelectionFormattingStates();
            return false;
          },
          DEFAULT_COMMAND_PRIORITY,
        ),
      );
    }, [editor, refreshSelectionFormattingStates]);

    const buttonClassName = inverse
      ? styles.FormatButtonInverse
      : styles.FormatButton;
    const isFormatted = useCallback(
      (action: FormatAction) => {
        switch (action) {
          case 'bold':
            return formattingStates.isBold;
          case 'italic':
            return formattingStates.isItalic;
          case 'underline':
            return formattingStates.isUnderline;
          case 'strikethrough':
            return formattingStates.isStrikethrough;
          case 'h1':
            return formattingStates.headingLevel === 1;
          case 'h2':
            return formattingStates.headingLevel === 2;
        }
      },
      [formattingStates],
    );

    const getActionElement = useCallback(
      (action: FormatAction) => {
        const data = actionMap.get(action);
        if (!data) {
          return null;
        }
        return (
          <IconButtonWithTooltip
            variant="borderless"
            tooltipProps={{
              title: data.title,
            }}
            className={buttonClassName}
            style={{ background: isFormatted(action) ? '#999999' : '' }}
            onClick={data.action}
          >
            <UIIcon src={data.icon} size="small" />
          </IconButtonWithTooltip>
        );
      },
      [isFormatted],
    );

    return (
      <div
        className={inverse ? styles.FormatToolbarInverse : styles.FormatToolbar}
      >
        {showLinkInput ? (
          <TextInput
            style={{
              color: 'var(--color__inverse__standard__text)',
            }}
            isTransparent
            size="small"
            placeholder={i18n.t('compose_editor_toolbar_action_enter_link')}
            value={linkInput}
            onChange={(e) => setLinkInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                e.preventDefault();
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkInput);
              }
            }}
          />
        ) : (
          formatActions.map((action, i) =>
            action === 'divider' ? (
              <div key={`divider-${i}`} className={styles.Divider} />
            ) : (
              getActionElement(action)
            ),
          )
        )}
      </div>
    );
  },
);
FormatToolbar.displayName = 'FormatToolbar';
