// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3448
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';

// Event_name: 'fail.unpublish_stack'
// Description: Fires when unpublishing fails by team admin
// Owner: otc
export type Fail_UnpublishStack = PAPEventGeneric<
  'stacks',
  'fail',
  'unpublish_stack',
  {
    // Answers if the event actor on the stack is the same as the creator of the stack
    isOwner?: boolean;
    // The stack id that represents the stack
    stackId?: string;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Fail_UnpublishStack(
  properties?: Fail_UnpublishStack['properties'],
): Fail_UnpublishStack {
  return <Fail_UnpublishStack>{
    class: 'stacks',
    action: 'fail',
    object: 'unpublish_stack',
    properties,
  };
}
