import { dash_feed } from '@dropbox/api-v2-client';
import { ContentIcon } from '@dropbox/dash-component-library';
import {
  ContentRowAvatar,
  ContentRowIcon,
} from '@mirage/dash-component-library/components/ContentRow';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import {
  getStackActivityObject,
  isStackActivityObject,
} from '@mirage/service-feed/util/helpers';
import { getInitialsFromDisplayName } from '@mirage/shared/account';
import { toActivityResult } from '@mirage/shared/feed/activity-result';
import { FileTypeIcon } from '@mirage/shared/icons';

type ActivityThumbnailProps = {
  activity: dash_feed.ActivityItem;
};

export const ActivityThumbnail = ({ activity }: ActivityThumbnailProps) => {
  let avatar = null;
  if (activity.actor) {
    avatar = (
      <ContentRowAvatar src={activity.actor.avatar_img}>
        {getInitialsFromDisplayName(activity.actor.name!)}
      </ContentRowAvatar>
    );
  } else {
    avatar = <ContentRowAvatar isGuest={!activity.actor} />;
  }

  return (
    <ContentRowIcon
      icon={avatar}
      badge={<ActivityObjectIcon activity={activity} />}
    />
  );
};

type ActivityObjectIconProps = {
  activity: dash_feed.ActivityItem;
};

const ActivityObjectIcon = ({ activity }: ActivityObjectIconProps) => {
  if (isStackActivityObject(activity)) {
    return <StackActivityObjectIcon activity={activity} />;
  } else {
    return <FileActivityObjectIcon activity={activity} />;
  }
};

type StackActivityObjectIconProps = {
  activity: dash_feed.ActivityItem;
};

const StackActivityObjectIcon = ({
  activity,
}: StackActivityObjectIconProps) => {
  const stack = getStackActivityObject(activity);

  return (
    <DashStackIcon
      size="small"
      colorIndex={stack?.stack_data?.color_index}
      emoji={stack?.stack_data?.emoji}
      shapeVariant="circle"
    />
  );
};

type FileActivityObjectIconProps = {
  activity: dash_feed.ActivityItem;
};

const FileActivityObjectIcon = ({ activity }: FileActivityObjectIconProps) => {
  const result = toActivityResult(activity);
  if (!result) {
    return null;
  }
  return (
    <ContentIcon
      size="small"
      shapeVariant="circle"
      hasBackground
      icon={<FileTypeIcon result={result} size="100%" />}
    />
  );
};
