import { IconButton } from '@dropbox/dig-components/buttons';
import { TextArea } from '@dropbox/dig-components/text_fields';
import { Truncate } from '@dropbox/dig-components/truncate';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { SendLine } from '@dropbox/dig-icons/assets';
import { InputContext } from '@mirage/shared/compose/compose-session';
import { KeyCodes } from '@mirage/shared/util/constants';
import i18n from '@mirage/translations';
import { memo, useCallback, useState } from 'react';
import styles from './ConversationInput.module.css';

interface ConversationInputProps {
  inputContext: InputContext | undefined;
  setInputContext: (inputContext: InputContext | undefined) => void;
  onSubmit: (text: string) => void;
  onFocus?: () => void;
}
export const ConversationInput = memo(
  ({
    inputContext,
    setInputContext,
    onSubmit,
    onFocus,
  }: ConversationInputProps) => {
    const [promptText, setPromptText] = useState('');

    const handleKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> =
      useCallback(
        (event) => {
          if (event.key === KeyCodes.enter && promptText.trim().length > 0) {
            if (!event.shiftKey) {
              // shift-enter should create newline as normal
              event.preventDefault();
              onSubmit(promptText);
              setPromptText('');
            }
          }
          if (event.key === 'Backspace' || event.key === 'Delete') {
            if (promptText.length === 0) {
              event.preventDefault();
              setInputContext(undefined);
            }
          }
        },
        [onSubmit, promptText, setInputContext],
      );
    const handleTextAreaChange: React.FormEventHandler<HTMLTextAreaElement> =
      useCallback((event) => {
        event.preventDefault();
        setPromptText(event.currentTarget.value);
      }, []);
    const handleSubmit: React.MouseEventHandler = useCallback(
      (event) => {
        event.preventDefault();
        if (promptText.length > 0) {
          onSubmit(promptText);
          setPromptText('');
        }
      },
      [onSubmit, promptText],
    );

    return (
      <div className={styles.ConversationInput}>
        <div className={styles.ConversationInputContainer}>
          <div className={styles.ConversationInputs}>
            {inputContext && (
              <Text className={styles.ConversationInputContext}>
                <Truncate lines={2}>{inputContext.selectedText}</Truncate>
              </Text>
            )}
            <TextArea
              className={styles.ConversationInputTextArea}
              placeholder={i18n.t('compose_prompt_dash_placeholder')}
              rows={1}
              resizable="auto"
              isTransparent
              value={promptText}
              onChange={handleTextAreaChange}
              onKeyDown={handleKeyDown}
              onFocus={onFocus}
            />
          </div>
          <IconButton
            className={styles.ConversationInputSendButton}
            variant="opacity"
            shape="standard"
            size="small"
            aria-label={i18n.t('send')}
            onClick={handleSubmit}
            disabled={promptText.trim().length === 0}
          >
            <UIIcon src={SendLine} size="standard" />
          </IconButton>
        </div>
      </div>
    );
  },
);
ConversationInput.displayName = 'ConversationInput';
