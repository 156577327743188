// @generated - Do not edit: this file is autogenerated by:
//   //dropbox/pap/codegen
//
// Event definition: https://pap.pp.dropbox.com/#/events/3473
import type { PAPEventGeneric } from '../base/event';
import type { ActionSurfaceComponent } from '../enums/action_surface_component';
import type { DashActionSurface } from '../enums/dash_action_surface';
import type { DashFilterName } from '../enums/dash_filter_name';
import type { DashFilterType } from '../enums/dash_filter_type';
import type { DashSurface } from '../enums/dash_surface';
import type { DashSurfaceBuild } from '../enums/dash_surface_build';
import type { Environment } from '../enums/environment';
import type { FeatureLine } from '../enums/feature_line';
import type { OperatingSystem } from '../enums/operating_system';

// Event_name: 'click.dash_filter'
// Description: This event fires when a user clicks into a filter tab within the Dash application
// Owner: otc-search-experiences
export type Click_DashFilter = PAPEventGeneric<
  'dash',
  'click',
  'dash_filter',
  {
    // id that groups all queries in a single "session" for logging
    searchSessionId?: string;
    // the start time of the event in ms
    startTimeMs?: number;
    // Whether the app dispatching the event is Prod, Test, or Dev (run locally by the developer)
    environment?: Environment;
    // The operating system platform the user is running on.
    operatingSystem?: OperatingSystem;
    // Which Dash surface (web, desktop, or browser extension) the event originated from.
    dashSurface?: DashSurface;
    // generic property representing build version of whatever is logging this event.
    buildVersion?: string;
    // The type of filter being opened by the user
    dashFilterType?: DashFilterType;
    // This is a string, or list of strings, of pre-determined filters to be leveraged within the Dash application
    dashFilter?: string;
    // Represents the filter that the user has acted upon.  This can be set via filter pills or tips within the Dash application.
    dashFilterName?: DashFilterName;
    // The surface in which an action was taken on Dash.
    // Note: serp and search_result_page are the same thing, but we're only using serp moving forward.
    dashActionSurface?: DashActionSurface;
    // Feature Line for dash
    featureLine?: FeatureLine;
    // Set to true when the search field has a value upon search
    hasQuery?: boolean;
    // Set to true when the search option is selected from typeahead search
    isTypeahead?: boolean;
    // Defines different types of dash surfaces
    dashSurfaceBuild?: DashSurfaceBuild;
    // The component within a surface in which an action was taken on Dash.
    actionSurfaceComponent?: ActionSurfaceComponent;
  }
>;

export function PAP_Click_DashFilter(
  properties?: Click_DashFilter['properties'],
): Click_DashFilter {
  return <Click_DashFilter>{
    class: 'dash',
    action: 'click',
    object: 'dash_filter',
    properties,
  };
}
