import { Button } from '@dropbox/dig-components/buttons';
import { Modal } from '@dropbox/dig-components/modal';
import i18n from '@mirage/translations';
import { memo } from 'react';

export const DeleteConfirmationModal = memo(
  ({
    title,
    description,
    onSubmit,
    onCancel,
    isOpen,
  }: {
    title: string;
    description: string;
    onSubmit: () => void;
    onCancel: () => void;
    isOpen: boolean;
  }) => {
    return (
      <Modal open={isOpen} withCloseButton="Close" onRequestClose={onCancel}>
        <Modal.Header hasBottomSpacing="title-standard">
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body hasVerticalSpacing={true}>{description}</Modal.Body>

        <Modal.Footer>
          <Button variant="opacity" onClick={onCancel}>
            {i18n.t('cancel')}
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            {i18n.t('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  },
);
DeleteConfirmationModal.displayName = 'DeleteConfirmationModal';
