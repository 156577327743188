import { Button } from '@dropbox/dig-components/buttons';
import { Checkbox } from '@dropbox/dig-components/controls';
import { Menu } from '@dropbox/dig-components/menu';
import { UIIcon } from '@dropbox/dig-icons';
import { StacksLine } from '@dropbox/dig-icons/assets';
import { createUxaElementId } from '@mirage/analytics/uxa';
import useConnectors from '@mirage/service-connectors/useConnectors';
import { Connector } from '@mirage/service-dbx-api/service';
import { ActivityFeedApplication } from '@mirage/service-feed/types';
import { isSupportedActivityFeedConnector } from '@mirage/service-feed/util/helpers';
import { ConnectorIcon } from '@mirage/shared/icons';
import { sortDisplayFilters } from '@mirage/shared/search/search-filters';
import i18n from '@mirage/translations';
import { useCallback, useMemo } from 'react';
import { type ApplicationsMenuSelection } from './FiltersMenu';

type ApplicationFilterMenuSegmentProps = {
  activeApplications?: ActivityFeedApplication[];
  onSetActiveApplications: (applications?: ActivityFeedApplication[]) => void;
};

export const ApplicationFilterMenuSegment = ({
  activeApplications,
  onSetActiveApplications,
}: ApplicationFilterMenuSegmentProps) => {
  const { connectors } = useConnectors();

  const isActiveApplication = useCallback(
    (application: ActivityFeedApplication) => {
      // if we have no application filter selected at all (even not manually unchecked), we default to all applications
      if (activeApplications === undefined) {
        return true;
      }

      return activeApplications.includes(application);
    },
    [activeApplications],
  );

  const options = useMemo(() => {
    let results = connectors
      .filter(isSupportedActivityFeedConnector)
      .map((connector: Connector) => {
        const name = connector.id_attrs!.type! as ActivityFeedApplication;
        const label = connector.branding!.display_name!;
        const icon = connector.branding!.icon_src!;

        const isSelected = isActiveApplication(name);

        const connectorFilter = {
          key: name,
          label,
          icon: <ConnectorIcon connectorName={name} iconUrl={icon} size={16} />,
          selected: isSelected,
        };

        return connectorFilter;
      });

    results = sortDisplayFilters(results);

    results.push({
      key: 'stacks',
      label: i18n.t('stacks'),
      icon: <UIIcon src={StacksLine} size="small" />,
      selected: isActiveApplication('stacks'),
    });

    return results;
  }, [connectors, isActiveApplication]);

  const showSelectAll = options.some((option) => !option.selected);

  const allApplicationOptions = useMemo(
    () => options.map((option) => option.key),
    [options],
  );

  const selectAll = useCallback(() => {
    onSetActiveApplications(undefined);
  }, [onSetActiveApplications]);

  return (
    <Menu.Segment
      withLabel={
        <>
          {i18n.t('filter_by_app')}
          {showSelectAll && (
            <Button
              size="small"
              variant="transparent"
              onClick={selectAll}
              data-uxa-log={createUxaElementId(
                `applications_filter_select_all`,
                {
                  actionSurfaceComponent: 'activity_feed',
                  featureLine: 'activity_feed',
                },
              )}
            >
              {i18n.t('select_all')}
            </Button>
          )}
        </>
      }
    >
      {options.map((filter, index) => (
        <Menu.ActionItem
          key={index}
          value={
            {
              segmentKey: 'applications',
              application: filter.key,
              allApplicationOptions,
            } as ApplicationsMenuSelection
          }
          role="menuitemcheckbox"
          aria-checked={filter.selected}
          data-uxa-log={createUxaElementId(
            `applications_filter_${filter.key}`,
            {
              actionSurfaceComponent: 'activity_feed',
              featureLine: 'activity_feed',
            },
          )}
          withLeftAccessory={
            <Checkbox checked={filter.selected} readOnly tabIndex={-1} />
          }
          withRightAccessory={<>{filter.icon}</>}
        >
          {filter.label}
        </Menu.ActionItem>
      ))}
    </Menu.Segment>
  );
};
