import { getCachedOrFetchFeatureValue } from '@mirage/service-experimentation';
import { tagged } from '@mirage/service-logging';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import * as jobs from '@mirage/shared/util/jobs';
import isEqual from 'lodash/isEqual';

const logger = tagged('typeahead/scoring/weights');

const DEFAULT_WEIGHTS: typeahead.Weights = Object.freeze({
  titleMatchScore: 100,
  lastClickedScore: 0,
  frequentlyClickedScore: 0,
  lastBrowserViewedScore: 0,
  frequentlyBrowserViewedScore: 0,
  fileTypeScore: 0,
});

const ZERO_WEIGHTS: typeahead.Weights = Object.freeze({
  titleMatchScore: 0,
  lastClickedScore: 0,
  frequentlyClickedScore: 0,
  lastBrowserViewedScore: 0,
  frequentlyBrowserViewedScore: 0,
  fileTypeScore: 0,
});

/**
 * Manage current value of `weights` here, expose via accessor
 */

let weights: typeahead.Weights = DEFAULT_WEIGHTS;

export function getWeights(): typeahead.Weights {
  return weights;
}

/**
 * Periodic job
 *
 * NOTE: Growthbook caches feature flags for 15m so in practice weights will
 * update every ~20 minutes.
 */

const SYNC_WEIGHTS_JOB_NAME = 'service-typeahead-search/sync-weights';
const SYNC_WEIGHTS_INTERVAL = 5 * 60 * 1000; // 5 minutes

export function startSyncWeights() {
  jobs.register(
    SYNC_WEIGHTS_JOB_NAME,
    SYNC_WEIGHTS_INTERVAL,
    true,
    syncWeights,
  );
}

export function cancelSyncWeights() {
  jobs.unregister(SYNC_WEIGHTS_JOB_NAME);
}

/**
 * Logic to update `weights`
 */

async function syncWeights() {
  const weightsFromGrowthbook = await getWeightsFromGrowthbook();

  if (!weightsFromGrowthbook) {
    logger.debug(`[updateWeights] No weights from Growthbook`);
    return;
  }

  if (isEqual(weightsFromGrowthbook, weights)) {
    logger.debug(
      `[updateWeights] Weights didn't change`,
      weightsFromGrowthbook,
    );
    return;
  }

  logger.debug(`[updateWeights] Weights changed`, weightsFromGrowthbook);
  weights = weightsFromGrowthbook;
}

/**
 * Syncing weights from Growthbook, and handling potential misconfigurations
 *
 * We'll be lenient with Growthbook's `dash_typeahead_weights` JSON value to a
 * degree:
 *
 * - If Growthbook JSON is missing all expect, we'll refuse to update weights.
 * - If Growthbook JSON is missing some expected keys, but has other expected
 *   keys, we'll update weights but set missing keys to 0 weight. We'll log a
 *   warning if we see this.
 * - If Growthbook has all expected keys, we'll use them without logging
 *   anything.
 * - If Growthbook is missing a key, we won't revert back to DEFAULT_WEIGHTS.
 *   Instead, we'll use ""whatever we can" from the valid keys in Growthbook to
 *   construct the new weights.
 *
 * The reason for this leniency is that we don't want a slight Growthbook
 * misconfiguration (i.e. a "forgotten key") to revert the weights all the way
 * back to DEFAULT_WEIGHTS.
 *
 * We'll log when we see missing keys so we have a change to fix Growthbook
 * misconfigurations
 */

const WEIGHTS_FEATURE_FLAG = 'dash_typeahead_weights';

async function getWeightsFromGrowthbook(): Promise<
  typeahead.Weights | undefined
> {
  const flagValue = await getCachedOrFetchFeatureValue(WEIGHTS_FEATURE_FLAG);

  if (!flagValue) {
    logger.warn(
      `[getWeightsFromGrowthbook] Couldn't get feature flag value`,
      WEIGHTS_FEATURE_FLAG,
    );
    return;
  }

  if (typeof flagValue !== 'object') {
    logger.warn(
      `[getWeightsFromGrowthbook] Feature flag value is not a JSON object`,
      weights,
    );
    return;
  }

  const flagObject = flagValue as { [key: string]: number };

  // We have a sane JSON object from Growthbook, but it may be missing keys
  //
  // NOTE: If we keep reusing the "Growthbook gives JSON object" pattern, we
  // should port this validation into service-experimentation. Leaving here for
  // now.

  const keysExpected: typeahead.WeightKey[] = [...typeahead.WEIGHT_KEYS];
  const keysExpectedAndPresent: typeahead.WeightKey[] = [];
  const keysExpectedAndMissing: typeahead.WeightKey[] = [];
  for (const key of keysExpected) {
    if (key in flagObject && typeof flagObject[key] === 'number') {
      keysExpectedAndPresent.push(key);
    } else {
      keysExpectedAndMissing.push(key);
    }
  }

  if (keysExpectedAndPresent.length === 0) {
    logger.warn(
      `[getWeightsFromGrowthbook] Feature flag value is missing all expected keys`,
    );
    return;
  }

  // We have enough to proceed, but still log if we're missing expected keys

  if (keysExpectedAndMissing.length > 0) {
    logger.warn(
      `[getWeightsFromGrowthbook] Feature flag value is missing expected keys: ${keysExpectedAndMissing}. But we're able to proceed. Updating weights with these missing values set to 0. Check Growthbook to add these keys!`,
    );
  }

  const newWeights = { ...ZERO_WEIGHTS };
  for (const key of keysExpectedAndPresent) {
    newWeights[key] = flagObject[key];
  }

  return newWeights;
}
