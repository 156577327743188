import { IconButton } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { UIIcon } from '@dropbox/dig-icons';
import { CheckmarkLine, CloseLine } from '@dropbox/dig-icons/dist/mjs/assets';
import { AiStorageMini } from '@dropbox/dig-illustrations';
import { ComposeSourceRow } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/ComposeSourceRow';
import { SourceSearch } from '@mirage/mosaics/ComposeAssistant/components/compose-sources/SourcesSearch';
import { SourcesContentCache } from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import {
  ComposeSource,
  getSourceUUID,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';
import styles from './ComposeSources.module.css';

interface ComposeSourcesProps {
  sources: ComposeSource[];
  sourcesContentCache: SourcesContentCache;
  onAddSource: (source: ComposeSource) => void;
  onRemoveSource: (source: ComposeSource) => void;

  sourcesTitleLabel?: ReactNode;
  searchTitleLabel?: ReactNode;
  variant?: 'sources-before-search-input' | 'sources-after-search-input';
  hideEmptySources?: boolean;
  hasBorder?: boolean;
  hideSearchResultsLabel?: boolean;
}
export const ComposeSources = memo(
  ({
    sources,
    sourcesContentCache,
    onAddSource,
    onRemoveSource,
    sourcesTitleLabel,
    searchTitleLabel,
    variant = 'sources-after-search-input',
    hideEmptySources,
    hasBorder,
    hideSearchResultsLabel,
  }: ComposeSourcesProps) => {
    const currentSourcesList =
      sources.length === 0 && hideEmptySources ? undefined : (
        <>
          {sourcesTitleLabel}
          {sources.length === 0 ? (
            <div className={styles.SourcesEmpty}>
              <AiStorageMini width="45" height="45" altText="No sources icon" />
              <div>
                <Text size="medium" isBold tagName="div">
                  {i18n.t('compose_sources_empty_state_title')}
                </Text>
                <Text size="small" color="subtle" tagName="div">
                  {i18n.t('compose_sources_empty_state_subtitle')}
                </Text>
              </div>
            </div>
          ) : (
            <div
              className={classNames(styles.SourceRows, {
                [styles.SourceRowsBordered]: hasBorder,
              })}
            >
              {sources.map((source, i) => {
                const sourceUUID = getSourceUUID(source);
                if (!sourceUUID) {
                  return null;
                }
                return (
                  <ComposeSourceRow
                    key={sourceUUID || i}
                    source={source}
                    action={{
                      icon: <UIIcon src={CloseLine} />,
                      variant: 'small',
                      onClick: () => onRemoveSource(source),
                      label: i18n.t('compose_source_row_remove_button_label'),
                    }}
                    stateIcon={
                      sourcesContentCache[sourceUUID]?.state === 'loaded' && (
                        <IconButton
                          variant="filled"
                          shape="circular"
                          size="small"
                          inverse
                        >
                          <UIIcon src={CheckmarkLine} />
                        </IconButton>
                      )
                    }
                    loadState={sourcesContentCache[sourceUUID]?.state}
                  />
                );
              })}
            </div>
          )}
        </>
      );
    return variant === 'sources-after-search-input' ? (
      <div className={styles.ComposeSources}>
        {searchTitleLabel}
        <SourceSearch
          existingSources={sources}
          onAddSource={onAddSource}
          aboveSearchResults={currentSourcesList}
          aboveSearchResultsLabel={i18n.t('compose_added_sources_label')}
          hasBorder={hasBorder}
          hideSearchResultsLabel={hideSearchResultsLabel}
        />
      </div>
    ) : (
      <div className={styles.ComposeSources}>
        {currentSourcesList}
        {searchTitleLabel}
        <SourceSearch
          existingSources={sources}
          onAddSource={onAddSource}
          hasBorder={hasBorder}
          hideSearchResultsLabel={hideSearchResultsLabel}
        />
      </div>
    );
  },
);
ComposeSources.displayName = 'ComposeSources';

const Divider = memo(() => {
  return <div className={styles.Divider} />;
});
Divider.displayName = 'Divider';
