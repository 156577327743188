import { ml_capabilities } from '@dropbox/api-v2-client/types/dropbox_types';
import {
  ContentCacheLoaded,
  SourcesContentCache,
} from '@mirage/mosaics/ComposeAssistant/data/ComposeSourcesCache';
import { callLLMApi } from '@mirage/mosaics/ComposeAssistant/data/llm/llm-apis';
import {
  getCurrentDraftPromptMessage,
  getInitialPromptMessage,
  getSoucePromptMessage,
  getVoicePromptMessage,
  messageToPromptMessage,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-prompts';
import {
  getTools,
  handleToolCall,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-tools';
import {
  AssistantResponse,
  GetAssistantResponseParams,
} from '@mirage/mosaics/ComposeAssistant/data/llm/llm-types';
import {
  ComposeAssistantDraftConfig,
  ContentTypeOption,
} from '@mirage/shared/compose/compose-session';
import i18n from '@mirage/translations';

/**
 * Get LLM response for the current session. This may involve multiple onResponse calls.
 * Upon full completion (after 0 or more onResponse calls), the returned Promise will be resolved.
 */
export async function getAssistantResponse(
  params: GetAssistantResponseParams,
  onResponse: (response: AssistantResponse) => void,
): Promise<void> {
  const historyMessages: ml_capabilities.ChatMessage[] = [];
  for (const message of params.messagesHistory) {
    const historyMessage = messageToPromptMessage(message);
    if (historyMessage) {
      historyMessages.push(historyMessage);
    }
  }
  const initialPromptMessage = getInitialPromptMessage(params);
  const { sourcePromptMessage, indexedCachedSources } = getSoucePromptMessage(
    params.sourcesContents,
    !params.mustIncludeSourceContents,
  );
  const voicePromptMessage = getVoicePromptMessage(
    params.voiceID,
    params.voiceSourceContents,
  );
  const currentDraftPromptMessage = getCurrentDraftPromptMessage(params);

  const messages = [initialPromptMessage];
  if (voicePromptMessage) {
    messages.push(voicePromptMessage);
  }
  if (sourcePromptMessage) {
    messages.push(sourcePromptMessage);
  }
  messages.push(...historyMessages);
  if (currentDraftPromptMessage) {
    messages.push(currentDraftPromptMessage);
  }
  messages.push(messageToPromptMessage(params.newMessage)!);

  let isDone = false;
  while (!isDone) {
    const pendingMessages = await getAssistantResponseIteration(
      params,
      messages,
      indexedCachedSources,
      onResponse,
    );
    if (pendingMessages.length > 0) {
      messages.push(...pendingMessages);
      isDone = false;
    } else {
      isDone = true;
    }
  }
}

/**
 * Run single iteration of LLM API call + response handling.
 * Returns new messages that should be appended for the next iteration.
 */
async function getAssistantResponseIteration(
  params: GetAssistantResponseParams,
  messages: ml_capabilities.ChatMessage[],
  indexedCachedSources: Map<number, ContentCacheLoaded>,
  onResponse: (response: AssistantResponse) => void,
): Promise<ml_capabilities.ChatMessage[]> {
  const tools = getTools(params.mustIncludeSourceContents);
  const response = await callLLMApi({
    messages,
    tools,
    toolChoice: params.mustGenerateDraft ? 'required' : 'auto',
  });
  if (response.toolCalls.length > 0) {
    // TODO: handle multiple tool calls
    const toolCall = response.toolCalls[0];
    return handleToolCall(response, toolCall, indexedCachedSources, onResponse);
  } else {
    onResponse({
      type: 'message',
      responseText: response.responseText,
    });
    return [];
  }
}

export function getSelectionActionPromptString(
  actionType: 'rewrite' | 'make-longer' | 'make-shorter',
) {
  switch (actionType) {
    case 'make-longer':
      return i18n.t('compose_selection_action_make_longer');
    case 'make-shorter':
      return i18n.t('compose_selection_action_make_shorter');
    case 'rewrite':
      return i18n.t('compose_selection_action_rewrite');
    default:
      actionType satisfies never;
      throw new Error(`Unknown selection action type: ${actionType}`);
  }
}

export function getDraftConfigPrompt(
  draftConfig: ComposeAssistantDraftConfig,
  sourceContents: SourcesContentCache,
): string {
  const generatePrompt = draftConfig.contentType
    ? i18n.t('compose_draft_prompt_generate', {
        contentTypeString: getContentTypeString(draftConfig.contentType),
      })
    : i18n.t('compose_draft_prompt_generate_no_content_type');
  const hasSources = Object.values(sourceContents).length > 0;
  return `${generatePrompt}:
${draftConfig.contentDescription}
${
  hasSources
    ? `\n${i18n.t('compose_draft_prompt_generate_include_sources')}`
    : ''
}`;
}

function getContentTypeString(contentType: ContentTypeOption): string {
  switch (contentType) {
    case 'email':
      return i18n.t('compose_context_form_content_type_email');
    case 'message':
      return i18n.t('compose_context_form_content_type_message');
    case 'report':
      return i18n.t('compose_context_form_content_type_report');
    case 'proposal':
      return i18n.t('compose_context_form_content_type_proposal');
    case 'social_media_post':
      return i18n.t('compose_context_form_content_type_social_media_post');
    case 'meeting_agenda':
      return i18n.t('compose_context_form_content_type_meeting_agenda');
    default:
      contentType satisfies never;
      throw new Error(`Unknown content type: ${contentType}`);
  }
}
