import { DASH_WEBAPP, DASH_WEBAPP_STAGE } from '../urls';
import { ExtensionInfo } from './types';

export const APP_NAME = 'Dropbox Dash';
export const APP_DESCRIPTION =
  'Search tabs, bookmarks, and history with Dropbox Dash.';

export const GROWTHBOOK_API_KEY_PROD = 'sdk-CER3PbqP0ZQNOj3';
export const GROWTHBOOK_API_KEY_DEVBOX = 'sdk-BcTM4q0VykUceUSY';

export const WEB_HOST_LOCAL = 'http://localhost:3000/';
export const WEB_HOST_CANARY_AMPLIFY =
  'https://web-master.d7kgc1ikry9m3.amplifyapp.com/';
export const WEB_HOST_STAGING_AMPLIFY =
  'https://web-staging.d2unov8w0g44qf.amplifyapp.com/';

// Note: You might want to use WEB_HOST_PROD instead WEB_HOST_PROD_AMPLIFY.
export const WEB_HOST_PROD_AMPLIFY =
  'https://web-prod.d2unov8w0g44qf.amplifyapp.com/';

// FYI: `dash.ai` redirects to `www.dash.ai`. Ensure ends with trailing slash.
export const WEB_HOST_PROD =
  DASH_WEBAPP + (DASH_WEBAPP.endsWith('/') ? '' : '/');

export const WEB_HOST_STAGING =
  DASH_WEBAPP_STAGE + (DASH_WEBAPP_STAGE.endsWith('/') ? '' : '/');

export const BOLT_ORIGIN_PROD = 'https://bolt.dropbox.com';
export const BOLT_ORIGIN_DEVBOX_TMPL =
  'https://bolt-${USER}.dev.corp.dropbox.com/';

export const THUNDER_ORIGIN_PROD = 'https://thunder.dropbox.com';
export const THUNDER_ORIGIN_DEVBOX_TMPL =
  'https://thunder-${USER}.dev.corp.dropbox.com/';

// https://app.sprig.com/eDbhQSQ8Fqzg/integrations/installation/javascript-sdk
export const SPRIG_ENVIRONMENT_ID_PROD = 'eDbhQSQ8Fqzg';
export const SPRIG_ENVIRONMENT_ID_DEV = 'iKKof-9MonRr';

export type DEVBOX_URLS =
  | typeof BOLT_ORIGIN_DEVBOX_TMPL
  | typeof THUNDER_ORIGIN_DEVBOX_TMPL;

// replace instances of ${USER} with `user`
export const getDevboxUrl = (key: DEVBOX_URLS, user: string) => {
  return key.replace(/\${USER}/, user);
};

export const CHROME_BETA_EXTENSION_INFO: ExtensionInfo = {
  name: 'Beta',
  browser: 'chrome',
  id: 'aclbkdnphcdelflmgjgmgbahlfngidma',
};
export const CHROME_PILOT_EXTENSION_INFO: ExtensionInfo = {
  name: 'Pilot',
  browser: 'chrome',
  id: 'cnpnkghhleflgnmhbfoeagfaglcnnced',
};
export const CHROME_ALPHA_EXTENSION_INFO: ExtensionInfo = {
  name: 'Alpha',
  browser: 'chrome',
  id: 'ijagjkkodbflllmhhiojodofmlimdmkm',
};
export const CHROME_DEVPROD_EXTENSION_INFO: ExtensionInfo = {
  name: 'DevProd',
  browser: 'chrome',
  id: 'kfhbahefocaeoacbamjblglcjbgmmhaj',
};
export const CHROME_DEVBOX_EXTENSION_INFO: ExtensionInfo = {
  name: 'Devbox',
  browser: 'chrome',
  id: 'flmocddocmoejebhmfckhiefghgcohpc',
};

export const EDGE_BETA_EXTENSION_INFO: ExtensionInfo = {
  name: 'Beta',
  browser: 'edge',
  id: 'kblhiphkmkbpcnokbgcnlgcnahnddedl',
};
export const EDGE_PILOT_EXTENSION_INFO: ExtensionInfo = {
  name: 'Pilot',
  browser: 'edge',
  id: 'ohcndfcogdbionmhifokdndcghdjbpdi',
};
// The Alpha extension is never released to the app store, so we can share the
// extension id with devprod.
export const EDGE_ALPHA_OR_DEVPROD_EXTENSION_INFO: ExtensionInfo = {
  name: 'Alpha/DevProd',
  browser: 'edge',
  id: 'kfhbahefocaeoacbamjblglcjbgmmhaj',
};
export const EDGE_DEVBOX_EXTENSION_INFO: ExtensionInfo = {
  name: 'Devbox',
  browser: 'edge',
  id: 'flmocddocmoejebhmfckhiefghgcohpc',
};
