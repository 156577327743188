import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  isToday,
  isYesterday,
} from 'date-fns';

/**
 * 1 - 60 sec: Updated seconds ago
 * 0 - 1 hours: Updated 15 minutes ago
 * 1 - 12 hours: Updated 10 hours ago
 * Same calendar day: Updated 14 hours ago
 * Previous calendar day: Updated yesterday
 * 2 - 10 calendar days: Updated 3 days ago
 * 10+ days: Last updated on [Abbreviated month, date, year]
 */

export function getTimeAgoString(
  pastDate: number,
  capitalize: boolean = false,
  abbreviate: boolean = false,
) {
  try {
    const currentDate = new Date();
    const minutesDifference = differenceInMinutes(currentDate, pastDate, {
      roundingMethod: 'floor',
    });
    const hoursDifference = differenceInHours(currentDate, pastDate);
    const daysDifference = differenceInCalendarDays(currentDate, pastDate);

    let timeAgo;
    if (minutesDifference <= 1) {
      timeAgo = 'seconds ago';
    } else if (minutesDifference <= 60) {
      timeAgo = abbreviate
        ? `${minutesDifference}m ago`
        : `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
    } else if (hoursDifference <= 12 || isToday(pastDate)) {
      timeAgo = abbreviate
        ? `${hoursDifference}h ago`
        : `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    } else if (isYesterday(pastDate)) {
      timeAgo = 'yesterday';
    } else if (daysDifference <= 10) {
      timeAgo = abbreviate
        ? `${daysDifference}d ago`
        : `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    } else {
      timeAgo = `${format(pastDate, 'MMM d, yyyy')}`;
    }

    if (timeAgo) {
      return capitalize ? timeAgo[0].toUpperCase() + timeAgo.slice(1) : timeAgo;
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error parsing date for time ago string.');
  }
  return '';
}
