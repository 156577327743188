import { ServiceId } from '@mirage/discovery/id';
import * as services from '@mirage/discovery/services';
import { tagged } from '@mirage/service-logging';
import { ComposeSession } from '@mirage/shared/compose/compose-session';
import { ComposeVoice } from '@mirage/shared/compose/compose-voice';
import { KVStorage } from '@mirage/storage';

import type { Observable } from 'rxjs';

export type Service = ReturnType<typeof composeService>;

interface LogoutServiceContract {
  listenForLogout(service: string): Observable<boolean>;
}

export interface ComposeSessionsStorage {
  sessions: ComposeSession[];
}

export interface ComposeVoicesStorage {
  voices: ComposeVoice[];
}

export enum StorageKey {
  Voices = 'voices',
  Sessions = 'sessions',
}

export default function composeService(
  sessionsStore: KVStorage<ComposeSessionsStorage>,
  voicesStore: KVStorage<ComposeVoicesStorage>,
  logoutService: LogoutServiceContract,
) {
  const logger = tagged('compose-service');

  async function loadComposeSessions(): Promise<ComposeSession[]> {
    const sessions = await sessionsStore.get(StorageKey.Sessions);
    logger.log('loaded ComposeSessions', sessions?.length);
    return sessions || [];
  }

  async function saveComposeSessions(sessions: ComposeSession[]) {
    await sessionsStore.set(StorageKey.Sessions, sessions);
    logger.log('saved ComposeSessions', sessions.length);
  }

  async function loadComposeVoices(): Promise<ComposeVoice[]> {
    const voices = (await voicesStore.get(StorageKey.Voices)) || [];
    logger.log('loaded ComposeVoices', voices?.length);
    return voices || [];
  }

  async function saveComposeVoices(voices: ComposeVoice[]) {
    await voicesStore.set(StorageKey.Voices, voices);
    logger.log('saved ComposeVoices', voices.length);
  }

  async function tearDown() {
    await sessionsStore.clear();
    await voicesStore.clear();
  }

  logoutService.listenForLogout(ServiceId.COMPOSE).subscribe(() => {
    return tearDown();
  });

  return services.provide(
    ServiceId.COMPOSE,
    {
      loadComposeSessions,
      saveComposeSessions,
      loadComposeVoices,
      saveComposeVoices,
      tearDown,
    },
    [ServiceId.DBX_API],
  );
}
