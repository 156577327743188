import { dash_feed, stacks } from '@dropbox/api-v2-client';
import { Connector } from '@mirage/service-dbx-api/service';
import { ConnectorName } from '@mirage/shared/connectors';
import { DEFAULT_FILTERS } from '../service';
import { ActivityFeedFilters } from '../types';

const getActivityObjectType = (activity: dash_feed.ActivityItem) => {
  return activity.object?.details?.object_details?.['.tag'];
};

export const getStackActivityObject = (activity: dash_feed.ActivityItem) => {
  const type = activity.object?.details?.object_details?.['.tag'];
  if (type === 'full_stack') {
    return activity.object?.details?.object_details as stacks.Stack;
  }
};

export const isStackActivityObject = (
  activity: dash_feed.ActivityItem,
): boolean => {
  const type = getActivityObjectType(activity);
  return type === 'full_stack';
};

// Returns true if activity feed filters are set, false otherwise
export const areFiltersSet = (filters: ActivityFeedFilters): boolean => {
  if (filters.isOnlyMine != DEFAULT_FILTERS.isOnlyMine) {
    return true;
  }

  if (filters.applications != DEFAULT_FILTERS.applications) {
    return true;
  }

  if (filters.actor != DEFAULT_FILTERS.actor) {
    return true;
  }

  return false;
};

const SUPPORTED_FEED_CONNECTORS: ConnectorName[] = [
  'dropbox',
  'confluence_cloud',
  'googledrive',
  'sharepoint',
  'onedrive',
];

export const isSupportedActivityFeedConnector = (connector: Connector) => {
  const isValid =
    connector.id_attrs?.type &&
    connector.branding?.display_name &&
    connector.branding?.icon_src;

  if (!isValid) {
    return false;
  }

  const isSupported = SUPPORTED_FEED_CONNECTORS.includes(
    (connector.id_attrs?.type || '') as ConnectorName,
  );

  return isSupported;
};
