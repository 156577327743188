import { stacks } from '@dropbox/api-v2-client';
import { Metadata } from '@dropbox/dash-component-library';
import { Button } from '@dropbox/dig-components/buttons';
import { Text } from '@dropbox/dig-components/typography';
import { Box } from '@dropbox/dig-foundations';
import { useAllStacksAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { ModuleCard } from '@mirage/dash-component-library/components/DashCard/ModuleCard';
import { DashStackIcon } from '@mirage/dash-component-library/components/DashStackIcon';
import {
  TileCard,
  TileCardGrid,
} from '@mirage/dash-component-library/components/TileCard';
import { StacksTabFilterButton } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/StacksTabFilterButton';
import { useStacksTabFilterPreference } from '@mirage/mosaics/StartPage/TabbedModule/TabbedPanels/useStacksTabFilterPreference';
import useDropboxAccount from '@mirage/service-auth/useDropboxAccount';
import {
  StackSortDirection,
  StackSortOption,
} from '@mirage/service-settings/service/types';
import { stackGetShareId } from '@mirage/service-stacks/service/utils';
import { privacyModeEnabledAtom } from '@mirage/shared/atoms/privacyMode';
import { Blur } from '@mirage/shared/blur/Blur';
import { convertStackToWorkingSetCardData } from '@mirage/stacks/AllStacksGrid/AllStacks';
import { useDynamicExpandableContent } from '@mirage/stacks/FullScreenStack/useDynamicExpandableContent';
import {
  useSortedStacks,
  useSortedStacksWithItemsBySortOption,
} from '@mirage/stacks/hooks';
import i18n from '@mirage/translations';
import { WorkingSetCardData } from '@mirage/working-set';
import classnames from 'classnames';
import { useAtomValue } from 'jotai/index';
import * as React from 'react';
import { forwardRef, Ref, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import styles from './StacksTabPanel.module.css';

const MAX_VISIBLE_COUNT = 8;

export const StacksTabPanel = ({
  headerButtonSlot,
  isActive,
  maxCardsPerRow,
}: {
  headerButtonSlot: Element | null;
  isActive: boolean;
  maxCardsPerRow: number;
}) => {
  const currentAccount = useDropboxAccount();
  const augustRevision = useAllStacksAugustRevisionEnabled();
  const privacyModeEnabled = useAtomValue(privacyModeEnabledAtom);
  const navigate = useNavigate();
  const indexOfSeeAllCard = maxCardsPerRow * 2 - 1;

  const sortedStacks = useSortedStacks();
  const { startPageStackFilterPreference } = useStacksTabFilterPreference();
  const sortPreferenceByRecentlyViewed = {
    option: StackSortOption.VIEWED,
    direction: StackSortDirection.DESC,
  };
  const { stacks: filteredSortedStacks } = useSortedStacksWithItemsBySortOption(
    sortPreferenceByRecentlyViewed,
    startPageStackFilterPreference,
  );

  const onSelectStack = React.useCallback(
    (stack: stacks.Stack) => {
      // TODO AYC: add logging DASHWEB-4415
      navigate(`/stacks/${stackGetShareId(stack)}`);
    },
    [navigate],
  );

  const convertStacksToWorkingSetCardData = useCallback(
    (stacks: stacks.Stack[]) => {
      return stacks.map((stack: stacks.Stack) => {
        return convertStackToWorkingSetCardData(
          stack,
          stacks,
          onSelectStack,
          {}, // don't need stackItemsMap yet, since we're not displaying nested items
          undefined, // not hooking in menu open logic
          currentAccount,
          augustRevision,
          undefined, // not hoooking in menu open logic
        );
      });
    },
    [augustRevision, currentAccount],
  );

  const stackList = React.useMemo(() => {
    return convertStacksToWorkingSetCardData(filteredSortedStacks ?? []);
  }, [filteredSortedStacks]);
  const isLoading = sortedStacks === undefined;
  const { expanded, showMore, setExpanded, wrapperRef, numVisible } =
    useDynamicExpandableContent(`div.${styles.selectorStacksCard}`, isActive);

  const withAccessory: React.ReactNode[] = [
    <StacksTabFilterButton key="filter-button" />,
  ];
  if (showMore) {
    withAccessory.push(
      <Box marginLeft="Micro Medium">
        <Button
          variant="transparent"
          onClick={() => setExpanded((prev) => !prev)}
          key="toggle-stacks"
          size="small"
        >
          {expanded ? i18n.t('show_less_answer') : i18n.t('show_more_answer')}
        </Button>
      </Box>,
    );
  }

  return (
    <Box
      style={{
        display: isActive ? 'block' : 'none',
        position: 'relative',
      }}
    >
      {headerButtonSlot && createPortal(withAccessory, headerButtonSlot)}
      <Blur isBlurred={privacyModeEnabled} blurAmount="high">
        <StacksTileCardGrid
          expanded={expanded}
          isLoading={isLoading}
          numVisible={numVisible}
          stackCardList={stackList}
          ref={wrapperRef}
          indexOfSeeAllCard={indexOfSeeAllCard}
        />
      </Blur>
    </Box>
  );
};

const StacksTileCardGrid = forwardRef(
  (
    {
      expanded,
      stackCardList,
      isLoading,
      numVisible,
      indexOfSeeAllCard,
    }: {
      expanded: boolean;
      stackCardList: WorkingSetCardData[];
      isLoading: boolean;
      numVisible: number;
      indexOfSeeAllCard: number;
    },
    ref?: Ref<HTMLDivElement>,
  ) => {
    return (
      <TileCardGrid
        expanded={expanded}
        tileCardClassName={styles.selectorStacksCard}
        isLoading={isLoading}
        loadingTileCount={MAX_VISIBLE_COUNT}
        ref={ref}
        numVisible={numVisible}
      >
        {stackCardList.map((stackData, i) => {
          return i !== indexOfSeeAllCard ? (
            <StackCard
              key={stackData.id}
              stackData={stackData}
              hidden={i >= numVisible}
              selectorClassname={styles.selectorStacksCard}
            />
          ) : (
            <SeeAllStackCard
              key="see-all-card"
              selectorClassname={styles.selectorStacksCard}
              numTotalStacks={stackCardList.length}
              hidden={i >= numVisible}
            />
          );
        })}
      </TileCardGrid>
    );
  },
);
StacksTileCardGrid.displayName = 'StacksTileCardGrid';

type StackCardProps = {
  stackData: WorkingSetCardData;
  selectorClassname: string;
  hidden: boolean;
};
export const StackCard = ({
  stackData,
  selectorClassname,
  hidden,
}: StackCardProps) => {
  return (
    <TileCard
      className={selectorClassname}
      hidden={hidden}
      onClick={stackData.onClickCard}
      visibleUrl={`/stacks/${stackData.id}`}
      titleLinesBeforeTruncate={2}
      icon={
        <DashStackIcon
          size="medium"
          colorIndex={stackData.colorIndex}
          emoji={stackData.emoji}
        />
      }
      title={stackData.title}
      withInteractiveAccessory
      metadata={
        <Metadata size="small" withDividers="blank">
          {/* TODO update this so it matches the final designs -- ticketed */}
          {/*  TODO Q. will this meta data match what we have in Pinned Stack section on the stacks page? -> NO*/}
          <Metadata.Item>
            {stackData.getMembersComponent('xsmall')}
          </Metadata.Item>
        </Metadata>
      }
    />
  );
};

const SeeAllStackCard = ({
  selectorClassname,
  numTotalStacks,
  hidden,
}: {
  selectorClassname: string;
  numTotalStacks: number;
  hidden: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <ModuleCard
      className={classnames(styles.card, selectorClassname, {
        [styles.hidden]: hidden,
      })}
      breakout={'xsmall'}
      onClick={() => navigate('/stacks')}
    >
      <>
        <Text isBold variant="label" size="medium">
          {i18n.t('see_all')}
        </Text>
        <Text variant="paragraph" size="medium">
          {i18n.t('count_stacks', { count: numTotalStacks })}
        </Text>
      </>
    </ModuleCard>
  );
};
