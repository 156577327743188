import { tagged } from '@mirage/service-logging';
import {
  AuthServiceContract,
  LoginData,
  LoginSyncMessage,
} from '@mirage/service-login-sync-v1/service/types';
import { nonNil } from '@mirage/shared/util/tiny-utils';

const logger = tagged('handleLoginSyncMessage');

export async function handleLoginSyncMessage(
  message: LoginSyncMessage,
  authService: AuthServiceContract,
  loginUsingAuthData: (loginData: LoginData) => void | Promise<void>,
  publishLoginState: (
    LoginSyncMessage: LoginSyncMessage,
  ) => Promise<LoginSyncMessage | undefined>,
  logout: () => Promise<void>,
): Promise<LoginSyncMessage | undefined> {
  logger.info(`Handling incoming message.type=${message.type}`);

  // Handle received message.
  switch (message.type) {
    case 'login':
    case 'loggedIn': {
      const authData = await authService.getAuthenticationData();

      if (authData?.accessToken) {
        const account = nonNil(
          await authService.getCurrentAccount(false),
          'account',
        );

        if (account.email === message.email) {
          logger.info(`Already logged in to same account (do nothing)`);
        } else {
          logger.info(
            `Peer logged in with different email => login to same account`,
          );

          await logout(); // clear existing data
          await loginUsingAuthData(message);
        }
      } else {
        logger.info(`Auto-login user from received credentials!`);
        await loginUsingAuthData(message);
      }
      break;
    }

    case 'logout':
    case 'loggedOut': {
      const authData = await authService.getAuthenticationData();

      if (authData?.accessToken) {
        logger.info(`Auto-logout user as peer logged out`);
        await logout();
      } else {
        logger.info(`User is already logged out (do nothing)`);
      }
      break;
    }

    case 'requestLogin': {
      const authData = await authService.getAuthenticationData();

      if (authData) {
        const account = await authService.getCurrentAccount(false);

        if (account) {
          logger.info(`Sending login tokens back to requestor`);

          // Note that we will both return the message and publish it to all
          // subscribers, so the sender has the option on deciding how to
          // handle the response (either using inline await or using a
          // listener callback).
          return publishLoginState({
            type: 'loggedIn',
            email: account.email,
            ...authData,
          });
        } else {
          logger.info(`Can't send login tokens to requestor`);
        }
      } else {
        logger.info(`Can't auto-login as app is logged out`);
        return publishLoginState({ type: 'loggedOut' });
      }
      break;
    }

    default:
      message satisfies never;
      throw new Error(`Unknown message type: ${JSON.stringify(message)}`);
  }
}
