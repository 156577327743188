import { ClickOutside } from '@dropbox/dig-components/click_outside';
import { useMirageAnalyticsContext } from '@mirage/analytics/AnalyticsProvider';
import { SessionStartReason } from '@mirage/analytics/events/enums/session_start_reason';
import { PAP_Click_DashSearchBar } from '@mirage/analytics/events/types/click_dash_search_bar';
import { PAP_Close_DashSearchBar } from '@mirage/analytics/events/types/close_dash_search_bar';
import { PAP_Shown_DashNoResults } from '@mirage/analytics/events/types/shown_dash_no_results';
import {
  createUxaElementId,
  dispatchElementClicked,
} from '@mirage/analytics/uxa';
import { useIsStartPageAugustRevisionEnabled } from '@mirage/august-revision-hook';
import { ChatEntryPoint } from '@mirage/conversations/types';
import { OpenTypeaheadAtom } from '@mirage/growth/onboarding/getting-started-checklist/useOnboardingChecklist';
import { DebugCard } from '@mirage/mosaics/DebugCard';
import { launchSelectedMainContentItem } from '@mirage/mosaics/GlobalNav/KeyboardNavigation';
import { useKeyboardNavigation } from '@mirage/mosaics/GlobalNav/useKeyboardNavigation';
import {
  TypeaheadResult,
  useConvertToTypeaheadResults,
} from '@mirage/mosaics/SearchBarWithTypeahead/useConvertToTypeaheadResults';
import { useConnectorsUI } from '@mirage/mosaics/SettingsPage/useConnectorsUI';
import { SearchHeader } from '@mirage/search';
import { TypeaheadOverlay } from '@mirage/search/SearchResults';
import { useAccountIsDropboxer } from '@mirage/service-auth/useDropboxAccount';
import { EnvCtx } from '@mirage/service-environment-context/global-env-ctx';
import { namespace } from '@mirage/service-operational-metrics';
import { typeahead } from '@mirage/service-typeahead-search/service/types';
import useAMISSearchResultLogging from '@mirage/shared/hooks/useAMISSearchResultLogging';
import { calculatePapPositions } from '@mirage/shared/hooks/useDashSearchResultLogger';
import { mouseActivityAtom } from '@mirage/shared/hooks/useInitDetectMouseActivity';
import { preventBubble } from '@mirage/shared/hotkeys';
import { registerHotkeys } from '@mirage/shared/hotkeys/registerHotkeys';
import {
  captureTypeaheadClickMetrics,
  captureTypeaheadImpressionMetrics,
} from '@mirage/shared/operational-metrics/operational-metrics';
import { useIsMobileSizeForSidebar } from '@mirage/shared/responsive/mobile';
import { SEARCH_DASH_UUID } from '@mirage/shared/search/cache-result';
import { KeyCodes } from '@mirage/shared/util/constants';
import { useShowStackChooserModal } from '@mirage/stacks/StackChooser/StackChooser';
import i18n from '@mirage/translations';
import classNames from 'classnames';
import { useAtom, useAtomValue } from 'jotai';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFlyoutPanel } from '../FlyoutPanel/useFlyoutPanel';
import { globalNavIsCollapsedAtom } from '../GlobalNav/atoms';
import { SummaryQnAEnum } from '../SummaryQnaPanel/utils';
import styles from './SearchBarWithTypeahead.module.css';
import { getAddToStackParams } from './util/util';

import type { stacks } from '@dropbox/api-v2-client';
import type { useTypeaheadSearch } from '@mirage/service-typeahead-search/hooks/useTypeaheadSearch';
import type { Handler, Handlers } from '@mirage/shared/hotkeys';
import type { SearchFilter } from '@mirage/shared/search/search-filters';

type UseTypeaheadSearch = ReturnType<typeof useTypeaheadSearch>;

// strip out all non-results for analytics reporting
const getRealResults = (results: (TypeaheadResult | undefined)[]) => {
  return results.filter(
    (result): result is TypeaheadResult =>
      result !== undefined &&
      ![
        typeahead.ResultType.SuggestedQuery,
        typeahead.ResultType.PreviousQuery,
      ].includes(result.scoredResult.type),
  );
};

type Props = {
  // From useTypeaheadSearch hook
  typeaheadQuery: UseTypeaheadSearch['typeaheadQuery'];
  setTypeaheadQuery: UseTypeaheadSearch['setTypeaheadQuery'];
  canShowTypeahead: UseTypeaheadSearch['canShowTypeahead'];
  setCanShowTypeahead: UseTypeaheadSearch['setCanShowTypeahead'];
  typeaheadResults: UseTypeaheadSearch['typeaheadResults'];
  activationType?: UseTypeaheadSearch['activationType'];
  performPreLaunchSearch: UseTypeaheadSearch['performPreLaunchSearch'];

  // Typeahead launch handlers
  removePreviousQuery: (result: typeahead.ScoredPreviousQuery) => void;
  onSearchSubmit: (query: string, filters?: SearchFilter[]) => void;
  papLogResultOpen: UseTypeaheadSearch['papLogResultOpen'];
  papLogResultCopy: UseTypeaheadSearch['papLogResultCopy'];
  papLogResultShown: UseTypeaheadSearch['papLogResultShown'];
  papLogAddToStack: UseTypeaheadSearch['papLogAddToStack'];
  shouldFocusOnRender?: boolean;
  fullWidth?: boolean;
  augustRevisionEnabled?: boolean;
  showBackground?: boolean;
  pageScrolled?: boolean;
};

const metrics = namespace('search-results');

export default function SearchBarWithTypeahead({
  canShowTypeahead,
  setCanShowTypeahead,
  typeaheadQuery,
  setTypeaheadQuery,
  typeaheadResults,
  removePreviousQuery,
  onSearchSubmit,
  papLogResultOpen,
  papLogResultCopy,
  papLogResultShown,
  papLogAddToStack,
  shouldFocusOnRender = false,
  activationType,
  performPreLaunchSearch,
  fullWidth = false,
  augustRevisionEnabled = false,
  showBackground = false,
  pageScrolled = false,
}: Props) {
  const searchHeaderRef = useRef<HTMLDivElement | null>(null);
  const searchHeaderInputRef = useRef<HTMLInputElement>(null);
  const platform = EnvCtx.platform;
  const navigate = useNavigate();

  const desktopExperience = EnvCtx.surface === 'desktop';

  // When the mobile left nav is open, keep the typeahead dropdown closed.
  // Otherwise the typeahead dropdown (with a lower z-index) will show on top
  // of the left nav (with a higher z-index).
  const [openedTypeahead, setOpenedTypeahead] = useState(false);
  const [shouldSearchBeBoosted, setShouldSearchBeBoosted] = useState(false);
  const isCollapsed = useAtomValue(globalNavIsCollapsedAtom);
  const isMobileSize = useIsMobileSizeForSidebar(); // for sidebar state only
  const { searchSessionManager, reportPapEvent } = useMirageAnalyticsContext();
  const [openTypeaheadListener, setOpenTypeaheadListener] =
    useAtom(OpenTypeaheadAtom);
  const showStackChooserModal = useShowStackChooserModal(navigate);
  const { summarize, closeFlyoutPanel } = useFlyoutPanel();

  const {
    moveFocusToResults,
    resetSelectedItem: resetSelectedMainContentItem,
    userHasNotInputKey,
    setUserHasNotInputKey,
  } = useKeyboardNavigation();

  const { pathname } = useLocation();
  const isAugustStartPageEnabled = useIsStartPageAugustRevisionEnabled();
  const { account, isDropboxer } = useAccountIsDropboxer();
  const {
    logTypeaheadResultShown: logAMISResultShown,
    logTypeaheadResultLaunched: logAMISResultLaunched,
  } = useAMISSearchResultLogging(EnvCtx, account, isDropboxer || false);
  const { openConnectors } = useConnectorsUI();

  useEffect(() => {
    if (canShowTypeahead) {
      setOpenedTypeahead(true);
    }
  }, [canShowTypeahead, setOpenedTypeahead]);

  useEffect(() => {
    if (!canShowTypeahead && openedTypeahead) {
      reportPapEvent(
        PAP_Close_DashSearchBar({
          actionSurfaceComponent: 'search_bar',
          featureLine: 'search',
          searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
        }),
      );
    }
  }, [canShowTypeahead, openedTypeahead, reportPapEvent, searchSessionManager]);

  useEffect(() => {
    if (canShowTypeahead && isMobileSize && !isCollapsed) {
      setCanShowTypeahead(false);
    }
    // TODO: Fix up the deps properly.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canShowTypeahead, isMobileSize, isCollapsed]);

  // close flyout panel when typeahead is opened
  useEffect(() => {
    if (canShowTypeahead) {
      closeFlyoutPanel();
    }
  }, [canShowTypeahead, closeFlyoutPanel]);

  const extendSearchSession = useCallback(
    (sessionStartReason: SessionStartReason | undefined, query: string) => {
      searchSessionManager.extendOrCreateSession(sessionStartReason);

      if (query !== '') {
        // If a query exists, set has_query to true
        // If a query does not exist, leave it as true for the remainder of the session
        searchSessionManager.updateProperties({
          hasQuery: true,
        });
      }
    },
    [searchSessionManager],
  );

  // handler to delegate to main search using prior or suggested query as input to main search
  const handleTaggedSearchSubmit = useCallback(
    (
      result: typeahead.ScoredPreviousQuery | typeahead.ScoredSuggestedQuery,
      isDefaultSearch: boolean,
    ) => {
      searchSessionManager.updateProperties({
        actionSurfaceComponent: 'search_dropdown',
      });
      const queryToSearch =
        typeaheadQuery && result.type === typeahead.ResultType.SuggestedQuery
          ? typeaheadQuery
          : result.result.query;

      setTypeaheadQuery(queryToSearch, !isDefaultSearch);
      onSearchSubmit(queryToSearch);
      extendSearchSession('select_typeahead_result', typeaheadQuery);
      searchHeaderInputRef.current?.focus();
    },
    [
      extendSearchSession,
      onSearchSubmit,
      searchSessionManager,
      setTypeaheadQuery,
      typeaheadQuery,
    ],
  );

  const handleSearchBarClick = () => {
    reportPapEvent(
      PAP_Click_DashSearchBar({
        actionSurfaceComponent: 'search_bar',
        featureLine: 'search',
        searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
      }),
    );
    openTypeahead(typeaheadQuery);
  };

  const onInteractedWithResult = useCallback(
    (result: typeahead.ScoredResult) => {
      searchSessionManager.updateProperties({
        actionSurfaceComponent: 'search_dropdown',
      });
      extendSearchSession('select_typeahead_result', typeaheadQuery);
      if ('id3p' in result.result) logAMISResultLaunched(result.result);
    },
    [
      extendSearchSession,
      logAMISResultLaunched,
      searchSessionManager,
      typeaheadQuery,
    ],
  );

  /**
   * Typeahead result mgmt
   */

  const convertScoredResultsToTypeaheadResults = useConvertToTypeaheadResults({
    typeaheadQuery,
    setTypeaheadQuery,
    shouldSearchBeBoosted,
    handleSearchSubmit: handleTaggedSearchSubmit,
    removePreviousQuery,
    onInteractedWithResult,
    papLogResultOpen,
    papLogResultCopy,
  });

  // Sort the typeaheadResults array to prioritize items with priority
  const searchResults =
    convertScoredResultsToTypeaheadResults(typeaheadResults);

  // strip out all non-results for analytics reporting
  const searchResultsOnlyActualResults = getRealResults(searchResults);
  const resultHash = hasResultsChanged(searchResultsOnlyActualResults);

  // report a pap event whenever we don't have any typeahead results for a query
  useEffect(() => {
    if (
      canShowTypeahead &&
      typeaheadQuery.length > 0 &&
      searchResultsOnlyActualResults.length === 0
    ) {
      getMostUpToDateTypeaheadResults((results) => {
        const realResults = getRealResults(results);
        if (realResults.length === 0) {
          reportPapEvent(
            PAP_Shown_DashNoResults({
              searchSessionId: searchSessionManager.getSessionIdOrUndefined(),
              dashActionSurface: 'search_typeahead',
              featureLine: 'search',
            }),
          );
        }
      });
    }

    // we want to carefully control the useEffect dep array here to use the hasResultsChanged logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultHash, typeaheadQuery.length, canShowTypeahead]);

  /**
   * Keyboard navigation and handlers
   */
  const isMouseActive = useAtomValue(mouseActivityAtom);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [hoveredIdx, setHoveredIdx] = useState<number | null>(null);

  useEffect(() => {
    setSelectedIdx(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasResultsChanged(searchResults)]);

  useEffect(() => {
    if (canShowTypeahead) return;
    setSelectedIdx(0);
  }, [canShowTypeahead]);

  const moveUp = useCallback<Handler>(
    (e) => {
      if (!canShowTypeahead) return;
      preventBubble(e);
      setSelectedIdx((prevIdx) => handlePrev(prevIdx, searchResults.length));
    },
    [canShowTypeahead, setSelectedIdx, searchResults.length],
  );

  const moveDown = useCallback<Handler>(
    (e) => {
      if (!canShowTypeahead || !typeaheadResults.length) {
        preventBubble(e);
        moveFocusToResults();
        return;
      }
      setSelectedIdx((prevIdx) => handleNext(prevIdx, searchResults.length));
    },
    [
      canShowTypeahead,
      setSelectedIdx,
      searchResults.length,
      moveFocusToResults,
      typeaheadResults.length,
    ],
  );

  /**
   * Does a fresh search with the most up to date version of the query, and
   * waits to call the callback until all sources have returned from the search
   */
  const getMostUpToDateTypeaheadResults = useCallback(
    // CAUTION: Returning (TypeaheadResult | undefined)[] because sentry is alerting that there are undefined items in the array for some reason
    // Keep these types as-is until we can figure out why this is happening
    (callback: (typeaheadResults: (TypeaheadResult | undefined)[]) => void) => {
      performPreLaunchSearch(typeaheadQuery, (finalResults) => {
        const typeaheadResults =
          convertScoredResultsToTypeaheadResults(finalResults);
        callback(typeaheadResults);
      });
    },
    [
      convertScoredResultsToTypeaheadResults,
      performPreLaunchSearch,
      typeaheadQuery,
    ],
  );

  const launchResultKeyboardHandler = useCallback<Handler>(() => {
    if (!canShowTypeahead) return;

    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const result = upToDateTypeaheadResults[selectedIdx];
      result?.launchResult?.('enter');
      // simulate uxa click for keyboard actions
      dispatchElementClicked(
        createUxaElementId(
          result?.uuid === SEARCH_DASH_UUID
            ? 'default_typeahead_result_row'
            : 'typeahead_result_row',
          {
            actionSurface: 'search_typeahead',
            actionSurfaceComponent: 'search_dropdown',
            featureLine: 'search',
          },
        ),
      );

      if (result) {
        captureTypeaheadClickMetrics(metrics, result, selectedIdx + 1);
      }

      setCanShowTypeahead(false);
    });
  }, [
    getMostUpToDateTypeaheadResults,
    canShowTypeahead,
    selectedIdx,
    setCanShowTypeahead,
  ]);

  const summarizeKeyboardHandler = useCallback<Handler>(() => {
    if (!canShowTypeahead) return;

    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const result = upToDateTypeaheadResults[selectedIdx];
      if (
        !result ||
        !result.summarizable ||
        result.summarizable !== 'yes_summarizable'
      )
        return;

      const { resultPosition, resultPositionNoCta } = calculatePapPositions(
        upToDateTypeaheadResults,
        (r) => r?.uuid === result.uuid,
      );

      summarize(
        result,
        SummaryQnAEnum.TYPEAHEAD,
        ChatEntryPoint.search_bar_inline,
        resultPosition,
        resultPositionNoCta,
      );

      // simulate uxa click for keyboard actions
      dispatchElementClicked(
        createUxaElementId(
          result?.uuid === SEARCH_DASH_UUID
            ? 'default_typeahead_result_row'
            : 'typeahead_result_row',
          {
            actionSurface: 'search_typeahead',
            actionSurfaceComponent: 'search_dropdown',
            featureLine: 'search',
          },
        ),
      );

      if (result) {
        captureTypeaheadClickMetrics(metrics, result, selectedIdx + 1);
      }

      setCanShowTypeahead(false);
    });
  }, [
    getMostUpToDateTypeaheadResults,
    canShowTypeahead,
    selectedIdx,
    setCanShowTypeahead,
  ]);

  const launchResultClickHandler = useCallback(
    (clickedIdx: number) => {
      if (!canShowTypeahead) return;

      getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
        const result = upToDateTypeaheadResults[clickedIdx];
        result?.launchResult?.('click');

        if (result) {
          captureTypeaheadClickMetrics(metrics, result, clickedIdx + 1);
        }

        setCanShowTypeahead(false);
      });
    },
    [getMostUpToDateTypeaheadResults, canShowTypeahead, setCanShowTypeahead],
  );

  const copySelected = useCallback(() => {
    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const selectedResult =
        upToDateTypeaheadResults[
          isMouseActive ? hoveredIdx ?? selectedIdx : selectedIdx
        ];
      if (selectedResult?.copyResult) {
        selectedResult.copyResult();
      }
    });
  }, [getMostUpToDateTypeaheadResults, hoveredIdx, isMouseActive, selectedIdx]);

  const addToStack = useCallback(() => {
    getMostUpToDateTypeaheadResults((upToDateTypeaheadResults) => {
      const selectedResult = upToDateTypeaheadResults[selectedIdx];
      if (!selectedResult) return;
      const typeaheadResult = typeaheadResults.find(
        (result) => result.uuid === selectedResult.uuid,
      );
      if (!typeaheadResult) return;

      const params = getAddToStackParams(typeaheadResult);
      if (!params) return;

      showStackChooserModal({
        metadata_title: params.title,
        url: params.url,
        callback: (stack: stacks.Stack | undefined) => {
          papLogAddToStack(typeaheadResult, stack);
        },
      });
    });
  }, [
    getMostUpToDateTypeaheadResults,
    selectedIdx,
    typeaheadResults,
    showStackChooserModal,
    papLogAddToStack,
  ]);

  const suggestedQueryResultIndex = searchResults.findIndex(
    (result) =>
      result.scoredResult.type === typeahead.ResultType.SuggestedQuery,
  );
  const suggestedQueryScoredResult = searchResults[suggestedQueryResultIndex]
    ?.scoredResult as typeahead.ScoredSuggestedQuery;
  const searchQuery = useCallback<Handler>(() => {
    if (
      !!canShowTypeahead &&
      typeaheadQuery.length > 0 &&
      !!suggestedQueryScoredResult
    ) {
      // set the selected index to the suggested query result so autocomplete reacts appropriately
      setSelectedIdx(suggestedQueryResultIndex);
      handleTaggedSearchSubmit(suggestedQueryScoredResult, true);
      papLogResultOpen(suggestedQueryScoredResult, 'enter');
    }
  }, [
    handleTaggedSearchSubmit,
    canShowTypeahead,
    papLogResultOpen,
    suggestedQueryResultIndex,
    suggestedQueryScoredResult,
    typeaheadQuery.length,
  ]);

  const openTypeahead = useCallback(
    (newQuery: string) => {
      setTypeaheadQuery(newQuery);
      setCanShowTypeahead(true);
      extendSearchSession('select_search_input', newQuery);
    },
    [extendSearchSession, setCanShowTypeahead, setTypeaheadQuery],
  );

  const keyHandlers = useMemo<Handlers<'typeahead'>>(
    () => ({
      moveUp,
      moveDown,
      launchResult: launchResultKeyboardHandler,
      summarize: summarizeKeyboardHandler,
      searchQuery,
      clearInput: (e) => {
        if (typeaheadQuery) {
          preventBubble(e);
          openTypeahead('');
        } else if (canShowTypeahead) {
          preventBubble(e);
          setCanShowTypeahead(false);
        }
      },
      copySelected,
      addToStack,
    }),
    [
      copySelected,
      canShowTypeahead,
      launchResultKeyboardHandler,
      summarizeKeyboardHandler,
      moveDown,
      moveUp,
      openTypeahead,
      searchQuery,
      setCanShowTypeahead,
      typeaheadQuery,
      addToStack,
    ],
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // This enables users to quickly launch the first item after foregrounding app
      if (userHasNotInputKey && event.key === KeyCodes.enter) {
        launchSelectedMainContentItem();
      }
      setUserHasNotInputKey(false);

      // Reset selected item when user starts typing or tabbing
      if (event.key !== KeyCodes.arrowDown) {
        resetSelectedMainContentItem();
      }

      registerHotkeys({
        event,
        keyHandlers,
        hotkeyMapName: 'typeahead',
        platform: EnvCtx.platform,
      });
    },
    [
      keyHandlers,
      resetSelectedMainContentItem,
      setUserHasNotInputKey,
      userHasNotInputKey,
    ],
  );

  const handleQueryChange = (query: string) => {
    openTypeahead(query);
  };

  const onTypeaheadResultView = useCallback(
    (item: TypeaheadResult) => {
      const result = typeaheadResults.find((r) => r.uuid === item.uuid);
      const position =
        typeaheadResults.findIndex((r) => r.uuid === item.uuid) + 1;
      if (!result) return;

      // XXX: shown results are for _all_ typeahead results -- currently filtering
      // on matching locally cached server results and not previous queries, etc.
      if ('id3p' in result.result) logAMISResultShown(result.result);
      if (!papLogResultShown) return;
      papLogResultShown(result);
      captureTypeaheadImpressionMetrics(metrics, item, position);
    },
    [logAMISResultShown, papLogResultShown, typeaheadResults],
  );

  useLayoutEffect(() => {
    if (openTypeaheadListener) {
      openTypeahead('');
      searchHeaderInputRef.current?.focus();
      setOpenTypeaheadListener(false);
    }
  }, [
    openTypeahead,
    openTypeaheadListener,
    setOpenTypeaheadListener,
    setTypeaheadQuery,
  ]);

  const isTypeaheadOpen = Boolean(
    canShowTypeahead && (searchResults.length || activationType),
  );

  return (
    // This key handler is not a user-interactable element.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onKeyDown={handleKeyDown}
      onBlur={() => {
        // close handled by outside click, this closes typeahead when tabbing
        if (isMouseActive) return;
        setCanShowTypeahead(false);
      }}
      className={classNames(styles.searchContainer, {
        [styles.fullWidthSearchbar]: desktopExperience,
        [styles.typeaheadOpen]: isTypeaheadOpen,
        [styles.augustRevision]: augustRevisionEnabled,
        [styles.showBackground]: showBackground,
        [styles.scrollingBorder]: pageScrolled && desktopExperience,
      })}
      data-testid="TopNav-searchBar"
    >
      {desktopExperience && (
        <div
          className={classNames(styles.searchDesktopSpacer, {
            [styles.showBackground]: showBackground,
          })}
        />
      )}
      <ClickOutside
        className={styles.clickOutside}
        isActive={isTypeaheadOpen}
        onClickOutside={() => {
          setCanShowTypeahead(false);
        }}
        isBlock
        isClickThroughPortaled={false}
        shouldPropagateMouseEvents={false}
      >
        <SearchHeader
          query={typeaheadQuery}
          onQueryUpdate={handleQueryChange}
          ref={searchHeaderRef}
          inputRef={searchHeaderInputRef}
          typeaheadIsOpen={isTypeaheadOpen}
          onClick={handleSearchBarClick}
          shouldFocusOnRender={shouldFocusOnRender}
          onCloseTypeahead={() => setCanShowTypeahead(false)}
          onShouldBoostSearchToTop={setShouldSearchBeBoosted}
          onOpenConnectors={openConnectors}
          fullWidth={fullWidth}
          isDarwin={platform === 'darwin'}
          animatePlaceholder={!isMobileSize}
          placeholderTexts={[
            `"${i18n.t('search_placeholder_prompt_1')}"`,
            `"${i18n.t('search_placeholder_prompt_2')}"`,
            `"${i18n.t('search_placeholder_prompt_3')}"`,
            `"${i18n.t('search_placeholder_prompt_4')}"`,
            `"${i18n.t('search_placeholder_prompt_5')}"`,
          ]}
          selectedIdx={selectedIdx}
          searchResults={searchResults}
          shouldSearchBeBoosted={shouldSearchBeBoosted}
          isStartPage={pathname === '/'}
        />
        <TypeaheadOverlay
          open={isTypeaheadOpen}
          anchor={searchHeaderRef}
          query={typeaheadQuery}
          results={searchResults}
          selectedIdx={selectedIdx}
          setSelectedIdx={setSelectedIdx}
          setHoveredIdx={setHoveredIdx}
          onResultIndexClicked={launchResultClickHandler}
          onListItemView={onTypeaheadResultView}
          isMouseActive={isMouseActive}
          activationType={activationType}
          fullWidth={fullWidth}
          setTypeaheadOpen={setCanShowTypeahead}
          disableActivation={pathname === '/settings/apps'}
          isAugustStartPageEnabled={isAugustStartPageEnabled}
        />
      </ClickOutside>
      {/* Portal the debug card to body so that it shows up over the search results card if it is showing */}
      {isTypeaheadOpen &&
        createPortal(
          <DebugCard typeaheadResults={typeaheadResults} />,
          document.body,
        )}
    </div>
  );
}

// Used to generate a string of uuids for react dep arrays to see if results have changed
function hasResultsChanged(results: TypeaheadResult[]): string {
  // match uuid and title because the default result needs to update when the query changes
  return results.reduce((acc, result) => acc + result.uuid + result.title, '');
}

function handleNext(current: number, numResults: number): number {
  return (current + 1) % numResults;
}

function handlePrev(current: number, numResults: number): number {
  return (current - 1 + numResults) % numResults;
}
